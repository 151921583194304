import { mapActions, mapGetters, mapMutations } from 'vuex'
import {EventBus} from "@common/lib/event-bus";

export const subscriptionPlanMixin = {
  computed: {
    ...mapGetters(['getPlan', 'getWorkspaces', 'getProfile']),
    isStarterPlan() {
      if (
        this.getPlan.subscription &&
        this.getPlan.subscription.slug
      ) {
        const name = this.getPlan.subscription.slug
        return name === 'contentstudio-starter-lifetime'
      }
      return false
    },
  },
  methods: {
    ...mapActions(['fetchPlan', 'limitReached']),
    ...mapMutations(['SET_PLAN_VIEW_LOADER']),
    getPricingImagePath(imageName) {
      return require('@src/assets/img/modules/billing/plans/' + imageName)
    },
    async Plan() {
      console.log("Method::Plan-s")
      await this.fetchPlan()
      await this.identify()
      // this.SET_PLAN_VIEW_LOADER(false)
    },
    checkSubscriptionLimits(limitType) {
      console.debug('Method::checkSubscriptionLimits', limitType)
      if (
        this.getPlan.used_limits &&
        this.getPlan.subscription &&
        this.getPlan.subscription.limits
      ) {
        switch (limitType) {
          case 'members':
            if (
              this.getPlan.used_limits.members >=
              this.getPlan.subscription.limits.members
            ) {
              this.showUpgradeModal()
              return true
            }
            break
          case 'blogs':
            if (
              this.getPlan.used_limits.blogs >=
              this.getPlan.subscription.limits.blogs
            ) {
              this.showUpgradeModal()
              return true
            }
            break
          case 'profiles':
            if (
              this.getPlan.used_limits.profiles >=
              this.getPlan.subscription.limits.profiles
            ) {
              this.showUpgradeModal()
              return true
            }
            break

          case 'workspaces':
            if (
              this.getPlan.used_limits.workspaces >=
              this.getPlan.subscription.limits.workspaces
            ) {
              this.showUpgradeModal()
              return true
            }
            break

          case 'topics':
            if (
              this.getPlan.used_limits.topics >=
              this.getPlan.subscription.limits.topics
            ) {
              this.showUpgradeModal()
              return true
            }
            break

          case 'automations':
            if (
              this.getPlan.used_limits.automations >=
              this.getPlan.subscription.limits.automations
            ) {
              this.showUpgradeModal()
              return true
            }
            break
          default:
            break
        }
      }
      return false
    },
    showUpgradeModal() {
      this.alertMessage(
        'You have reached your plan limits, please upgrade your plan to continue.',
        'warn'
      )
      this.limitReached(true)
      if (this.hasPermission('can_see_subscription')) {
        EventBus.$emit('show-upgrade-modal')
      }
    },

    isTrialPlan() {
      if (
        this.getPlan.subscription &&
        this.getPlan.subscription.slug
      ) {
        if (this.getPlan.subscription.slug.includes('trial')) {
          return true
        }
      }
      return false
    },

    isOnLifetimePlan() {
      if (
        this.getPlan.subscription &&
        this.getPlan.subscription.slug
      ) {
        const name = this.getPlan.subscription.slug

        // for the prime lifetime deals (2022)
        if (name.includes('prime')) {
          return false
        }

        if (
          name.includes('lifetime') ||
          name.includes('jvzoo') ||
          name.includes('appsumo')
        ) {
          if (name.includes('bruce') || name.includes('starter')) {
            return false
          }
          return true
        }
      }
      return false
    },
    isInsightsAllowed() {
      if (this.getPlan.subscription && this.getPlan.subscription.slug) {
        const name = this.getPlan.subscription.slug
        if (name.includes('prime')) return this.getProfile.insights_unlocked
      }
      if (this.isOnLifetimePlan()) return this.getProfile.insights_unlocked
      return true
    },
  },
}
