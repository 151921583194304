import TikTokIcon from '@assets/img/integration/tiktok-rounded.svg'
import ThreadsIcon from '@assets/img/integration/threads-rounded.svg'
import WebFlowIcon from '@assets/img/integration/webflow.svg'
import ShopifyIcon from '@assets/img/integration/shopify.svg'

const plansComparison = {
  categories: [
    {
      name: 'Publishing & Scheduling',
      features: [
        {
          name: 'Workspaces',
          nameTooltip:
            'A workspace is like a separate space for each of the brands or clients you are working with. So, everything is organized in a much better way. For example, if you are managing 5 different clients, you can create 5 workspaces and each workspace can have its own social accounts, planner, and team members connected to it.',
          standard: { value: '1', tooltip: '' },
          advanced: {
            value: '2 ($10 per extra)',
            tooltip: '',
          },
          'agency-unlimited': {
            value: 'Unlimited workspaces',
            tooltip: '',
          },
        },
        {
          name: 'Social accounts',
          nameTooltip:
            'The number of social accounts/pages you can connect to ContentStudio. For example: 5 Twitter profiles, 3 Facebook pages, and 2 Instagram accounts would count as 10 social accounts in total.',
          standard: { value: '5', tooltip: '' },
          advanced: {
            value: '10 ($5 per extra)',
            tooltip: '',
          },
          'agency-unlimited': {
            value: '25 ($5 per extra)',
            tooltip: '',
          },
        },
        {
          name: 'Users',
          nameTooltip:
            'A user is an individual login who manages the social media account connected in a workspace.',
          standard: { value: '1 User', tooltip: '' },
          advanced: {
            value: '2 Users ($10 per extra)',
            tooltip: '',
          },
          'agency-unlimited': {
            value: 'Unlimited users',
            tooltip: '',
          },
        },
        {
          name: 'Posts per month',
          nameTooltip: 'The total number of posts you can schedule in a month.',
          standard: { value: 'Unlimited' },
          advanced: { value: 'Unlimited' },
          'agency-unlimited': { value: 'Unlimited' },
        },
        {
          name: 'Social networks',
          nameTooltip: '',
          standard: { value: 'integrated_social_networks' },
          advanced: { value: 'integrated_social_networks' },
          'agency-unlimited': { value: 'integrated_social_networks' },
          socialNetworks: [
            'facebook',
            'twitter',
            'instagram',
            'linkedin',
            'tumblr',
            'youtube',
            'pinterest',
            'google',
            'tiktok',
            'threads',
          ],
        },
        {
          name: 'Media storage',
          nameTooltip:
            'The total amount of media files you can store in your account.',
          standard: {
            value: '10 GB',
            tooltip: '',
          },
          advanced: {
            value: '15 GB',
            tooltip: 'Get 1 GB extra for every added user',
          },
          'agency-unlimited': {
            value: '25 GB',
            tooltip: 'Get 1 GB extra for every added user',
          },
        },
        {
          name: 'Publishing to blogs',
          nameTooltip:
            'Each WordPress blog, each Medium blog, each Tumblr blog, and so on, counts as one blog site.',
          standard: { value: false, tooltip: '' },
          advanced: {
            value: '3 blog sites ($5 per extra)',
            tooltip: '',
          },
          'agency-unlimited': {
            value: '10 blog sites ($5 per extra)',
            tooltip: '',
          },
        },
        {
          name: 'Blog networks',
          nameTooltip: '',
          standard: { value: false, tooltip: '' },
          advanced: { value: 'integrated_blog_networks' },
          'agency-unlimited': { value: 'integrated_blog_networks' },
          blogNetworks: ['wordpress', 'medium', 'shopify', 'webflow', 'tumblr'],
        },
        {
          name: 'Post customization per network',
          nameTooltip: 'Customize your posts for each social network.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'AI assistant',
          nameTooltip:
            'AI assistant helps you with content ideas, hashtags, and more. Access pre-made templates to automate workflows.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'AI text credits (words)',
          nameTooltip:
            'AI text credits are used for generating content ideas, captions, and more.',
          standard: {
            value: '10,000',
            tooltip:
              'Text credits are consumed per word generated. 1 credit equals 1 word, So 10,000 credits = 10,000 words',
          },
          advanced: {
            value: '20,000',
            tooltip:
              'Text credits are consumed per word generated. 1 credit equals 1 word, So 20,000 credits = 20,000 words',
          },
          'agency-unlimited': {
            value: '50,000',
            tooltip:
              'Text credits are consumed per word generated. 1 credit equals 1 word, So 50,000 credits = 50,000 words',
          },
        },
        {
          name: 'AI image credits',
          nameTooltip:
            'AI image credits are used for generating images for your posts.',
          standard: {
            value: '10',
            tooltip:
              'Each credit allows you to generate 1 AI image. 10 credits = 10 AI images',
          },
          advanced: {
            value: '10',
            tooltip:
              'Each credit allows you to generate 1 AI image. 10 credits = 10 AI images',
          },
          'agency-unlimited': {
            value: '20',
            tooltip:
              'Each credit allows you to generate 1 AI image. 20 credits = 20 AI images',
          },
        },
        {
          name: 'Best time to post recommendation',
          nameTooltip:
            'Find the best time to post for each social media platform, based on your unique activity and audience.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Hashtag suggestions',
          nameTooltip:
            'Get hashtag suggestions based on your content and audience.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Recurring posts',
          nameTooltip: 'Schedule posts to repeat at set intervals.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Labels',
          nameTooltip: 'Organize your posts with labels.',
          standard: { value: 'Unlimited' },
          advanced: { value: 'Unlimited' },
          'agency-unlimited': { value: 'Unlimited' },
        },
        {
          name: 'Canva & VistaCreate integration',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Feedly integration',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Third party integrations (PostNitro, Bit.Ly, Pocket, Replug)',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'X (Twitter) custom app integration',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'EasyConnect',
          nameTooltip:
            "Connect all your client's social accounts via a secure EasyConnect link",
          standard: { value: false },
          advanced: { value: false },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Gmail signin',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Custom link shortener',
          nameTooltip: 'Use your own custom domain to shorten links.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Custom video thumbnails',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Auto first comment',
          nameTooltip:
            'Schedule first comment that gets posted automatically on your Instagram, Facebook page posts or Youtube videos.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Evergreen campaigns for automated posting',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Auto-posting via RSS Feeds',
          nameTooltip:
            'Automatically post content from your favorite websites.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Curated content automation campaigns',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Bulk upload via CSV',
          nameTooltip: 'Upload multiple posts at once using a CSV file.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
      ],
    },
    {
      name: 'Planning & Collaboration',
      features: [
        {
          name: 'Calendar view',
          nameTooltip: 'View your scheduled posts in a calendar format.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Shareable post link',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'List view',
          nameTooltip: 'View your scheduled posts in a list format.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Feed view',
          nameTooltip: 'View your scheduled posts in a feed format.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Instagram grid view',
          nameTooltip: 'View your scheduled posts in an Instagram grid format.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Instagram collaboration post',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'TikTok grid view',
          nameTooltip: 'View your scheduled posts in a TikTok grid format.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Approval workflow system',
          nameTooltip:
            'Get your posts approved by your team members or clients. Add comments & feedback notes to collaborate effectively.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Invite clients/team',
          nameTooltip:
            'Invite your clients or team members to collaborate on your social media content.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Approver or client dashboard',
          nameTooltip: 'Give your clients access to view and approve posts.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
      ],
    },
    {
      name: 'Analytics & Reporting',
      features: [
        {
          name: 'Performance overview',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Individual network analytics',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Historical data',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Demographics insights',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Usermaven web analytics dashboard',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Boosted post insights',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Labels & campaigns analytics',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Competitor benchmark analytics',
          nameTooltip:
            'Compare your social media performance with your competitors.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Export reports in PDF',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Schedule reports via email',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Whitelabel reports',
          nameTooltip: 'Customize your reports with your own branding.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
      ],
    },
    {
      name: 'Social Inbox',
      features: [
        {
          name: 'Monitor and reply to incoming messages',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Monitor and reply to comments on posts',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Inbox integration on mobile app (iOS)',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Team assignment',
          nameTooltip: 'Assign messages to your team members.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Tagging & labels',
          nameTooltip: 'Tag and label your messages for better organization.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Saved replies',
          nameTooltip: 'Save replies to common questions for quick responses.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Internal notes',
          nameTooltip:
            'Add internal notes to messages for better collaboration.',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
      ],
    },
    {
      name: 'Content & Influencer Discovery',
      features: [
        {
          name: 'Search relevant trending content',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Saved topics for filtered curated content',
          standard: { value: false },
          advanced: { value: 'Unlimited' },
          'agency-unlimited': { value: 'Unlimited' },
        },
        {
          name: 'Global shared folder in media library',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Curated quotes',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Filter content by location',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Content insights for research & planning',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Find X (Twitter) influencers',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Find Instagram influencers',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Save & export list of influencers',
          standard: { value: false },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
      ],
    },
    {
      name: 'Essentials & Support',
      features: [
        {
          name: 'iOS mobile app',
          nameTooltip:
            'Manage your social media on the go with the ContentStudio mobile app for iOS.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Android mobile app',
          nameTooltip:
            'Manage your social media on the go with the ContentStudio mobile app for Android.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Chrome extension',
          nameTooltip: 'Schedule and share content directly from your browser.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Self-service knowledgebase',
          nameTooltip: 'Access our knowledgebase for self-service support.',
          standard: { value: true },
          advanced: { value: true },
          'agency-unlimited': { value: true },
        },
        {
          name: 'Live training',
          standard: { value: 'On-demand' },
          advanced: { value: 'On-demand' },
          'agency-unlimited': {
            value: '2 hours onboarding & training session with full team',
          },
        },
        {
          name: 'Customer support',
          standard: { value: 'Standard' },
          advanced: { value: 'Standard' },
          'agency-unlimited': { value: 'Priority' },
        },
      ],
    },
  ],
}

const socialIconMap = {
  facebook: 'fab fa-facebook',
  twitter: 'fab fa-twitter',
  instagram: 'fab fa-instagram',
  linkedin: 'fab fa-linkedin',
  youtube: 'fab fa-youtube',
  pinterest: 'fab fa-pinterest',
  google: 'fab fa-google',
  tiktok: TikTokIcon,
  threads: ThreadsIcon,
  tumblr: 'fab fa-tumblr',
}

const blogIconMap = {
  wordpress: 'fab fa-wordpress',
  medium: 'fab fa-medium',
  shopify: ShopifyIcon,
  tumblr: 'fab fa-tumblr',
  webflow: WebFlowIcon,
}

// Icon background colors
const socialIconColors = {
  facebook: '#0078FF',
  twitter: '#101010',
  instagram: '#bc338f',
  linkedin: '#007bb6',
  youtube: '#FF0000',
  pinterest: '#cb2027',
  google: '#4756b2',
  tiktok: '#000000',
  threads: '#000000',
  tumblr: '#32506d',
}

const blogIconColors = {
  wordpress: '#000d21',
  medium: '#000d21',
  shopify: '#95BF47',
  tumblr: '#32506d',
}

const getIconStyle = (network) => ({
  background: socialIconColors[network] || '#e3e8eb',
})

const getBlogIconStyle = (network) => ({
  background: blogIconColors[network] || '#e3e8eb',
})

export {
  plansComparison,
  socialIconMap,
  blogIconMap,
  getBlogIconStyle,
  getIconStyle,
}
