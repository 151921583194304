import { computed } from 'vue'
import {
  WORKSPACE_ROLES,
  WORKSPACE_MEMBER_TYPES,
} from '@common/constants/composer'
import { useStore } from '@/src/store/base'

export default function usePermissions() {
  const store = useStore()

  const getLoggedUser = computed(() => {
    const workspace = store?.getters?.getActiveWorkspace
    const user = store?.getters?.getProfile?._id
    let member = null
    // finding member from states
    if (user && workspace._id && workspace.members)
      member = workspace.members.find((item) => {
        return item.user_id === user
      })
    return member
  })

  const getUserRole = computed(() => {
    const user = getLoggedUser.value
    return user ? getMutatedUserRole(user.role) : ''
  })

  const getUserRoleClass = computed(() => {
    const user = getLoggedUser.value
    return user ? getRoleClass(user.role) : ''
  })

  const getTeamMembership = computed(() => {
    const user = getLoggedUser.value
    if (user) {
      if (user.role === 'super_admin') return 'team'
      if (user.membership) return user.membership
    }
    return 'team'
  })

  const isEnableGlobalCategoryOption = computed(() => {
    console.log('isEnableGlobalCategoryOption', getLoggedUser.value)
    if (getLoggedUser.value?.role) {
      return (
        getLoggedUser.value.role === WORKSPACE_ROLES.SUPER_ADMIN ||
        (getLoggedUser.value.role === WORKSPACE_ROLES.ADMIN &&
          getLoggedUser.value.membership === WORKSPACE_MEMBER_TYPES.TEAM)
      )
    }
    return false
  })

  function getMutatedUserRole(role) {
    if (role === WORKSPACE_ROLES.SUPER_ADMIN) return 'Super Admin'
    if (role === WORKSPACE_ROLES.ADMIN) return 'Admin'
    if (role === WORKSPACE_ROLES.COLLABORATOR) return 'Collaborator'
    if (role === WORKSPACE_ROLES.APPROVER) return 'Approver'
    return ''
  }

  function getRoleClass(role) {
    if (role === WORKSPACE_ROLES.SUPER_ADMIN) return 'text-orange-500'
    if (role === WORKSPACE_ROLES.ADMIN) return 'text-blue-500'
    if (role === WORKSPACE_ROLES.COLLABORATOR) return 'text-purple-500'
    if (role === WORKSPACE_ROLES.APPROVER) return 'text-green-500'
    return ''
  }

  function hasPermission(action, owner = false, postStatus = 'draft') {
    const user = getLoggedUser.value

    if (!user && action === 'can_save_workspace') return true
    if (user) {
      // if owner then allowed
      if (owner && owner === user.user_id) return true
      if (user.role === 'super_admin') return true
      if (user.role === 'admin') {
        switch (action) {
          case 'can_see_subscription':
            return user.permissions && user.permissions.hasBillingAccess
          case 'can_see_development':
            return false
        }
        return true
      }
      if (user.role === 'collaborator') {
        switch (action) {
          case 'can_save_blog':
            return !!(user.permissions && user.permissions.addBlog)
          case 'can_save_social':
            return !!(user.permissions && user.permissions.addSocial)
          case 'can_save_source':
            return !!(user.permissions && user.permissions.addSource)
          case 'can_save_topic':
            return !!(user.permissions && user.permissions.addTopic)
          case 'can_view_team':
            return !!(user.permissions && user.permissions.viewTeam)
          case 'can_schedule_plan':
          case 'can_edit_post':
          case 'can_schedule_automation_plan':
            return !(user.permissions && user.permissions.postsReview)
          case 'can_reschedule_plan':
            return !!(user.permissions && user.permissions.rescheduleQueue)
          case 'can_change_fb_group_publishing_method':
            return !(
              user.permissions &&
              // eslint-disable-next-line no-prototype-builtins
              user.permissions.hasOwnProperty('changeFBGroupPublishAs') &&
              user.permissions.changeFBGroupPublishAs === false
            )

          case 'can_access_workspace_setting':
            return true
          case 'can_access_top_header':
            return true
          case 'can_use_bulk_operations':
            return true
          case 'can_use_actions':
            return true
          case 'can_save_workspace':
            return store.getters.getProfile.subscription_name !== 'No Package'
          case 'can_use_email_notification_status':
            return false
        }
      }
      if (user.role === 'approver') {
        switch (action) {
          case 'can_edit_post':
            return (
              user?.permissions?.approverCanEditPost &&
              postStatus === 'reviewed'
            )
        }
        return false
      }
    }

    return false
  }

  function hasRoutePermission(routeName) {
    const user = getLoggedUser.value
    if (user) {
      if (user.role === WORKSPACE_ROLES.APPROVER) {
        switch (routeName) {
          case 'feed_view':
            return true
          case 'list_plans':
            return true
          case 'calender_plans':
            return true
          case 'notifications':
            return true
          case 'profile':
            return true
          case 'grid_tiktok':
            return true
          case 'grid_instagram':
            return true
          case 'setPassword':
            return true
          default:
            return false
        }
      }
    }
    return true
  }

  function canViewUpgradeOffer() {
    console.log('method: canViewUpgradeOffer')
    const user = getLoggedUser.value
    return !!(user && user.role.includes(WORKSPACE_ROLES.SUPER_ADMIN))
  }

  function isPlanUpgradeable() {
    const listOfPlans = [
      'business-large',
      'lifetime',
      'contentstudio-business-small-lifetime',
      'contentstudio-business-medium-lifetime',
      'contentstudio-bundle-lifetime',
      'business-agency-large-annual-custom',
      'business-agency-small-bf',
      'business-large-annual',
      'jvzoo_agency_upgrade',
      'contentstudio-enterprise-yearly-plan',
      'bruce-contentstudio-large-agency-bee-lifetime',
      'bruce-contentstudio-pro-lifetime',
      'bruce-contentstudio-business-agency-small-lifetime',
      'bruce-contentstudio-business-agency-medium-lifetime',
      'bruce-contentstudio-business-agency-large-lifetime',
      'contentstudio-covid-professional-lifetime',
      'contentstudio-covid-business-agency-small-lifetime',
      'contentstudio-covid-business-agency-medium-lifetime',
      'contentstudio-covid-business-agency-large-lifetime',
      'contentstudio-covid-professional-with-addons-lifetime',
      'contentstudio-starter-lifetime-unlimited-automations',
        // new plans
      'standard-monthly',
      'standard-annually',
      'advanced-monthly',
      'advanced-annually',
      'agency-unlimited-annually',
      'agency-unlimited-monthly'
    ]
    if (store?.getters?.getPlan?.subscription?.slug) {
      if (
          listOfPlans.indexOf(
              store.getters.getPlan.subscription.slug
          ) >= 0
      ) {
        return false
      }
    }
    return true
  }

  function isTrialPlan() {
    return store?.getters?.getPlan?.subscription?.slug?.includes('trial') || false;
  }

  /**
   * Hide Vista for Alphagraphics users
   * @returns {boolean}
   */
  function hideVista() {
    return store.getters.getProfile?.email
      ?.toLowerCase()
      ?.includes('@alphagraphics.com')
  }

  return {
    getLoggedUser,
    getUserRole,
    getUserRoleClass,
    getTeamMembership,
    isEnableGlobalCategoryOption,
    hasPermission,
    hasRoutePermission,
    canViewUpgradeOffer,
    isPlanUpgradeable,
    isTrialPlan,
    hideVista,
  }
}
