import proxy from '@common/lib/http-common'
import { confirmation } from '@common/store/mutation-types'
import {
  DEVICE_NOTIFICATION_SETTING_SAVING_ERROR,
  UNKNOWN_ERROR
} from '@common/constants/messages'
import { instagram } from '@src/modules/integration/store/states/mutation-types'
import { removeDeviceUrl } from '@src/modules/planner/config/api-utils'
import {
  changeInstagramNotificationDevicesURL,
  removeInstagramExternalIntegrationURL
} from '@src/modules/integration/config/api-utils'

const getDefaultInstagramAccountsState = () => {
  return {
    items: [],
    all_items: []
  }
}

export default {
  state: {
    accounts: getDefaultInstagramAccountsState(),
    notification_devices: {},
    connector_account: {}
  },
  getters: {
    getInstagramAccounts: (state) => state.accounts,
    getInstagramNotificationDevices: (state) => state.notification_devices,
    getInstagramConnectorAccount: (state) => state.connector_account,
    getInstagramIds: (state) => {
      return state.accounts.items.map((item) => item.instagram_id)
    }
  },
  actions: {
    async removeNotificationDevice (
      { commit, getters, rootGetters, dispatch },
      payload
    ) {
      console.debug('Method:removeNotificationDevice', payload)

      return proxy
        .post(removeDeviceUrl, payload)
        .then((response) => {
          if (response.data.status === true) {
            commit(confirmation.SET_STAGE, 'success')
            dispatch('toastNotification', {
              message: 'Device has been removed.',
              type: 'success'
            })
            getters.getMobileDevices.forEach(function (device, index) {
              if (device.device_id === payload.device_id) {
                getters.getMobileDevices.splice(index, 1)
                return false
              }
            })
            return true
          }
          dispatch('toastNotification', {
            message: response.data.message
              ? response.data.message
              : 'Unable to remove device.',
            type: 'error'
          })
          commit(confirmation.SET_STAGE, 'fail')
          return false
        })
        .catch((error) => {
          console.debug('Exception in removeNotificationDevice', error)
          commit(confirmation.SET_STAGE, 'fail')
          return false
        })
    },

    async changeInstagramNotificationDevices ({
      commit,
      getters,
      rootGetters,
      dispatch
    }) {
      console.debug('Action::changeInstagramNotificationDevices')

      return proxy
        .post(changeInstagramNotificationDevicesURL, {
          disabled_devices:
            getters.getInstagramNotificationDevices.disabled_devices,
          instagram_id: getters.getInstagramNotificationDevices.instagram_id,
          workspace_id: getters.getWorkspaces.activeWorkspace._id
        })
        .then((response) => {
          if (response.data.status === true) {
            getters.getInstagramAccounts.items.forEach(function (
              account,
              index
            ) {
              if (
                account.instagram_id ===
                getters.getInstagramNotificationDevices.instagram_id
              ) {
                getters.getInstagramAccounts.items[index].disabled_devices =
                  getters.getInstagramNotificationDevices.disabled_devices
                return false
              }
            })
            return true
          } else {
            dispatch('toastNotification', {
              message: DEVICE_NOTIFICATION_SETTING_SAVING_ERROR,
              type: 'error'
            })
            return false
          }
        })
        .catch((error) => {
          console.debug(
            'Exception in changeInstagramNotificationDevices',
            error
          )
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return false
        })
    },

    /**
     * Removing external integration from Instagram account
     * @param commit
     * @param getters
     * @param rootGetters
     * @param dispatch
     * @param payload[
     * platform_identifier (string) actual id of the instagram account
     * workspace_id (string) id of the workspace
     * ]
     * @returns {Promise<*>}
     */
    async removeInstagramExternalIntegration (
      { commit, getters, rootGetters, dispatch },
      payload
    ) {
      console.debug('Action:removeInstagramExternalIntegration', payload)

      return proxy
        .post(removeInstagramExternalIntegrationURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            // changing status of the integration from states
            dispatch('toastNotification', {
              message: 'Integration has been removed.',
              type: 'success'
            })
            const items = getters.getInstagramAccounts.items
            const index = items.findIndex((item) => {
              return item.instagram_id === payload.platform_identifier
            })
            if (index > -1) items[index].linked_to = payload.integrations
            return true
          }
          dispatch('toastNotification', {
            message: response.data.message
              ? response.data.message
              : 'Unable to remove integration.',
            type: 'error'
          })
          return false
        })
        .catch((error) => {
          console.debug(
            'Exception in removeInstagramExternalIntegration',
            error
          )
          return false
        })
    }
  },
  mutations: {
    [instagram.SET_ACCOUNTS] (state, account) {
      state.accounts.items = account.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    },

    [instagram.SET_ALL_ACCOUNTS] (state, account) {
      state.accounts.all_items = account.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    },

    [instagram.SET_NOTIFICATION_DEVICES] (state, devices) {
      if (!devices.hasOwnProperty('disabled_devices')) {
        devices.disabled_devices = []
      }
      state.notification_devices = devices
    },

    [instagram.SET_CONNECTOR_ACCOUNT] (state, account) {
      state.connector_account = account
    },

    [instagram.SET_DEFAULT] (state) {
      state.accounts = getDefaultInstagramAccountsState()
      state.notification_devices = {}
      state.connector_account = {}
    },
    [instagram.SET_LOCATION] (state, payload) {
      const { accountId, location } = payload
      const account = state.accounts.all_items.find(acc => acc._id === accountId)
      if (account) {
        account.default_location = location
      }
      const itemAccount = state.accounts.items.find(acc => acc._id === accountId)
      if (itemAccount) {
        itemAccount.default_location = location
      }
    }
  }
}
