<template>
  <div class="basic_form">
    <div class="field_group field_no_label">
      <input
        id="ws-name"
        ref="workspace_name_modal"
        v-model="getWorkspaceDetails.name"
        class="relative z-10"
        data-cy="workspace_name"
        type="text"
        placeholder="Workspace Name"
        maxlength="35"
        @keydown="fetchWorkspaceLogoSuggessions($event)"
      />
      <label for="ws-name" class="label-animated z-0">Workspace Name - e.g. Nike</label>
    </div>

    <div class="field_group">
      <label for="super-admin">Set Workspace Time Zone</label>
      <div
        class="timezone_search_dropdown social_dropdown dropdown default_style_dropdown"
      >
        <div
          data-toggle="dropdown"
          class="dropdown_header d-flex align-items-center"
          data-cy="create_workspace_timezone_clickable"
        >
          <div class="text d-inline-block align-middle">{{
            getWorkspaceDetails.timezone
          }}</div>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>
        <div
          class="dropdown-menu platform_filters_dropdown_inner dropdown-menu-left"
          data-cy="create_workspace_timezone"
        >
          <ul class="inner">
            <div class="search_input with_icon_right">
              <div class="search_inner w-100">
                <input
                  id="search_timezone"
                  v-model="search_timezone"
                  type="text"
                  placeholder="Search by name"
                  data-cy="search-timezone"
                />
              </div>
            </div>

            <template v-if="getFilteredTimezones.length">
              <template v-for="(timezone, key) in getFilteredTimezones" :key="key">
                <li
                  class="list_item_li"
                  data-cy="timezone-selection"
                  @click.prevent="changeTimezone(timezone.value)"
                >
                  {{ timezone.name }}
                </li>
              </template>
            </template>

            <li v-if="!getFilteredTimezones.length" class="no_text_li">
              No Results found
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Set Super Admin Field -->
    <div class="field_group">
      <label for="super-admin">Set Super Admin</label>
      <div
        class="timezone_search_dropdown social_dropdown dropdown default_style_dropdown"
      >
        <div
          data-toggle="dropdown"
          :class="{
            disabled: this.otherWorkspacesSuperAdmins.length === 0,
          }"
          class="dropdown_header d-flex align-items-center"
          data-cy="create_workspace_super_admin_clickable"
        >
          <div class="text d-inline-block align-middle">{{
            selectedSuperAdmin.full_name +
            ' - ' +
            selectedSuperAdmin.email
          }}</div>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>
        <div
          class="dropdown-menu platform_filters_dropdown_inner dropdown-menu-left"
          data-cy="create_workspace_timezone"
        >
          <ul class="inner">
              <template
                v-for="(
                  superAdmin, key
                ) in this.otherWorkspacesSuperAdmins"
                :key="key"
              >
                <li
                  v-if="isActiveUser(superAdmin)"
                  class="list_item_li"
                  @click.prevent="changeSuperAdmin(superAdmin, false)"
                >
                  {{
                    superAdmin.firstname + ' ' + superAdmin.lastname+ ' - ' + superAdmin.email
                  }}
                </li>
                <li v-else class="disabled_super_admin list_item_li">
                  {{
                    superAdmin.firstname + ' ' + superAdmin.lastname+ ' - ' + superAdmin.email
                  }}
                  <span>{{ getUserState(superAdmin.state) }}</span>
                </li>
              </template>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label for="">Workspace Logo</label>
        <div class="upload_logo">
          <div class="image_upload">
            <div
              class="upload_section"
              :class="{
                loader_overlay_with_loader:
                  getSettingLoaders.uploadWorkspaceLogo,
              }"
            >
              <!--// for real dummy image -->
              <div v-if="!getWorkspaceDetails.logo" class="img">
                <img
                  class="picture"
                  src="../../../../assets/img/settings/leaf_upload_image.svg"
                  alt=""
                />
              </div>
              <!--// for real image -->
              <div v-else class="img">
                <img class="picture" :src="getWorkspaceDetails.logo" alt="" />
              </div>

              <clip-loader
                v-if="getSettingLoaders.uploadWorkspaceLogo"
                class="center_loader"
                color="#436aff"
                :size="'16px'"
              ></clip-loader>
              <div
                v-else
                data-cy="workspace_logo"
                :class="{ image_default: !getWorkspaceDetails.logo }"
                class="upload_btn_block"
                @click="triggerWorkspaceChangeLogo('w_logo_image_new')"
              >
                <input
                  id="w_logo_image_new"
                  type="file"
                  autocomplete="off"
                  accept="image/*"
                  @change.prevent="uploadWorkspaceLogo($event, true)"
                />
                <i class="icon-Camera"></i>
                <p v-if="!getWorkspaceDetails.logo">Upload</p>
                <p v-else>Change</p>
              </div>
            </div>
            <p>Recommended size: Less than 2MB (130px x 130px)</p>
          </div>
        </div>
      </div>
      <div
        v-if="
          getWorkspaceDetails.logoSuggestions.length ||
          getWorkspaceLogoSuggestionsLoader
        "
        class="col-md-8"
      >
        <label for="">Logo Suggestions</label>
        <div class="clearfix"></div>
        <div
          class="workspace_logo_suggestions"
          data-cy="workspace_logo_suggestions"
        >
          <div class="logo_suggestions_inner">
            <ul>
              <li
                v-if="getWorkspaceLogoSuggestionsLoader"
                class="loader_overlay_with_loader"
              >
                <clip-loader :color="'#5c77f8'" :size="'16px'"></clip-loader>
              </li>
              <li
                v-for="(
                  suggestion, index
                ) in getWorkspaceDetails.logoSuggestions"
                v-else
                :key="index"
                class=""
                data-cy="workspace_logo_suggestion"
                @click="SET_WORKSPACE_LOGO(suggestion.logo)"
              >
                <img :src="suggestion.logo" alt="" />
              </li>
            </ul>
          </div>

          <p v-if="getWorkspaceLogoSuggestionsLoader === false"
            >Click on one of the logos above to set as the Workspace logo.</p
          >
        </div>
      </div>
    </div>

    <div class="text-center">
      <button
        data-cy="submit_workspace"
        class="btn btn-studio-theme-space"
        :disabled="getSettingLoaders.saveWorkspace"
        @click.prevent="saveWorkspace(true, true)"
      >
        <span>Save</span>
        <clip-loader
          v-if="getSettingLoaders.saveWorkspace"
          :color="'#ffffff'"
          :size="'16px'"
        ></clip-loader>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { timezones } from '@src/modules/setting/config/timezone.js'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'
import useWorkspace from '@/src/modules/common/composables/useWorkspace';

export default {
  components: {},
  setup(){
    const { filteredWorkspacesOwnedByMe, fetchWorkspacesSuperAdmins} = useWorkspace()
    return {
      filteredWorkspacesOwnedByMe,
      fetchWorkspacesSuperAdmins
    }
  },
  data () {
    return {
      timezones,
      slugError: false,
      manuallySlugUpdated: false,
      search_timezone: '',
      selectedSuperAdmin: {
        full_name: '',
        email: '',
        id: ''
      },
      otherWorkspacesSuperAdmins: []
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaceDetails',
      'getSettingLoaders',
      'getWorkspaceLogoSuggestionsLoader',
      'getSlugAvailableLoader',
      'getWorkspaceSlugValidation',
      'getProfile'
    ]),
    getFilteredTimezones () {
      return this.timezones.filter((s) =>
        s.value.toLowerCase().includes(this.search_timezone.toLowerCase())
      )
    }
  },

  created () {},
  async mounted () {

    // Add this block to fetch other workspaces super admins
    try {
      this.otherWorkspacesSuperAdmins = await this.fetchWorkspacesSuperAdmins();
    } catch (error) {
      console.error('Failed to fetch other workspaces super admins:', error)
    }

    this.fetchWorkspaceLogoSuggessions('social media')
    if (!this.getWorkspaceDetails.name && !this.getWorkspaceDetails.slug) {
      this.$store.commit(
        workspaceTypes.SET_WORKSPACE_TIMEZONE,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    }

    // set Selected Super Admin
    this.$nextTick(() => {

      if (!this.isNoSubscription() && !this.isProfileUserTrialFinished() && this.getProfile.role === 'super_admin') {
        this.selectedSuperAdmin.full_name = this.getProfile.full_name
        this.selectedSuperAdmin.email = this.getProfile.email
        this.selectedSuperAdmin.id = this.getProfile._id
        this.getWorkspaceDetails.superAdmin = this.getProfile._id
      } else if (this.otherWorkspacesSuperAdmins.length > 0) {
        for (const superAdmin of this.otherWorkspacesSuperAdmins) {
          if (superAdmin.state === 'active') {
            this.selectedSuperAdmin.full_name = superAdmin.firstname + ' ' + superAdmin.lastname
            this.selectedSuperAdmin.email = superAdmin.email
            this.selectedSuperAdmin.id = superAdmin._id
            this.getWorkspaceDetails.superAdmin = superAdmin._id
            break
          }
        }
      } else if (this.isProfileUserTrialFinished()) {
        this.$router.replace({ name: 'trial_expired' })
      }
    })
  },

  methods: {
    ...mapMutations([workspaceTypes.SET_WORKSPACE_LOGO]),
    slugUpdated () {
      this.manuallySlugUpdated = true
    },
    isActiveUser (user) {
      return user.state === 'active'
    },
    isProfileUserTrialFinished () {
      return this.getProfile.state === 'trial_finished'
    },
    isNoSubscription () {
      return this.getProfile.subscription_name === 'No Package'
    },
    getUserState (state) {
      switch (state) {
        case 'trial_finished':
          return 'Trial Finished'
        default:
          return state.replace('_', ' ')
      }
    },

    getOtherWorkspacesSuperAdmins () {
      const wsSuperAdmins = []
      this.filteredWorkspacesOwnedByMe(false).forEach((workspace) => {
        if (workspace.role === 'admin') {
          workspace.workspace.members.forEach((member) => {
            if (
              member.role === 'super_admin' &&
              wsSuperAdmins.filter((admin) => admin.user_id === member.user_id)
                .length === 0
            ) {
              wsSuperAdmins.push(member)
            }
          })
        }
      })
      return wsSuperAdmins
    },
    changeSuperAdmin (superAdmin, isProfileUser) {
      console.debug('Method::changeSuperAdmin', superAdmin)
      if (!superAdmin && isProfileUser && this.getProfile.role === 'super_admin') {
        this.selectedSuperAdmin.full_name = this.getProfile.full_name
        this.selectedSuperAdmin.email = this.getProfile.email
        this.selectedSuperAdmin.id = this.getProfile._id
        this.getWorkspaceDetails.superAdmin = this.getProfile._id
      } else {
        this.selectedSuperAdmin.full_name = superAdmin.firstname + ' ' + superAdmin.lastname
        this.selectedSuperAdmin.email = superAdmin.email
        this.selectedSuperAdmin.id = superAdmin._id
        this.getWorkspaceDetails.superAdmin = superAdmin._id
      }
    },
    changeTimezone (timezone) {
      console.debug('Method::changeTimezone', timezone)
      this.getWorkspaceDetails.timezone = timezone
      this.search_timezone = ''
    }
  },
}
</script>

<style lang="scss" scoped>
  .field_no_label input:not(:placeholder-shown) + label {
    z-index: 0;
  }
</style>
