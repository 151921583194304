<script>
import { mapGetters } from 'vuex'

import { platforms } from '@src/modules/integration/store/states/mutation-types'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import SavePlatform from '../../dialogs/SavePlatform'
import SaveWordpressBlog from '../../dialogs/wordpress/SaveWordpressBlog'
import ManageShopifyStoreConnection from '../../dialogs/ManageShopifyStoreConnection'
import PluginToken from '../../dialogs/wordpress/PluginToken'
import RemoveIntegration from '../../dialogs/remove/RemoveIntegration'
import BlogListing from './BlogListing'

export default {
  components: {
    RemoveIntegration,
    PluginToken,
    SaveWordpressBlog,
    BlogListing,
    SavePlatform,
    ManageShopifyStoreConnection,
  },

  data() {
    return {
      type: '',
      platform: '',
      blogIntegrationsNames,
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getWorkspaces', 'getActiveWorkspace']),
  },
  watch: {
    'getActiveWorkspace._id'(val) {
      console.debug('Watch::Blog-getActiveWorkspace._id', val.length)
      if (val.length > 0) {
        this.initializeSection()
        this.$destroy()
      }
    },
  },
  created() {
    this.initializeSection()
  },

  beforeUnmount() {
    console.debug('beforeUnmount blog')
  },

  methods: {
    async initializeSection() {
      console.debug('Blog::initializeSection')
      this.$store.dispatch('fetchBlogs')
      const response = await this.$store.dispatch(
        'checkConnectorState',
          { hash: this.$route.hash }
      )
      if (response && response.data) {
        if (response.data.limitExceed) {
          this.showUpgradeModal()
          return false
        }
        if (response.data.status === true) {
          if (
            response.data.profiles ||
            response.data.blogs?.length ||
            response.data.publications ||
            response.data.sites
          ) {
            this.$store.commit(platforms.SET_CONNECTION_STATE, response.data)
            this.$bvModal.show('save_platforms_modal')
            return false
          } else if(response.data.message) {
            this.$store.dispatch('toastNotification', {
              message: response.data.message,
              type: 'success',
            })
          }
        } else {
            this.$store.dispatch('toastNotification', {
              message: response.data?.message || 'Something went wrong',
              type: 'error',
            })
            return false
        }
      }
    },
  },
}
</script>

<template>
  <div class="setting_groups_component setting_social_component">
    <div class="component_inner">
      <SavePlatform></SavePlatform>
      <RemoveIntegration :type="type" :platform="platform"></RemoveIntegration>
      <SaveWordpressBlog></SaveWordpressBlog>
      <ManageShopifyStoreConnection></ManageShopifyStoreConnection>
      <PluginToken></PluginToken>
      <div class="social_block">
        <BlogListing
          v-for="(item, index) in blogIntegrationsNames"
          :key="`integration_${index}`"
          :type="item"
        ></BlogListing>
      </div>
    </div>
  </div>
</template>
