import { useStore } from '@/src/store/base'
import moment from "moment";
import vueCookie from "vue-cookie";
import {cookieDomain} from "@src/config/api-utils";
import {usermavenClient} from "@usermaven/sdk-js";

const store = useStore()

export function useIdentify() {

    const userMaven = usermavenClient({
        key: process.env.VUE_APP_USERMAVEN_DATA_KEY,
        tracking_host: process.env.VUE_APP_USERMAVEN_TRACKING_HOST,
        autocapture: true
    })

    const getSuperAdminByWorkspaces =  (workspace) => {
        console.debug('getSuperAdminByWorkspaces', workspace)

        const teamMembers = workspace.members

        if (teamMembers) {
            for (let i = 0; i < teamMembers.length; i++) {
                const member = teamMembers[i]
                if (member.role === 'super_admin') {
                    return member.user
                }
            }
        }

        return null
    }
    const userMavenCommonPayload =  (profile, admin = {}) => {
        console.debug('userMavenCommonPayload', profile, admin)
        // eslint-disable-next-line camelcase
        const { id, email, first_name, last_name, created_at, type } = profile
        // eslint-disable-next-line camelcase
        const admin_id = admin._id || null
        // eslint-disable-next-line camelcase
        const admin_name = admin.email || null

        const admin_created_at = admin.created_at || null

        let data = {
            // Required attributes
            id, // Unique ID for the user in database.
            email, // Email address for the user.
            // Recommended attributes
            created_at,

            first_name,
            last_name,
            type
        }


        if(admin_id && admin_name && admin_created_at) {

            data = {
                ...data,
                company: {
                    // Required Attributes
                    id: admin_id, // Company ID
                    name: admin_name, // Company Name.
                    created_at: admin_created_at
                }
            }
        }

        return data
    }

    // Identify the user to all the tracking tools
    const helpscoutIdentify = (payload) => {
        let name = payload.user.first_name + ' ' + payload.user.last_name
        if (!name.trim()) {
            name = payload.user.email.substring(
                0,
                payload.user.email.lastIndexOf('@')
            )
        }
        const hsPayload = {
            id: payload.user.id,
            name,
            email: payload.user.email,
            'team-role': payload.user.role,
            phone_number: payload.user.phone_no || null,
            business_type: payload.user.business_type,
        }

        if (Object.hasOwn(payload, 'account') && payload.account.plan_display) {
            console.log('helpscout identify -> ', payload)
            if (payload.account.plan_fs.includes('trial')) {
                hsPayload['billing-type'] = 'Trial'
            } else if (payload.account.plan_fs.includes('lifetime')) {
                hsPayload['billing-type'] = 'Lifetime'
            }
            //  Checking in the plan_display if it monthly or annual for the billing type.
            else if (
                payload.account.plan_display.toLowerCase().includes('monthly')
            ) {
                hsPayload['billing-type'] = 'Monthly'
            } else if (
                payload.account.plan_display.toLowerCase().includes('annually')
            ) {
                hsPayload['billing-type'] = 'Annual'
            }
            if (payload?.workspace?.members) {
                hsPayload['super-admin-email'] =
                    payload.workspace.members.find(
                        (item) => item.role === 'super_admin'
                    )?.user?.email || ''
            }

            hsPayload['internal-plan-name'] = payload.account.plan_fs
            hsPayload['current-plan'] = payload.account.plan_display
            hsPayload['total-workspaces'] = payload.account.count_workspaces
            hsPayload['total-social-accounts'] =
                payload.account.count_social_accounts
            hsPayload['total-blog-accounts'] = payload.account.count_blogs_accounts
            hsPayload['total-team-members'] = payload.account.count_team_members
            // hsPayload.CustomTopics = payload.account.count_custom_topics
        }
        try {
            // eslint-disable-next-line no-undef
            Beacon('identify', hsPayload)
            console.log(payload)
            console.log('event: helpscout fired')
        } catch (e) {}
    }
    const customerIOIdentify = (payload) => {
        const cioPayload = {
            id: payload.user.id,
            name: payload.user.first_name + ' ' + payload.user.last_name,
            first_name: payload.user.first_name,
            last_name: payload.user.last_name,
            email: payload.user.email,
            created_at: payload.user.created_at_timestamp,
            state: payload.user.state,
            team_role: payload.user.role,
            phone_number: payload.user.phone_no || null,
            business_type: payload.user.business_type,
        }

        if (Object.hasOwn(payload, 'account') && payload.account.plan_fs) {
            cioPayload.current_plan = payload.account.plan_fs
            cioPayload.count_blogs = payload.account.count_blogs_accounts
            cioPayload.count_social_accounts = payload.account.count_social_accounts
            cioPayload.count_workspaces = payload.account.count_workspaces
            cioPayload.count_team = payload.account.count_team_members
            cioPayload.count_custom_topics = payload.account.count_custom_topics
        }
        try {
            // eslint-disable-next-line no-undef
            _cio.identify(cioPayload)
            console.log('event: customerio fired')
        } catch (e) {}
    }
    const hubspotIdentify = (payload) => {
        try {
            const _hsq = (window._hsq = window._hsq || [])
            const leadProperties = {
                id: payload.user.id,
                email: payload.user.email,
                firstname: payload.user.first_name,
                lastname: payload.user.last_name,
                signed_up_at: payload.user.created_at_timestamp * 1000, // hubspot needs to be passed timestamp by multiplying with 1000
                team_role: payload.user.role,
                phone: payload.user.phone_no || null,
                business_type: payload.user.business_type,
            }
            if (Object.hasOwn(payload, 'account') && payload.account.plan_fs) {
                leadProperties.subscription_plan = payload.account.plan_fs
                leadProperties.total_blogs = payload.account.count_blogs_accounts
                leadProperties.total_social_accounts =
                    payload.account.count_social_accounts
                leadProperties.total_workspaces = payload.account.count_workspaces
                leadProperties.total_team_members = payload.account.count_team_members
                leadProperties.total_custom_topics =
                    payload.account.count_custom_topics
            }

            _hsq.push(['identify', leadProperties])

            console.log('goquared fired...', leadProperties)
        } catch (e) {}
    }
    const profitWellScriptLoad = (email) => {
        const script = document.createElement('script')

        // Set the ID of the script element
        script.id = 'profitwell-js'

        // Set the data-pw-auth attribute
        script.setAttribute('data-pw-auth', '0e427e1143d32f52ed1925243ac46217')

        // Define the inline script
        const inlineScript = document.createTextNode(
            "(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)}; a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+ s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m); })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js'); profitwell('start', { 'user_email': '" +
            email +
            "' });"
        )

        // Append the inline script to the script element
        script.appendChild(inlineScript)

        // Append the script element to the body of the document
        document.body.appendChild(script)
    }
    const userMavenIdentity = (payload) => {
        console.debug('userMavenIdentity', payload)
        try {
            const admin = getSuperAdminByWorkspaces(payload.workspace) || {}
            const userMavenPayload = userMavenCommonPayload(payload.user, admin)

            let customPayload = {}

            if (payload.account) {
                customPayload = {
                    plan: payload.account.plan_fs,
                    role: payload.user.role,
                    workspaces: payload.account.count_workspaces,
                    social_accounts: payload.account.count_social_accounts,
                    blogs: payload.account.count_blogs_accounts,
                    team: payload.account.count_team_members,
                    custom_topics: payload.account.count_custom_topics,
                    phone_number: payload.user.phone_no || null,
                    business_type: payload.user.business_type
                }
            }

            userMaven.id({
                ...userMavenPayload,
                custom: {
                    ...customPayload
                }
            })
        } catch (e) {
            console.debug(e)
        }
    }

    const identity = () => {
        console.debug('Method::Identify')

        const profile = store.getters.getProfile
        const plan = store.getters.getPlan
        const workspace = store.getters.getActiveWorkspace

        setTimeout(async function () {
            if (!profile._id) return
            const payload = {
                event: 'user',
                logged_in: true, // either true or false (boolean)
            }

            const state = profile.state ? profile.state : 'active'
            let loggedUser = {}

            if (workspace.members) {
                loggedUser = workspace.members.find((item) => {
                    return item.user_id === profile._id
                })
                console.log('Firing event...', loggedUser)
            }

            payload.user = {
                id: profile._id,
                email: profile.email,
                first_name: profile.firstname,
                last_name: profile.lastname,
                created_at: profile.created_at,
                created_at_timestamp: moment
                    .utc(profile.created_at, 'YYYY-MM-DD HH:mm:ss')
                    .unix(),
                state,
                role: 'super_admin',
                phone_no: profile.phone_no || null,
                business_type: profile.business_type,
            }

            if (loggedUser && loggedUser.user_id) {
                payload.user = {
                    id: loggedUser.user_id,
                    email: profile.email,
                    first_name: profile.firstname,
                    last_name: profile.lastname,
                    created_at: profile.created_at,
                    created_at_timestamp: moment
                        .utc(profile.created_at, 'YYYY-MM-DD HH:mm:ss')
                        .unix(),
                    role: loggedUser.role,
                    state,
                    phone_no: profile.phone_no || null,
                    business_type: profile.business_type,
                }
            }
            // subscription instance is available.

            if (plan.subscription && plan.subscription.display_name) {
                payload.account = {
                    plan_fs: plan.subscription.slug,
                    plan_display: plan.subscription.display_name,
                    count_social_accounts: plan.used_limits.profiles,
                    count_blogs_accounts: plan.used_limits.blogs,
                    count_team_members: plan.used_limits.members,
                    count_workspaces: plan.used_limits.workspaces,
                    count_custom_topics: plan.used_limits.topics,
                    /* 'count_blog_posts': 0,
                      'count_social_posts': 0, */
                    // 'count_fav_folders': 0,
                    // 'count_custom_sources': 0
                }
            }

            if (workspace) {
                payload.workspace = workspace
            }

            if (Object.hasOwn(profile, 'addons')) {
                payload.addons = profile.addons
            }

            console.log('Method::Identify payload', payload)
            if (!vueCookie.get('admin_logged_user', { domain: cookieDomain })) {
                console.log('Method::Identify triggered')
                userMavenIdentity(payload)
                helpscoutIdentify(payload)
                customerIOIdentify(payload)
                hubspotIdentify(payload)
                profitWellScriptLoad(
                    payload.workspace?.members
                        ? payload.workspace.members.find(
                            (item) => item.role === 'super_admin'
                        )?.user?.email
                        : payload.user.email
                )
            }
            return true
        }, 0)
    }

    return {
        userMavenIdentity,
        helpscoutIdentify,
        customerIOIdentify,
        hubspotIdentify,
        profitWellScriptLoad,
        identity
    }
}
