<template>
  <div
    class="pl-3 py-3 !border-l-0 !border-t-0 !border-b-0 border !border-[#EAF0F6] relative"
  >
    <div>
      <div class="text-base my-3 px-0.5 font-weight-500">
        Social Analytics
      </div>

      <nav aria-label="Navigation">
        <template
          v-for="platform in socialAnalyticsRoutes"
          :key="`platform_${platform.title}`"
        >
          <router-link v-if="platform.show" :to="platform.getLink">
            <div
              class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
              :class="getOptionClass(platform.routeName)"
            >
              <div
                class="h-8 w-8 mr-4 flex items-center justify-center"
                :class="platform.iconWrapperClass"
                v-html="platform.icon"
              >
              </div>
              <span class="group-hover:text-black-900">
                {{ platform.title }}
              </span>
              <span
                v-if="platform.isNew"
                class="px-1.5 py-0.5 absolute right-2 select-none"
              >
                <img src="@src/assets/img/common/new_tag.svg" alt="Icon" />
              </span>
            </div>
          </router-link>
        </template>
      </nav>

      <template v-if="competitorAnalyticsAccess?.allowed">
        <div class="my-3 flex items-center gap-1 relative">
          <span class="text-base font-weight-500">Competitor Analytics</span>
        </div>
        <nav aria-label="Navigation">
          <template
            v-for="platform in competitorAnalyticsRoutes"
            :key="`platform_${platform.title}`"
          >
            <router-link v-if="platform.show" :to="platform.getLink">
              <div
                class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
                :class="getOptionClass(platform.routeName)"
              >
                <div
                  class="h-8 w-8 mr-4 flex items-center justify-center"
                  :class="platform.iconWrapperClass"
                  v-html="platform.icon"
                >
                </div>
                <span class="group-hover:text-black-900">
                  {{ platform.title }}
                </span>
                <span
                  v-if="platform.isNew"
                  class="px-1.5 py-0.5 absolute right-2 select-none"
                >
                  <img src="@src/assets/img/common/new_tag.svg" alt="Icon" />
                </span>
              </div>
            </router-link>
          </template>
        </nav>
      </template>
      <template v-else>
        <div
            v-tooltip="!competitorAnalyticsAccess.allowed ? competitorAnalyticsAccess.error?.message : ''"
            @click="handleCompetitorAnalyticsClick"
        >
          <div
              class="hover:bg-[#F2F4F6] mt-7 py-1 rounded-sm"
              :class="[
                $route.name.includes('competitor_analytics') && 'bg-gray-100 font-medium text-[#000D21]',
                { 'cursor-pointer': !competitorAnalyticsAccess.allowed }
              ]"
          >
            <div class="text-gray-900 my-3 flex items-center gap-2 relative">
              <span class="text-base font-weight-500">Competitor Analytics</span>
              <img
                  v-if="!competitorAnalyticsAccess.allowed"
                  src="@assets/img/icons/lock_black.svg"
                  class="w-4"
                  alt=""
              />
            </div>
            <div class="text-[#69788E]">
              <div class="flex items-center mb-1.5 py-0.5 px-2">
                <i class="h-8 w-8 bg-[#F1FAFF] rounded-md fab fa-facebook-square inline-flex justify-center items-center text-[#02B2FF] text-xl mr-4"></i>
                <span>Facebook</span>
              </div>
              <div class="flex items-center mb-1.5 justify-between rounded-sm py-0.5 px-2">
            <span class="flex justify-center items-center">
              <i class="h-8 w-8 bg-[#FFEFF3] rounded-md fab fa-instagram inline-flex justify-center items-center text-[#EE446E] text-xl mr-4"></i>
              <span class="">Instagram</span>
            </span>
              </div>
            </div>
          </div>
        </div>
      </template>


      <div class="text-base my-3 font-weight-500"> Performance Analytics </div>

      <nav aria-label="Navigation">
        <template
            v-for="platform in augmentedPerformanceReportRoutes"
            :key="`platform_${platform.title}`"
        >
          <div
              v-tooltip="!platform.isAccessible ? platform.errorMessage : ''"
              @click="handlePerformanceAnalyticsClick(platform)"
          >
            <div
                class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
                :class="[
                  getOptionClass(platform.routeName),
                  { 'opacity-70 !cursor-default': !platform.isAccessible },
                ]"
            >
              <div
                  class="h-8 w-8 mr-4 flex items-center justify-center"
                  :class="platform.iconWrapperClass"
                  v-html="platform.icon"
              >
              </div>
              <span class="group-hover:text-black-900">
            {{ platform.title }}
          </span>
              <span
                  v-if="platform.isNew"
                  class="px-1.5 py-0.5 absolute right-2 select-none"
              >
            <img src="@src/assets/img/common/new_tag.svg" alt="Icon" />
          </span>
              <img
                  v-if="!platform.isAccessible"
                  src="@assets/img/icons/lock_black.svg"
                  class="w-4 ml-auto"
                  alt="Locked"
              />
            </div>
          </div>
        </template>
      </nav>

      <div class="text-base my-3 font-weight-500">
        Web Analytics
        <span
          class="px-1.5 absolute right-2 select-none"
        >
          <img
            src="@src/assets/img/common/new_tag.svg"
            alt="New Icon"
          />
        </span>
      </div>

      <nav class="" aria-label="analytics">
        <template
          v-for="platform in webAnalyticsRoutes"
          :key="`platform_${platform.title}`"
        >
          <router-link v-if="platform.show" :to="platform.getLink">
            <div
              class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
              :class="getOptionClass(platform.routeName)"
            >
              <div
                class="h-8 w-8 mr-4 flex items-center justify-center"
                :class="platform.iconWrapperClass"
                v-html="platform.icon"
              >
              </div>
              <span class="group-hover:text-black-900">
                {{ platform.title }}
              </span>
              <span
                v-if="platform.isNew"
                class="px-1.5 py-0.5 absolute right-2 select-none"
              >
                <img
                  src="@src/assets/img/common/new_tag.svg"
                  alt="New Icon"
                />
              </span>
            </div>
          </router-link>
        </template>
      </nav>

      <div class="text-base my-3 font-weight-500"> Manage Reports</div>

      <nav aria-label="Navigation">
        <template
          v-for="platform in manageReportRoutes"
          :key="`platform_${platform.title}`"
        >
          <router-link v-if="platform.show" :to="platform.getLink">
            <div
              class="flex items-center mb-1.5 hover:bg-[#F2F4F6] hover:bg-opacity-50 rounded-sm py-0.5 px-2 cursor-pointer group"
              :class="getOptionClass(platform.routeName)"
            >
              <div
                class="h-8 w-8 mr-4 flex items-center justify-center"
                :class="platform.iconWrapperClass"
                v-html="platform.icon"
              >
              </div>
              <span class="group-hover:text-black-900">
                {{ platform.title }}
              </span>
              <span
                v-if="platform.isNew"
                class="px-1.5 py-0.5 absolute right-2 select-none"
              >
                <img src="@src/assets/img/common/new_tag.svg" alt="Icon" />
              </span>
            </div>
          </router-link>
        </template>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import useAnalyticsRoutes from '@src/modules/analytics/components/common/composables/useAnalyticsRoutes'
import { useRoute } from 'vue-router'
import {useFeatures} from "@modules/billing/composables/useFeatures";
import {useStore} from "@state/base";
import {useBilling} from "@modules/billing/composables/useBilling";
import {useRouter} from "@src/router";

const props = defineProps({
  competitorAnalyticsAccess: {
    type: Object,
    default: () => {},
  },
})

const route = useRoute()
const router = useRouter()
const {getters} = useStore()

const {
  socialAnalyticsRoutes,
  competitorAnalyticsRoutes,
  manageReportRoutes,
  performanceReportRoutes,
  webAnalyticsRoutes,
} = useAnalyticsRoutes()

const {showUpgradeModal} = useBilling()
const { canAccess } = useFeatures()
const campaignLabelAccess = canAccess('campaign_label_analytics')?.value

// Filter and augment performanceReportRoutes
const augmentedPerformanceReportRoutes = computed(() => {
  return performanceReportRoutes.value?.map(platform => {
    if (platform.title === 'Campaign & Label') {
      return {
        ...platform,
        isAccessible: campaignLabelAccess?.allowed,
        errorMessage: campaignLabelAccess?.error?.message,
      }
    }
    return { ...platform, isAccessible: true }
  })
})

const getOptionClass = (routeName) => {
  return route?.name?.includes(routeName)
    ? 'bg-gray-100 !font-medium !text-[#000D21]'
    : 'text-[#69788E]'
}

const handleCompetitorAnalyticsClick = () => {
  if (!props.competitorAnalyticsAccess.allowed) {
    if (getters?.getPlan?.subscription?.paddle_billing) {
      showUpgradeModal()
    } else {
      router.push({ name: 'competitor_analytics' })
    }
  }
}

const handlePerformanceAnalyticsClick = (platform) => {
  if (!platform.isAccessible) {
    showUpgradeModal()
  } else {
    router.push({ name: platform.routeName })
  }
}
</script>
