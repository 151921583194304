<script setup>
import { computed, watch, ref } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/tiktok/components/graphs/MainGraph.vue'
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import usePinterestAnalytics from '@/src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'

// state
const {
  pinsPostingPerformance,
  pinsAndEngagementCharts,
  pinsAndImpressionsCharts,
  dataZoomOptions,
  pinsAndEngagementChartOptions: EngagementsChartOptions,
  pinsAndImpressionsChartOptions: ImpressionsChartOptions,
  performanceSelectedCheckbox,
  performanceFilterCheckbox: filterCheckbox,

  analyticsDesignSystem,
  legendOptions,
  isReportView,
  routes,
  isNoAnalyticsData,
  fetchMedia,
  getPerformanceChartPayload,
} = usePinterestAnalytics()

const props = defineProps({
  type: {
    type: String,
    default: 'Engagements',
    validator: (value) => {
      return ['Engagements', 'Impressions'].includes(value)
    },
  },
})
const optionsDropdown = [{ label: 'Engagements' }, { label: 'Impressions' }]

const selectedOptionType = ref(
  optionsDropdown.find((i) => i.label === 'Engagements')
)

const fetchFilteredData = () => {
  fetchMedia(routes.PIN_POSTING_PERFORMANCE, getPerformanceChartPayload())
}

const updateSelectedCheckbox = (value) => {
  performanceSelectedCheckbox.value = [value]
  fetchFilteredData()
}

const isNoData = computed(() => {
  return isNoAnalyticsData(pinsPostingPerformance)
})

const getSelectedType = computed(() => {
  return isReportView.value ? props.type : selectedOptionType.value.label
})

const selectedChart = computed(() => {
  if (getSelectedType.value === 'Engagements') {
    return EngagementsChartOptions.value
  } else {
    return ImpressionsChartOptions.value
  }
})

const getTooltip = computed(() => {
  if (getSelectedType.value === 'Engagements') {
    return 'Evaluate the correlation between your daily posting frequency during the selected time period and the resulting engagement metrics, including pin clicks, outbound clicks and saves.'
  } else {
    return 'Evaluate the correlation between your daily posting frequency during the selected time period and the resulting pin impressions received.'
  }
})

const IGNORE_LEGENDS = ['Pins']

watch(
  () => pinsPostingPerformance,
  () => {
    EngagementsChartOptions.value.series = []
    const options = pinsPostingPerformance.value || {}
    EngagementsChartOptions.value.legend = {
      ...legendOptions,
      data: pinsAndEngagementCharts.value?.map(
        (chartType) =>
          !IGNORE_LEGENDS.includes(chartType.name) && chartType.name
      ),
    }
    pinsAndEngagementCharts.value?.forEach((chart) => {
      const series = {
        name: chart.name,
        type: 'bar',
        stack: chart?.stack || undefined,
        data: options[chart && chart?.api_response_key] || [],
        color: chart.color,
        colorBy: 'series',
        barMaxWidth: analyticsDesignSystem?.graphs?.dualBarMaxWidth,
        yAxisIndex: chart?.yIndex,
        areaStyle: { opacity: 0.4, cursor: 'auto' },
        cursor: 'auto',
      }
      EngagementsChartOptions.value.series?.push(series)
    })
    EngagementsChartOptions.value.xAxis.data = options?.buckets

    ImpressionsChartOptions.value.series = []

    ImpressionsChartOptions.value.legend = {
      ...legendOptions,
      data: pinsAndImpressionsCharts.value?.map(
        (chartType) =>
          !IGNORE_LEGENDS.includes(chartType.name) && chartType.name
      ),
    }
    pinsAndImpressionsCharts.value?.forEach((chart) => {
      const series = {
        name: chart.name,
        type: 'bar',
        stack: chart?.stack || undefined,
        data: options[chart && chart?.api_response_key] || [],
        color: chart.color,
        colorBy: 'series',
        yAxisIndex: chart?.yIndex,
        barMaxWidth: analyticsDesignSystem?.graphs?.dualBarMaxWidth,
        areaStyle: { opacity: 0.4, cursor: 'auto' },
        cursor: 'auto',
      }
      ImpressionsChartOptions.value.series?.push(series)
    })
    ImpressionsChartOptions.value.xAxis.data = options?.buckets
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper
    type="Pins-Posting-and-engagements"
    :enable-modal="true"
  >
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <CstDropdown
            dropdown-placement="bottom"
            container-classes="!max-h-96"
            dropdown-classes="!rounded-md !border-[#70707029]"
            button-classes="flex !px-5 !w-[22rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
          >
            <template v-slot:arrow>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </template>
            <template v-slot:selected>
              <p class="text-[14px] font-weight-500 capitalize">
                {{ getSelectedType }} vs Daily Posting Pattern
              </p>
            </template>
            <template v-slot>
              <template
                v-for="(item, index) in optionsDropdown"
                :key="`activity_type_${index}`"
              >
                <CstDropdownItem @click="selectedOptionType = item">
                  <p class="text-gray-900 text-sm"
                    >{{ item.label }} vs Daily Posting Pattern</p
                  >
                </CstDropdownItem>
              </template>
            </template>
          </CstDropdown>
          <div class="flex align-items-center gap-2">
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  {{ getTooltip }}
                </p>
              </template>
            </v-menu>
          </div>
        </div>
        <div class="flex items-center gap-4">
          <template v-for="(item, i) in filterCheckbox" :key="i">
            <CstSimpleCheckbox
              :id="item.label"
              v-model="performanceSelectedCheckbox"
              :name="item.label"
              reverse
              @change="updateSelectedCheckbox(item.label)"
            >
              <template v-slot:label>{{ item.label }}</template>
            </CstSimpleCheckbox>
          </template>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <MainGraph
          v-if="!isNoData"
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...selectedChart,
                  ...dataZoomOptions,
                  grid: { ...selectedChart.grid, bottom: 70 },
                }
              : selectedChart
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No Pins data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
