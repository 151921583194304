// src/composables/usePosting.js
import proxy from '@common/lib/http-common'
import useToast  from '@src/modules/common/composables/useToast'
import { pausePostingURL, resumePostingURL } from '@src/modules/setting/config/api-utils'

// Error message constants
const PAUSE_WORKSPACE_POSTING_ERROR = 'Error pausing workspace posting.'
const UNPAUSE_WORKSPACE_POSTING_ERROR = 'Error resuming workspace posting.'
const UNKNOWN_ERROR = 'An unknown error occurred.'

export function usePosting() {
  const { alertMessage } = useToast()

  const pausePosting = async (payload) => {
    console.debug('Action:pausePosting', payload)
    try {
      const response = await proxy.post(pausePostingURL, payload)
      if (response.data.status === true) {
        return response
      } else {
        const message = response.data.message || PAUSE_WORKSPACE_POSTING_ERROR
        alertMessage(message, 'error')
        return false
      }
    } catch (error) {
      console.debug('Exception in pausePosting', error)
      alertMessage(UNKNOWN_ERROR, 'error')
      return false
    }
  }

  const resumePosting = async (payload) => {
    console.debug('Action:resumePosting', payload)
    try {
      const response = await proxy.post(resumePostingURL, payload)
      if (response.data.status === true) {
        return response
      } else {
        const message = response.data.message || UNPAUSE_WORKSPACE_POSTING_ERROR
        alertMessage(message, 'error')
        return false
      }
    } catch (error) {
      console.debug('Exception in resumePosting', error)
      alertMessage(UNKNOWN_ERROR, 'error')
      return false
    }
  }

  return {
    pausePosting,
    resumePosting,
  }
}
