<template>
  <!-- TODO: convert to BEM later on -->
  <div class="discovery_analytic_component mr-3 ml-3">
    <!--.....unlock insight feature.......-->
    <div class="component_inner">
      <div class="analytic_verification">
        <!--show if review submitted-->
        <div
          v-if="review_submitted"
          class="analytic_verification_inner unlock_block"
        >
          <img
            style="width: 200px"
            src="../../../../../assets/img/common/unblock_post.svg"
          />
          <h2>Thank you for submitting the review</h2>
          <p
            >After we verify your review, this page will be automatically
            unlocked within the next 24-48 hours.</p
          >
        </div>

        <!--if no review added yet -->
        <div v-if="!review_submitted" class="analytic_verification_inner">
          <div class="head_text">
            <h2
              >Insights is a new tool that we have added recently and we are
              giving it away for FREE to our lifetime users.</h2
            >
            <p
              >But we need a small favor from you to UNLOCK this tool for
              you.</p
            >
          </div>
          <div class="step_box">
            <h2>Step 1</h2>
            <p
              >Please write a few words about {{appData.businessName}} on any of the below
              mentioned website.</p
            >
            <div class="web_list">
              <li :class="{ completed: review.service === 'capterra' }">
                <div
                  class="img"
                  @click.prevent="selectReviewWebsite('capterra')"
                >
                  <img
                    style="width: 135px"
                    src="../../../../../assets/img/common/capterra_logo.png"
                  />
                </div>
                <a
                  target="_blank"
                  class="d-block mt-2"
                  href="https://rplg.co/cs-app-review-capterra"
                  >Write on Capterra</a
                >
              </li>
              <li :class="{ completed: review.service === 'g2crowd' }">
                <div
                  class="img"
                  @click.prevent="selectReviewWebsite('g2crowd')"
                >
                  <img
                    style="width: 110px"
                    src="../../../../../assets/img/common/crowd_logo.png"
                  />
                </div>
                <a
                  target="_blank"
                  class="d-block mt-2"
                  href="https://rplg.co/cs-app-g2crowd-review"
                  >Write on G2Crowd</a
                >
              </li>
            </div>
          </div>
          <div class="step_box basic_form">
            <h2>Step 2</h2>
            <p
              >Once you have submitted your review, please send us a message
              below with a proof to verify that you’ve actually submitted the
              review.</p
            >
            <p class="sub_text"
              >Add a link to the review post that you’ve submitted and/or send
              us a screenshot or a video proof using useloom.com/screencast
              etc.</p
            >

            <div class="text_block input_field">
              <textarea
                id=""
                v-model="review.message"
                name=""
                cols="30"
                rows="5"
                placeholder="Type here.."
              ></textarea>
              <form method="post" enctype="multipart/form-data" class="d-none">
                <div class="upload_file_block">
                  <input
                    id="prof_ss"
                    type="file"
                    name="reviewFiles"
                    accept="image/*,video/*"
                    @change.prevent="uploadReviewFiles($event)"
                  />
                  <label for="prof_ss">
                    <span class="btn gradient_btn">
                      <span>Upload a file</span>
                    </span>
                    <!--<span class="text">No file selected.</span>-->
                  </label>
                </div>
              </form>
            </div>
          </div>
          <div class="btn_block text-center">
            <button
              class="btn btn_round large_btn"
              @click.prevent="submitReview"
              >Submit</button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
import { unlockInsightsURL } from '@src/modules/discovery/config/api-utils.js'

export default {
  setup(){
    const {appData} = useWhiteLabelApplication()

    return {
      appData
    }
  },
  data () {
    return {
      review_submitted: false,
      review: {
        service: '',
        message: ''
      }
    }
  },
  methods: {
    selectReviewWebsite (service) {
      if (service === 'g2crowd') {
        window.open('https://rplg.co/cs-app-g2crowd-review', '_blank')
      } else if (service === 'capterra') {
        window.open('https://rplg.co/cs-app-review-capterra', '_blank')
      }
      this.review.service = service
    },
    submitReview () {
      this.postRequest(
        unlockInsightsURL,
        { review: this.review },
        (response) => {
          if (response.data.status) {
            this.alertMessage(response.data.message)
            this.review_submitted = true
          } else {
            this.alertMessage(response.data.message, 'error')
          }
        },
        (response) => {
          this.alertMessage(response.data.message, 'error')
        }
      )
    },
    uploadReviewFiles (event) {
      // NOTE: currently, we are asking user to create the video using useloom or screencast.
      console.debug('Method:uploadReviewFiles')

      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()

      const fileType = []
      for (let i = 0; i < files.length; i++) {
        if (fileType.includes(files[i].type) === false) {
          fileType.push(files[i].type)
        }

        formData.append('files[]', files[i])
      }
    }
  }
}
</script>

<style lang="scss">
.analytic_verification {
  .completed {
    .img {
      border: 2px solid #1ecb7b !important;
    }
  }
}
</style>
