<template>
  <div id="gSignInWrapper">
    <div id="customBtn" class="customGPlusSignIn" @click="handleSignIn">
        <span class="icon"></span>
        <span class="buttonText">Google</span>
    </div>
  </div>
</template>

<script setup>
import {defineProps, onMounted} from 'vue'

const props = defineProps({
  authorizationLink: {
    type: String,
    default: ""
  },
})

const handleSignIn = () => {
  window.location.href = props.authorizationLink
}

onMounted(() => {
  // No need to load Google script for this approach
})
</script>

<style scoped>
#gSignInWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.customGPlusSignIn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #FFF;
  color: #444;
  border-radius: 7px;
  border: 1px solid #e5e7eb;
  white-space: nowrap;
  padding: 5px;
  cursor: pointer;
  height: 3rem;
  width: 100%;
}

.icon {
  background: url('https://developers.google.com/identity/images/g-logo.png') transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 2.53rem;
  height: 2.53rem;
  background-size: 1.3rem;
  background-position: center;
}

.buttonText {
  color: #3A4557;
  font-size: 13.5px;
  width: calc(100% - 58px)
}
.buttonText:hover {
  color: #0068E5;
}
</style>
