<script setup>
import { computed } from 'vue'
import { allPlanHeadAttachments } from '@common/lib/planner'
import useNumber from '@common/composables/useNumber'
import OverlayPost from './OverlayPost'
import useInstagramGridView from '@/src/modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  isTiktok: {
    type: Boolean,
    default: false,
  },
  isDropping: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: 0,
  },
})

defineEmits(['dragover', 'dragstart', 'dragend'])

const { formatNumber } = useNumber()

const {
  getOverlayDetails,
  isVideo,
  isRemoteMedia,
  shouldDisplayIcon,
  isRemoteCarousal,
  getThumbnail,
  isPlanCarousel,
  getStatusIcon,
  getFeedStatus,
  isTiktokCarousel,
  getFormattedDate,
  getFormattedTime,
} = useInstagramGridView()

const getFeedItemTooltip = computed(() => {
  return `<p class='text-xs'>Created from <img width='12px' height='12px' src='${
    props.isTiktok
      ? 'https://www.tiktok.com/favicon.ico'
      : 'https://static.cdninstagram.com/rsrc.php/v3/yI/r/VsNE-OHk_8a.png'
  }'  alt=''/></p>`
})

const getClassList = computed(() => {
  return [
    !isRemoteMedia(props.item) && 'cursor-grab',
    props.isDropping && 'cursor-grabbing',
    props.isTiktok ? 'aspect-rectangle' : 'aspect-static',
  ]
})
</script>

<template>
  <div
    class="flex relative col-span-3 w-full pr-[1px] pt-[1px] group"
    :class="getClassList"
  >
    <OverlayPost
      :details="getOverlayDetails(item, isTiktok)"
      :item="item"
      :is-tiktok="isTiktok"
      item-type="feed"
    >
      <div
        class="h-full w-full relative"
        draggable="true"
        @dragstart="$emit('dragstart', $event, index)"
        @dragover="$emit('dragover', $event, index)"
        @dragend="$emit('dragend', $event)"
      >
        <div
          v-if="!isRemoteMedia(item)"
          class="backdrop-blur-sm !z-10 inset-0 bg-gray-800/30 rounded flex flex-col items-center justify-center invisible absolute group-hover:!visible pointer-events-none"
        >
          <p
            class="lg:text-sm md:text-xs sm:text-xxs text-center text-white font-semibold text-shadow-lg"
          >
            {{ getFormattedDate(item?.execution_time?.date) }}
          </p>
          <p
            class="lg:text-sm md:text-xs sm:text-xxs text-center text-white font-semibold text-shadow-lg"
          >
            {{ getFormattedTime(item?.execution_time?.date) }}
          </p>
        </div>
        <div v-if="isDropping">
          <div
            class="absolute z-20 inset-0 text-center flex items-center justify-center text-gray-900 text-sm font-semibold"
          >
            Drop to swap posts
          </div>
        </div>
        <img
          v-else-if="!isRemoteMedia(item)"
          class="w-full h-full object-cover"
          loading="lazy"
          alt="Post"
          :src="getResizedImageURL(getThumbnail(item, isTiktok), 105, 105)"
          @error="
            $event.target.src =
              'https://storage.googleapis.com/lumotive-web-storage/default/no_media_found.svg'
          "
        />
        <img
          v-else
          v-tooltip.top="{
            content: getFeedItemTooltip,
            allowHTML: true,
            followCursor: true,
          }"
          class="w-full h-full object-cover"
          loading="lazy"
          alt="Remote Post"
          :src="
            getResizedImageURL(
              isTiktok ? item.cover_image_url : getThumbnail(item, isTiktok),
              105,
              105
            )
          "
          @error="
            $event.target.src =
              'https://storage.googleapis.com/lumotive-web-storage/default/no_media_found.svg'
          "
        />
        <div
          v-if="isTiktok"
          class="absolute bottom-2 flex items-center gap-1 left-1 z-10"
        >
          <img
            src="@assets/img/common/video-play-outlined.svg"
            alt="video icon"
            class="drop-shadow w-[0.8rem] h-[0.8rem]"
          />
          <p
            class="text-gray-300 drop-shadow text-sm font-medium leading-none font-sans"
            >{{ item?.view_count && formatNumber(item?.view_count) }}</p
          >
        </div>
        <div
          v-if="shouldDisplayIcon(item)"
          class="flex align-items-center justify-content-center absolute rounded bg-[#202324] bg-opacity-60 p-[0.2rem] border border-[#D2D5DF] top-1 left-1 z-10"
        >
          <img
            v-tooltip="getFeedStatus(item, isTiktok) || ''"
            :src="getStatusIcon(item, isTiktok)"
            alt="Status Icon"
            class="w-[1rem] h-[1rem]"
          />
        </div>
        <div
          v-if="item?.isDraggable"
          v-tooltip="'Drag to swap posts'"
          draggable="false"
          class="invisible group-hover:!visible absolute top-1.5 right-1 !z-20 drop-shadow"
        >
          <img
            src="@assets/img/icons/planner/grid-view/drag-item.svg"
            alt="Drag Icon"
            class="w-[1.25rem] h-[1.25rem]"
          />
        </div>
      </div>
    </OverlayPost>
    <div
      v-if="!isTiktok"
      class="absolute top-2 right-1 flex items-center gap-1 z-10 visible"
      :class="{
        'group-hover:!invisible': item?.isDraggable && !isRemoteMedia(item),
      }"
    >
      <img
        v-if="isVideo(item) && item.instagram_post_type !== 'carousel'"
        src="@assets/img/icons/planner/grid-view/video-play.svg"
        alt="Video Icon"
        class="w-[1rem] h-[1rem] drop-shadow"
      />
      <img
        v-if="isRemoteMedia(item) && isRemoteCarousal(item)"
        src="@assets/img/icons/planner/grid-view/carousal.svg"
        alt="Carousel Icon"
        class="w-[1rem] h-[1rem] drop-shadow"
      />
      <img
        v-if="
          isPlanCarousel(item) ||
          allPlanHeadAttachments(item).length > 1 ||
          item.instagram_post_type === 'carousel'
        "
        src="@assets/img/icons/planner/grid-view/carousal.svg"
        alt="Carousel Icon"
        class="w-[1rem] h-[1rem] drop-shadow"
      />
    </div>
    <template v-else>
      <div
        v-if="
          item?.cover_image_url?.includes('photomode') || isTiktokCarousel(item)
        "
        class="visible rounded p-[0.2rem] absolute top-1 right-1"
        :class="{
          'group-hover:!invisible': !item?.isDraggable && !isRemoteMedia(item),
        }"
      >
        <img
          src="@assets/img/icons/planner/grid-view/image-post.svg"
          alt="Image Post Icon"
          class="w-[1rem] h-[1rem]"
        />
      </div>
      <img
        v-if="item.tiktok_options?.privacy_level === 'MUTUAL_FOLLOW_FRIENDS'"
        v-tooltip="'Mutual Follow Friends Post'"
        src="@assets/img/icons/planner/grid-view/mutual-follow-friends.svg"
        alt="mutual-friends-icon"
        class="drop-shadow w-[1rem] h-[1rem] absolute bottom-2 right-1 z-10"
      />
      <img
        v-else-if="item.tiktok_options?.privacy_level === 'SELF_ONLY'"
        v-tooltip="'Private Post'"
        :src="require('@assets/img/common/private_lock.svg')"
        alt="self-only-icon"
        class="drop-shadow absolute bottom-2 right-1 w-4 h-4 z-10"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
.aspect-static {
  aspect-ratio: 1;
}

.aspect-rectangle {
  aspect-ratio: 0.8;
}
</style>
