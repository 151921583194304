<script setup>
import { computed, watch } from 'vue'
import DualChartComponent from '@src/modules/analytics/views/tiktok/components/graphs/DualChartComponent.vue'
import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'

const props = defineProps({
  label: {
    type: String,
    default: 'Engagements',
  },
  defaultType: {
    type: String,
    default: 'line',
    validator: (v) => {
      return ['line', 'bar'].includes(v)
    },
  },
})

const {
  multipleSeriesBarChartOptions: barChartOptions,
  multipleSeriesLineChartOptions: lineChartOptions,
  legendOptions,
  dailyAndTotalEngagements,
  isReportView,
  analyticsDesignSystem,
  DUAL_GRAPHS,
  getDualGraphTitle,
  getDualGraphTooltip,
  isNoAnalyticsData,
} = useTiktokAnalytics(props.label)

const isNoData = computed(() => {
  return isNoAnalyticsData(dailyAndTotalEngagements)
})

const chartTypes = [
  {
    name: 'Likes',
    api_response_key: {
      daily: 'daily_video_likes',
      total: 'total_video_likes',
    },
    color: '#61BAE4',
  },
  {
    name: 'Comments',
    api_response_key: {
      daily: 'daily_video_comments',
      total: 'total_video_comments',
    },
    color: '#8AC1A2',
  },

  {
    name: 'Shares',
    api_response_key: {
      daily: 'daily_video_shares',
      total: 'total_video_shares',
    },
    color: '#8081B9',
  },
]

watch(
  () => dailyAndTotalEngagements,
  async () => {
    barChartOptions.value.series = []
    lineChartOptions.value.series = []

    barChartOptions.value.legend = {
      ...legendOptions,
      data: chartTypes?.map((chartType) => chartType.name),
    }

    lineChartOptions.value.legend = {
      ...legendOptions,
      data: chartTypes?.map((chartType) => chartType.name),
    }

    const options = dailyAndTotalEngagements.value[0] || {}
    const xAxisData = options?.days_bucket

    // bar and line chart options
    chartTypes.forEach((chartType) => {
      const apiKey = chartType?.api_response_key

      const seriesStack = {
        stack: 'total',
      }

      const barSeries = {
        name: chartType.name,
        type: 'bar',
        color: chartType.color,
        barCategoryGap: '70%',
        barMaxWidth: analyticsDesignSystem?.graphs?.singleBarMaxWidth,
        data: options[apiKey.daily] || [],
        yAxisIndex: 0,
        areaStyle: { opacity: 0.4, cursor: 'auto' },
        cursor: 'auto',
      }

      const lineSeries = {
        name: chartType.name,
        color: chartType.color,
        yAxisIndex: 0,
      }

      lineChartOptions.value.series.push({
        ...lineSeries,
        type: 'line',
        data: options[apiKey.total],
        areaStyle: { opacity: 0.4, cursor: 'auto' },
        cursor: 'auto',
        showSymbol: true,
        symbolSize: 8,
        colorBy: 'series',
      })

      barChartOptions.value.series.push({ ...seriesStack, ...barSeries })
    })

    lineChartOptions.value.xAxis.data = xAxisData
    barChartOptions.value.xAxis.data = xAxisData
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <DualChartComponent
    :default-chart-type="defaultType"
    :line-chart-options="lineChartOptions"
    :bar-chart-options="barChartOptions"
    :daily-label="getDualGraphTitle(DUAL_GRAPHS.ENGAGEMENT, 'daily')"
    :cumulative-label="getDualGraphTitle(DUAL_GRAPHS.ENGAGEMENT, 'cumulative')"
    :is-no-data="isNoData"
  >
    <template v-slot:label="{ chartMode }">
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  {{ getDualGraphTooltip(DUAL_GRAPHS.ENGAGEMENT, chartMode) }}
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
  </DualChartComponent>
</template>
