<template>
  <b-modal
    id="set-default-location-modal"
    ref="set-default-location-modal"
    centered
    hide-header
    hide-footer
    @hide="onModalHidden"
    @shown="onModalShow"
  >
    <div class="modal_head">
      <div class="flex items-center mx-auto gap-x-2">
        <img
          src="@assets/img/common/location-pin.svg"
          alt="location"
          class="w-6 h-6"
        />
        <h4 class="font-semibold">Default Location</h4>
      </div>

      <button
        type="button"
        class="modal_head__close float-right mx-0"
        data-dismiss="modal"
        aria-label="Close"
        @click.prevent="$bvModal.hide('set-default-location-modal')"
        >&times;
      </button>
    </div>

    <div class="modal_body p-2">
      <div class="text-center py-2 px-12 my-2 text-gray-900">
        This location will be added by default whenever you create new posts for
        this account
      </div>

      <!--  Search Location  -->
      <div
        class="flex items-center border border-gray-300 bg-gray-50 rounded-md px-4 py-2 my-4 mx-2 group"
      >
        <img
          src="@src/assets/img/chat_bot/search-icon.svg"
          class="h-5 w-5"
          alt="search"
        />
        <input
          v-model="searchTerm"
          type="text"
          class="bg-transparent w-full ml-2 text-sm border-0 p-1 rounded"
          placeholder="Search Location"
        />
        <img
          src="@src/assets/img/common/circle-xmark.svg"
          alt="x-mark"
          class="h-5 w-5 cursor-pointer invisible group-hover:!visible"
          @click="clearSearch"
        />
      </div>

      <!-- Search Results -->
      <div v-if="locations.length > 0" class="mx-2 mb-4">
        <ul class="max-h-40 overflow-y-auto border border-gray-300 rounded-md">
          <li
            v-for="location in locations"
            :key="location.id"
            class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            :class="{ 'text-blue-600': location.id === selectedLocation?.id }"
            @click="handleSelectLocation(location)"
          >
            {{ location.name }}

            <img
              v-if="location?.verified"
              src="@src/assets/img/composer/verified-icon.svg"
              alt="verified"
              class="w-4 h-4 ml-1"
            />
          </li>
        </ul>
      </div>
      <div
        v-else-if="
          searchTerm.length > 3 &&
          !isLoading &&
          locations.length === 0 &&
          !userSelectedLocation
        "
        class="mx-2 mb-4 text-center"
      >
        <span class="text-gray-700 text-sm">
          No results found. Please check the page ID or location name
        </span>
      </div>

      <!-- Loading indicator -->
      <clip-loader
        v-if="isLoading"
        :color="'#9da6ac'"
        :size="'13px'"
        class="spinner"
        variant="info"
      />

      <!--  Action Buttons  -->
      <div class="flex items-center justify-end gap-x-2 py-4 px-2">
        <CstButton
          variant="text"
          @click.prevent="$bvModal.hide('set-default-location-modal')"
          >Cancel
        </CstButton>
        <CstButton
          variant="primary"
          :disabled="disableSave || isProcessing"
          @click.prevent="handleSaveLocation"
          >Save
        </CstButton>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { ref, inject, computed } from 'vue'
import CstButton from '@ui/Button/CstButton.vue'
import { useLocation } from '@common/composables/useLocation'

const props = defineProps({
  defaultLocation: {
    type: String,
    default: '',
  },
  platform: {
    type: String,
    default: '',
  },
  accountId: {
    type: String,
    default: '',
  },
})

const { $bvModal } = inject('root')
const {
  searchTerm,
  locations,
  isLoading,
  platformType,
  userSelectedLocation,
  saveLocation,
  setInitialLocation,
  selectLocation,
  clearStates,
} = useLocation()

const selectedLocation = ref(null)
const initialLocation = ref(null)
const isProcessing = ref(false)

// Compute if the location has changed from the initial state
const isLocationChanged = computed(() => {
  return selectedLocation.value !== initialLocation.value
})

const disableSave = computed(() => {
  return (
    !isLocationChanged.value ||
    (searchTerm.value.length && !selectedLocation.value?.id)
  )
})

// Set initial location if provided
const onModalShow = () => {
  platformType.value = props.platform
  setInitialLocation(props.defaultLocation)
  selectedLocation.value = props.defaultLocation
    ? { name: props.defaultLocation }
    : null
  initialLocation.value = selectedLocation.value
}

// Clears the search input and resets related states
const clearSearch = () => {
  searchTerm.value = ''
  locations.value = []
  userSelectedLocation.value = false
  selectedLocation.value = null
}


// Handles the selection of a location from the search results
const handleSelectLocation = (location) => {
  selectedLocation.value = location
  selectLocation(location)
}

// Saves the selected location (including null for removal)
const handleSaveLocation = async () => {
  isProcessing.value = true
  const payload = {
    platform_type: props.platform,
    platform_id: props.accountId,
    location: selectedLocation.value,
  }
  await saveLocation(payload)
  $bvModal.hide('set-default-location-modal')
  isProcessing.value = false
}

// Resets the modal state when hidden
const onModalHidden = () => {
  clearStates()
  selectedLocation.value = null
  initialLocation.value = null
}
</script>
