<template>
  <div id="AI_chat_widget" v-tooltip="'AI Writing Assistant'"
       :class="{'!bottom-[1.4rem]': (getTeamMembership === 'client' && getUserRole === 'Approver')}"
       class="fixed right-8 bottom-24 z-1040">
    <div class="relative">
      <div
        class="flex align-items-center justify-content-center bg-cs-primary hover:bg-black-500 transition-all ease-in-out rounded-full w-16 h-16 text-center  border-solid border-1.5 border-white cursor-pointer shadow-sm"
        @click="props.openModal('openChatModal')"
      >
        <img src="@src/assets/img/chat_bot/cyrus-icon.svg" alt="chat-icon" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, onBeforeUnmount} from 'vue'
import {EventBus} from "@common/lib/event-bus";

const props = defineProps({
  openModal: {
    type: Function,
    default: () => {},
  },
})

EventBus.$on('open-chat-modal', () => {
  props.openModal('openChatModal')
})

onBeforeUnmount(() => {
  EventBus.$off('open-chat-modal')
})
</script>

<style scoped></style>
