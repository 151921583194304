<template>
  <div class="workspace_component">
    <div class="component_inner">
      <remove-confirmation></remove-confirmation>
      <!--<router-link class="add_icon" v-if="getWorkspaces.activeWorkspace._id" :to="{'name': 'discovery', params: { workspace: getWorkspaces.activeWorkspace.slug}}">-->
      <button
        v-if="
          getWorkspaces.activeWorkspace._id &&
          !checkIfActiveWorkspaceHasPaymentIssue()
        "
        class="close_icon float-right"
        type="button"
        @click.prevent="redirectForDashboard"
        >&times;
      </button>
      <!--</router-link>-->
      <div class="component_inner__workspace_list">
        <div class="component_inner__workspace_list__top_heading">
          <h2>All Workspaces</h2>
          <p
            >A workspace is a place where you keep all of your content, accounts
            and teams for one brand in one single place. So, create a different
            workspace for each brand or client you have.</p
          >
        </div>
        <template v-if="isWorkspaceLoading" class="text-center">
          <beat-loader :color="'#436aff'"></beat-loader>
        </template>
        <template v-else>
          <div class="component_inner__workspace_list__workspace_top">
            <div
              class="component_inner__workspace_list__workspace_top__top_left"
            >
              <div class="search-bar-input">
                <div class="search-bar-inner">
                  <input
                    v-model="searchQuery"
                    placeholder="Search a workspace…"
                    type="text"
                  />
                  <button class="search_btn">
                    <i class="icon-Search"></i>
                  </button>
                </div>
              </div>
            </div>
            <!--//for managing team member button -->
            <div
              v-if="
                getOrganizationLoaders.retrieve ||
                (getOrganizationMembers.workspaces &&
                  getOrganizationMembers.workspaces.length > 0)
              "
              class="ml-auto"
            >
              <button
                :disabled="getOrganizationLoaders.retrieve"
                class="btn btn-studio-theme-space"
                @click="$router.push({ name: 'manageTeam' })"
              >
                <span>Manage Team </span>
                <clip-loader
                  v-if="getOrganizationLoaders.retrieve"
                  :size="'13px'"
                  class="d-inline-block align-middle mr-1"
                  color="#ffffff"
                ></clip-loader>
              </button>

              <button
                class="btn btn-studio-theme-space ml-3"
                @click="$router.push({ name: 'manageLimits' })"
              >
                <span>Manage Limits</span>
              </button>
            </div>
          </div>

          <h2 class="style_header">Workspaces Owned By Me</h2>

          <hr class="solid" />

          <div class="box_container">
            <div
              v-if="hasPermission('can_save_workspace')"
              class="
                component_inner__workspace_list__w_box
                component_inner__workspace_list--box_create
              "
            >
              <div class="box_inner">
                <div
                  class="create_workspace"
                  data-cy="workspace_create"
                  @click.prevent="createWorkspaceModal"
                >
                  <i class="icon-add-new-member-cs"></i>
                  <h3>Create a workspace</h3>
                  <p>e.g : Nike, Pepsi, Disney</p>
                </div>
              </div>
            </div>

            <template
              v-for="item in filteredWorkspacesOwnedByMe"
              data-cy="workspace_items_list"
            >
              <div
                v-if="item.workspace && item.status === 'joined'"
                :key="item.workspace_id"
                class="component_inner__workspace_list__w_box"
              >
                <template v-if="checkIfWorkspaceHasPaymentIssue(item)">
                  <WorkspaceLockedTile :item="item"></WorkspaceLockedTile>
                </template>
                <template v-else class="box_inner">
                  <WorkspaceActiveTile :item="item"></WorkspaceActiveTile>
                </template>
              </div>
            </template>
          </div>

          <template v-if="filteredWorkspacesNotOwnedByMe.length > 0">
            <h2 class="style_header">Workspaces I Am A Member Of</h2>

            <hr class="solid" />

            <div class="box_container">
              <template
                v-for="item in this.filteredWorkspacesNotOwnedByMe"
                data-cy="workspace_items_list"
              >
                <div
                  v-if="item.workspace && item.status === 'joined'"
                  :key="item.workspace_id"
                  class="component_inner__workspace_list__w_box"
                >
                  <template v-if="checkIfWorkspaceHasPaymentIssue(item)">
                    <WorkspaceLockedTile :item="item"></WorkspaceLockedTile>
                  </template>
                  <template v-else class="box_inner">
                    <WorkspaceActiveTile :item="item"></WorkspaceActiveTile>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { commonMethods } from '@common/store/common-methods'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'
import removeConfirmation from './RemoveConfirmation'
import WorkspaceActiveTile from './reusable/WorkspaceActiveTile'
import WorkspaceLockedTile from './reusable/WorkspaceLockedTile'
import useWorkspace from '@/src/modules/common/composables/useWorkspace'
import { EventBus } from '@common/lib/event-bus'

export default {
  components: {
    removeConfirmation,
    WorkspaceActiveTile,
    WorkspaceLockedTile,
  },
  setup(){
    const {fetchAllUserWorkspaces, isWorkspaceLoading, userWorkspaces, updateUserWorkspaces} = useWorkspace()

    return {
      isWorkspaceLoading,
      userWorkspaces,
      fetchAllUserWorkspaces,
      updateUserWorkspaces
    }
  },
  data() {
    return {
      searchQuery: '',
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getCoveredStories',
      'getCuratedQuotes',
      'getProfile',
      'getOrganizationMembers',
      'getOrganizationLoaders',
    ]),
    showSearchOption() {
      return this.getWorkspaces.items.length > 4
    },
    filteredWorkspacesOwnedByMe() {
      return this.filterWorkspaces(true)
    },

    filteredWorkspacesNotOwnedByMe() {
      return this.filterWorkspaces(false)
    },
  },
  created() {
    this.$store.commit(workspaceTypes.SET_WORKSPACE_SEARCH, '')
    if (this.$route.query.is_admin_logged_in) {
      this.setIsAdminLogin(true)
    }
    this.$store.dispatch('fetchAllMembersOfOrganization')
    if(this.userWorkspaces.length === 0){
      this.fetchAllUserWorkspaces()
    }
    // if (this.$route.name === 'workspaces') {
    //     document.body.classList.add('force-white-bg')
    // } else {
    //     document.body.classList.remove('force-white-bg')
    // }
  },
  mounted() {
    EventBus.$on('fetch-latest-workspaces', async () => {
     await this.fetchAllUserWorkspaces()
    })
    // Listen for updates and handle actions based on type and fields
    EventBus.$on('update-users-workspaces', (info = {}) => {
      console.log('update-users-workspaces', info)
      this.updateUserWorkspaces(info);
    });

    commonMethods.toggleWidgets(true)
  },
  unmounted() {
    EventBus.$off('fetch-latest-workspaces')
    EventBus.$off('update-users-workspaces')
    commonMethods.toggleWidgets(false)
  },
  methods: {
    ...mapActions(['setIsAdminLogin']),
    redirectForDiscovery() {
      if (this.getWorkspaces.activeWorkspace) {
        const activeWorkspaceMember = this.getLoggedUserRole(
          this.getWorkspaces.activeWorkspace
        )
        if (
          activeWorkspaceMember &&
          activeWorkspaceMember.role === 'approver'
        ) {
          this.$router.push({
            name: 'planner_feed_v2',
            params: { workspace: this.getWorkspaces.activeWorkspace.slug },
          })
        } else {
          if (this.getCoveredStories) {
            this.$router.push({
              name: 'cover_stories',
              params: { workspace: this.getWorkspaces.activeWorkspace.slug },
            })
            return
          }
          if (this.getCuratedQuotes) {
            this.$router.push({
              name: 'topics_quotes',
              params: { workspace: this.getWorkspaces.activeWorkspace.slug },
            })
            return
          }
          this.$router.push({
            name: 'discover_search',
            params: { workspace: this.getWorkspaces.activeWorkspace.slug },
          })
        }
      }
    },
    redirectForDashboard() {
      console.log('METHOD::redirectForDashboard')
      if (this.getWorkspaces.activeWorkspace) {
        const activeWorkspaceMember = this.getLoggedUserRole(
          this.getWorkspaces.activeWorkspace
        )
        if (
          activeWorkspaceMember &&
          activeWorkspaceMember.role === 'approver'
        ) {
          this.$router.push({
            name: 'planner_feed_v2',
            params: { workspace: this.getWorkspaces.activeWorkspace.slug },
          })
        } else {
          if (this.getCoveredStories) {
            this.$router.push({
              name: 'cover_stories',
              params: { workspace: this.getWorkspaces.activeWorkspace.slug },
            })
            return
          }
          if (this.getCuratedQuotes) {
            this.$router.push({
              name: 'topics_quotes',
              params: { workspace: this.getWorkspaces.activeWorkspace.slug },
            })
            return
          }
          this.$router.push({
            name: 'dashboard',
            params: { workspace: this.getWorkspaces.activeWorkspace.slug },
          })
        }
      }
    },
    filterWorkspaces(isOwner) {
      const ownedByMe = []
      const ownedByOthers = []
      const lowercaseSearch = this.searchQuery.toLowerCase()
      for (let x = 0; x < this.userWorkspaces.length; x++) {
        const workspace = this.userWorkspaces[x]
        const workspaceName = workspace.workspace.name.toLowerCase()

        if (workspaceName.includes(lowercaseSearch)) {
          if (workspace.workspace.user_id === this.getProfile._id) {
            ownedByMe.push(workspace)
          } else {
            ownedByOthers.push(workspace)
          }
        }
      }
      if (isOwner) {
        return ownedByMe
      } else {
        return ownedByOthers
      }
    },
  },
}
</script>

<style lang="less">
.status-show {
  margin-top: 0.625rem;
}

/* Solid border */
hr.solid {
  border-top: 0.5px solid #bbb;
  margin-left: 20px;
  margin-right: 20px;
}

.style_header {
  margin-left: 20px;
}
</style>
