<template>
  <div
      v-tooltip="{
          content: tooltipContent,
        }">
    <input
       :id="generatedId"
      type="radio"
      :name="name"
      :value="value"
      :checked="isChecked"
      :disabled="disabled"
      @change="$emit('change', $event.target.value)"
    />
    <label :for="generatedId" class="mb-0" :class="{ 'opacity-50': disabled }">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CstRadio',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number, Array, Boolean],
      default: '',
    },
    modelValue: {
      type: [String, Number, Array],
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltipContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      content: this.value,
    }
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value
    },
    generatedId() {
      if (this.id.trim() === '') {
        const S4 = function () {
          return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
        }
        return (
          S4() +
          S4() +
          '-' +
          S4() +
          '-' +
          S4() +
          '-' +
          S4() +
          '-' +
          S4() +
          S4() +
          S4()
        )
      }

      return this.id
    },
  },
  methods: {
    handleInput($event) {
      this.$emit('change', $event)
    },
  },
}
</script>

<style scoped>
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  @apply text-gray-900;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid rgba(58, 69, 87, 0.3);
  border-radius: 100%;
  background: #fbfcfc;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 3px solid #549aee;
  border-radius: 100%;
  background: #fff;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

[type='radio']:checked + label {
  @apply text-gray-900 font-medium;
}
</style>
