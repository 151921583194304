export const integrations = {
  SET_FETCH_ACCOUNTS_STATUS: 'SET_FETCH_INTEGRATIONS_ACCOUNTS_STATUS',
  SET_FETCH_ACCOUNTS_LOADER: 'SET_FETCH_INTEGRATIONS_ACCOUNTS_LOADER',
  SET_FETCH_AUTHORIZATION_STATUS: 'SET_FETCH_INTEGRATIONS_AUTHORIZATION_STATUS',
  SET_AUTHORIZATION: 'SET_INTEGRATIONS_AUTHORIZATION',
  SET_POCKET_ACCOUNTS: 'SET_POCKET_ACCOUNTS',
  SET_REPLUG_ACCOUNT: 'SET_REPLUG_ACCOUNT',
  SET_REPLUG_BRANDS: 'SET_REPLUG_BRANDS',
  SET_REPLUG_SELECTED_BRAND: 'SET_REPLUG_SELECTED_BRAND',
  SET_REPLUG_SELECTED_CTA: 'SET_REPLUG_SELECTED_CTA',
  SET_REPLUG_EDIT_STATUS: 'SET_REPLUG_EDIT_STATUS',
  SET_FEEDLY_ACCOUNTS: 'SET_FEEDLY_ACCOUNTS',
  SET_FEEDLY_TOPICS: 'SET_FEEDLY_TOPICS',
}

export const platforms = {
  SET_CONNECTION_STATE: 'SET_PLATFORMS_CONNECTION_STATE',
  SET_SAVE_PLATFORMS_LOADER: 'SET_SAVE_PLATFORMS_LOADER',
  SET_DEFAULT_STATES: 'SET_DEFAULT_PLATFORMS_STATES',
  SET_ALL_PLATFORMS: 'SET_ALL_PLATFORMS',
}

export const blog = {
  SET_AUTHORIZATION: 'SET_BLOGS_AUTHORIZATION',
  SET_FETCH_STATUS: 'SET_FETCH_BLOGS_STATUS',
  SET_FETCH_BLOGS_LOADER: 'SET_FETCH_BLOGS_LOADER',
  SET_FETCH_BLOG_LOADER: 'SET_FETCH_BLOG_LOADER',
  SET_BLOGS: 'SET_BLOGS',
  SET_BLOG_DETAILS: 'SET_BLOG_DETAILS',
  SET_PLUGIN_TOKEN: 'SET_PLUGIN_TOKEN',
}

export const social = {
  SET_AUTHORIZATION_LINKS: 'SET_SOCIAL_AUTHORIZATION_LINKS',
  SET_ACCOUNTS_STATUS: 'SET_ACCOUNTS_STATUS',
  SET_ACCOUNTS_LOADER: 'SET_ACCOUNTS_LOADER',
  SET_QUEUE_SELECTED_ITEM: 'SET_ACCOUNT_QUEUE_SELECTED_ITEM',
  SET_FETCH_QUEUE_SLOTS_LOADER: 'SET_FETCH_ACCOUNT_QUEUE_SLOTS_LOADER',
  SET_QUEUE_PLANS_COUNT: 'SET_ACCOUNT_QUEUE_PLANS_COUNT',
  SET_QUEUE_TYPE: 'SET_ACCOUNT_QUEUE_TYPE',
  SET_QUEUE_CUSTOM_SLOTS: 'SET_ACCOUNT_QUEUE_CUSTOM_SLOTS',
  SET_QUEUE_PRIME_STATUS: 'SET_ACCOUNT_QUEUE_PRIME_STATUS',
  SET_QUEUE_PRIME_SLOTS: 'SET_ACCOUNT_QUEUE_PRIME_SLOTS',
  SET_DEFAULT_STATES: 'SET_DEFAULT_SOCIAL_STATES',
  SET_PLATFORM_LIST: 'SET_PLATFORM_ACCOUNTS_LIST',
}

export const facebook = {
  SET_ACCOUNTS: 'SET_FACEBOOK_ACCOUNTS',
  SET_ALL_ACCOUNTS: 'SET_ALL_FACEBOOK_ACCOUNTS',
  SET_DEFAULT: 'SET_DEFAULT_FACEBOOK',
  SET_LOCATION: 'SET_FACEBOOK_LOCATION',
}

export const twitter = {
  SET_ACCOUNTS: 'SET_TWITTER_ACCOUNTS',
  SET_ALL_ACCOUNTS: 'SET_ALL_TWITTER_ACCOUNTS',
  SET_DEFAULT: 'SET_DEFAULT_TWITTER',
}

export const threads = {
  SET_ACCOUNTS: 'SET_THREADS_ACCOUNTS',
  SET_ALL_ACCOUNTS: 'SET_ALL_THREADS_ACCOUNTS',
  SET_DEFAULT: 'SET_DEFAULT_THREADS',
}

export const linkedin = {
  SET_ACCOUNTS: 'SET_LINKEDIN_ACCOUNTS',
  SET_ALL_ACCOUNTS: 'SET_ALL_LINKEDIN_ACCOUNTS',
  SET_DEFAULT: 'SET_DEFAULT_LINKEDIN',
}

export const pinterest = {
  SET_ACCOUNTS: 'SET_PINTEREST_ACCOUNTS',
  SET_ALL_ACCOUNTS: 'SET_ALL_PINTEREST_ACCOUNTS',
  SET_DEFAULT: 'SET_DEFAULT_PINTEREST',
}

export const tumblr = {
  SET_ACCOUNTS: 'SET_TUMBLR_ACCOUNTS',
  SET_ALL_ACCOUNTS: 'SET_ALL_TUMBLR_ACCOUNTS',
  SET_DEFAULT: 'SET_DEFAULT_TUMBLR',
}

export const instagram = {
  SET_ACCOUNTS: 'SET_INSTAGRAM_ACCOUNTS',
  SET_ALL_ACCOUNTS: 'SET_ALL_INSTAGRAM_ACCOUNTS',
  SET_NOTIFICATION_DEVICES: 'SET_INSTAGRAM_NOTIFICATION_DEVICES',
  SET_CONNECTOR_ACCOUNT: 'SET_INSTAGRAM_CONNECTOR_ACCOUNT',
  SET_DEFAULT: 'SET_DEFAULT_INSTAGRAM',
  SET_LOCATION: 'SET_INSTAGRAM_LOCATION',
}

export const gmb = {
  SET_ACCOUNTS: 'SET_GMB_ACCOUNTS',
  SET_ALL_ACCOUNTS: 'SET_ALL_GMB_ACCOUNTS',
  SET_DEFAULT: 'SET_DEFAULT_GMB',
}

export const youtube = {
  SET_ACCOUNTS: 'SET_YOUTUBE_ACCOUNTS',
  SET_ALL_ACCOUNTS: 'SET_ALL_YOUTUBE_ACCOUNTS',
  SET_DEFAULT: 'SET_DEFAULT_YOUTUBE',
}

export const tiktok = {
  SET_ACCOUNTS: 'SET_TIKTOK_ACCOUNTS',
  SET_ALL_ACCOUNTS: 'SET_ALL_TIKTOK_ACCOUNTS',
  SET_DEFAULT: 'SET_DEFAULT_TIKTOK',
}

export const shortener = {
  SET_BITLY_ACCOUNT: 'SET_BITLY_ACCOUNT',
  SET_BITLY_BASE: 'SET_BITLY_BASE',
}

export const rewriter = {
  SET_CHIMP_REWRITER_ACCOUNT: 'SET_CHIMP_REWRITER_ACCOUNT',
  SET_SPIN_REWRITER_ACCOUNT: 'SET_SPIN_REWRITER_ACCOUNT',
  SET_QUILLBOT_ACCOUNT: 'SET_QUILLBOT_ACCOUNT',
  SET_YANDEX_ACCOUNT: 'SET_YANDEX_ACCOUNT',
}
