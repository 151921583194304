<script>
import { mapGetters } from 'vuex'
import proxy from '@common/lib/http-common'
import { billingBaseUrl } from '@src/config/api-utils'

export default {
  data () {
    return {
      loaders: {
        cancel_subscription: false
      },
      step: 'first',
      cancellation_reason: '',
      can_stay: '',
      question: 'I have a question about getting started with Contentstudio.io',
      question_details: '',
      permission_granted: false
    }
  },
  computed: {
    ...mapGetters(['getCancelPlanLoader']),
    showBackButton () {
      return (
        this.step === 'second' || this.step === 'third' || this.step === 'forth'
      )
    }
  },
  methods: {
    moveBack () {
      if (this.step === 'second') return this.moveToStep('first')
      if (this.step === 'third') return this.moveToStep('second')
      if (this.step === 'forth') return this.moveToStep('third')
    },
    moveToStep (step) {
      if (step === 'third') {
        if (this.cancellation_reason === '') { return this.alertMessage('Please select canceling reason.', 'error') }
        if (this.can_stay === '') {
          return this.alertMessage(
            'Please provide some details of canceling.',
            'error'
          )
        }
        if (this.can_stay.length < 10) {
          return this.alertMessage(
            'Canceling details must be at least 10 characters.',
            'error'
          )
        }
      }
      this.step = step
    },
    async cancelSubscriptionPlan () {
      console.debug('Method::cancelPlan')
      this.loaders.cancel_subscription = true
      const res = await proxy
        .post(`${billingBaseUrl}paddle/user_cancellation`, {
          plan_name: this.$store.getters.getPlan?.subscription?.display_name,
          cancellation_reason: this.cancellation_reason,
          can_stay: this.can_stay,
          type: this.$store.getters.getPlan.type
        })
        .then((res) => {
          // send event to usermaven
          this.trackUserMaven('account_cancellation_feedback',{
            plan_name: this.$store.getters.getPlan?.subscription?.display_name,
            cancellation_reason: this.cancellation_reason,
            other: this.can_stay,
          })

          if (res.data.message) {
            this.$store.dispatch('toastNotification', {
              message: res.data.message
            })
            this.$bvModal.hide('cancel-recurring-plan-dialog')
            this.step = 'first'
            setTimeout(() => {
              window.location.reload()
            }, 3000)
            // this.$router.push({ 'name': 'subscription_cancelled' })
          }

          this.loaders.cancel_subscription = false
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 422) {
            this.$store.dispatch('toastNotification', {
              message: err.response.data.message,
              type: 'error'
            })
          }
          this.loaders.cancel_subscription = false
        })
    }
  }
}
</script>

<template>
  <!--step_one-->
  <b-modal
    id="cancel-recurring-plan-dialog"
    modal-class="normal_modal cancelPlanStepsModal"
    hide-footer
    hide-header
    :dialog-class="{ step_four: step === 'forth' }"
    @hidden="moveToStep('first')"
  >
    <div class="basic_form">
      <div class="modal_head">
        <h2 v-if="step === 'first'" class=" ">Closing your account?</h2>
        <h2 v-else-if="step === 'second'" class=" "
          >It breaks our <i class="far fa-heart"></i> to see you leave
        </h2>
        <h2 v-else-if="step === 'third'">We still think you're missing out</h2>
        <h2 v-else-if="step === 'forth'">Let us know what's on your mind</h2>
        <i
          class="fal fa-times normal_close"
          @click="$bvModal.hide('cancel-recurring-plan-dialog')"
        ></i>
      </div>

      <div class="modal_body basic_form">
        <transition name="fadeIn" mode="out-in">
          <div v-if="step === 'first'" class="step_one">
            <p class="warning_box"
              ><i class="far fa-exclamation mr-2"></i>You will lose access to
              your account if you cancel your account.</p
            >
          </div>

          <div v-else-if="step === 'second'" class="step_two">
            <p class="desc"
              >You'll lose access to your work if you cancel your account.
              <br />
              Please export anything you want to save first.</p
            >
            <div class="input_field">
              <label for="" class="font-weight-500"
                >Why are you canceling?<span class="reddit ml-1">*</span></label
              >
              <select v-model="cancellation_reason">
                <option value="" disabled="disabled">Select a reason</option>
                <option value="Team not adapting">Team not adapting</option>
                <option value="Missing features">Missing features</option>
                <option value="I don't see enough value for the price"
                  >I don't see enough value for the price
                </option>
                <option value="Price is too high for us to afford it"
                  >Price is too high for us to afford it
                </option>
                <option value="Closing Company/Project/Downsizing"
                  >Closing Company/Project/Downsizing
                </option>
                <option value="Already paying for another account"
                  >Already paying for another account
                </option>
                <option
                  value="Could not upgrade/downgrade due to credit card issue"
                  >Could not upgrade/downgrade due to credit card issue
                </option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div class="input_field">
              <label for="" class="font-weight-500"
                >Please provide some details?
                <span class="reddit ml-1">*</span></label
              >
              <textarea
                v-model="can_stay"
                name=""
                cols="30"
                rows="3"
              ></textarea>
            </div>
          </div>
        </transition>

        <div class="input_field d-flex align-content-center">
          <div v-if="showBackButton" class="left">
            <b-button variant="studio-theme-grey-space" @click="moveBack">
              Back
            </b-button>
          </div>

          <div class="right ml-auto">
            <template v-if="step === 'first' || step === 'second'">
              <button
                v-if="step === 'first'"
                class="p-0 border-0 red border_btn btn large_btn btn_loader loader_right"
                @click="moveToStep('second')"
              >
                <span>Close account</span>
              </button>

              <button
                v-if="step === 'second'"
                :disabled="loaders.cancel_subscription"
                class="p-0 border-0 red border_btn btn large_btn btn_loader loader_right"
                @click="cancelSubscriptionPlan"
              >
                <span
                  >Cancel plan
                  <clip-loader
                    v-if="loaders.cancel_subscription"
                    :size="'12px'"
                    color="#e02f5f"
                  ></clip-loader>
                </span>
              </button>

              <b-button
                variant="studio-theme-space"
                class="ml-3"
                @click="$bvModal.hide('cancel-recurring-plan-dialog')"
              >
                Keep my account
              </b-button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style lang="less">
.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 0.2s;
}

.fadeIn-enter, .fadeIn-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.step_one {
  .warning_box {
    padding: 0.8rem 1rem;
  }
}
</style>
