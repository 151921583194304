<template>
  <div class='inbox-body'>
    <!-- Users List -->
    <div class='inbox-users-list'>
      <div class='inbox_header'>
        <div class='inbox_header__label'
        >{{ capitalizeFilterName(getFilterName) }}
        </div>
        <div class='inbox_header__searchbar'>
          <form v-click-away='toggleClose' @submit='executeFromZero'>
            <input
              id='search'
              ref='search-bar'
              v-model='searchTerm'
              type='search'
              placeholder='Search conversation'
              @keydown.enter.exact.prevent='executeFromZero'
            />
            <span
              v-if='!toggleBlock'
              class='close-btn'
              @click='toggleClose(false)'
            >
              <i class='far fa-times'></i>
            </span>
            <label for='search' @click='expendSearch'>
              <i class='icon-Search'></i>
            </label>
          </form>
        </div>
      </div>
      <div class='inbox-users-list__filter'>
        <template v-if='selectedBulkUpdate.length <= 1'>
          <b-dropdown
            ref='dropdown'
            variant='studio-theme'
            class='studio-theme-dropdown studio-theme-dropdown--checkbox'
            no-caret
            menu-class='!w-56'
          >
            <template v-slot:button-content>
              <i class='far fa-filter studio-theme-icon'></i>
              <span class='capitalize_text'>Filter by type</span>
              <i class='icon-dropdown-cs'></i>
            </template>

            <b-form-group class='m-0' @submit.stop.prevent>
              <div class='field_group m-0'>
                <div class='checkbox_container'>
                  <label class='!p-[0.5rem] hover:text-[#16181b] hover:bg-[#e9ecef]'>
                    All
                    <input
                      v-model='allTypeFilter'
                      type='checkbox'
                      @change="commonMethods.setUnsetAll(temp, 'type')"
                    />
                    <span class='check !left-auto !top-auto right-[15px]'></span>
                  </label>
                </div>
              </div>
            </b-form-group>

            <template v-for='type in filterTypes' :key='type.key'>
              <b-form-group
                v-if='showFilter(type.key)'
                class='m-0'
                @submit.stop.prevent
              >
                <div class='field_group m-0'>
                  <div class='checkbox_container group'>
                    <label class=' !p-[0.5rem] hover:text-[#16181b] hover:bg-[#e9ecef]'>
                      {{ type.name }}
                      <input
                        :id='type.key'
                        v-model='selectedFilterTypes'
                        type='checkbox'
                        :value='type.key'
                        @change='setTypeFilters'
                      />
                      <span class='check !left-auto !top-auto right-[15px]'></span>
                      <span
                        data-only='true'
                        class='ml-2 text-xs font-normal border rounded-md bg-gray-400 hover:bg-gray-500 px-2 py-1 hidden group-hover:inline-flex absolute right-6 mr-4'
                        @click='onClickTest($event, type.key)'
                      >
                        only
                      </span>
                    </label>
                  </div>
                </div>
              </b-form-group>
            </template>
          </b-dropdown>
        </template>

        <template v-else>
          <b-dropdown
            ref='dropdown'
            variant='studio-theme'
            class='studio-theme-dropdown studio-theme-dropdown--nested'
            no-caret
          >
            <template v-slot:button-content>
              <i class='far fa-box studio-theme-icon'></i>
              <span class='capitalize_text'>
                Bulk Actions ({{ selectedBulkUpdate.length }})</span
              >
              <i class='icon-dropdown-cs'></i>
            </template>

            <b-dropdown-item v-b-hover='handleBulkHover'>
              <span class='item'
              >Assign To <i class='fa fa-angle-right'></i
              ></span>
              <transition name='fade'>
                <b-list-group v-if='isBulkHovered' class='list'>
                  <b-list-group-item
                    @click="
                      updateConversationsStatuses('assigned', false, null)
                    "
                  >
                    <div class='list-image pr-2'>
                      <img :src='commonMethods.getDefaultImage()' alt='' />
                    </div>
                    <p class='text'>Unassigned</p>
                    <div
                      v-if="getInboxFilter.action === 'UNASSIGNED'"
                      class='ml-auto pl-4'
                    >
                      <i class='fa fa-check check_assign'></i>
                    </div>
                  </b-list-group-item>

                  <template v-for='member in getWorkspaceInboxTeamMembers' :key='member.user._id'>
                    <template v-if='member.user'>
                      <b-list-group-item
                        @click="
                          updateConversationsStatuses(
                            'assigned',
                            true,
                            member.user,
                          )
                        "
                      >
                        <div v-if='member.user.image' class='list-image pr-2'>
                          <img :src='member.user.image' alt='' />
                        </div>
                        <div v-else class='list-image pr-2'>
                          <img :src='commonMethods.getDefaultImage()' alt='' />
                        </div>

                        <p class='text'>{{ member.user.full_name }}</p>
                        <div
                          v-if="
                            getInboxFilter.action === 'MINE' &&
                            getProfile._id === member.user._id
                          "
                          class='ml-auto pl-4'
                        >
                          <i class='fa fa-check check_assign'></i>
                        </div>
                      </b-list-group-item>
                    </template>
                  </template>
                </b-list-group>
              </transition>
            </b-dropdown-item>
            <b-dropdown-item
              @click="updateConversationsStatuses('marked_done', true)"
            ><span class='item'>Mark as Done </span></b-dropdown-item
            >
            <b-dropdown-item
              @click="updateConversationsStatuses('archived', true)"
            ><span class='item'>Archive </span></b-dropdown-item
            >
          </b-dropdown>
        </template>

        <div class='inbox-users-list__filter-controls'>
          <clip-loader
            v-if='profileLoaderTop'
            class='d-inline-block align-middle mr-2 ml-1'
            color='#989eb5'
            :size="'20px'"
          />
          <i
            v-if="selectedOrder === 'asc'"
            v-tooltip.top="'Sort by Newest'"
            class='icon-Sort-2 down'
            @click="setOrder('asc')"
          ></i>
          <i
            v-else
            v-tooltip.top="'Sort by Oldest'"
            class='icon-Sort-2'
            @click="setOrder('desc')"
          ></i>

          <div v-if='canPerformBulkAction()' class='field_group'>
            <div class='checkbox_container'>
              <label>
                <input
                  v-if='canPerformBulkAction()'
                  type='checkbox'
                  :checked='allBulkSelect'
                  @change='selectAllConversations'
                />
                <span class='check'></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class='inbox-users-list__users'>
        <ul class='inbox-users-list__users-list'>
          <div
            v-if='
              toggleBlock === false &&
              search === false &&
              inboxDetails.length > 0
            '
            class='loader_content no_data_found_content padding_top_25'
          >
            <content-available-state
              :type="'inbox_search_conversations'"
              :size="'small'"
            >
              <h2 slot='headline'>Search for Posts/ Conversations here</h2>
              <p slot='text'>Type the name to search</p>
            </content-available-state>
          </div>
          <template v-else-if='inboxDetails.length > 0'>
            <template v-for='inboxDetail in getConversationList' :key='inboxDetail.element_details.element_id'>
              <template
                v-if="
                  inboxDetail.platform === 'linkedin' &&
                  !inboxDetail.element_details.post_message &&
                  !inboxDetail.element_details.post_attachment.length
                "
              >
              </template>
              <template v-else>
                <div
                  v-tooltip.top='
                    {
                      content: getPageName(inboxDetail.platform, inboxDetail.platform_id),
                    }'>
                  <li
                    class='inbox-users-list__user'
                    :class='[
                    {
                      active:
                        inboxDetail.element_details.element_id ===
                        activeInboxDetail.element_details.element_id,
                    },
                    {
                      selected_conversation: selectedBulkUpdate.includes(
                        inboxDetail.element_details.element_id,
                      ),
                    },
                    checkReadStatus(inboxDetail.element_details.read_status),
                  ]'
                    @click='setElementDetails(inboxDetail)'
                    @mouseover='inboxDetail.is_mouse_hover = true'
                    @mouseleave='inboxDetail.is_mouse_hover = false'
                  >
                    <div
                      class='inbox-users-list__user-avatar'
                      :class='getPlatformNameColor(inboxDetail.platform)'
                    >
                      <img
                        :src='
                        commonMethods.getPostedByImage(
                          inboxDetail.inbox_details.posted_by,
                        )
                      '
                        alt=''
                        @error="
                        $event.target.src = fallbackImagePreview(
                          commonMethods
                            .getPostedByName(
                              inboxDetail.inbox_details.posted_by
                                ? inboxDetail.inbox_details.posted_by
                                : '',
                            )[0]
                            .toUpperCase(),
                          '63A3F2',
                          'white',
                        )
                      "
                      />
                      <i :class='getPlatformName(inboxDetail.platform)'></i>
                    </div>
                    <div class='flex-grow-1'>
                      <div
                        class='inbox-users-list__user-info'
                        :class='getPlatformNameColor(inboxDetail.platform)'
                      >
                        <div class='inbox-users-list__user-info--title'>
                          <strong
                          >{{
                              limitText(
                                commonMethods.getPostedByName(
                                  inboxDetail.inbox_details.posted_by,
                                  capitalizeFirstLetter(inboxDetail.platform),
                                ),
                                15,
                              )
                            }}
                          </strong>
                          <div
                            class='checkbox_input_image'
                            :style="{
                            opacity:
                              (canPerformBulkAction() &&
                                inboxDetail['is_mouse_hover']) ||
                              selectedBulkUpdate.includes(
                                inboxDetail.element_details.element_id,
                              )
                                ? 1
                                : 0,
                            pointerEvents:
                              (canPerformBulkAction() &&
                                inboxDetail['is_mouse_hover']) ||
                              selectedBulkUpdate.includes(
                                inboxDetail.element_details.element_id,
                              )
                                ? 'unset'
                                : 'none',
                          }"
                            style='
                            position: absolute;
                            right: 0;
                            padding-right: 1rem;
                            margin-top: 0.725rem;
                          '
                          >
                            <div class='field_group'>
                              <div class='checkbox_container'>
                                <label
                                  :for='inboxDetail.element_details.element_id'
                                  @click.stop=''
                                >
                                  <input
                                    :id='inboxDetail.element_details.element_id'
                                    v-model='selectedBulkUpdate'
                                    type='checkbox'
                                    :value='
                                    inboxDetail.element_details.element_id
                                  '
                                    @click.stop=''
                                  />
                                  <span class='check'></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <span
                            :style="{
                            opacity:
                              (canPerformBulkAction() &&
                                inboxDetail['is_mouse_hover']) ||
                              selectedBulkUpdate.includes(
                                inboxDetail.element_details.element_id,
                              )
                                ? 0
                                : 1,
                            pointerEvents:
                              (canPerformBulkAction() &&
                                inboxDetail['is_mouse_hover']) ||
                              selectedBulkUpdate.includes(
                                inboxDetail.element_details.element_id,
                              )
                                ? 'none'
                                : 'unset',
                          }"
                            class='sub_text date_time'
                          >
                          {{ commonMethods.timesAgo(inboxDetail.updated_at) }}
                          <i
                            v-tooltip="
                              capitalizeFirstLetter(
                                inboxDetail.inbox_type
                                  ? inboxDetail.inbox_type
                                  : '',
                              )
                            "
                            class='ml-1 fal'
                            :class='icon[inboxDetail.inbox_type]'
                          >
                          </i>
                          <span
                            v-tooltip='
                              commonMethods.getSocialDetail(temp, inboxDetail)
                                .name
                            '
                            class='read-img'
                          >
                            <img
                              style='width: 100%; border-radius: 50%'
                              :src='
                                commonMethods.getSocialDetail(temp, inboxDetail)
                                  .image
                              '
                              alt=''
                              @error='
                                $event.target.src = fallbackImagePreview(
                                  commonMethods.getSocialDetail(
                                    temp,
                                    inboxDetail,
                                  ).name[0],
                                )
                              '
                            />
                          </span>
                        </span>
                        </div>
                        <p
                          v-if="
                          inboxDetail.inbox_details.last_replied_by &&
                          inboxDetail.platform !== 'linkedin'
                        "
                          class='inbox-users-list__user-info--message'
                        >
                        <span
                          v-if='
                            inboxDetail.inbox_details.last_replied_by.user_name
                          '
                        >
                          {{
                            inboxDetail.inbox_details.last_replied_by.user_name
                          }}:
                        </span>
                          <span v-else>{{
                              limitText(checkType(inboxDetail), 18)
                            }}</span>
                        </p>
                        <p v-else class='inbox-users-list__user-info--message'>
                          <!--                                        Social image -->
                          <span
                            v-if="inboxDetail.platform === 'linkedin'"
                            v-html='
                            inboxText(
                              inboxDetail.element_details.post_message,
                              30,
                            )
                          '
                          ></span>
                          <span v-else>{{
                              limitText(checkType(inboxDetail), 30)
                            }}</span>
                        </p>
                      </div>
                    </div>
                  </li>
                </div>

              </template>
            </template>

            <InfiniteLoading
              style='padding-top: 0.625rem; padding-bottom: 0.625rem'
              spinner='spiral'
              @infinite='infiniteHandler'
            >
              <template v-slot:no-more>
                <div class='infinite-loading-message'>End of the line.</div>
              </template>
              <template v-slot:no-results>
                <div
                  class='loader_content no_data_found_content padding_top_25'
                >
                  <content-available-state
                    :type="'inbox_search_conversations'"
                    :size="'small'"
                  >
                    <template v-slot:headline><h2>No Conversations or Posts Found</h2></template>
                    <template v-slot:text><p>Change filters to Fetch Posts/ Conversations</p></template>
                  </content-available-state>
                </div>
              </template>
            </InfiniteLoading>
          </template>
        </ul>
      </div>
    </div>

    <!--  Messages   -->
    <div
      :class='{
        loader_overlay_with_loader: !commonMethods.isEmpty(activeInboxDetail),
      }'
      class='chat_conversation_block inbox-message-area'
    >
      <div
        v-if='
          toggleBlock === false && search === false && inboxDetails.length > 0
        '
        class='padding_top_25'
      >
        <content-available-state :type="'inbox_search_conversations'">
          <h2 slot='headline'></h2>
          <p slot='text'><strong>Search for Posts/ Conversations</strong></p>
        </content-available-state>
      </div>
      <div
        v-else-if='profileLoader'
        style='
          margin-left: auto;
          margin-right: auto;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          flex-direction: column;
        '
      >
        <clip-loader
          color='#989eb5'
          size='6.2rem'
          style='position: unset; margin-bottom: 1.2rem'
        />
        <h2 style='font-weight: 600; font-size: 20px; text-align: center'
        >Loading Conversations and Posts.</h2
        >
      </div>
      <div
        v-else-if='!commonMethods.isEmpty(activeInboxDetail)'
        style='padding: 5rem'
      >
        <content-available-state :type="'inbox_search_conversations'">
          <h2 slot='headline'>No Messages/Comments Found</h2>
          <p slot='text'></p>
        </content-available-state>
      </div>
      <template v-else>
        <template v-if="activeInboxDetail.inbox_type === 'conversation'">
          <Conversation
            :active-inbox-detail='activeInboxDetail'
            :reset-view='removeElementFromInboxDetails'
            @go-to='referenceToAnotherConversation'
          ></Conversation>
        </template>
        <template v-else>
          <Post
            v-if="activeInboxDetail.platform === 'facebook'"
            :active-inbox-detail='activeInboxDetail'
            :reset-view='removeElementFromInboxDetails'
            @go-to='referenceToAnotherConversation'
          >
          </Post>
          <TwitterPost
            v-else-if="activeInboxDetail.platform === 'twitter'"
            :active-inbox-detail='activeInboxDetail'
            :reset-view='removeElementFromInboxDetails'
          >
          </TwitterPost>
          <InstagramPost
            v-else-if="activeInboxDetail.platform === 'instagram'"
            :active-inbox-detail='activeInboxDetail'
            :reset-view='removeElementFromInboxDetails'
          >
          </InstagramPost>
          <GmbReview
            v-else-if="activeInboxDetail.platform === 'google_my_business'"
            :active-inbox-detail='activeInboxDetail'
            :reset-view='removeElementFromInboxDetails'
          >
          </GmbReview>
          <LinkedinPost
            v-else-if="activeInboxDetail.platform === 'linkedin'"
            :active-inbox-detail='activeInboxDetail'
            :reset-view='removeElementFromInboxDetails'
          >
          </LinkedinPost>
        </template>
      </template>
    </div>

    <!--  User Details Panel -->
    <InboxUserDetailSidebar
      v-if='commonMethods.isEmpty(activeInboxDetail)'
      :active-inbox-detail='activeInboxDetail'
      :inbox-assigned-tags='activeInboxDetail && activeInboxDetail.inbox_details.tags'
      :all-tags='workspaceTags'
      @update-conversations='updateUserDetails'
      @update-active-inbox-detail='updateActiveInboxDetail'
    ></InboxUserDetailSidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as _ from 'underscore'
import { pusherAuthSocketInbox } from '@common/lib/pusher'
import InfiniteLoading from 'vue-infinite-loading'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import {
  filterTypes,
  teamMemberFilters,
} from '@src/modules/inbox/store/inbox-filters'
import { parseDescriptionHtml } from '@common/lib/helper'
import { EventBus } from '@common/lib/event-bus'
import { nextTick } from 'vue'
import { isEqual } from "lodash";
import Conversation from './Conversation'
import Post from './Post'
import TwitterPost from './TwitterPost'
import LinkedinPost from './LinkedinPost'
import InboxUserDetailSidebar from './InboxUserDetailSidebar'
import InstagramPost from './InstagramPost'
import GmbReview from './GmbReview'

export default {
  components: {
    GmbReview,
    InboxUserDetailSidebar,
    InstagramPost,
    Conversation,
    Post,
    TwitterPost,
    LinkedinPost,
    InfiniteLoading,
  },
  props: {
    inboxDetailFilter: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      isBulkHovered: false,
      toggleBlock: true,
      eventCounts: 0,
      profileLoader: false,
      temp: this,
      inboxDetails: [],
      teamMembers: [],
      commonMethods,
      filterTypes,
      allTypeFilter: true,
      selectedFilterTypes: [],
      selectedOrder: 'desc',
      teamMemberFilters,
      selectedTeamMember: null,
      filter: null,
      icon: {
        post: 'fa-poll-h',
        conversation: 'fa-comments-alt',
        review: 'fa-stars',
      },
      activeInboxDetail: null,
      searchTerm: '',
      search: false,
      inbox_profile_channel: '',
      selectedBulkUpdate: [],
      allBulkSelect: false,
      profileLoaderTop: false,
      loadLimit: 20,
      page: 1,
      searchExpended: false,
      workspaceTags: [],
      classes: 'd-flex align-items-center  justify-content-center __icon fab',
    }
  },
  computed: {
    ...mapGetters([
      'getActiveWorkspaceAddonsLifetime',
      'getPlan',
      'getWorkspaceInboxTeamMembers',
      'getActiveWorkspace',
      'getProfile',
      'getPlatformsList',
      'getInboxFilter',
    ]),
    hasSocialInboxAccess() {
      return this.getPlan?.subscription?.features?.social_inbox
    },
    getConversationList() {
      console.log('inbox details >>', this.inboxDetails)
      if (this.inboxDetails.length > 0) {
        const conversations = orderBy(
          this.inboxDetails,
          ['updated_at'],
          ['desc'],
        )
        return conversations
      }
      return []
    },
    getFilterName() {
      return this.getInboxFilter?.action?.replace('_', ' ')?.replace('_', ' ')
    },
  },
  watch: {
    getInboxFilter: async function(newVal, oldVal) {
      if(newVal?.workspace_id && !isEqual(newVal, oldVal)) {
      console.log('WATCH::getInboxFilter ~ newVal, oldVal -> ', newVal, oldVal)
      this.selectedTeamMember = null
      this.selectedBulkUpdate = []
      this.allBulkSelect = false
      this.executeFromZero()}
    },

    activeInboxDetail: async function(newVal, oldVal) {
      // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)

      if (typeof oldVal !== 'undefined' && oldVal != null) {
        this.removeSocketListners(oldVal)
      }
      if (typeof newVal !== 'undefined' && newVal != null) {
        this.bindPusherConversations()
      }

      if (
        typeof oldVal !== 'undefined' &&
        oldVal != null &&
        typeof newVal !== 'undefined' &&
        newVal != null &&
        newVal.element_details.element_id !== oldVal.element_details.element_id
      ) {
        commonMethods.redirectConversationLink(this)
      }
    },

    selectedBulkUpdate: async function(newVal, oldVal) {
      // watch it
      this.allBulkSelect =
        newVal.length > 0 && newVal.length === this.inboxDetails.length
    },
  },
  async beforeMount() {
    await commonMethods.setUnsetAll(this, 'type', false)
    this.selectedTeamMember = null
  },
  created() {
  },
  beforeUnmount() {
    if (this.inbox_profile_channel && this.activeInboxDetail !== null) {
      this.removeSocketListners(this.activeInboxDetail)
      this.inbox_profile_channel.unsubscribe(
        'presence-inbox-profile-' + this.getActiveWorkspace._id,
      )
    }
  },
  mounted() {
    this.temp = this
    this.fetchAllTags()
    const pusher = pusherAuthSocketInbox(
      this.getProfile._id,
      this.getProfile.full_name,
      this.getProfile.email,
    )
    this.inbox_profile_channel = pusher.subscribe(
      'presence-inbox-profile-' + this.getActiveWorkspace._id,
    )
    if (this.activeInboxDetail !== null) {
      this.bindPusherConversations()
    }

    EventBus.$on('refresh-inbox-detail', () => {
      const index = this.findConversation(
        this.activeInboxDetail.element_details.element_id,
      )
      const inbox = [...this.inboxDetails]
      const selectedConversation = { ...this.activeInboxDetail }
      selectedConversation.updated_at = moment()
        .utc()
        .format('YYYY-MM-DDThh:mm:ss.000Z')
      inbox[index] = { ...selectedConversation }
      this.inboxDetails = [...inbox]
      this.activeInboxDetail = { ...selectedConversation }
    })
  },
  methods: {
    ...mapActions([
      'fetchFilteredInboxDetails',
      'searchInboxDetails',
      'markAsRead',
      'bulkUpdate',
      'fetchWorkspaceTags',
    ]),
    ...mapMutations(['setInboxFilter']),

    removeElementFromInboxDetails(elementId) {
      const index = this.findConversation(
        elementId,
      )
      const inbox = [...this.inboxDetails]

      inbox.splice(index, 1)

      this.inboxDetails = [...inbox]
      if (inbox.length > 1) {
        const selectedConversation = { ...inbox[index] }
        this.activeInboxDetail = { ...selectedConversation }
      }
    },
    onClickTest(e, key) {
      if (e.target.dataset.only) {
        this.handleOnlyFilter(key)
      }
    },
    handleOnlyFilter(key) {
      this.selectedFilterTypes = []
      const onlyFilter = [key]
      EventBus.$emit('update-side-bar', { typeFilters: onlyFilter })
    },
    handleBulkHover(hovered) {
      this.isBulkHovered = hovered
    },
    expendSearch() {
      if (this.toggleBlock) {
        this.toggleBlock = !this.toggleBlock
        this.search = false
        this.searchExpended = true
        this.$refs['search-bar'].classList.add('expended')
      } else {
        //  Submit Search
        this.executeFromZero()
      }
    },
    expendClose() {
      this.searchExpended = false
      this.$refs['search-bar'].classList.remove('expended')
    },
    executeFromZero() {
      this.page = 1
      this.inboxDetails = []
      this.executeFilter()
    },
    mouseHasLeftTheList() {
      console.log('settting all to false .. ')
      for (const inboxDetail of this.inboxDetails) {
        inboxDetail.is_mouse_hover = false
      }
    },

    async infiniteHandler($state) {
      console.log('METHOD::infiniteHandler')
      const existingFilters = this.getInboxFilter
      const filter = {
        inbox_types: this.selectedFilterTypes,
        all_channels: existingFilters.all_channels,
        order_by: this.selectedOrder,
        search_term: this.searchTerm,
        team_member: this.selectedTeamMember?.user?._id || null,
        limit: this.loadLimit,
        page: this.page,
        workspace_id: existingFilters.workspace_id,
        tags: existingFilters.tags,
        action: existingFilters.action,
      }
      const response = await this.fetchFilteredInboxDetails(filter)
      if (response.isValid) {
        this.page += 1
        for (const inboxDetail of response.conversations) {
          this.inboxDetails.push(inboxDetail)
        }
        $state.loaded()
      } else {
        $state.loaded()
        $state.complete()
      }
    },

    bindPusherConversations() {
      if (this.activeInboxDetail === null) {
        console.log('activeInboxDetail null ==> bindPusherConversation')
        return
      }
      const tail =
        this.activeInboxDetail.element_details.element_id +
        '-' +
        this.activeInboxDetail.workspace_id
      const channelForConversationUpdateStatus =
        'channel-update-conversation-status-' + tail
      const channelForAssignStatus = 'channel-assign-status-' + tail

      const channelForUpdateConversation = 'channel-conversation-' + tail
      const channelForNewConversation =
        'channel-new-conversation-' + this.activeInboxDetail.workspace_id

      const channelUpdateInbox =
        'updated-inbox-details-' + this.activeInboxDetail.workspace_id

      const presenceChannelSubscriptionSucceeded =
        'pusher:subscription_succeeded'
      const presenceChannelSubscriptionError = 'pusher:subscription_error'

      this.inbox_profile_channel.bind(
        presenceChannelSubscriptionSucceeded,
        function(members) {
          console.log('presence channel members ==> ', members)
        },
      )

      this.inbox_profile_channel.bind(
        presenceChannelSubscriptionError,
        function(status) {
        },
      )

      this.inbox_profile_channel.bind(channelUpdateInbox, (data) => {
        this.executeFromZero()
      })

      this.inbox_profile_channel.bind(
        channelForConversationUpdateStatus,
        (data) => {
          console.log('receiving on --> ', channelForConversationUpdateStatus)
          if (
            data.conversation.element_details.element_id ===
            this.activeInboxDetail.element_details.element_id &&
            data.conversation.workspace_id ===
            this.activeInboxDetail.workspace_id
          ) {
            this.activeInboxDetail = data.conversation
            this.deleteConversation(data)
          }
        },
      )

      this.inbox_profile_channel.bind(channelForAssignStatus, (data) => {
        console.log('assign updated ==>')
        console.log(data)

        if (
          data.conversation.element_details.element_id ===
          this.activeInboxDetail.element_details.element_id &&
          data.conversation.workspace_id === this.activeInboxDetail.workspace_id
        ) {
          this.activeInboxDetail = data.conversation
          this.deleteConversation(data)
        }
      })

      this.inbox_profile_channel.bind(channelForUpdateConversation, (data) => {
        console.log('conversation received ==>')
        console.log(data)
        this.setConversation(data)
      })

      this.inbox_profile_channel.bind(channelForNewConversation, (data) => {
        console.log('new conversation received ==>')
        console.log(data)
        this.setConversation(data, true)
      })
    },

    toggleClose(clickway = true) {
      // user was typing then clicked away from search bar
      if (clickway && this.searchTerm !== '') {
        return
      }

      if (clickway && this.searchTerm === '' && this.toggleBlock === false) {
        this.executeFromZero()
      }

      // dismissed search box by clicking on the cross icon
      if (clickway === false) {
        this.searchTerm = ''
        this.toggleBlock = true
        this.executeFromZero()
        this.expendClose()
      }
    },
    capitalizeFilterName(name) {
      if (!name) return ''
      return name.replace(/\w\S*/g, function(name) {
        return name.charAt(0).toUpperCase() + name.substr(1).toLowerCase()
      })
    },
    checkType(inboxDetail) {
      switch (inboxDetail.inbox_type) {
        case 'post':
          return inboxDetail.element_details.post_message
        case 'conversation':
          return inboxDetail.element_details.snippet
        case 'review':
          return inboxDetail.review_reply.review_message
            ? inboxDetail.review_reply.review_message
            : inboxDetail.element_details.post_message
      }
    },
    checkReadStatus(readStatus) {
      if (readStatus === 'unread') {
        return 'unread_message'
      } else {
        return ''
      }
    },

    getPlatformName(platform) {
      switch (platform) {
        case 'facebook':
          return `${this.classes} fab fa-facebook-f`
        case 'twitter':
          return `${this.classes} fab fa-twitter`
        case 'instagram':
          return `${this.classes} fab fa-instagram`
        case 'google_my_business':
          return `${this.classes} icon-GMB`
        case 'linkedin':
          return `${this.classes} fab fa-linkedin-in`
      }
    },
    getPlatformNameColor(platform) {
      switch (platform) {
        case 'facebook':
          return 'fb'
        case 'twitter':
          return 'tw'
        case 'instagram':
          return 'ins'
        case 'google_my_business':
          return 'gmb'
        case 'linkedin':
          return 'lin'
      }
    },

    limitText(value, limit) {
      if (value && value.length > limit) {
        return value.substring(0, limit) + '...'
      }
      return value
    },

    setTypeFilters() {
      console.log('METHOD::setTypeFilters')
      this.allTypeFilter =
        this.selectedFilterTypes.length === this.filterTypes.length
      EventBus.$emit('update-side-bar', { typeFilters: this.selectedFilterTypes })
      this.executeFromZero()
    },

    setOrder(order) {
      if (order === 'asc') {
        this.selectedOrder = 'desc'
      } else {
        this.selectedOrder = 'asc'
      }
      this.executeFromZero()
    },

    setTeamMember(member) {
      this.selectedTeamMember = member
      this.executeFromZero()
    },
    showFilter(type) {
      const self = this
      let response = true
      if (type === 'conversation') {
        response = false
        _.forEach(this.getInboxFilter.all_channels, function(e, k) {
          if (self.getInboxFilter.all_channels[k].length > 0) {
            response = true
          }
        })
      }
      return response
    },

    async executeFilter() {
      if (!this.hasSocialInboxAccess) {
        return
      }

      if (this.profileLoader !== true) {
        this.profileLoader = true
        const filter = {
          inbox_types: this.selectedFilterTypes,
          channels: _.flatten(this.getInboxFilter?.all_channels || []),
          order_by: this.selectedOrder,
          search_term: this.searchTerm,
          team_member: this.selectedTeamMember?.user?._id || null,
          limit: this.loadLimit,
          page: this.page
        }
        this.setInboxFilter(filter)

        const response = await this.fetchFilteredInboxDetails(
            this.getInboxFilter
        )
        if (response.isValid) {
          this.page += 1
          for (const inboxDetail of response.conversations) {
            this.inboxDetails.push(inboxDetail)
          }
          if (this.inboxDetails.length > 0) {
            if (this.$route.params.conversation) {
              const index = this.findConversation(
                  this.$route.params.conversation
              )
              if (index >= 0) {
                this.activeInboxDetail = this.getConversationList[index]
              } else {
                console.log('no conversation found')
                this.activeInboxDetail = this.getConversationList[0]
              }
              this.search = true
            } else {
              this.activeInboxDetail = this.getConversationList[0]
            }
          }
          this.profileLoader = false
        } else {
          this.inboxDetails = []
          this.$router.push({
            path:
              '/' +
              this.getActiveWorkspace.slug +
              '/inbox/' +
              this.$route.params.filter +
              '/conversation',
          })
          this.activeInboxDetail = null
          this.profileLoader = false
        }
      }
    },

    referenceToAnotherConversation(payload) {
      console.log('reference to another conversation:', payload.element_id)

      this.$router.push({
        name: 'inboxConversation',
        params: {
          workspace: this.getWorkspaces.activeWorkspace.slug,
          conversation: payload.element_id,
          filter: 'all',
        },
      })
      const inbox = this.findConversation(payload.element_id)
      console.log(inbox)
      this.setElementDetails(this.inboxDetails[inbox])
    },

    async setElementDetails(inbox) {
      console.log('inbox clicked:', inbox)
      const response = await this.markAsRead({
        workspace_id: inbox.workspace_id,
        platform_id: inbox.platform_id,
        element_id: inbox.element_details.element_id,
        inbox_type: inbox.inbox_type,
      })
      if (response.isValid) {
        inbox = response.inboxDetail
        console.log('inbox marked as read:', inbox)
        this.setConversation(inbox)
      }
      this.activeInboxDetail = inbox
      console.log(
        'active inbox is this:',
        this.activeInboxDetail.inbox_details.posted_by.name,
      )
    },

    findConversation(elementId) {
      return this.inboxDetails.findIndex(
        (x) => x.element_details.element_id === elementId,
      )
    },
    setConversation(inbox, newConv = false) {
      if (
        inbox === null ||
        inbox.element_details === null ||
        typeof inbox === 'undefined' ||
        typeof inbox.element_details === 'undefined'
      ) {
        return
      }
      if(inbox.updated_at) {
        inbox.updated_at = moment.utc(inbox.updated_at, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format();
      }
      const index = this.findConversation(inbox.element_details.element_id)
      if (index >= 0) {
        this.inboxDetails[index] = inbox
        console.log('testol', this.inboxDetails)
      } else {
        if (
          this.getInboxFilter.action === 'ALL' ||
          this.getInboxFilter.action === 'UNASSIGNED'
        ) {
          if (this.inboxDetails.length === 0) {
            this.inboxDetails[0] = inbox
            this.activeInboxDetail = this.inboxDetails[0]
          } else {
            this.inboxDetails.unshift(inbox)
          }
          console.log('these are inbox details', this.inboxDetails)
        }
      }
    },
    canDelete(action, status = false, assignedTo = null) {
      let value = false
      switch (this.getInboxFilter.action) {
        case 'UNASSIGNED':
          value = !(action === 'assigned' && assignedTo === null)
          break
        case 'MINE':
          value = !(
            action === 'assigned' &&
            assignedTo !== null &&
            assignedTo.user_id === this.getProfile._id
          )
          break
        case 'ASSIGNED':
          value = !(action === 'assigned' && assignedTo !== null)
          break
        case 'MARKED_AS_DONE':
          if (action === 'marked_done' && status === false) {
            value = true
          }
          break
        case 'ARCHIVED':
          if (action === 'archived' && status === false) {
            value = true
          }
          break
        case 'ALL':
          break
      }
      return value
    },
    deleteConversation(data) {
      const response =
        data.action === 'assigned'
          ? this.canDelete(data.action, false, data.assigned_to)
          : this.canDelete(data.action, data.action_status)
      if (!response) {
        return
      }
      const index = this.findConversation(
        this.activeInboxDetail.element_details.element_id,
      )
      if (index >= 0) {
        this.inboxDetails.splice(index, 1)
      }
      if (this.inboxDetails[index]) {
        this.activeInboxDetail = this.inboxDetails[index]
      } else if (this.inboxDetails[0]) {
        this.activeInboxDetail = this.inboxDetails[0]
      } else {
        this.activeInboxDetail = null
      }
    },
    updateUserDetails(payload) {
      console.log('update krna hai')
      this.inboxDetails.forEach(function(inboxDetail) {
        if (payload.conversation_ids.indexOf(inboxDetail._id) >= 0) {
          inboxDetail.inbox_details.posted_by = payload.posted_by
        }
      })
    },
    updateActiveInboxDetail(updatedInboxDetail) {
    this.activeInboxDetail = updatedInboxDetail;
    },
    canPerformBulkAction() {
      return (
        this.getInboxFilter.action === 'UNASSIGNED' ||
        this.getInboxFilter.action === 'MINE' ||
        this.getInboxFilter.action === 'ASSIGNED'
      )
    },
    selectAllConversations() {
      this.allBulkSelect = !this.allBulkSelect
      const elementDetails = _.pluck(this.inboxDetails, 'element_details')
      this.selectedBulkUpdate = this.allBulkSelect
        ? _.pluck(elementDetails, 'element_id')
        : []
    },
    async updateConversationsStatuses(action, status, assignedTo = null) {
      const payload = {
        workspace_id: this.getActiveWorkspace._id,
        element_ids: this.selectedBulkUpdate,
        action_performed_by: {
          user_id: this.getProfile._id,
          user_name: this.getProfile.firstname,
          user_image: this.getProfile.image,
        },
        action,
        assigned_to:
          assignedTo !== null
            ? {
              user_id: assignedTo._id,
              user_name: assignedTo.full_name,
              user_image: assignedTo.image,
            }
            : null,
        status,
      }
      if (assignedTo !== null) {
        payload.assigned_to = {
          user_id: assignedTo._id,
          user_name: assignedTo.full_name,
          user_image: assignedTo.image,
        }
      }

      this.profileLoaderTop = true
      const response = await this.bulkUpdate(payload)
      if (response.isValid) {
        console.log('i am response')
        console.log(response)
        this.profileLoaderTop = false
      } else {
        this.alertMessage(response.message, 'error')
        this.profileLoaderTop = false
      }
    },
    removeSocketListners(value) {
      const tail = value.element_details.element_id + '-' + value.workspace_id
      const channelForConversationUpdateStatus =
        'channel-update-conversation-status-' + tail
      const channelForAssignStatus = 'channel-assign-status-' + tail

      const channelForUpdateConversation = 'channel-conversation-' + tail
      const channelForNewConversation =
        'channel-new-conversation-' + this.getActiveWorkspace._id

      const channelUpdateInbox =
        'updated-inbox-details-' + this.getActiveWorkspace._id
      const presenceChannelSubscriptionSucceeded =
        'pusher:subscription_succeeded'

      const presenceChannelSubscriptionError = 'pusher:subscription_error'

      this.inbox_profile_channel.unbind(channelForConversationUpdateStatus)
      this.inbox_profile_channel.unbind(channelForAssignStatus)

      this.inbox_profile_channel.unbind(channelForUpdateConversation)
      this.inbox_profile_channel.unbind(channelForNewConversation)

      this.inbox_profile_channel.unbind(channelUpdateInbox)
      this.inbox_profile_channel.unbind(presenceChannelSubscriptionSucceeded)
      this.inbox_profile_channel.unbind(presenceChannelSubscriptionError)
    },
    async fetchAllTags() {
      const response = await this.fetchWorkspaceTags({
        workspace_id: this.getActiveWorkspace._id,
      })
      if (response.isValid) {
        this.workspaceTags = _.sortBy(response.tags, 'tag_name')
      } else {
        this.workspaceTags = []
      }
      console.log('workspace_tags', this.workspaceTags)
    },

    inboxText(postMessage, limit) {
      return parseDescriptionHtml(postMessage, limit, true, true)
    },
    /**
     * Find page name against each message
     * @param platformId
     * @returns {*|string}
     */
    getPageName(platformName, platformId) {
      const pageDetails = this.getPlatformsList.find(
        (item) => item[item.channel_identifier] === platformId,
      )
      return pageDetails
        ? platformName === 'google_my_business'
          ? pageDetails.location_name
          : pageDetails.name
        : ''
    },
  },
}
</script>

<style lang='less' scoped>
.check_assign {
  color: #1ecb7b;
}

.profile_picture .picture_block_background .img {
  height: 30px;
  width: 30px;
}

.team_dropdown_inbox .dropdown-menu,
.inbox_head_dropdown .dropdown-menu {
  min-width: 220px;
}

.action_icon {
  font-size: 0.875rem;
  margin-left: 8px;
  cursor: pointer;
  border: solid 1px rgba(165, 176, 193, 0.4);
  padding: 7px 0.625rem;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 0.2s linear !important;
  -moz-transition: all 0.2s linear !important;
  -o-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  align-content: center;
  background: #ffffff;
}

.link_icons {
  margin-left: 90px;
}

.bulk_action_checkbox {
  /*display: none;*/
  font-size: 0.875rem;
  color: #00c489;
  font-family: 'Font Awesome 5 Pro' !important;
  font-weight: 300;
  position: absolute;
}

.inbox_main_view .user_profiles_sidebar .sidebar_inner ul.links,
.inbox_main_view .user_profiles_sidebar .sidebar_inner .links {
  position: relative;
}

/*test code for dropdown*/
.dropdown-bulk {
  width: 180px;
}

.sidebar-nav {
  padding: 9px 0;
}

.dropdown-menu .sub-menu {
  left: 100%;
  position: absolute;
  top: 0;
  visibility: hidden;
  margin-top: -1px;
}

.sub-menu {
  width: 100%;
  /*margin-top: 8px;*/
  padding: 0;
  -webkit-box-shadow: 0 4px 10px 0 rgba(184, 189, 209, 0.3);
  -moz-box-shadow: 0 4px 10px 0 rgba(184, 189, 209, 0.3);
  box-shadow: 0 4px 10px 0 rgba(184, 189, 209, 0.3);
  background-color: #ffffff;
  border: solid 1px rgba(102, 107, 133, 0.05);
  min-width: 180px;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  border-radius: 0.625rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.dropdown-menu li:hover .sub-menu {
  visibility: visible;
}

.dropdown:focus .dropdown-menu {
  display: block;
}

.nav-tabs .dropdown-menu,
.nav-pills .dropdown-menu,
.navbar .dropdown-menu {
  margin-top: 0;
}

.navbar .sub-menu:before {
  border-bottom: 7px solid transparent;
  border-left: none;
  border-right: 7px solid rgba(0, 0, 0, 0.2);
  border-top: 7px solid transparent;
  left: -7px;
  top: 0.625rem;
}

.navbar .sub-menu:after {
  border-top: 6px solid transparent;
  border-left: none;
  border-right: 6px solid #fff;
  border-bottom: 6px solid transparent;
  left: 0.625rem;
  top: 11px;
  left: -6px;
}

.dropdown-submenu-bulk {
  padding: 10px 0;

  li {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 0.875rem;

    a,
    div {
      color: #3a4557;
      font-size: 0.875rem;
      text-decoration: none;
      font-family: 'Roboto', sans-serif;
    }

    &:hover {
      background: #f5f9fc;
    }
  }
}

.checkbox_input_image input[type='checkbox'] + label.checkbox_right:after,
.checkbox_input_image input[type='checkbox'] + label.checkbox_right:before {
  font-size: 1.125rem;
}

.select_all {
  font-size: 1.125rem;
  margin-left: 8px;
  margin-top: 2px;
  cursor: pointer;
}

.padding_top_25 {
  padding-top: 10%;
}

.infinite-loading-message {
  margin: 1rem auto;
  font-weight: 400;
  padding: 3px;
  border-radius: 15px;
  border: 2px solid #eaeaea;
  width: 130px;
  height: 30px;
  font-size: 13px;
  text-align: center;
}
</style>
