<template>
  <div
      class="px-5 pt-[5.6rem] pb-5 relative pointer-events-none cst-editor mt-[-4.25rem] mb-1.5 rounded-md border !border-t-0 !border-r-0 !border-l-0"
  >
    <div
        class="mb-6 w-full flex gap-3 justify-start items-center !pointer-events-auto"
    >
      <h2 class="pl-8 text-base font-weight-500">
        {{getDetailedPulseChartTitle(data?.dataType)}}

        </h2>
      <v-menu
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
          popover-inner-class="rounded-lg text-[979CA0] border p-2 !bg-white"
          class="inline-block"
      >
        <i class="far fa-question-circle p-0 cursor-pointer"></i>
        <template v-slot:popper>
          <p class="text-sm text-gray-900">
            {{getDetailedPulseInfoTooltip(data?.dataType, platformName)}}
          </p>
        </template>
      </v-menu>
    </div>
<!--    <div v-if="isLoading">-->
<!--      <SkeletonBox class="!w-full !h-[30rem]"></SkeletonBox>-->
<!--    </div>-->
    <div v-if="noDataFound" class="!pointer-events-auto flex flex-col justify-center items-center">
      <img src="@src/assets/img/analytics/no_post.svg" alt="" class="w-40"/>
      <p>No Data Found for {{ data.name }}.</p>
    </div>

    <div v-else class="flex !pointer-events-auto">
      <div class="flex-1">
        <div ref="chartRef" :style="{ height: chartHeight }"></div>
      </div>
    </div>
  </div>
</template>

<script>
// libraries
import {
  computed,
  defineComponent,
  watch,
} from 'vue'

// composable
import useEcharts from "@src/modules/analytics_v3/composables/useEcharts"
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'
import useDateFormat from '@/src/modules/common/composables/useDateFormat';

export default defineComponent({
  name: 'DetailedPulseChart',
  props: {
    title: {
      type: String,
      default: "Label/Campaign",
    },
    chartHeight: {
      type: [Number, String],
      default: '350px',
    },
    xAxisLabel: {
      type: String,
      default: 'Timeline',
    },
    yAxisLabel: {
      type: String,
      default: 'Posts',
    },
    data: {
      type: Object,
      default: () => {
      },
    },
    type: {
      type: String,
      default: 'posts',
    },
    legend: {
      type: Object,
      default: () => ({
        show: false,
        data: [],
      }),
    },
    chartTypes: {
      type: Array,
      default: () => ['line']
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    platformName: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const {
      getDetailedPulseInfoTooltip,
      getDetailedPulseChartTitle,
      getPulseTooltipTitle,
    } = useLabelAndCampaign()

    const { momentWrapper } = useDateFormat()

    /**
     * @description condition if there's no data available for plotting chart.
     * @returns {boolean}
     */
    const noDataFound = computed(() => !props.data.values?.length)

    /**
     * @description creates and sorts(w.r.t. date) the data for series
     * @returns {*}
     */
    const createSeriesData = () => {
      if(!props.data) return []
      return {
        symbolSize: 8,
        colorBy: 'series',
        name: props.data?.name,
        type: 'line',
        areaStyle: { opacity: 0.3, cursor: 'auto'},
        lineStyle: { opacity : 0.6 },
        color: props.data?.color,
        data: props.data.values,
        cursor: 'auto',
      }
    }

    /**
     * tooltip for pulse graph
     */
    const tooltipFormatter = (params) => {
      const {data} = params[0]
      const count = parseInt(data[1])
      const date = new Date(data[0])

      return `
        <div class="flex flex-col">
          <div class="flex items-center">
            <span class="text-[#000D21] font-weight-300">${momentWrapper(date).formatDate()}</span>
          </div>
            <div class="flex items-end justify-between mt-2.5">
              <span class="text-[979CA0] text-sm mr-1.5 capitalize">${getPulseTooltipTitle(props.data?.dataType)}: </span>
              <span class="text-[#69788E] text-sm font-bold">${count.toLocaleString()}</span>
            </div>
        </div>
        `
    }

    const chartOptions = computed(() => ({
      tooltip: {
        borderColor: props.data?.color,
        show: true,
        trigger: 'axis',
        axisPointer: {
          crossStyle: {
            color: '#595c5f',
            width: 1,
          },
        },
        formatter: tooltipFormatter,
      },
      legend: props.legend,
      grid: {
        left: 30,
        top: 30,
        right: 40,
        bottom: 30,
        show: true,
        width: 'auto',
        borderColor: '#E9EFF6',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        name: props.xAxisLabel,
        axisLabel: {
          color: '#979CA0',
          fontSize: 12,
          fontWeight: 400,
          margin: 10,
          formatter: (value) => {
            const date = new Date(value)
            return `${momentWrapper(date).formatDate()}`
          },
        },
        nameLocation: 'end',
        nameTextStyle: {
          align: 'right',
          verticalAlign: 'top',
          lineHeight: 70,
          color: 'gray',
          fontWeight: 'bold',
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          lineStyle: {
            color: '#E9EFF6',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
      },
      yAxis: {
        type: 'value',
        sort: 'ascending',
        name: props.yAxisLabel,
        min: 'dataMin',
        nameTextStyle: {
          align: 'center',
          verticalAlign: 'bottom',
          color: 'gray',
          fontWeight: 'bold',
        },
        axisLabel: {
          color: '#979CA0',
          fontSize: 12,
          fontWeight: 400,
          formatter: (value) => {
            return value.toLocaleString('en-US', {
              notation: 'compact',
              compactDisplay: 'short',
            })
          },
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          lineStyle: {
            color: '#E9EFF6',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
        splitLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
      },
      series: createSeriesData(),
    }))

    // setting up the eChart
    const {setup, chartRef} = useEcharts(
        chartOptions.value,
        props.chartTypes,
    )

    watch(
        () => chartRef.value,
        () => {
          setup(chartOptions.value)
        }
    )

    return {
      chartRef,
      chartOptions,
      noDataFound,
      getDetailedPulseInfoTooltip,
      getDetailedPulseChartTitle,
      getPulseTooltipTitle,
    }
  },
})
</script>
