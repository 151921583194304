import { mapGetters } from 'vuex'
import moment from 'moment'

import { usermavenClient } from "@usermaven/sdk-js"

const userMaven = usermavenClient({
  key: process.env.VUE_APP_USERMAVEN_DATA_KEY,
  tracking_host: process.env.VUE_APP_USERMAVEN_TRACKING_HOST,
  autocapture: true
})


const userMavenMixin = {
  computed: {
    ...mapGetters(['getPlan', 'getProfile'])
  },
  methods: {
    initUserMaven () {
      console.debug('initUserMaven')
      this.trackUserMaven('pageview')
    },
    userMavenCommonPayload (profile, admin = {}) {
      console.debug('userMavenCommonPayload', profile, admin)
      // eslint-disable-next-line camelcase
      const { id, email, first_name, last_name, created_at, type } = profile
      // eslint-disable-next-line camelcase
      const admin_id = admin._id || null
      // eslint-disable-next-line camelcase
      const admin_name = admin.email || null

      const admin_created_at = admin.created_at || null

      let data = {
        // Required attributes
        id, // Unique ID for the user in database.
        email, // Email address for the user.
        // Recommended attributes
        created_at,

        first_name,
        last_name,
        type
      }


      if(admin_id && admin_name && admin_created_at) {

        data = {
          ...data,
          company: {
            // Required Attributes
            id: admin_id, // Company ID
            name: admin_name, // Company Name.
            created_at: admin_created_at
          }
        }
      }

      return data
    },
    userMavenIdentity (payload) {
      console.debug('userMavenIdentity', payload)
      try {
        const admin = this.getSuperAdminByWorkspaces(payload.workspace) || {}
        const userMavenPayload = this.userMavenCommonPayload(payload.user, admin)

        let customPayload = {}

        if (payload.account) {
          customPayload = {
            plan: payload.account.plan_fs,
            role: payload.user.role,
            workspaces: payload.account.count_workspaces,
            social_accounts: payload.account.count_social_accounts,
            blogs: payload.account.count_blogs_accounts,
            team: payload.account.count_team_members,
            custom_topics: payload.account.count_custom_topics,
            phone_number: payload.user.phone_no || null,
            business_type: payload.user.business_type
          }
        }

        userMaven.id({
          ...userMavenPayload,
          custom: {
            ...customPayload
          }
        })
      } catch (e) {
        console.debug(e)
      }
    },
    // eslint-disable-next-line camelcase
    async trackSignUpEvent  () {
      try {
        const payload = await this.getUserCustomDetails()
        await this.userMavenIdentity(payload)

        this.trackUserMaven('signed_up')
      } catch (e) {
        console.debug(e)
      }
    },

    getUserCustomDetails () {
      const self = this
      const profile = this.getProfile
      const plan = this.getPlan
      const workspace = this.getActiveWorkspace

      if (!profile._id) return {}
      const payload = {
        'event': 'user',
        'logged_in': true // either true or false (boolean)
      }

      const state = profile.state ? profile.state : 'active'

      let loggedUser = { }
      if (workspace.members) {
        loggedUser = workspace.members.find(item => { return item.user_id === profile._id })
      }
      payload.user = {
        id: profile._id,
        email: profile.email,
        first_name: profile.firstname,
        last_name: profile.lastname,
        created_at: profile.created_at,
        created_at_timestamp: moment.utc(profile.created_at, 'YYYY-MM-DD HH:mm:ss').unix(),
        state,
        phone_no: profile.phone_no || null,
        type: profile.type || null
      }

      if (loggedUser && loggedUser.user_id) {
        payload.user = {
          id: loggedUser.user_id,
          email: profile.email,
          first_name: profile.firstname,
          last_name: profile.lastname,
          created_at: profile.created_at,
          created_at_timestamp: moment.utc(profile.created_at, 'YYYY-MM-DD HH:mm:ss').unix(),
          role: loggedUser.role,
          state,
          phone_no: profile.phone_no || null,
          type: profile.type || null
        }
      }

      // subscription instance is available.

      if (plan.subscription && plan.subscription.display_name) {
        payload.account = {
          'plan_fs': plan.subscription.slug,
          'plan_display': plan.subscription.display_name,
          'count_social_accounts': plan.used_limits.profiles,
          'count_blogs_accounts': plan.used_limits.blogs,
          'count_team_members': plan.used_limits.members,
          'count_workspaces': plan.used_limits.workspaces,
          'count_custom_topics': plan.used_limits.topics
        }
      }

      if (workspace) {
        payload.workspace = workspace
      }

      if (profile.hasOwnProperty('addons')) {
        payload.addons = profile.addons
      }

      payload.admin = this.getSuperAdminByWorkspaces(payload.workspace) || {}

      return payload
    },


    trackUserMaven (tagName, payload = {}) {
      // if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
      //   return;
      // }
      userMaven.track(tagName, payload)
    },

    getSuperAdminByWorkspaces (workspace) {
      console.debug('getSuperAdminByWorkspaces', workspace)

      const teamMembers = workspace.members

      if (teamMembers) {
        for (let i = 0; i < teamMembers.length; i++) {
          const member = teamMembers[i]
          if (member.role === 'super_admin') {
            return member.user
          }
        }
      }

      return null
    }
  }

}

export { userMavenMixin, userMaven }
