import { reactive, ref, toRefs, computed } from 'vue'
import proxy from '@common/lib/http-common'
import { useStore } from '@state/base'
import {
  fetchWhiteLabelWorkspaceData,
  sendEmailToUsers,
  updateWhiteLabelStatus,
} from '@src/modules/setting/config/api-utils'
import usePlatform from '@common/composables/usePlatform'

const whiteLabelData = reactive({
  generalSettings: {},
  domainSettings: {},
  emailSettings: {},
})

const whiteLabelId = ref('')
const whiteLabelUrl = ref('')
const isWhiteLabelEnabled = ref(false)
const isSetupComplete = ref(false)
const isEditing = ref(false)
const currentStep = ref(0)
const completedSteps = ref([false, false, false])

export default function useWhiteLabel() {
  const { getters } = useStore()
  const { showToast } = usePlatform()


  // computed to check if whitelabel subscription is unlocked
  const isWhiteLabelUnlocked = computed(
    () => getters?.getPlan?.subscription?.features?.white_label_subscription
  )

  const goToStep = (step) => {
    currentStep.value = step
  }

  const nextStep = () => {
    if (currentStep.value < 2) {
      currentStep.value++
    }
  }

  const prevStep = () => {
    if (currentStep.value > 0) {
      currentStep.value--
    }
  }

  const transformGeneralSettings = (settings) => {
    return {
      businessName: settings.business_name,
      websiteURL: settings.website_url,
      brandLogo: settings.logo,
      favicon: settings.favicon,
      privacyPolicy: settings.privacy_policy,
      termsAndConditions: settings.terms_of_service,
    }
  }

  const transformDomainSettings = (settings) => {
    return {
      whiteLabelDomain: settings.domain,
      isDomainConnected: settings.is_domain_connected,
    }
  }

  const transformEmailSettings = (settings) => {
    return {
      fromEmail: settings.from_email,
      senderName: settings.sender_name,
      smtpHost: settings.smtp_host,
      smtpPort: settings.smtp_port,
      smtpUsername: settings.smtp_username,
      smtpPassword: settings.smtp_password,
      smtpEncryption: settings.smtp_encryption,
    }
  }

  const setWhiteLabelData = (data) => {
    whiteLabelId.value = data._id
    whiteLabelUrl.value = data.url
    isSetupComplete.value = data.is_whitelabel_setup_completed
    isWhiteLabelEnabled.value = data.is_whitelabel_enabled

    whiteLabelData.generalSettings = transformGeneralSettings(data)

    whiteLabelData.domainSettings = transformDomainSettings(data)

    whiteLabelData.emailSettings = transformEmailSettings(data)
  }

  const fetchWhiteLabel = async () => {
    try {
      const { data } = await proxy.get(fetchWhiteLabelWorkspaceData, {
        params: {
          workspace_id: getters.getActiveWorkspace._id,
        },
      })

      if (data.status) {
        setWhiteLabelData(data.whitelabel)
      } else {
        resetLocalStates()
      }
    } catch (error) {
      console.error('Error fetching whitelabel data', error)
    }
  }

  const toggleWhiteLabelActiveStatus = async () => {
    const payload = {
      status: !isWhiteLabelEnabled.value,
      workspace_id: getters.getActiveWorkspace._id,
      id: whiteLabelId.value,
    }
    try {
      const { data } = await proxy.post(updateWhiteLabelStatus, payload)

      if (data.status) {
        isWhiteLabelEnabled.value = data?.whitelabel?.is_whitelabel_enabled
        await showToast(
          `White Label App ${
            isWhiteLabelEnabled.value ? 'enabled' : 'disabled'
          } successfully`,
          'success'
        )
      }
    } catch (error) {
      await showToast(`${error.response?.data?.message}`, 'error')
    }
  }

  const sendEmail = async () => {
    try {
      const { data } = await proxy.post(sendEmailToUsers, {
        workspace_id: getters.getActiveWorkspace._id,
        id: whiteLabelId.value,
      })

      if (data.status) {
        await showToast('Emails are on their way!', 'success')
      }
    } catch (error) {
      await showToast(`${error.response?.data?.message}`, 'error')
    }
  }

  const resetLocalStates = () => {
    currentStep.value = 0
    whiteLabelId.value = ''
    whiteLabelUrl.value = ''
    isWhiteLabelEnabled.value = false
    isSetupComplete.value = false

    whiteLabelData.generalSettings = {}

    whiteLabelData.domainSettings = {}

    whiteLabelData.emailSettings = {}
  }

  return {
    ...toRefs(whiteLabelData),
    currentStep,
    isSetupComplete,
    isWhiteLabelEnabled,
    whiteLabelId,
    whiteLabelUrl,
    isWhiteLabelUnlocked,
    isEditing,
    completedSteps,

    goToStep,
    nextStep,
    prevStep,
    transformGeneralSettings,
    transformDomainSettings,
    transformEmailSettings,
    fetchWhiteLabel,
    sendEmail,
    toggleWhiteLabelActiveStatus,
    resetLocalStates,
  }
}
