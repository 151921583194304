<template>
  <b-modal
    id="save-social-accounts"
    ref="saveSocialAccounts"
    scrollable
    centered
    no-close-on-backdrop
    header-class="cs-modal-header"
    title-class="cs-modal-title"
    size="lg"
  >
    <template v-slot:modal-title>{{ modalHeader }}</template>

    <div v-if="platformName" class="mx-2 my-4">
      <!-- First Section -->
      <section class="my-4">
        <CstAlert v-if="platformName === 'facebook'" type="warn" class="mb-5"
          >Unable to view all of your pages?
          <a
            href="https://docs.contentstudio.io/article/377-how-to-connect-facebook-pages"
            target="_blank"
            >Follow this guide to allow permissions to access them.</a
          >
        </CstAlert>

        <CstAlert v-if="platformName === 'instagram'" type="warn" class="mb-5"
          >Unable to view all of your business accounts?
          <a
            href="https://docs.contentstudio.io/article/729-getting-started-with-instagram-publishing"
            target="_blank"
            >Follow this guide on how to get started with Instagram.</a
          >
        </CstAlert>
        <div class="flex justify-between items-center mb-3">
          <h2 class="text-base font-semibold">{{ firstSectionHeader }}</h2>
          <span
            class="cursor-pointer text-blue-900 select-none"
            :class="{
              'opacity-50 cursor-not-allowed': isEmpty(firstSectionItems),
            }"
            @click="handleSelectAll('first')"
            >Select
            {{
              isFirstItemsAllSelected && !isEmpty(firstSectionItems)
                ? 'None'
                : 'All'
            }}</span
          >
        </div>

        <div
          v-if="firstSectionItems && firstSectionItems.length"
          class="grid gap-x-3.5 gap-y-2"
          :class="
            platformName === 'gmb' || platformName === 'youtube'
              ? 'grid-cols-2'
              : 'grid-cols-3'
          "
        >
          <template v-for="(item, key) in firstSectionItems" :key="key">
            <CstCardCheckbox
              v-model="data.selectedPlatforms.firstItems"
              class="w-full overflow-hidden"
              :value="getPlanId(item)"
              :title="getAccountName(item)"
            >
              <div class="flex items-center">
                <img
                  :src="getImageLink(item)"
                  alt="profile_image"
                  class="rounded-full w-10 h-10"
                />
                <div
                  class="ml-4 inline-block truncate select-none"
                  :class="
                    platformName === 'gmb' || platformName === 'youtube'
                      ? 'w-72'
                      : 'w-44'
                  "
                >
                  {{ getAccountName(item) }}
                </div>
              </div>
            </CstCardCheckbox>
          </template>
        </div>
        <div v-else>
          <p class="text-center text-gray-700 py-4">{{
            firstSectionMessage
          }}</p>
        </div>
      </section>

      <!-- Second Section -->
      <section
        v-if="
          platformName === 'linkedin' ||
          platformName === 'tumblr'
        "
        class="my-4"
      >
        <div class="flex justify-between items-center mb-3">
          <h2 class="text-base font-semibold">{{ secondSectionHeader }}</h2>
          <span
            class="cursor-pointer text-blue-900 select-none"
            :class="{
              'opacity-50 cursor-not-allowed': isEmpty(secondSectionItems),
            }"
            @click="handleSelectAll('second')"
            >Select
            {{
              isSecondItemsAllSelected && !isEmpty(secondSectionItems)
                ? 'None'
                : 'All'
            }}</span
          >
        </div>

        <div
          v-if="secondSectionItems && secondSectionItems.length"
          class="grid gap-x-3.5 gap-y-2 pt-1.5"
          :class="
            platformName === 'gmb' || platformName === 'youtube'
              ? 'grid-cols-2'
              : 'grid-cols-3'
          "
        >
          <template v-for="(item, key) in secondSectionItems" :key="key">
            <CstCardCheckbox
              v-model="data.selectedPlatforms.secondItems"
              class="w-full relative"
              :value="getPlanId(item)"
            >
              <div class="flex items-center">
                <img
                  :src="getImageLink(item)"
                  alt="profile_image"
                  class="rounded-full w-10 h-10"
                />
                <div
                  class="ml-4 inline-block truncate select-none"
                  :class="
                    platformName === 'gmb' || platformName === 'youtube'
                      ? 'w-72'
                      : 'w-44'
                  "
                >
                  {{ getAccountName(item) }}
                </div>

                <div
                  v-if="isAppNotInstalled(item)"
                  class="absolute -right-1.5 -top-2"
                  @click="(e) => handleAppNotInstalledClick(e, item.id)"
                >
                  <div
                    v-tooltip.top="{
                      content: `<div class='text-center w-52'>
                        In order to publish content to your group <strong>${getAccountName(
                          item,
                        )}</strong> you must authorize ContentStudio application. Click on the icon to authorize permission.
                    </div>`,
                      html: true,
                    }"
                    class="w-5 h-5 rounded-full bg-red-600 text-xxs flex justify-center items-center text-white cursor-pointer"
                  >
                    <i class="fas fa-exclamation"></i>
                  </div>
                </div>
              </div>
            </CstCardCheckbox>
          </template>
        </div>
        <div v-else>
          <p class="text-center text-gray-700 py-4">{{
            secondSectionMessage
          }}</p>
        </div>
      </section>
    </div>
    <div v-else class="py-12">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>

    <template v-slot:modal-footer>
      <!--    Footer    -->
      <div class="flex justify-end">
        <CstButton
          size="large"
          variant="secondary"
          class="mr-3 w-28"
          text="Cancel"
          @click="hideModal"
        />
        <CstButton
          variant="primary"
          size="large"
          class="w-28"
          :loading="getSavePlatformsLoader"
          :disabled="
            getSavePlatformsLoader ||
            (isEmpty(secondSectionItems) && isEmpty(firstSectionItems))
          "
          @click="saveSelectedPlatforms"
        >
          Save
        </CstButton>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { defineComponent, ref, reactive, computed, watch, inject } from 'vue'
import CstAlert from '@ui/Alert/CstAlert'
import CstCardCheckbox from '@ui/CheckBox/CstCardCheckbox'
import CstButton from '@ui/Button/CstButton'
import { platformImage } from '@common/lib/integrations'
import {
  SAVE_PLATFORMS_ERROR,
  SAVE_PLATFORMS_SUCCESS,
} from '@common/constants/messages'
import {
  blog,
  facebook,
  gmb,
  instagram,
  linkedin,
  pinterest,
  tumblr,
  youtube,
} from '@src/modules/integration/store/states/mutation-types'
import isEmpty from 'is-empty'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    CstAlert,
    CstCardCheckbox,
    CstButton,
  },
  props: {
    isExternalLink:{
      type: Boolean,
      default: false
    },
  },
emits: ['save-social-accounts'],
  setup(props, { emit }) {
    const { getters, dispatch, commit } = useStore()
    const route = useRoute()
    const root = inject('root')
    const {
      $bvModal,
      showUpgradeModal,
      onboardingStepsCompleted,
      Plan,
      getMobileDevices,
    } = root
    // Getting root states


    // Refs to the modal
    const saveSocialAccounts = ref(null)

    // Reactive data
    const data = reactive({
      selectedPlatforms: {
        firstItems: [],
        secondItems: [],
      },
    })

    const getSavePlatformsLoader = computed(() => {
      return getters.getSavePlatformsLoader
    })

    /**
     * Computed property for modal header based on platform name
     */
    const platformName = computed(() => {
      return (
          getters.getPlatformsConnectionState?.process
              ? getters.getPlatformsConnectionState.process
              : ''
      ).toLowerCase()
    })

    /**
     * Computed property for modal header based on platform name
     */
    const modalHeader = computed(() => {
      switch (platformName.value) {
        case 'instagram':
          return 'Choose Instagram Business Profiles'
        case 'facebook':
          return 'Choose Facebook Pages and Groups'
        case 'pinterest':
          return 'Choose Pinterest Boards'
        case 'linkedin':
          return 'Choose Linkedin Pages and Groups'
        case 'gmb':
          return 'Choose Google My Business Locations'
        case 'youtube':
          return 'Choose Youtube Channels'
        case 'tumblr':
          return 'Choose Tumblr Profile and Blogs'
      }
      return 'Fetching Accounts...'
    })

    /**
     * Computed property for first section message
     */
    const firstSectionMessage = computed(() => {
      switch (platformName.value) {
        case 'instagram':
          return 'No instagram business profile found.'
        case 'facebook':
          return 'No facebook page found.'
        case 'pinterest':
          return 'No pinterest boards found.'
        case 'linkedin':
          return 'No linkedin profiles found.'
        case 'gmb':
          return 'No location found.'
        case 'youtube':
          return 'No youtube channels found.'
        case 'tumblr':
          return 'No tumblr blogs found.'
      }
      return 'No account found!'
    })

    /**
     * Computed property for second section message
     */
    const secondSectionMessage = computed(() => {
      switch (platformName.value) {
        case 'facebook':
          return 'No facebook groups found.'
        case 'linkedin':
          return 'No linkedin pages found.'
        case 'tumblr':
          return 'No tumblr profile found'
      }
      return 'No account found!'
    })

    /**
     * Computed property for modal first section header
     */
    const firstSectionHeader = computed(() => {
      switch (platformName.value) {
        case 'instagram':
          return 'Business Profiles'
        case 'facebook':
          return 'Pages'
        case 'pinterest':
          return 'Boards'
        case 'linkedin':
          return 'Profiles'
        case 'gmb':
          return 'Locations'
        case 'youtube':
          return 'Channels'
        case 'tumblr':
          return 'Blogs'
      }
      return ''
    })

    /**
     * Computed property for modal second section header
     */
    const secondSectionHeader = computed(() => {
      switch (platformName.value) {
        case 'facebook':
          return 'Groups'
        case 'linkedin':
          return 'Pages'
        case 'tumblr':
          return 'Profiles'
      }
      return ''
    })

    /**
     *  Computed property for modal's section 1st items
     */
    const firstSectionItems = computed(() => {
      switch (platformName.value) {
        case 'instagram':
          return getters.getPlatformsConnectionState?.profiles
        case 'facebook':
          return getters.getPlatformsConnectionState?.pages
        case 'pinterest':
          return getters.getPlatformsConnectionState?.boards
        case 'linkedin':
          return [getters.getPlatformsConnectionState?.profile]
        case 'gmb':
          return getters.getPlatformsConnectionState?.locations
        case 'youtube':
          return getters.getPlatformsConnectionState?.channels
        case 'tumblr':
          return getters.getPlatformsConnectionState?.blogs
      }
      return []
    })

    /**
     *  Computed property for modal's section 2nd items
     */
    const secondSectionItems = computed(() => {
      switch (platformName.value) {
        case 'facebook':
          return getters.getPlatformsConnectionState?.groups
        case 'linkedin':
          return getters.getPlatformsConnectionState?.pages
        case 'tumblr':
          return getters.getPlatformsConnectionState?.profiles
      }
      return []
    })

    /**
     * Computed property to check if all first section items are selected
     */
    const isFirstItemsAllSelected = computed(() => {
      if (firstSectionItems.value) {
        return (
            data.selectedPlatforms.firstItems.length ===
            firstSectionItems.value.length
        )
      }
      return false
    })

    /**
     * Computed property to check if all second section items are selected
     */
    const isSecondItemsAllSelected = computed(() => {
      if (secondSectionItems.value) {
        return (
            data.selectedPlatforms.secondItems.length ===
            secondSectionItems.value.length
        )
      }
      return false
    })

    /**
     *  Method to get plan Id
     */
    const getPlanId = (item) => {
      return item.platform_identifier
          ? item.platform_identifier
          : item.id
              ? item.id
              : item.instagram_id
                  ? item.instagram_id
                  : item.name
                      ? item.name
                      : ''
    }

    /**
     *  Method to get account image
     */
    const getImageLink = (item) => {
      if (platformName.value === 'gmb') {
        return 'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
      }
      return platformImage(item)
    }

    /**
     *  Method to get account name
     */
    const getAccountName = (item) => {
      if (Object.hasOwn(item, 'platform_name')) return item.platform_name

      if (
          Object.hasOwn(item, 'locationName') &&
          Object.hasOwn(item, 'address')
      ) {
        const name = item.locationName + ' - ' + item.address.locality
        return item.address.postalCode
            ? name + ' - ' + item.address.postalCode
            : name
      }

      if (Object.hasOwn(item, 'name')) {
        let name = item.name
        if (item.location && item.location.city) {
          name += ' (' + item.location.city
          if (item.location.country) name += ', ' + item.location.country
          return name + ')'
        } else if (item.location_name) {
          name += ' (' + item.location_name + ' )'
        }
        return name
      }
      if (Object.hasOwn(item, 'firstName')) {
        return item.firstName + ' ' + item.lastName
      }
      if (Object.hasOwn(item, 'first_name')) {
        return item.first_name + ' ' + item.last_name
      }
      if (Object.hasOwn(item, 'url')) return item.url
      if (Object.hasOwn(item, 'title')) return item.title
      return ''
    }

    /**
     * Method to select all items
     * @param section
     */
    const handleSelectAll = (section = 'first') => {
      switch (section) {
        case 'first':
          if (isFirstItemsAllSelected.value) {
            data.selectedPlatforms.firstItems = []
            return
          }
          if (firstSectionItems.value && firstSectionItems.value.length > 0) {
            const itemIds = firstSectionItems.value.map((item) => {
              return getPlanId(item)
            })

            data.selectedPlatforms.firstItems = [...itemIds]
          }
          break
        case 'second':
          if (isSecondItemsAllSelected.value) {
            data.selectedPlatforms.secondItems = []
            return
          }
          if (secondSectionItems.value && secondSectionItems.value.length > 0) {
            const itemIds = secondSectionItems.value.map((item) => {
              return getPlanId(item)
            })

            data.selectedPlatforms.secondItems = [...itemIds]
          }
          break
      }
    }

    /**
     *  Method to check if app is connected to platform
     */
    const isAppNotInstalled = (item) => {
      return secondSectionHeader.value === 'Groups' && !item.app_installed
    }

    /**
     *  Method to check if selected group has app installed
     */
    const groupsWithoutAppInstall = (accounts) => {
      return accounts.filter((account) => {
        return account.type === 'Group' && !account.app_installed
      })
    }

    /**
     *  Method to hide the modal
     */
    const hideModal = () => {
      saveSocialAccounts.value.hide()
    }

    /**
     *  Method to hide the modal
     */
    const showModal = () => {
      saveSocialAccounts.value.show()
    }

    /**
     * Method to save selected platforms
     */
    const saveSelectedPlatforms = async () => {
      if(props.isExternalLink){
        emit('save-social-accounts', {
          Platforms: {
            firstItems: data.selectedPlatforms.firstItems,
            secondItems: data.selectedPlatforms.secondItems
          },
          platformName: platformName.value
        })
        return
      }

      const selectedPlatforms = {
        firstItems: data.selectedPlatforms.firstItems,
        secondItems: data.selectedPlatforms.secondItems,
      }

      // check if user selected any platforms
      if (
          selectedPlatforms.firstItems.length === 0 &&
          selectedPlatforms.secondItems.length === 0
      ) {
        dispatch('toastNotification', {
          message: 'Please select at least one platform to continue.',
          type: 'error',
        })
        return false
      }

      // Getting the complete list of fetched platforms
      const connectionState = JSON.parse(
          JSON.stringify(getters.getPlatformsConnectionState)
      )

      if (platformName.value === 'instagram') {
        const selectedAccounts = connectionState.profiles.filter((value) => {
          return selectedPlatforms.firstItems.includes(value.instagram_id)
        })

        // saving the selected accounts  (api call)
        const response = await dispatch('processSaveIGBusinessProfile', {
          connectionState: {
            social_accounts: selectedAccounts,
            workspace_id: getters.getWorkspaces.activeWorkspace._id,
          }
        })

        // hiding the connection modal
        hideModal()

        if (response?.data) {
          if (response.data.status === true) {
            // if the user's social accounts limit exceeded
            if (response.data.limitExceed) {
              // showing the upgrade plan modal
              showUpgradeModal()
            } else {
              if (response.data?.not_allowed.length) {
                EventBus.$emit('showNotAllowedModal', {
                  notAllowedIds: response.data.not_allowed,
                  firstSectionItems: firstSectionItems.value,
                  firstSectionHeader: firstSectionHeader.value,
                  firstSectionMessage: firstSectionMessage.value,
                  secondSectionItems: secondSectionItems.value,
                  secondSectionHeader: secondSectionHeader.value,
                  secondSectionMessage: secondSectionMessage.value,
                })
              } else {
                // check if the user has connected mobile devices
                if (getMobileDevices.length === 0) {
                  $bvModal.show('instagram-connect-modal')
                }
                EventBus.$emit('refetchSocialPlatforms')

                dispatch('toastNotification', {
                  message: SAVE_PLATFORMS_SUCCESS,
                  type: 'success',
                })
              }

              // setting the workspace account onboarding status
              if (
                  !getters.getActiveWorkspace?.onboarding_steps
                      ?.connect_social_account?.status
              ) {
                await onboardingStepsCompleted('connect_social_account')
              }
            }

            // setting the saved platforms in state
            dispatch('setPlatforms', {
              items: response.data.accounts,
              type: 'instagram',
              all_item_setter: instagram.SET_ALL_ACCOUNTS,
              item_setter: instagram.SET_ACCOUNTS,
            })

            // fetching social accounts list
            dispatch('fetchPlatformsList')

            Plan()
          } else {
            // if the user's social accounts limit exceeded
            if (response.data.limitExceed) {
              showUpgradeModal()
            } else if (response.data.message) {
              dispatch('toastNotification', {
                message: response.data.message,
                type: 'error',
              })
            } else {
              dispatch('toastNotification', {
                message: SAVE_PLATFORMS_ERROR,
                type: 'error',
              })
            }
          }
        }
      } else {
        // Setting the selected accounts in state
        connectionState.selectedPlatform = selectedPlatforms
        connectionState.workspace_id = getters.getWorkspaces.activeWorkspace._id

        // saving the selected accounts (api call)
        const response = await dispatch('processSavePlatforms', {connectionState})

        // hiding the connection modal
        hideModal()

        if (response?.data) {
          if (response.data.status === true) {
            // if the user's social accounts limit exceeded
            if (response.data.limitExceed) {
              showUpgradeModal()
            } else {
              if (response.data?.not_allowed.length) {
                EventBus.$emit('showNotAllowedModal', {
                  notAllowedIds: response.data.not_allowed,
                  firstSectionItems: firstSectionItems.value,
                  firstSectionHeader: firstSectionHeader.value,
                  firstSectionMessage: firstSectionMessage.value,
                  secondSectionItems: secondSectionItems.value,
                  secondSectionHeader: secondSectionHeader.value,
                  secondSectionMessage: secondSectionMessage.value,
                })
              } else {
                EventBus.$emit('refetchSocialPlatforms')
                dispatch('fetchImportantNotifications')
                dispatch('toastNotification', {
                  message: SAVE_PLATFORMS_SUCCESS,
                  type: 'success',
                })
              }

              // setting the workspace account onboarding status
              if (
                  !getters.getActiveWorkspace?.onboarding_steps
                      ?.connect_social_account?.status
              ) {
                await onboardingStepsCompleted('connect_social_account')
              }

              switch (connectionState.process) {
                case 'Facebook':
                  dispatch('setPlatforms', {
                    items: response.data.items,
                    type: 'facebook',
                    all_item_setter: facebook.SET_ALL_ACCOUNTS,
                    item_setter: facebook.SET_ACCOUNTS,
                  })
                  break
                case 'Pinterest':
                  dispatch('setPlatforms', {
                    items: response.data.items,
                    type: 'pinterest',
                    all_item_setter: pinterest.SET_ALL_ACCOUNTS,
                    item_setter: pinterest.SET_ACCOUNTS,
                  })
                  break
                case 'Linkedin':
                  dispatch('setPlatforms', {
                    items: response.data.items,
                    type: 'linkedin',
                    all_item_setter: linkedin.SET_ALL_ACCOUNTS,
                    item_setter: linkedin.SET_ACCOUNTS,
                  })
                  break
                case 'Gmb':
                  dispatch('setPlatforms', {
                    items: response.data.items,
                    type: 'gmb',
                    all_item_setter: gmb.SET_ALL_ACCOUNTS,
                    item_setter: gmb.SET_ACCOUNTS,
                  })
                  break
                case 'Youtube':
                  dispatch('setPlatforms', {
                    items: response.data.items,
                    type: 'youtube',
                    all_item_setter: youtube.SET_ALL_ACCOUNTS,
                    item_setter: youtube.SET_ACCOUNTS,
                  })
                  break

                case 'tumblr':
                  dispatch('setPlatforms', {
                    items: response.data.items.accounts,
                    type: 'tumblr_profiles',
                    all_item_setter: tumblr.SET_ALL_ACCOUNTS,
                    item_setter: tumblr.SET_ACCOUNTS,
                  })
                  commit(blog.SET_BLOGS, {
                    items: response.data.items.blogs,
                    type: connectionState.process,
                  })
                  break
              }

              // fetching social accounts list
              dispatch('fetchPlatformsList')

              Plan()
            }
          } else {
            if (response.data.limitExceed) {
              showUpgradeModal()
              return false
            }

            if (response.data.message) {
              dispatch('toastNotification', {
                message: response.data.message,
                type: 'error',
              })
              return false
            }

            dispatch('toastNotification', {
              message: SAVE_PLATFORMS_ERROR,
              type: 'error',
            })
          }
        }
      }
    }

    const handleAppNotInstalledClick = (event, itemId) => {
      event.preventDefault()
      window
          .open(
              `https://www.facebook.com/groups/${itemId}/edit/?launch_popover=app_detail&app_id=157148231359191`,
              '_blank'
          )
          .focus()
    }

    /**
     *  Watcher to look for already selected first section items
     */
    watch(firstSectionItems, (currentValue) => {
      if (currentValue?.length > 0) {
        data.selectedPlatforms.firstItems = currentValue
            .filter((item) => {
              return item.added
            })
            .map((item) => {
              return getPlanId(item)
            })
      }
    })

    /**
     *   Watcher to look for already selected second section items
     */
    watch(secondSectionItems, (currentValue) => {
      if (currentValue?.length > 0) {
        data.selectedPlatforms.secondItems = currentValue
            .filter((item) => {
              return item.added
            })
            .map((item) => {
              return getPlanId(item)
            })
      }
    })

    return {
      data,
      platformName,
      modalHeader,
      firstSectionHeader,
      secondSectionHeader,
      saveSocialAccounts,
      firstSectionItems,
      secondSectionItems,
      isFirstItemsAllSelected,
      isSecondItemsAllSelected,
      isAppNotInstalled,
      firstSectionMessage,
      secondSectionMessage,
      getPlanId,
      getAccountName,
      getImageLink,
      handleSelectAll,
      hideModal,
      showModal,
      saveSelectedPlatforms,
      getSavePlatformsLoader,
      handleAppNotInstalledClick,
      isEmpty,
    }
  },
})
</script>
