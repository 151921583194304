<template>
  <div class="max_container_1800 automation_main_component">
    <div class="component_inner">
      <div class="top_content_main">
        <h2>Automation Recipes</h2>
        <p
          >Using the automation recipes given below, you can create highly
          customized campaigns by setting up a variety of filters and rules
          according to your needs.</p
        >
      </div>

      <div class="automation_pinterest_warning">
        <i
            class="fas fa-exclamation-triangle">
        </i>
        <p>
          Pinterest removal from automation campaigns (except 'Bulk Uploader') to adhere to Pinterest's policies, starting {{momentWrapper('May 12 2022').formatDate()}}.
          <a
            class="beacon ml-2"
            href="#" data-beacon-article-modal="627b500db2de5178f8882990">
            Read More.
          </a>
        </p>
      </div>

      <template v-if="isWorkspaceOnHold">
        <div class="input_field">
          <div class="warning_box warning-alert">
            <p v-if="hasPermission('can_change_hold_status')"
              ><i class="fa fa-warning"></i> Note: You cannot select accounts
              because you've paused publishing for this workspace,
              <router-link :to="{ name: 'workspaces' }" class="ml-2"
                >Click here to resume publishing
              </router-link>
            </p>
            <p v-if="!hasPermission('can_change_hold_status')"
              ><i class="fa fa-warning"></i> Note: You cannot select accounts
              because the admin of this workspace has paused publishing for this
              workspace.</p
            >
          </div>
        </div>
      </template>

      <div class="automation_boxes">
        <div class="a_box">
          <div class="box_inner">
            <div class="tag-most-used float-right"
              ><span><i class="far fa-star mr-2"></i>Featured</span>
            </div>
            <div class="a_img">
              <img
                class=""
                src="../../../assets/img/automation/article_social.svg"
                alt=""
              />
            </div>
            <h3>Article Links to Social Media</h3>
            <p
              >Share article links on your social media profiles, pages and
              groups with smart rules and filters.</p
            >
            <div
              v-if="!isWorkspaceOnHold"
              class="d-flex align-items-center"
              :class="{
                'justify-content-center':
                  !getAutomationCounts.articleSocialAutomation,
              }"
            >
              <div v-tooltip="!articleSocialAccess.allowed ? articleSocialAccess.error?.message : ''">
                <div
                    @click="handleArticleSocialClick"
                >
                  <a
                      class="btn btn-studio-theme-transparent btn-size-small"
                      :class="{ 'pointer-events-none opacity-50': !articleSocialAccess.allowed }"
                      data-cy="articles-links"
                  >
                    <img
                        v-if="!articleSocialAccess.allowed"
                        src="@assets/img/icons/lock_black.svg"
                        class="w-3 mr-1.5"
                        alt="Locked"
                    />
                    <i v-else class="icon_left add_icon cs-plus"></i>
                    <span>New Campaign</span>
                  </a>
                </div>
              </div>

              <!-- For Article Social Automation -->
              <template
                v-if="getAutomationCounts.articleSocialAutomation"
              >
                <div
                    v-if="!articleSocialAccess.allowed"
                    v-tooltip="articleSocialAccess.error?.message"
                    class="ml-auto"
                    @click="showUpgradeModal"
                >
                  <a class="btn btn-studio-theme-transparent btn-size-small pointer-events-none opacity-50">
                    <span>View existing ({{ getAutomationCounts.articleSocialAutomation }})</span>
                    <img src="@assets/img/icons/lock_black.svg" class="w-3 ml-1.5" alt="Locked" />
                  </a>
                </div>
                <router-link
                    v-else
                    class="btn btn-studio-theme-transparent ml-auto btn-size-small"
                    :to="{
                  name: 'articleSocialAutomationListing',
                  params: { workspace: getWorkspaces.activeWorkspace.slug },
                }"
                >
                  <span>View existing ({{ getAutomationCounts.articleSocialAutomation }})</span>
                </router-link>
              </template>
            </div>
          </div>
        </div>
        <!-- rss feed to social -->
        <div class="a_box">
          <div class="box_inner">
            <div class="tag-most-used float-right">
              <span><i class="far fa-star mr-2"></i>Most used</span>
            </div>
            <div class="a_img">
              <img src="../../../assets/img/automation/rss_feed.svg" alt="" />
            </div>
            <h3>RSS Feed to Social Media</h3>
            <p>Share RSS feeds content on your social media.</p>
            <div
                v-if="!isWorkspaceOnHold"
                class="d-flex align-items-center"
                :class="{
              'justify-content-center': !getAutomationCounts.rssAutomation,
            }"
            >
              <div
                  v-tooltip="!rssAutomationAccess.allowed ? rssAutomationAccess.error?.message : ''"
              >
                <div @click.prevent="handleRssAutomationClick">
                  <a
                      class="btn btn-studio-theme-transparent btn-size-small"
                      data-cy="rss-links"
                      :class="{ 'pointer-events-none opacity-50': !rssAutomationAccess.allowed }"
                  >
                    <img
                        v-if="!rssAutomationAccess.allowed"
                        src="@assets/img/icons/lock_black.svg"
                        class="w-3 mr-1.5"
                        alt="Locked"
                    />
                    <i v-else class="icon_left add_icon cs-plus"> </i>
                    <span>New Campaign</span>
                  </a>
                </div>
              </div>

              <!-- For RSS Automation -->
              <template
                v-if="getAutomationCounts.rssAutomation"
              >
                <div
                    v-if="!rssAutomationAccess.allowed"
                    v-tooltip="rssAutomationAccess.error?.message"
                    class="ml-auto"
                    @click="showUpgradeModal"
                >
                  <a class="btn btn-studio-theme-transparent btn-size-small pointer-events-none opacity-50">
                    <span>View existing ({{ getAutomationCounts.rssAutomation }})</span>
                    <img src="@assets/img/icons/lock_black.svg" class="w-3 ml-1.5" alt="Locked" />
                  </a>
                </div>
                <router-link
                    v-else
                    class="btn btn-studio-theme-transparent btn-size-small ml-auto"
                    :to="{
                      name: 'rssAutomationListing',
                      params: { workspace: getWorkspaces.activeWorkspace.slug },
                    }"
                >
                  <span>View existing ({{ getAutomationCounts.rssAutomation }})</span>
                </router-link>
              </template>
            </div>
          </div>
        </div>

        <!-- evergreen recipe -->
        <div class="a_box">
          <div class="box_inner">
            <div class="tag-most-used float-right">
              <span><i class="far fa-star mr-2"></i>Most used</span>
            </div>
            <div class="a_img">
              <img src="../../../assets/img/automation/article_social.svg" alt="" />
            </div>
            <h3>Evergreen</h3>
            <p>Recycle your evergreen posts at pre-defined time intervals over and over again for increased engagement.</p>
            <div
                v-if="!isWorkspaceOnHold"
                class="d-flex align-items-center"
                :class="{
              'justify-content-center': !getAutomationCounts.evergreenAutomation,
            }"
            >
              <div
                  v-tooltip="!evergreenAutomationAccess.allowed ? evergreenAutomationAccess.error?.message : ''"
              >
                <div @click.prevent="handleEvergreenClick">
                  <a
                      class="btn btn btn-studio-theme-transparent btn-size-small"
                      :class="{ 'pointer-events-none opacity-50': !evergreenAutomationAccess.allowed }"
                  >
                    <img
                        v-if="!evergreenAutomationAccess.allowed"
                        src="@assets/img/icons/lock_black.svg"
                        class="w-3 mr-1.5"
                        alt="Locked"
                    />
                    <i v-else class="icon_left add_icon cs-plus" data-cy="evergreen-links"></i>
                    <span>New Campaign</span>
                  </a>
                </div>
              </div>

              <!-- For Evergreen Automation -->
              <template
                  v-if="getAutomationCounts.evergreenAutomation"
              >
                <div
                    v-if="!evergreenAutomationAccess.allowed"
                    v-tooltip="evergreenAutomationAccess.error?.message"
                    class="ml-auto"
                    @click="showUpgradeModal"
                >
                  <a class="btn btn-studio-theme-transparent btn-size-small pointer-events-none opacity-50">
                    <span>View existing ({{ getAutomationCounts.evergreenAutomation }})</span>
                    <img src="@assets/img/icons/lock_black.svg" class="w-3 ml-1.5" alt="Locked" />
                  </a>
                </div>
                <router-link
                    v-else
                    class="btn btn-studio-theme-transparent btn-size-small ml-auto"
                    :to="{
                    name: 'evergreenAutomationListing',
                    params: { workspace: getWorkspaces.activeWorkspace.slug },
                  }"
                >
                  <span>View existing ({{ getAutomationCounts.evergreenAutomation }})</span>
                </router-link>
              </template>
            </div>
          </div>
        </div>

        <!-- Bulk Uploader -->
        <div class="a_box">
          <div class="box_inner">
            <div class="a_img">
              <img style="width: 45px" src="../../../assets/img/automation/bulk_upload.svg" alt="" />
            </div>
            <h3>Bulk Uploader</h3>
            <p>Schedule multiple messages to your social media channels via CSV data import.</p>
            <div v-if="!isWorkspaceOnHold" class="text-center">
              <div v-tooltip="!bulkUploadAccess.allowed ? bulkUploadAccess.error?.message : ''">
                <div @click="handleBulkUploadClick">
                  <a
                      class="btn btn btn-studio-theme-transparent btn-size-small"
                      :class="{ 'pointer-events-none opacity-50': !bulkUploadAccess.allowed }"
                  >
                    <img
                        v-if="!bulkUploadAccess.allowed"
                        src="@assets/img/icons/lock_black.svg"
                        class="w-3 mr-1.5"
                        alt="Locked"
                    />
                    <i v-else class="icon_left add_icon cs-plus"></i>
                    <span>New Upload</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="a_box">
          <div class="box_inner">
            <div class="a_img">
              <img
                class=""
                src="../../../assets/img/automation/video_social.svg"
                alt=""
              />
            </div>
            <h3>Videos to Social Media</h3>
            <p
              >Share videos on your social media profiles, pages and groups with
              smart rules and filters.</p
            >
            <div
              v-if="!isWorkspaceOnHold"
              class="d-flex align-items-center"
              :class="{
                'justify-content-center':
                  !getAutomationCounts.videoSocialAutomation,
              }"
            >
              <div v-tooltip="!videoSocialAccess.allowed ? videoSocialAccess.error?.message : ''">
                <div @click="handleVideoSocialClick">
                  <a
                      class="btn btn-studio-theme-transparent btn-size-small"
                      :class="{ 'pointer-events-none opacity-50': !videoSocialAccess.allowed }"
                  >
                    <img
                        v-if="!videoSocialAccess.allowed"
                        src="@assets/img/icons/lock_black.svg"
                        class="w-3 mr-1.5"
                        alt="Locked"
                    />
                    <i v-else class="icon_left add_icon cs-plus"> </i>
                    <span>New Campaign</span>
                  </a>
                </div>
              </div>

              <!-- For Video Social Automation -->
              <template
                  v-if="getAutomationCounts.videoSocialAutomation"
              >
                <div
                    v-if="!videoSocialAccess.allowed"
                    v-tooltip="videoSocialAccess.error?.message"
                    class="ml-auto"
                    @click="showUpgradeModal"
                >
                  <a class="btn btn-studio-theme-transparent btn-size-small pointer-events-none opacity-50">
                    <span>View existing ({{ getAutomationCounts.videoSocialAutomation }})</span>
                    <img src="@assets/img/icons/lock_black.svg" class="w-3 ml-1.5" alt="Locked" />
                  </a>
                </div>
                <router-link
                    v-else
                    class="btn btn-studio-theme-transparent ml-auto btn-size-small"
                    :to="{
                    name: 'videoSocialAutomationListing',
                    params: { workspace: getWorkspaces.activeWorkspace.slug },
                  }"
                >
                  <span>View existing ({{ getAutomationCounts.videoSocialAutomation }})</span>
                </router-link>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { evergreen } from '@src/modules/automation/store/automation-mutation-type.js'
import useDateFormat from "@common/composables/useDateFormat";
import {useFeatures} from "@modules/billing/composables/useFeatures";
import {useBilling} from "@modules/billing/composables/useBilling";
export default {
  components: {},
  setup(){
    const { momentWrapper } = useDateFormat();
    const {canAccess} = useFeatures();
    const {showUpgradeModal} = useBilling()

    const evergreenAutomationAccess = canAccess('evergreen_recycling')?.value;
    const rssAutomationAccess = canAccess('rss_scheduling')?.value;
    const bulkUploadAccess = canAccess('bulk_upload_csv')?.value;
    const articleSocialAccess = canAccess('article_automation')?.value;
    const videoSocialAccess = canAccess('video_automation')?.value;

    return {
      momentWrapper,
      evergreenAutomationAccess,
      rssAutomationAccess,
      bulkUploadAccess,
      articleSocialAccess,
      videoSocialAccess,
      showUpgradeModal
    };
  },
  data () {
    return {
      showLoader: false,
      evergreenView: 'on'
    }
  },
  computed: {
    ...mapGetters(['getAutomationCounts', 'getWorkspaces', 'getProfile']),
    isWorkspaceOnHold () {
      return !!(
          this.getWorkspaces.activeWorkspace &&
          this.getWorkspaces.activeWorkspace.on_hold
      )
    }
  },
  created () {
    console.debug('automationMain Call')
    this.initializeAutomationSection()

  },
  methods: {
    ...mapActions([
      'setAccountSelection',
      'setAutomationScheduleOptions',
      'setEditQueueStatus'
    ]),
    handleEvergreenClick () {
      if(!this.evergreenAutomationAccess?.allowed){
        this.showUpgradeModal()
      } else {
        this.$store.commit(evergreen.SET_EVERGREEN_DEFAULT_STATES)
        this.setEditQueueStatus(false)
        this.$store.commit('setAccountSelection', null)
        this.$store.commit('setAutomationScheduleOptions', null)
        this.$router.push({
          name: 'saveEvergreenAutomation',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug }
        })
      }
    },
    handleArticleSocialClick() {
      if (!this.articleSocialAccess?.allowed) {
        this.showUpgradeModal()
      } else {
        this.createArticleAutomationProcess('saveArticleSocialAutomation')
      }
    },

    handleRssAutomationClick() {
      if (!this.rssAutomationAccess?.allowed) {
        this.showUpgradeModal()
      } else {
        this.createRssAutomationProcess()
      }
    },

    handleVideoSocialClick() {
      if (!this.videoSocialAccess?.allowed) {
        this.showUpgradeModal()
      } else {
        this.createVideoAutomationProcess('saveVideoSocialAutomation')
      }
    },
    handleBulkUploadClick() {
      if (!this.bulkUploadAccess.allowed) {
        this.showUpgradeModal()
      } else {
        this.$router.push({
          name: 'saveBulkCsvAutomation',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug }
        })
      }
    }
  },
}
</script>

<style lang="less" scoped>
.tag-most-used {
  margin-top: -1rem;
  margin-right: -20px;
  span {
    border-top-right-radius: 4px;
    font-size: 0.8rem;
    padding: 0.5rem 0.625rem;
    color: #3a4557;
    background: #f4f7fa;
  }
}

.automation_pinterest_warning {
  display: flex;
  padding: 1rem;
  color: #3a4557;
  background: #faf2dc;
  align-items: center;
  border-radius: 1rem;
  max-width: fit-content;
  margin: 0.4rem auto;

  i {
    color: red;
    font-size: 0.9rem;
  }

  p {
    color: #3d4450;
    margin-left: 10px;
    font-size: 0.9rem;
  }
}
</style>
