<script setup>
import { useStore } from 'vuex'
import { vInfiniteScroll } from '@vueuse/components'
import useSocialGridView from "@src/modules/planner_v2/composables/useSocialGridView";
import { EventBus } from '@/src/modules/common/lib/event-bus'
import useInstagramGridView from '@/src/modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'

const store = useStore()
const { selectedAccount, selectedTab, fetching, fetchingMoreItems } =
  useInstagramGridView()

const {tabs} = useSocialGridView()

const onLoadMore = () => {
  if (
    !fetching.value &&
    store.getters.getPlans.items.length < store.getters.getPlans.total_items
  ) {
    fetchingMoreItems.value = true
    EventBus.$emit('refetch-more')
  }
}
</script>

<template>
  <!-- Tabs -->
  <div class="grid grid-cols-12 w-full mb-2">
    <div
      v-for="(tab, index) in tabs"
      :key="tab.name"
      class="
        relative
        h-[2.75rem]
        flex
        col-span-4
        items-center
        p-2
        bg-[#F4F6FA]
        cursor-pointer
        justify-content-center
      "
      @click="selectedTab = tab"
    >
      <div v-if="index !== tabs.length - 1" class="right-border"></div>

      <div class="flex justify-center items-center">
        <ImageIcon
          :path="tab.icon"
          :active="selectedTab.name === tab.name"
          :active-suffix="'-active-blue'"
        />
        <h3
          class="text-sm ml-2"
          :class="
            selectedTab.name === tab.name
              ? 'text-[#2961D2] font-bold'
              : 'font-normal text-gray-800'
          "
        >
          {{ tab.name }}
        </h3>
      </div>
    </div>
  </div>

  <!-- Rendering the component dynamically -->
  <clip-loader
    v-if="fetching && !fetchingMoreItems"
    class="flex items-center spinner ml-2 mt-5"
    color="#e0dfdf"
    size="1.875rem"
  >
  </clip-loader>

  <template v-else>
    <div
      v-if="selectedAccount"
      v-infinite-scroll="onLoadMore"
      class="sidebar-height w-full overflow-auto !pt-0 p-4"
    >
      <keep-alive>
        <component :is="selectedTab.components.sidebar" />
      </keep-alive>
    </div>

    <p v-else class="font-normal text-sm text-center text-[#757A8A] py-8">
      No post created yet
    </p>
  </template>
</template>

<style lang="scss" scoped>
.grid {
  .right-border {
    position: absolute;
    right: 8px;
    height: 14px;
    color: #757a8a;
    border-right: 1px solid;
  }

  h3 {
    margin-top: 2px;
  }
}

.sidebar-height {
  height: calc(100% - 50px);
}
</style>
