<template>
  <CstDropdown
    class="w-full min-w-[20rem]"
    size="small"
    button-classes="flex !rounded-xl w-full"
    container-classes="max-h-[30rem]"
    @on-close="searchQuery = ''"
  >
    <template v-slot:selected>
      <span class="flex flex-row w-full items-center">
        <span class="connected-accounts-images">
          <template v-if="selectedAccounts.length === 0">
            <i class="fad fa-user-circle text-3xl mr-0"></i>
          </template>
          <template
            v-for="(account, index) in localSelectedAccounts"
            :key="index + '_overview_select'"
          >
            <template v-if="index < 5">
              <img
                class="selected"
                :src="channelImage(account, account?.account_type)"
                alt=""
                @error="
                  $event.target.src = account?.name
                    ? fallbackImagePreview(account.name[0])
                    : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                "
              />
            </template>
          </template>
          <!-- Last -->
          <template v-if="getAllAccountsLength > 5">
            <span class="selected">+{{ getAllAccountsLength - 5 }}</span>
          </template>
        </span>
        <span
          class="capitalize_text text-left inline-flex flex-col mx-0.5 font-bold justify-start"
        >
          {{ getAllAccountsLength }} Account{{
            getAllAccountsLength > 1 ? 's' : ''
          }}
          <small>Connected</small>
        </span>
      </span>
    </template>
    <template v-slot>
      <div class="py-2 px-3 sticky top-0 bg-white z-50 shadow-sm">
        <label class="w-full flex items-center mt-1">
          <input
            v-model="searchQuery"
            class="rounded-xl border py-2 pl-1 w-full text-grey-darkest"
            placeholder="Search Account By Name"
            type="text"
          />
          <i
            v-if="searchQuery.length"
            class="fas fa-times-circle absolute cursor-pointer opacity-80 right-4 hover:text-red-400"
            @click="searchQuery = ''"
          ></i>
        </label>
        <SimpleCheckbox
          v-if="!searchQuery && getAllAccounts.length > 1"
          v-model="allSelected"
          @change="selectUnselectAll"
        >
          <template v-slot:label>
            <span class="font-medium">Select All</span></template
          >
        </SimpleCheckbox>
      </div>
      <div>
        <template v-if="searchQuery">
          <template v-if="searchedAccounts?.length > 0">
            <div v-for="(account, i) in searchedAccounts" :key="i">
              <CstDropdownItem :close-on-click="false">
                <SimpleCheckbox
                  v-model="selectedAccounts"
                  :name="account"
                  :disabled="!hasInstaPermission(account)"
                  class="group"
                >
                  <template v-slot:label>
                    <span class="flex items-center">
                      <span class="relative">
                        <img
                          :src="channelImage(account, account?.account_type)"
                          alt=""
                          class="rounded-full object-cover border"
                          data-fallback="0"
                          height="32"
                          width="32"
                          @error="handleImageError"
                        />

                        <img
                          :src="getSocialImageRounded(account?.account_type)"
                          alt=""
                          class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                          :class = "{ 'bg-white rounded-full': account?.account_type === 'twitter' }"

                        />
                      </span>
                      <span class="flex flex-col ml-3">
                        <span class="flex flex-row">
                          <span
                            v-tooltip="{
                              content: hasInstaPermission(account)
                                ? account.name
                                : isIGPersonalAccount(account)
                                ? 'Analytics are not available for Instagram personal accounts.'
                                : 'Insufficient permissions, please reconnect profile to enable Analytics.',
                            }"
                            class="text-sm text-ellipsis font-normal text-black-100 select-none"
                          >
                            {{ account.name }}
                          </span>
                        </span>
                        <span class="block text-xs select-none text-gray-800">
                          {{ getProfileType(account?.account_type, account) }}
                        </span>
                      </span>
                    </span>
                  </template>
                </SimpleCheckbox>
              </CstDropdownItem>
            </div>
          </template>
          <template v-else>
            <CstDropdownItem
              class="active:bg-transparent hover:bg-transparent"
              :close-on-click="false"
              :disabled="true"
            >
              <span>No Account Found</span>
            </CstDropdownItem>
          </template>
        </template>
        <template v-else>
          <div v-for="(account, i) in getAllAccounts" :key="i">
            <CstDropdownItem :close-on-click="false">
              <SimpleCheckbox
                v-model="selectedAccounts"
                :name="account"
                :disabled="!hasInstaPermission(account)"
                class="group"
              >
                <template v-slot:label>
                  <span class="flex items-center">
                    <span class="relative">
                      <img
                        :src="channelImage(account, account?.account_type)"
                        alt=""
                        class="rounded-full object-cover border"
                        data-fallback="0"
                        height="32"
                        width="32"
                        @error="handleImageError"
                      />

                      <img
                        :src="getSocialImageRounded(account?.account_type)"
                        alt=""
                        class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                        :class = "{ 'bg-white rounded-full': account?.account_type === 'twitter' }"

                      />
                    </span>
                    <span class="flex flex-col ml-3">
                      <span class="flex flex-row">
                        <span
                          v-tooltip="{
                            content: hasInstaPermission(account)
                              ? account.name
                              : isIGPersonalAccount(account)
                              ? 'Analytics are not available for Instagram personal accounts.'
                              : 'Insufficient permissions, please reconnect profile to enable Analytics.',
                          }"
                          class="text-sm text-ellipsis font-normal text-black-100 select-none"
                        >
                          {{
                            account.name ? account.name : account.platform_name
                          }}
                        </span>
                      </span>
                      <span class="block text-xs select-none text-gray-800">
                        {{ getProfileType(account?.account_type, account) }}
                      </span>
                    </span>
                  </span>
                </template>
              </SimpleCheckbox>
            </CstDropdownItem>
          </div>
        </template>
      </div>

      <div class="p-2 mr-1 sticky bottom-0 border-top bg-white z-50">
        <CstDropdownItem
          class="active:bg-transparent hover:bg-transparent !p-0.5 text-right"
          size="small"
          :close-on-click="true"
        >
          <CstButton
            text="Apply"
            @click="handleApply"
          />
        </CstDropdownItem>
      </div>
    </template>
  </CstDropdown>
</template>

<script setup>
import {
  ref,
  computed,
  defineProps,
  defineEmits,
  watch,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  nextTick,
} from 'vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import * as _ from 'underscore'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem.vue'
import SimpleCheckbox from '@src/modules/planner_v2/components/SimpleCheckbox'
import CstButton from '@ui/Button/CstButton.vue'
import useAnalyticsUtils from '@src/modules/analytics/components/common/composables/useAnalyticsUtils.js'
import { EventBus } from '@common/lib/event-bus'
import useString from '@/src/modules/common/composables/useString'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'
import usePlatform from '@/src/modules/common/composables/usePlatform'

const { fallbackImagePreview } = useString()
const { channelImage, getSocialImageRounded } = useComposerHelper()
const { isIGPersonalAccount } = usePlatform()
const { selectAllAccounts, getProfileType, getAccountById, getAccountId } = useAnalyticsUtils()

const emits = defineEmits(['onAccountsChange'])

const props = defineProps({
  ignoreAccounts: {
    type: Array,
    default: () => [],
  },
  onApply: {
    type: Function,
    default: () => {},
  },
  defaultSelectedAccounts: {
    type: Array,
    default: () => undefined,
  },
})

onBeforeMount(() => {
  EventBus.$on('workspace-changed', () => {
    console.log('workspace-changed')
    filterDefaultSelectedAccounts()
    getSelectedAccounts()
    handleApplyAccountSelection()
  })
})
onMounted(() => {
  if(props?.defaultSelectedAccounts){
    filterDefaultSelectedAccounts()
  }
  getSelectedAccounts()
  handleApplyAccountSelection()
})

onBeforeUnmount(() => {
  EventBus.$off('workspace-changed')
})

const searchQuery = ref('')
const searchedAccounts = ref([])
const allSelected = ref(true)
const localSelectedAccounts = ref([])
const filteredDefaultAccounts = ref(undefined)
const selectedAccounts = ref(true)

const getAllAccountsLength = computed(() => {
  return localSelectedAccounts.value?.length
})

const getAllAccounts = computed(() => {
  return selectAllAccounts(props.ignoreAccounts)
})

// computed getDefaultOrAllAccounts
const getDefaultOrAllAccounts = computed(() => {
  return filteredDefaultAccounts.value?.map((id)=> getAccountById(id)) ?? getAllAccounts.value
})


watch(selectedAccounts, (newVal) => {
  allSelected.value = newVal.length === getAllAccounts.value.length
})

watch(()=> props.defaultSelectedAccounts, (newVal) => {
  nextTick(() => {
    filterDefaultSelectedAccounts()
  })
}, {deep: true})

watch(searchQuery, (newVal, oldVal) => {
  if (newVal !== oldVal && newVal.length > 0) {
    searchedAccounts.value = getAllAccounts.value?.filter((account) =>
      account.name?.toLowerCase().includes(searchQuery.value?.toLowerCase())
    )
    return
  }
  searchedAccounts.value = []
})

const filterDefaultSelectedAccounts = () => {
  if (!props?.defaultSelectedAccounts) {
    filteredDefaultAccounts.value = undefined
    return;
  }

   // Create a set of account IDs from getAllAccounts
  const accountIdsSet = new Set(getAllAccounts.value?.map(account => getAccountId(account)));

  // Filter the default selected accounts based on the presence in accountIdsSet
  filteredDefaultAccounts.value = props?.defaultSelectedAccounts?.filter(id => accountIdsSet.has(id.toString()));
}


const emitAndUpdate = (value) => {
  emits('onAccountsChange', { value, allSelected: allSelected.value })
}

const getSelectedAccounts = () => {
  allSelected.value = true
  const list = getDefaultOrAllAccounts.value
  localSelectedAccounts.value = selectedAccounts.value =
    checkInstagramPermission(list)
}

const handleApplyAccountSelection = () => {
  localSelectedAccounts.value = selectedAccounts.value
  allSelected.value =
    selectedAccounts.value.length === getAllAccounts.value.length

  emitAndUpdate(selectedAccounts.value)
}

const handleApply = () => {
  handleApplyAccountSelection()
  props?.onApply()
}

const hasInstaPermission = (channel) => {
  if (channel.account_type === 'instagram') {
    const analyticPermission = _.findWhere(channel.permissions, {
      permission: 'instagram_manage_insights',
    })
    return analyticPermission ? analyticPermission.status === 'granted' : false
  } else return true
}

const checkInstagramPermission = (list) => {
  list.forEach((item) => {
    if (item?.account_type === 'instagram') {
      if (!hasInstaPermission(item)) {
        list = _.without(
          list,
          _.findWhere(list, {
            instagram_id: item.instagram_id,
          })
        )
      }
    }
  })
  return list
}

const selectUnselectAll = () => {
  if (allSelected.value) {
    const list = getAllAccounts.value
    selectedAccounts.value = checkInstagramPermission(list)
  } else {
    selectedAccounts.value = []
  }
}

const handleImageError = (event) => {
  event.target.src =
    'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
}
</script>
