<script setup>
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'
import { EventBus } from '@common/lib/event-bus'
import { utc } from 'moment'
import useDashboard from '@src/modules/dashboard/composables/useDashboard'
import useWhiteLabelApplication from '@modules/setting/composables/whitelabel/useWhiteLabelApplication'

defineProps({
  videosData: {
    type: Array,
    default: () => [],
  },
})

const { navigationNext, navigationPrev, sortOnDateBasis } = useDashboard()
const { isWhiteLabelDomain } = useWhiteLabelApplication()

const handleVideoClick = (videoID = '') => {
  EventBus.$emit('show-yt-video-modal', videoID)
}
</script>

<template>
  <Carousel
    navigation-enabled
    center-mode
    autoplay-timeout="5000"
    :pagination-enabled="false"
    :scroll-per-page="false"
    :per-page-custom="[
      [0, 2],
      [680, 3],
      [860, 4],
      [1000, 1],
      [1420, 3],
      [1560, 4],
      [1640, 5],
    ]"
    :navigation-next-label="navigationNext"
    :navigation-prev-label="navigationPrev"
    class="h-full w-full dashboardCarousel__carousel dashboardCarouselVideo__carousel"
  >
    <template
      v-for="(video, videosIndex) in sortOnDateBasis(
        videosData,
        (item) => item.updatedAt
      )"
      :key="`news-key-${videosIndex}`"
    >
      <Slide>
        <div
          class="video-card h-[10.625rem] w-[13.75rem] grid_box_shadow rounded-lg bg-white"
        >
          <div
            v-tooltip="{
              content: 'Play Video',
              followCursor: true,
            }"
            class="relative cursor-pointer"
            @click="handleVideoClick(video.videoID)"
          >
            <img
              class="h-[7.25rem] w-full rounded-[0.75rem] p-1.5"
              :src="`https://i.ytimg.com/vi/${video.videoID}/maxresdefault.jpg`"
              alt=""
            />
            <div
              class="px-[3px] py-[2px] flex items-center justify-center bg-[#202324] bg-opacity-80 absolute right-3 bottom-3 rounded-sm"
            >
              <p class="text-[0.625rem] text-white">{{ video.duration }}</p>
            </div>
          </div>
          <div class="w-full items-start gap-x-2 flex mx-2">
            <img
              v-if="!isWhiteLabelDomain"
              class="w-4 h-4 mt-[0.188rem]"
              src="@src/assets/img/integration/cs_logo.svg"
              alt=""
            />
            <div class="max-w-[10.625rem]">
              <p
                class="text-gray-cs-900 text-[0.7rem] font-medium line-clamp-2"
                >{{ video.title }}</p
              >
              <p class="text-[0.6rem] text-secondary-cs-700 truncate"
                >{{ !isWhiteLabelDomain ? 'ContentStudio ·' : '' }}
                {{ utc(video.videoPublishTime)?.fromNow() }}</p
              >
            </div>
          </div>
        </div>
      </Slide>
    </template>
  </Carousel>
</template>

<style scoped lang="scss">
.video-card {
  margin: 0 0.625rem;
}
</style>
