<template>
  <div>
    <div class="subscription-widget-information d-flex justify_space_between">
      <p class="opacity-70 ml-0" :class="{ limit_exceeded_for: isOverUsed }">
        {{ title }}
      </p>
      <p class="opacity-60 mr-0"> {{ usedLimit }} of {{ totalLimit || 0 }} </p>
    </div>
    <div class="subscription-widget-stats mb-3">
      <b-progress :max="totalLimit == 0 ? 1 : totalLimit" style="height: 7px">
        <b-progress-bar
          :variant="'studio-theme'"
          :value="usedLimit"
        ></b-progress-bar>
      </b-progress>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {},
  props: ['title', 'isOverUsed', 'totalLimit', 'usedLimit'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {},
  methods: {
    ...mapActions([]),
  },
  watch: {},
}
</script>

<style lang="less" scoped></style>
