<script setup>
import { ref, inject, computed, onMounted, onUnmounted } from 'vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import { EventBus } from '@common/lib/event-bus'
import usePinterestAnalytics from '@src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'
import PinterestPostModal from '@src/modules/analytics/views/pinterest/components/PinterestPostModal.vue'
import { debounce } from 'lodash'
import EngagementIcon from '@src/assets/img/icons/analytic/engagement.svg'
import EngagementRateIcon from '@src/assets/img/icons/analytic/rate.svg'
import ViewsIcon from '@src/assets/img/icons/analytic/eye-icon.svg'
import SavesIcon from '@src/assets/img/icons/analytic/save.svg'
import useNumber from '@common/composables/useNumber'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import PerformingPostsCard from '@/src/modules/analytics_v3/components/PerformingPostsCard.vue'

const { formatNumber } = useNumber()

const {
  isReportView,
  TopLeastEngagementDropdown: optionsDropdown,
  selectedTopLeastSortType: selectedOptionType,
  routes,
  screenWidth,

  fetchMedia,
  getTopLeastEngagingPayload,
} = usePinterestAnalytics()

const props = defineProps({
  selectedAccount: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  topPosts: {
    type: Array,
    required: true,
    default: () => [],
  },
  leastPosts: {
    type: Array,
    required: true,
    default: () => [],
  },
  threshold: {
    type: Number,
    default: 5,
  },
  customReportLabel: {
    type: String,
    default: 'Engagements',
  },
  customReportDataKey: {
    type: String,
    default: 'engagement_rate',
  },
})

const root = inject('root')
const { $bvModal } = root
const selectedPost = ref({})
const isLoadingTop = ref(false)
const isLoadingLeast = ref(false)

const SELECTED_SORT_ICON_KEYS = {
  pin_clicks: {
    icon: EngagementIcon,
    dataKey: 'pinClicks',
  },
  outbound_clicks: {
    icon: EngagementIcon,
    dataKey: 'outboundClicks',
  },
  saves: {
    icon: SavesIcon,
    dataKey: 'saves',
  },
  engagement_rate: {
    icon: EngagementIcon,
    dataKey: 'engagement',
  },
  impressions: {
    icon: ViewsIcon,
    dataKey: 'impressions',
  },
}

const postsDisplayLimit = computed(() => (screenWidth.value >= 1510 ? 5 : 4))

const displayedTopPosts = computed(() =>
  props.topPosts?.slice(0, postsDisplayLimit.value)
)

const displayedLeastPosts = computed(() =>
  props.leastPosts?.slice(0, postsDisplayLimit.value)
)

const updateScreenWidth = debounce(() => {
  screenWidth.value = window.innerWidth
}, 200)

const selectedTopLabel = computed(() =>
  isReportView.value
    ? props.customReportLabel
    : selectedOptionType.value.top.label
)

const selectedLeastLabel = computed(() =>
  isReportView.value
    ? props.customReportLabel
    : selectedOptionType.value.least.label
)

onMounted(() => {
  window.addEventListener('resize', updateScreenWidth)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateScreenWidth)
})

const handlePostSelect = (post) => {
  selectedPost.value = post

  $bvModal.show('post-details-modal')
}

const handleShowAll = () => EventBus.$emit('tab-change', '#pins')

const updateMedia = async (type) => {
  try {
    switch (type) {
      case 'top':
        isLoadingTop.value = true
        await fetchMedia(
          routes.TOP_PERFORMING_PINS,
          getTopLeastEngagingPayload(type)
        )
        break
      case 'least':
        isLoadingLeast.value = true
        await fetchMedia(
          routes.LEAST_PERFORMING_PINS,
          getTopLeastEngagingPayload(type)
        )
        break
      default:
        break
    }
  } catch (error) {
    console.error(`Failed to update media for ${type}:`, error)
  } finally {
    isLoadingTop.value = false
    isLoadingLeast.value = false
  }
}

const updateTopLeastSortType = (type, value) => {
  switch (type) {
    case 'top':
      selectedOptionType.value.top = value
      updateMedia(type)
      break
    case 'least':
      selectedOptionType.value.least = value
      updateMedia(type)
      break
    default:
      break
  }
}

const getSelectedSortIconAndKey = (key) => {
  if (isReportView.value) {
    return SELECTED_SORT_ICON_KEYS[props.customReportDataKey]
  }
  return SELECTED_SORT_ICON_KEYS[key]
}

const formattedEngagementRate = (value) =>
  Math.round((value + Number.EPSILON) * 100) / 100
</script>

<template>
  <div
    class="color-border cst-editor relative w-full rounded-md bg-white p-5 h-full"
  >
    <div class="grid grid-cols-2 gap-28 mb-6">
      <div class="flex gap-3 items-center justify-start">
        <p class="text-base font-weight-500 select-none"
          >Top Performing Pins by</p
        >
        <CstDropdown
          dropdown-placement="bottom"
          container-classes="!max-h-96"
          dropdown-classes="!rounded-md !border-[#70707029]"
          button-classes="flex !px-5 !w-auto !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
        >
          <template v-slot:arrow>
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </template>
          <template v-slot:selected>
            <p class="text-[14px] font-weight-500 capitalize">
              {{ selectedTopLabel }}
            </p>
          </template>
          <template v-slot>
            <template
              v-for="(item, index) in optionsDropdown"
              :key="`activity_type_${index}`"
            >
              <CstDropdownItem @click="updateTopLeastSortType('top', item)">
                <p class="text-gray-900 text-sm">{{ item.label }}</p>
              </CstDropdownItem>
            </template>
          </template>
        </CstDropdown>
        <v-menu
          v-if="!isReportView"
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
          :delay="300"
        >
          <i class="far fa-question-circle p-0 cursor-pointer"></i>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
              The top performing pins by {{ selectedOptionType?.top?.label }},
              published during the selected time period.
            </p>
          </template>
        </v-menu>
      </div>

      <div
        class="flex items-center"
        :class="{ 'justify-between': topPosts?.length || leastPosts?.length }"
      >
        <div class="flex gap-3 items-center justify-center">
          <p class="text-base font-weight-500 select-none"
            >Least Performing Pins by</p
          >
          <CstDropdown
            dropdown-placement="bottom"
            container-classes="!max-h-96"
            dropdown-classes="!rounded-md !border-[#70707029]"
            button-classes="flex !px-5 !w-auto !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
          >
            <template v-slot:arrow>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </template>
            <template v-slot:selected>
              <p class="text-[14px] font-weight-500 capitalize">
                {{ selectedLeastLabel }}
              </p>
            </template>
            <template v-slot>
              <template
                v-for="(item, index) in optionsDropdown"
                :key="`activity_type_${index}`"
              >
                <CstDropdownItem @click="updateTopLeastSortType('least', item)">
                  <p class="text-gray-900 text-sm">{{ item.label }}</p>
                </CstDropdownItem>
              </template>
            </template>
          </CstDropdown>
          <v-menu
            v-if="!isReportView"
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
          >
            <i class="far fa-question-circle p-0 cursor-pointer"></i>
            <template v-slot:popper>
              <p class="text-sm text-gray-900">
                The least performing pins by
                {{ selectedOptionType?.least?.label }}, published during the
                selected time period.
              </p>
            </template>
          </v-menu>
        </div>

        <p
          v-if="(topPosts?.length || leastPosts?.length) && !isReportView"
          class="text-base font-medium text-[#2961D2] cursor-pointer"
          @click="handleShowAll"
        >
          Show All Pins
        </p>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-28 rounded !p-2 mx-1">
      <!-- top posts -->
      <div v-if="isLoadingTop">
        <SkeletonBox width="100%" height="150px" radius="12px" />
      </div>
      <div
        v-else-if="displayedTopPosts?.length"
        class="grid w-full mt-2 grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-2"
      >
        <!-- Item -->
        <PerformingPostsCard
          v-for="post in displayedTopPosts"
          :key="post"
          :data="post"
          class="max-w-[8rem]"
          parent-classes="h-full"
          footer-classes="p-2"
          priority-metric="impressions"
          @preview-performance-post="handlePostSelect(post)"
        >
          <template v-slot:media-content>
            <img
              :src="post.coverImage"
              loading="lazy"
              alt=""
              class="rounded-lg h-[7.25rem] w-full max-w-[8rem]"
              @error="
                $event.target.src =
                  'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
              "
            />
          </template>
          <template v-slot:card-footer>
            <div class="grid grid-cols-2 mt-1 w-full p-2">
              <span
                class="flex justify-center w-full select-none"
                :class="topPostsClass"
              >
                <img
                  :src="
                    getSelectedSortIconAndKey(selectedOptionType?.top?.api_key)
                      ?.icon
                  "
                  alt=""
                  class="mr-0.5 h-[0.85rem]"
                />
                <span
                  class="text-sm"
                  :class="
                    post[
                      getSelectedSortIconAndKey(
                        selectedOptionType?.top?.api_key
                      )?.dataKey
                    ] < 0
                      ? 'text-red-500'
                      : 'text-[#56C288]'
                  "
                >
                  {{
                    formatNumber(
                      post[
                        getSelectedSortIconAndKey(
                          selectedOptionType?.top?.api_key
                        )?.dataKey
                      ]
                    )
                  }}
                </span>
              </span>
              <span class="flex justify-center items-center w-full select-none">
                <img
                  :src="EngagementRateIcon"
                  alt=""
                  class="mr-0.5 h-[0.85rem]"
                />
                <span class="text-sm">{{
                  formattedEngagementRate(post.engagementRate)
                }}</span>
              </span>
            </div>
          </template>

          <template v-slot:tooltip-content>
            <p class="text-sm flex justify-between text-gray-900">
              Impressions
              <span class="ml-3 font-bold">
                {{ post.impressions }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Total Eng.
              <span class="ml-3 font-bold"> {{ post.engagement }} </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Eng. Rate
              <span class="ml-3 font-bold"> {{ post.engagementRate }}% </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Pin Clicks
              <span class="ml-3 font-bold">
                {{ post.pinClicks }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Outbound Clicks
              <span class="ml-3 font-bold">
                {{ post.outboundClicks }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Saves
              <span class="ml-3 font-bold">
                {{ post.saves }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Post Type
              <span class="ml-3 font-bold">
                {{ post.mediaType }}
              </span>
            </p>
          </template>
        </PerformingPostsCard>
      </div>
      <div v-else class="flex justify-center items-center w-full h-48">
        <p class="font-medium text-sm">No Pins found.</p>
      </div>

      <!--      least posts-->
      <div v-if="isLoadingLeast">
        <SkeletonBox width="100%" height="150px" radius="12px" />
      </div>
      <div
        v-else-if="displayedLeastPosts?.length"
        class="grid w-full mt-2 grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-2"
      >
        <!-- Item -->
        <PerformingPostsCard
          v-for="post in displayedLeastPosts"
          :key="post"
          :data="post"
          class="max-w-[8rem]"
          parent-classes="h-full"
          footer-classes="p-2"
          priority-metric="impressions"
          @preview-performance-post="handlePostSelect(post)"
        >
          <template v-slot:media-content>
            <img
              :src="post.coverImage"
              loading="lazy"
              alt=""
              class="rounded-lg h-[7.25rem] w-full max-w-[8rem]"
              @error="
                $event.target.src =
                  'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
              "
            />
          </template>
          <template v-slot:card-footer>
            <div class="grid grid-cols-2 mt-1 w-full p-2">
              <span
                class="flex justify-center w-full select-none"
                :class="topPostsClass"
              >
                <img
                  :src="
                    getSelectedSortIconAndKey(
                      selectedOptionType?.least?.api_key
                    )?.icon
                  "
                  alt=""
                  class="mr-0.5 h-[0.85rem]"
                />
                <span
                  class="text-sm"
                  :class="
                    post[
                      getSelectedSortIconAndKey(
                        selectedOptionType?.least?.api_key
                      )?.dataKey
                    ] < 0
                      ? 'text-red-500'
                      : 'text-[#56C288]'
                  "
                >
                  {{
                    formatNumber(
                      post[
                        getSelectedSortIconAndKey(
                          selectedOptionType?.least?.api_key
                        )?.dataKey
                      ]
                    )
                  }}
                </span>
              </span>
              <span class="flex justify-center items-center w-full select-none">
                <img
                  :src="EngagementRateIcon"
                  alt=""
                  class="mr-0.5 h-[0.85rem]"
                />
                <span class="text-sm">{{
                  formattedEngagementRate(post.engagementRate)
                }}</span>
              </span>
            </div>
          </template>
          <template v-slot:tooltip-content>
            <p class="text-sm flex justify-between text-gray-900">
              Impressions
              <span class="ml-3 font-bold">
                {{ post.impressions }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Total Eng.
              <span class="ml-3 font-bold"> {{ post.engagement }} </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Eng. Rate
              <span class="ml-3 font-bold"> {{ post.engagementRate }}% </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Pin Clicks
              <span class="ml-3 font-bold">
                {{ post.pinClicks }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Outbound Clicks
              <span class="ml-3 font-bold">
                {{ post.outboundClicks }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Saves
              <span class="ml-3 font-bold">
                {{ post.saves }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Post Type
              <span class="ml-3 font-bold">
                {{ post.mediaType }}
              </span>
            </p>
          </template>
        </PerformingPostsCard>
      </div>
      <div v-else class="flex justify-center items-center w-full h-48">
        <p class="font-medium text-sm">No Pins found.</p>
      </div>
    </div>
  </div>
  <PinterestPostModal
    :selected-account="selectedAccount"
    :selected-post="selectedPost"
  />
</template>
