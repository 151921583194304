<template>
  <div class="rounded-2xl py-5 bg-white relative" :class="customClasses">
    <div
      v-if="slots?.header"
      :class="`group-hover:text-primary-cs px-8 pb-5 ${headerClasses}`"
      style="border-bottom: 2px solid #f3f5fa"
    >
      <slot name="header"></slot>
    </div>
    <div :class="`px-8 ${slots?.header ? 'pt-5' : ''} ${bodyClasses}`">
      <slot name="body"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import { useSlots, defineProps } from 'vue'

defineProps({
  customClasses: {
    type: [String, Object, Array],
    default: '',
  },
  headerClasses: {
    type: [String, Object, Array],
    default: '',
  },
  bodyClasses: {
    type: [String, Object, Array],
    default: '',
  },
})

const slots = useSlots()
</script>
