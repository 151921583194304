<template>
        <div
v-if="isOpen" v-click-away="handleClickAway"
             class="w-max absolute -right-20 rounded-lg dropdown-shadow  bg-white   focus:outline-none z-50" style="min-width:18rem;max-width:18rem;max-height:32rem;">
           <div class="p-3">
             <span class="text-gray-700 block w-full py-2"> Select from saved UTM presets</span>
             <CstDropdown class="w-full my-1" size="small" button-classes="flex justify-between bg-cs-light-gray">
               <template v-if="selectedUtm && selectedUtm._id" v-slot:selected>
                 {{ selectedUtm.name }}
               </template>
               <template v-else v-slot:selected>
                 Create New
               </template>
               <CstDropdownItem @click="selectUtm({})">Create New</CstDropdownItem>
               <template v-for="(item,index) in getUtms.items" :key="index">
                 <CstDropdownItem @click="selectUtm(item)">{{ item.name }}</CstDropdownItem>
               </template>
             </CstDropdown>
             <template v-if="selectedUtm && selectedUtm._id">
               <div class="border rounded-md bg-cs-light-gray text-black w-full p-1 text-base">
                 <div class="my-2">
                     URL
                   <p v-if="inputUrl" class="text-gray-800 text-xs">{{inputUrl}}</p>
                 </div>
                 <div class="my-2">
                     Campaign Source
                     <p v-if="selectedUtm.auto_add_social_channel" class="text-gray-800 text-sm">Automatically add social's channel name as a source</p>
                     <p v-else class="text-gray-800 text-sm">{{selectedUtm.source}}</p>
                 </div>
                 <div class="my-2">
                     Campaign Medium
                     <p class="text-gray-800 text-sm">{{selectedUtm.medium}}</p>
                 </div>
                 <div class="my-2">
                     Campaign Name
                     <p class="text-gray-800 text-sm">{{selectedUtm.name}}</p>
                 </div>
                 <div class="my-2">
                     Campaign Ads Content
                     <p class="text-gray-800 text-sm">{{selectedUtm.content}}</p>
                 </div>
                 <div class="my-2">
                   <button
data-v-6582dc08="" class="flex text-sm text-black bg-gray-500 rounded-md border-0 px-3 py-1 ml-auto"
                   @click="updateUtm">Edit</button>
                 </div>
               </div>
             </template>
             <template v-else>
               <CstInputFields
:value="inputUrl" class="w-full my-1" type="text" placeholder="URL"
                               @value="(val) => inputUrl = val"
               />
               <CstInputFields
:value="editUtm.source" class="w-full my-1" type="text" placeholder="Campaign Source"
                               :disabled="editUtm.auto_add_social_channel"
                               @value="(val) => editUtm.source = val"/>
               <div class="px-2 py-2">
                 <input id="channel_source" v-model="editUtm.auto_add_social_channel" type="checkbox">
                 <label class="inline mx-2 text-gray-800 text-sm" for="channel_source">Automatically add social
                   channel's name as a source</label>
               </div>
               <CstInputFields
v-model="editUtm.medium" class="w-full my-1" type="text" placeholder="Campaign Medium"
                               @value="(val) => editUtm.medium = val"/>
               <CstInputFields
v-model="editUtm.name" class="w-full my-1" type="text" placeholder="Campaign Name"
                               @value="(val) => editUtm.name = val"/>
               <CstInputFields
v-model="editUtm.content" class="w-full my-1" type="text"
                               placeholder="Campaign Ad Content"
                               @value="(val) => editUtm.content = val"/>
             </template>
             <button v-if="selectedUtm && selectedUtm._id" class="mt-2 text-sm bg-cs-primary focus:outline-none rounded-md text-white border-0 px-3 py-2" @click="applyUtm">Apply</button>
             <button v-else class="mt-2 text-sm bg-cs-primary focus:outline-none rounded-md text-white border-0 px-3 py-2" @click="saveApplyUtm">Save & Apply</button>
           </div>
        </div>
</template>

<script>
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import CstInputFields from '@ui/Input/CstInputFields'
import {UNKNOWN_ERROR, UTM_INVALID_NAME, UTM_INVALID_SOURCE, UTM_SAVE_SUCCESS,} from '@common/constants/messages'
import {saveUTMUrl} from '@src/modules/setting/config/api-utils'
import {fetchLongLinkUrl} from "@src/modules/publish/config/api-utils";
import {mapGetters} from "vuex";
import {EventBus} from "@common/lib/event-bus";

const clone = require("rfdc/default")
export default {
  name: "UtmDropdown",
  components: {
    CstDropdown,
    CstDropdownItem,
    CstInputFields
  },
  props: {
    utm: {
      type: Object,
      default: () => ({})
    },
    detail: {
      type: Object,
      default: () => ({})
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    firstComment: {
      type: Boolean,
      default: false
    },
    checkLink: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultUtm: {
        id: '',
        auto_add_social_channel: false,
        name: '',
        source: '',
        medium: '',
        content: ''
      },
      selectedUtm: {},
      editUtm: {},
      inputUrl: '',
      originalLink: '',
    }
  },
  computed: {
    ...mapGetters(['getUtms', 'getWorkspaces']),
  },
    beforeUnmount() {
      this.$off('handle-utm')
      EventBus.$off('apply-utms')
    },
    mounted() {
      if(this.firstComment){
      this.selectedUtm = this.getActiveUtm()
      }
      this.getUrl()

      EventBus.$on('apply-utms', () => {
        // check if auto apply is enabled in settings
        if (this.getWorkspaces.activeWorkspace.utmStatus){
          this.getUrl()
          this.applyUtm()
        }
      })
    },

    methods: {
      handleClickAway() {
        if (this.selectedUtm?._id === '') return
        this.$emit('handle-click', this.isOpen, 'utm')
      },
      getUrl() {
        let url = ''

        if (this.firstComment) {
          const firstCommentUrls = this.getLinksFromText(this.detail)
          if (firstCommentUrls && firstCommentUrls.length > 0) url =  firstCommentUrls.find((url)=>!this.hasUtmParameters(url))
        }


        if (this.detail.url)
          url = this.detail.url
        else {
          let urls = []
          if (this.detail.message)
            urls = this.getLinksFromText(this.detail.message)
          if (urls && urls.length > 0) url = urls[0]
        }

      if(url && this.originalLink !== url){
        this.originalLink = url
        if (this.isShortLink(url)) {
          this.postRequest(
              fetchLongLinkUrl,
              {
                link: url,
                workspace_id: this.getWorkspaces.activeWorkspace._id,
              },
              (response) => {
                if (response.data.status) {
                  this.inputUrl = this.removeExistingUtmParameters(response.data.link)
                }
              },
              (response) => {
                console.debug('fetchLongLinkEXCEPTION', response)
                this.alertMessage(UNKNOWN_ERROR, 'error')
              }
          )
        } else {
          this.inputUrl = this.removeExistingUtmParameters(url)
        }
      }
    },
    selectUtm(utm){
      this.selectedUtm = utm
      this.editUtm = clone(this.defaultUtm)
    },
    updateUtm(){
      if(this.selectedUtm._id){
        this.editUtm = clone(this.selectedUtm)
        this.selectedUtm._id = ''
      }

    },
    applyUtm(){
      if(!this.inputUrl || this.inputUrl.trim().length === 0){
        this.alertMessage('Link is required', 'error')
        return false
      }
     else if(!this.isValidUrl(this.inputUrl)){
        this.alertMessage('Link is invalid', 'error')
        return false
      }
     const response = this.fetchUtmBasedLinks(
          this.inputUrl,
          this.selectedUtm
      )
      this.$emit('handle-utm','apply',response)
      this.$emit('handle-click',true,'utm')
    },
    saveApplyUtm() {
      if (!this.editUtm.name) {
        this.alertMessage(UTM_INVALID_NAME, 'error')
        return
      }
      if (!this.editUtm.auto_add_social_channel && !this.editUtm.source) {
        this.alertMessage(UTM_INVALID_SOURCE, 'error')
        return
      }
      if (!this.editUtm.medium || this.editUtm.medium === '') {
        this.alertMessage('Please enter a valid medium for UTM', 'error')
        return
      }
      const payload = {
        id: this.editUtm._id,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        name: this.editUtm.name,
        source: this.editUtm.source,
        medium: this.editUtm.medium,
        content: this.editUtm.content,
        auto_add_social_channel: this.editUtm.auto_add_social_channel,
      }
      this.postRequest(
          saveUTMUrl,
          payload,
          (response) => {
            if (response.data.status) {
              // this.$emit('handle-utm','fetch')
              this.selectedUtm = response.data.utm
              this.setUtmsStatus(false)
              this.alertMessage(UTM_SAVE_SUCCESS, 'success')
              this.applyUtm()
              this.fetchAllUtms()
            } else {
              this.alertMessage(response.data.message, 'error')
            }
          },
          (err) => {
            console.error(err)
            this.alertMessage(UNKNOWN_ERROR, 'error')
          }
      )
    },
      getActiveUtm() {
        return this.getUtms.items.find((item) => item.active) || 'ContentStudio'
      },
      hasUtmParameters(url) {
        return url.includes('utm_source') || url.includes('utm_medium') || url.includes('utm_campaign') || url.includes('utm_content')
      },
  }
}
</script>

<style lang="scss">
.utm_input{
  &:disabled{
    background-color: #f5f5f5;
  }
  @apply w-full my-1 rounded-md px-2.5 py-2 text-sm border-0 font-normal text-gray-800  bg-gray-200

}
</style>
