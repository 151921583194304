import axios from 'axios'
import VueCookie from 'vue-cookie'
import { cookieDomain } from '@src/config/api-utils'

// Base proxy instance
const proxy = axios.create({})

// Attach cancel token utilities to the base proxy instance
proxy.CancelToken = axios.CancelToken
proxy.source = axios.CancelToken.source()
proxy.isCancel = axios.isCancel

// Default request interceptor (for handling logged_user_jwt)
proxy.interceptors.request.use(
    (config) => {
        let token = VueCookie.get('logged_user_jwt', { domain: cookieDomain })
        if (!token) {
            token = localStorage.getItem('logged_user_jwt')
        }
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        config.headers.Accept = 'application/json'
        return config
    },
    (error) => Promise.reject(error)
)

// Create a clone of the proxy instance with the same configuration using axios.create
const easyConnectProxy = axios.create({ ...proxy.defaults })

// Add an interceptor for easyConnectProxy to handle external link token
easyConnectProxy.interceptors.request.use(
    (config) => {
        const externalLinkToken = JSON.parse(sessionStorage.getItem('external_link_token'))
        if (externalLinkToken?.token) {
            config.headers['EXTERNAL-LINK-TOKEN'] = externalLinkToken.token
        }
        config.headers.Accept = 'application/json'
        return config
    },
    (error) => Promise.reject(error)
)

// Store for cancel tokens (shared across requests)
const requests = []

// Function to generate and manage cancel tokens for requests
function getProxyCancelToken(proxy) {
    console.debug('removePendingRequest', proxy)
    // Clear previous pending requests
    for (let i = 0; i < requests.length; i++) {
        const remove = requests[i]
        remove()
    }
    let cancel
    const token = new proxy.CancelToken(function executor(c) {
        cancel = c
    })
    // Store the cancel function for later use
    requests.push(cancel)
    return { cancelToken: token }
}

// Export both instances and utility functions
export default proxy
export { easyConnectProxy, getProxyCancelToken }
