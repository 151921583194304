export const PUBLISH_OPTIONS_TIME = {
  DRAFT: 'draft',
  SCHEDULE: 'schedule',
  CONTENT_CATEGORY: 'content_category',
  NOW: 'now',
}

// Plan status
export const PLAN_STATUS = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
  UNDER_REVIEW: 'Under Review',
  APPROVED: 'Approved',
  REJECTED: 'rejected',
  QUEUED: 'queued',
  PROCESSING: 'processing',
  FAILED: 'failed',
  PARTIALLY_FAILED: 'partial',
  REVIEW: 'review',
}

export const POST_STATE = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
  QUEUED: 'queued',
  PROCESSING: 'processing',
  FAILED: 'failed',
  PARTIALLY_FAILED: 'partial',


  UNDER_REVIEW: 'under_review',
  REVIEW: 'review',
  REVIEWED: 'reviewed',
  APPROVED: 'approved',
  REJECTED: 'rejected',

  MISSED_REVIEW: 'missedReview',
}

export const WORKSPACE_ROLES = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  COLLABORATOR: 'collaborator',
  APPROVER: 'approver',
}

export const WORKSPACE_MEMBER_STATUS = {
  JOINED: 'joined',
  INVITED: 'invited',
}

export const WORKSPACE_MEMBER_TYPES = {
  TEAM: 'team',
  CLIENT: 'client',
}
export const APPROVER_STATUS = {
  APPROVE: 'approve',
  REJECT: 'reject',
  PENDING: 'pending',
}

export const SUPPORTED_PLATFORMS = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  PINTEREST: 'pinterest',
  TIKTOK: 'tiktok',
  TUMBLR: 'tumblr',
  GOOGLE_MY_BUSINESS: 'google_my_business',
}

// List of multimedia allowed platforms
export const MULTIMEDIA_ALLOWED_PLATFORMS = ['instagram', 'threads', 'multi-threads', 'facebook']
