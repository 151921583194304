<script setup>
import { inject } from 'vue'
import { useRouter } from 'vue-router'
import CstButton from '@ui/Button/CstButton'
import { EventBus } from '@/src/modules/common/lib/event-bus'

const props = defineProps({
  heading: {
    type: String,
    default: 'No post created yet',
  },
  buttonText: {
    type: String,
    default: 'Compose a Post',
  },
  action: {
    type: String,
    default: null,
  },
  compose: {
    type: Boolean,
    default: false,
  },
})

const router = useRouter()
const root = inject('root')
const { $bvModal } = root

const handleButtonClick = () => {
  console.debug('Props in Empty State: ', props)
  if (props.compose) {
    console.debug('Props in Empty State: ', props)
    EventBus.$emit('publication-composer-post-loader', true)
    $bvModal.show('composer-modal')

    setTimeout(function () {
      EventBus.$emit('publication-composer-post', {
        mode: 'new',
      })
    }, 100)
  } else {
    router.push(props.action)
  }
}
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <h3 class="text-sm font-normal text-center text-[#979CA0]">
      {{ heading }}
    </h3>
    <div class="mt-4">
      <CstButton size="small" :text="buttonText" @click="handleButtonClick" />
    </div>
  </div>
</template>
