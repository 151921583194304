<template>
  <div>
    <div ref="instagramCompetitorMainBody" class="pt-5 pr-5 pl-5 pb-0">
      <div
          v-if="dataTableMetricsLoadingState"
          class="grid grid-cols-5 gap-7 mb-7"
      >
        <SkeletonBox class="!h-[8.5rem]"/>
        <SkeletonBox class="!h-[8.5rem]"/>
        <SkeletonBox class="!h-[8.5rem]"/>
        <SkeletonBox class="!h-[8.5rem]"/>
        <SkeletonBox class="!h-[8.5rem]"/>
      </div>
      <transition-group
          v-else-if="dataTableMetricsResponse.length"
          tag="div"
          class="grid grid-cols-5 gap-7 mb-7"
          name="slide-fade"
      >
        <template
            v-for="(competitor, index) in dataTableMetricsResponse"
            :key="`competitor_tile_${index}`"
        >
          <CompetitorTile
              :id="competitor.business_account_id"
              :name="competitor.name ? competitor.name : competitor.slug"
              :slug="competitor.slug"
              :image="competitor.image"
              :color="competitor.color"
              :followers="competitor.followersCount"
              :graph="getTileGraphValue(index)"
              :change="competitor.followersCountDiff"
              :state="competitor.state"
              :engagement-rate="competitor.engagementRate"
              :max-er="state.maxER"
              :min-er="state.minER"
              :max-followers="state.maxFollowers"
              :min-followers="state.minFollowers"
          />
          <CompetitorTile
              v-if="
              dataTableMetricsResponse.length < 5 &&
              index + 1 === dataTableMetricsResponse.length
            "
              :key="`empty_tile${index}`"
              :is-empty="true"
              name=""
              image=""
              color=""
              followers=""
              @click="
              EventBus.$emit(
                'show-manage-competitors-modal',
                reportCompetitorsInfo,
              )
            "
          />
        </template>
      </transition-group>

      <div class="mb-7 relative">
        <PerformanceComparison
            :title="performanceComparisonData.title"
            :legend="{
            show: true,
            data: getPerformanceComparisonLegends,
          }"
            :x-axis-label="performanceComparisonData.xAxisLabel"
            :y-axis-label="performanceComparisonData.yAxisLabel"
            :is-all-data-fetched="isAllDataFetched"
            :data="filteredDataTableMetrics"
            :is-loading="dataTableMetricsLoadingState"
            type="performanceComparison"
        />
      </div>

      <div class="mb-7">
        <CompetitorsTable
            :title="performanceReviewTableData.title"
            :data="filteredDataTableMetrics"
            :is-all-data-fetched="isAllDataFetched"
            :headers="performanceReviewTableData.headers"
            :is-loading="dataTableMetricsLoadingState"
            :followers-growth-data="getFollowersGrowthData"
            :type="performanceReviewTableData.type"
        />
      </div>

      <div class="mb-7 grid grid-cols-2 gap-4">
        <FollowersComparisonBarChart
            :title="performanceReviewGraphData.title"
            :x-axis-label="performanceReviewGraphData.xAxisLabel"
            :y-axis-label="performanceReviewGraphData.yAxisLabel"
            :is-all-data-fetched="isAllDataFetched"
            :data="filteredDataTableMetrics"
            :is-loading="dataTableMetricsLoadingState"
            type="followersComparisonGraph"
        />

        <DoubleBarChart
            :title="postingByTypeData.title"
            :x-axis-label="postingByTypeData.xAxisLabel"
            :y-axis-label="postingByTypeData.yAxisLabel"
            :y-axis-second-label="postingByTypeData.yAxisSecondLabel"
            :data="postingActivityGraphByTypesResponse"
            :is-loading="postingActivityGraphByTypesLoadingState"
            :is-all-data-fetched="isAllDataFetched"
            :date-range-diff="dateRangeDiff"
            :colors="colors"
            :platform="platform"
            type="allTypesGraph"
        />
      </div>

      <div
          ref="specificPostingContent"
          class="mb-7 grid grid-cols-2 w-full gap-4"
      >
        <DoubleBarChart
            :title="postingBySpecificType.title"
            :date-range-diff="dateRangeDiff"
            :data="postingBySpecificType.data"
            :is-all-data-fetched="isAllDataFetched"
            :x-axis-label="postingBySpecificType.xAxisLabel"
            :y-axis-label="postingBySpecificType.yAxisLabel"
            :y-axis-second-label="postingBySpecificType.yAxisSecondLabel"
            :selected-type="getFormattedMediaType(null)"
            :all-available-types="getAllAvailablePostsTypes"
            :is-loading="postingActivitySpecificTypeLoadingState"
            type="postingActivityBySpecificType"
            :platform="platform"
            @chart-type="changeChartType"
        />

        <CompetitorsTable
            :title="activityTypeTableData.title"
            :data="getSpecificTypeTableData"
            :is-all-data-fetched="isAllDataFetched"
            :headers="activityTypeTableData.headers"
            :is-loading="postingActivityTableByTypeLoadingState"
            :type="activityTypeTableData.type"
            :selected-type="getFormattedMediaType(null)"
            :all-available-types="getAllAvailablePostsTypes"
            @chart-type="changeChartType"
        />
      </div>

      <div class="mb-7">
        <CompetitorsTable
            :title="hashtagsTableData.title"
            :show-info-cell="false"
            :data="topHashtagsResponse"
            :headers="hashtagsTableData.headers"
            :is-all-data-fetched="isAllDataFetched"
            :is-loading="topHashtagsLoadingState"
            :type="hashtagsTableData.type"
            :single-hashtag-data="individualHashtagDataResponse"
            :single-hashtag-header="singleHashtagContent.headers"
            :index-to-sort="2"
            @single-hashtag="fetchSingleHashtagData"
        />
      </div>

      <div class="mb-7">
        <CompetitorsTable
            :title="bioAnalysisTableData.title"
            :data="getBioAnalysisTableData"
            :is-all-data-fetched="isAllDataFetched"
            :is-loading="biographyDataLoadingState"
            :headers="bioAnalysisTableData.headers"
            :type="bioAnalysisTableData.type"
        />
      </div>

      <div class="mb-7">
        <TopAndLeastPerformingPosts
            :data="getTopAndLeastPostsData"
            :is-all-data-fetched="isAllDataFetched"
            :is-loading="topAndLeastPerformingPostsLoadingState"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
// libraries
import {
  onMounted,
  reactive,
  computed,
  watch,
  ref,
  onBeforeMount,
  defineProps,
  defineEmits,
  inject,
  onBeforeUnmount,
} from 'vue'
import {EventBus} from '@common/lib/event-bus'

// components
import CompetitorTile from '@src/modules/analytics_v3/components/CompetitorTile'
import PerformanceComparison from '@src/modules/analytics_v3/components/PerformanceComparison'
import CompetitorsTable from '@src/modules/analytics_v3/components/CompetitorsTable.vue'
import TopAndLeastPerformingPosts from '@src/modules/analytics_v3/components/TopAndLeastPerformingPosts.vue'
import DoubleBarChart from '@src/modules/analytics_v3/components/DoubleBarChart.vue'
import FollowersComparisonBarChart from '@src/modules/analytics_v3/components/FollowersComparisonBarChart.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'

// composable
import useCompetitorHelper from '@src/modules/analytics_v3/composables/useCompetitorHelper'
import useCompetitorReport from '@src/modules/analytics_v3/composables/useCompetitorReport'
import useDataTableMetrics from '@src/modules/analytics_v3/composables/useDataTableMetrics'
import usePostingActivityGraphByTypes from '@src/modules/analytics_v3/composables/usePostingActivityGraphByTypes'
import usePostingBySpecificType from '@src/modules/analytics_v3/composables/usePostingBySpecificType'
import useFollowersGrowthComparison from "@src/modules/analytics_v3/composables/useFollowersGrowthComparison";
import usePostingActivityTableByType from "@src/modules/analytics_v3/composables/usePostingActivityTableByType";
import useTopHashtags from "@src/modules/analytics_v3/composables/useTopHashtags";
import useIndividualHashtagData from "@src/modules/analytics_v3/composables/useIndividualHashtagData";
import useBiographyData from "@src/modules/analytics_v3/composables/useBiographyData";
import useTopAndLeastPerformingPosts from "@src/modules/analytics_v3/composables/useTopAndLeastPerformingPosts";
import useCompetitorsFactory from "@src/modules/analytics_v3/composables/useCompetitorsFactory"

// constants
import {PLATFORMS} from '@src/modules/analytics_v3/constants/constants'

import {useStore} from "@state/base";
import {useRoute} from "vue-router";

const props = defineProps({
  competitorAnalyticsAccess: {
    type: Object,
    default: () => {},
  },
})

defineEmits(['show-manage-competitors-modal'])

// functionality

/** data */
const root = inject('root')
const { $router } = root
const { getters } = useStore()
const $route = useRoute()

const state = reactive({
  labelBgColor: '#ffff',
  maxER: 0,
  minER: 0,
  maxFollowers: 0,
  minFollowers: 0,
})
const specificPostingContent = ref(null)
const instagramCompetitorMainBody = ref(null)
const colors = [
  '#339ca8',
  '#cda819',
  '#37A2DA',
  '#8081B9',
  '#FFE27D',
  '#FFAE8B',
  '#71F6F9',
]

const {getMinMax} = useCompetitorHelper()
const {platform} = useCompetitorsFactory()
const {reportCompetitorsInfo, reportId, getReportInfo} = useCompetitorReport()
const {
  getDataTableMetrics,
  dataTableMetricsResponse,
  dataTableMetricsLoadingState,
} = useDataTableMetrics()
const {
  getPostingActivityGraphByTypes,
  postingActivityGraphByTypesResponse,
  postingActivityGraphByTypesLoadingState,
  dateRangeDiff,
  mediaType,
  mediaProductType,
} = usePostingActivityGraphByTypes()
const {
  getPostingActivityBySpecificType,
  postingActivitySpecificTypeLoadingState,
  postingActivitySpecificTypeResponse,
} = usePostingBySpecificType()
const {
  getFollowersGrowthComparison,
  followersGrowthComparisonResponse,
  followersGrowthComparisonLoadingState
} = useFollowersGrowthComparison()
const {getTopHashtags, topHashtagsResponse, topHashtagsLoadingState} = useTopHashtags()
const {
  getIndividualHashtag,
  individualHashtagDataResponse,
} = useIndividualHashtagData()
const {
  getPostingActivityTableByType,
  postingActivityTableByTypeResponse,
  postingActivityTableByTypeLoadingState
} = usePostingActivityTableByType()
const {getBiographyData, biographyDataResponse, biographyDataLoadingState} = useBiographyData()
const {
  getTopAndLeastPerformingPosts,
  topAndLeastPerformingPostsResponse,
  topAndLeastPerformingPostsLoadingState
} = useTopAndLeastPerformingPosts()

// data for the performance comparison graph
const performanceComparisonData = {
  title: "Competitors' Performance Comparison",
  xAxisLabel: 'Engagement Rate',
  yAxisLabel: 'Followers',
  yAxisSecondLabel: 'Followers %',
}

// data for performance review table
const performanceReviewTableData = {
  title: 'Competitors’ Comparative Table',
  type: 'performanceReviewTable',
  headers: [
    'followersCount',
    'followersCountDiff',
    'averagePostsPerWeek',
    'averagePostsPerWeekDiff',
    'engagementRate',
    'engagementRateDiff',
  ],
}

// data for performance review graph
const performanceReviewGraphData = {
  title: 'Competitors by Total Followers vs Net Change in Followers',
  xAxisLabel: 'Competitors',
  yAxisLabel: 'Followers',
}

// data for general posting activity by type graph
const postingByTypeData = {
  xAxisLabel: 'Post Type',
  title: 'Posting Activity by Post Type',
  yAxisLabel: 'Average Engagement',
  yAxisSecondLabel: 'Posts/ Week',
  xAxisSecondLabel: 'Engagement',
  colors,
}

// data for specific activity type table
const activityTypeTableData = {
  type: 'postingActivityTableByType',
  headers: ['count', 'totalEngagement', 'engagementRate'],
  title: 'Activity by Competitors',
}

// data for most engaged hashtags (table)
const hashtagsTableData = {
  headers: [
    'tag',
    'companies_using',
    'count',
    'engagement_per_post',
    'engagement_per_follower',
    'engagement_rate_by_follower',
  ],
  type: 'mostEngagedHashtagsTable',
  title: 'Most Engaged Hashtags',
}

// data for single hashtag data w.r.t competitors
const singleHashtagContent = {
  headers: [
    'name',
    'count',
    'engagement_per_post',
    'engagement_per_follower',
    'engagement_rate_by_follower',
  ],
  type: 'singleHashtagTableData',
}

// data for bio analysis (table)
const bioAnalysisTableData = {
  headers: ['biography', 'biography_length'],
  type: 'bioAnalysisTableData',
  colors,
  title: 'Bio Analysis',
}


/** computed */

// returns boolean on the basis of presence of 'Added' value against state key in competitors' objects.
const isAllDataFetched = computed(() =>
    dataTableMetricsResponse.value?.every((item) => item.state === 'Added')
)

const filteredDataTableMetrics = computed(() => {
  if (isAllDataFetched.value) return []
  return dataTableMetricsResponse.value?.filter(
      (item) => item.state !== 'Added'
  )
})

// This computed property returns filtered Instagram analytics data for CompetitorsTable having type postingActivityTableByType.
const getSpecificTypeTableData = computed(() =>
    postingActivityTableByTypeResponse.value?.filter(
        (competitor) => competitor.state !== 'Added'
    )
)

// This computed property returns filtered Instagram analytics data for TopAndLeastPerformingPosts.
const getTopAndLeastPostsData = computed(() =>
    topAndLeastPerformingPostsResponse.value?.filter(
        (competitor) => competitor.state !== 'Added'
    )
)

// This computed property returns filtered Instagram analytics data for CompetitorsTable having type bioAnalysisTableData.
const getBioAnalysisTableData = computed(() =>
    biographyDataResponse.value?.filter((competitor) => competitor.state !== 'Added')
)

// This computed property returns all the available posts types of competitors in report. /
const getAllAvailablePostsTypes = computed(() =>
    postingActivityGraphByTypesResponse.value?.map((item) => getFormattedMediaType(item.mediaType))
)

// This computed property returns all the names of all the added competitors.
const getPerformanceComparisonLegends = computed(() =>
    filteredDataTableMetrics.value?.map((item) =>
        item.name ? item.name : item.slug
    )
)

// data for followers growth comparison graph
const getFollowersGrowthData = computed(() => ({
  data: followersGrowthComparisonResponse.value,
  xAxisLabel: 'Timeline',
  yAxisLabel: 'Followers',
  title: 'Followers Growth Comparison',
  isLoading: followersGrowthComparisonLoadingState.value
}))

// data for specific activity graph, maps the colors w.r.t specific competitors & then filter on the basis of state.
const postingBySpecificType = computed(() => {
  const data = postingActivitySpecificTypeResponse.value
      ?.map((item) => {
        for (const competitor of dataTableMetricsResponse.value) {
          if (competitor.name === item.name) {
            if (competitor.state === 'Added') {
              return null
            }
            return {...item, color: competitor.color}
          }
        }
        return null
      })
      .filter((competitor) => competitor && competitor.color)

  return {
    xAxisLabel: 'Competitors',
    yAxisLabel: 'Average Engagement',
    yAxisSecondLabel: 'Posts/ Week',
    title: 'Activity by Competitors',
    dateRangeDiff: dateRangeDiff.value,
    data,
  }
})

/** watch */
watch(
    () => getters.getActiveWorkspace._id,
    () => {
      if ($route?.path?.includes('/instagram-competitor')) {
        $router.push({ name: 'instagram_competitor_overview_v3' })
      }
    }
)

watch(
    () => dataTableMetricsResponse.value,
    (newVal) => {
      state.loading = false
      if (newVal) {
        const engagementRate = getMinMax(newVal, 'engagementRate')
        const followers = getMinMax(newVal, 'followersCount')
        state.maxFollowers = followers.max
        state.minFollowers = followers.min
        state.maxER = engagementRate.max
        state.minER = engagementRate.min
      }
    }
)

/**  lifecycle hooks */
onBeforeMount(() => {
  // explicitly check for false  ** router in component guard not used due to vue router migration
  if (!props.competitorAnalyticsAccess?.allowed) {
    if(getters?.getPlan?.subscription?.paddle_billing) {
      $router.push({name: 'analytics_overview_v3'})
    }
    $router.push({ name: 'competitor_analytics' })
  }
})

onMounted(async () => {
  if(!platform.value) platform.value = 'instagram'
  if (reportId.value) {
    await getReportInfo();
    fetchReportAnalytics()
  }

  instagramCompetitorMainBody.value.scrollTo({ top: 0, behavior: 'smooth' })

  EventBus.$on('re-fetch-report-data', () => {
    if(reportId.value && platform.value) fetchReportAnalytics()
  })

  EventBus.$on('fetch-specific-type-data', () => {
    if (mediaType.value && mediaProductType.value && $route.name === 'instagram_competitor_v3') {
      fetchSpecificPostTypeData()
    }
  })

  EventBus.$on('chart-type', (type) => {
    changeChartType(type, 'allTypes')
  })
})

onBeforeUnmount(() => {
  EventBus.$off('re-fetch-report-data')
  EventBus.$off('fetch-specific-type-data')
  EventBus.$off('chart-type')
})

/** methods */
const fetchReportAnalytics = () => {
  getDataTableMetrics(PLATFORMS.INSTAGRAM)
  getPostingActivityGraphByTypes(PLATFORMS.INSTAGRAM)
  getFollowersGrowthComparison(PLATFORMS.INSTAGRAM)
  getTopHashtags(PLATFORMS.INSTAGRAM)
  getBiographyData(PLATFORMS.INSTAGRAM)
  getTopAndLeastPerformingPosts(PLATFORMS.INSTAGRAM)
}

const getFormattedMediaType = (value) => {
  switch (value ?? mediaType.value) {
    case 'VIDEO REEL':
      return 'Reel'
    case 'CAROUSEL ALBUM':
      return 'Carousel'
    case 'IMAGE':
      return 'Image'
    case 'VIDEO':
      return 'Video'
    default:
      return ''
  }
}

const fetchSpecificPostTypeData = () => {
  getPostingActivityBySpecificType(PLATFORMS.INSTAGRAM, mediaType.value, mediaProductType.value)
  getPostingActivityTableByType(PLATFORMS.INSTAGRAM, mediaType.value, mediaProductType.value)
}

/**
 * @description method called upon clicking a label/bar/scatter point of a graph. Changes the chart and table data of specific type chart and table.
 * @param value
 * @param changeOriginator
 */
const changeChartType = (value, changeOriginator = '') => {
  if(mediaType.value === value) return
  mediaType.value = value
  mediaProductType.value = value === 'VIDEO REEL' ? 'REELS' : 'FEED'
  fetchSpecificPostTypeData()

  if (changeOriginator === 'allTypes') {
    specificPostingContent.value?.scrollIntoView({behavior: 'smooth'})
  }
}

/**
 * @description emits the hashtag whose date is to be fetched
 * @param hashtag
 */
const fetchSingleHashtagData = (hashtag) => {
  getIndividualHashtag(PLATFORMS.INSTAGRAM, hashtag)
}

/**
 * @description depending on the ER, sets the status of competitor tile graph.
 * @param index
 * @returns {string}
 */
const getTileGraphValue = (index) => {
  const engagementRate = dataTableMetricsResponse.value[index].engagementRate
  let status
  if (engagementRate > 0) {
    status = 'up'
  } else if (engagementRate < 0) {
    status = 'down'
  } else {
    status = 'default'
  }
  return status
}
</script>
