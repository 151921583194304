<template>
  <div>
    <b-tabs
      nav-class="analytics-tabs"
      content-class="mt-3 analytics-tabs-content"
    >
      <div
        v-for="(account, key) in all_accounts"
        :key="`${key}_${account.length}`"
      >
        <b-tab :active="key === 'overall'" lazy>
          <template v-slot:title>
            {{ capitalizeFirstLetter(key) }}
            <b-badge variant="light">{{ account.length }}</b-badge>
          </template>
          <div v-if="account.length < 1">
            <div class="analytics-no-data-found">
              <img
                src="../../../assets/imgs/no_data_images/no_post_found.svg"
                alt=""
              />
              <p>No data found.</p>
            </div>
          </div>
          <div
            v-else
            class="row"
            :class="{ 'analytics-performance-report': type === 'report' }"
          >
            <template v-for="(acc, i) in account">
              <template v-if="i < show_more_limit">
                <div
                  :key="`${i}_${acc.account_type}`"
                  :class="account.length > 2 ? 'col-4' : 'col-6'"
                >
                  <AnalyticsPerformanceBox :account="acc" />
                </div>
              </template>
            </template>
            <template v-if="type !== 'report'">
              <div class="col-12 d-flex justify_center mt-3">
                <button
                  v-if="!(active_account_limit >= account.length)"
                  class="btn btn-studio-theme-grey-space btn-size-small"
                  @click.prevent="show_more = !show_more"
                >
                  {{
                    show_more_limit === active_account_limit
                      ? 'Show More'
                      : 'Show Less'
                  }}
                </button>
              </div>
            </template>
          </div>
        </b-tab>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'

import AnalyticsPerformanceBox from '@src/modules/analytics/views/overview/components/AnalyticsPerformanceBox'

export default {
  name: 'AnalyticsAccountPerformance',
  components: {
    AnalyticsPerformanceBox,
  },
  mixins: [analyticsUtilsMixin],
  props: {
    accounts: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'overview',
    },
    accountPerformance: {
      type: Array,
      default: () => [],
    },
    accountSelectFlags: {
      type: Object,
      default: () => {
        return {
          facebook: false,
          instagram: false,
          linkedin: false,
          tiktok: false,
          twitter: false,
          pinterest: false,
          youtube: false
        }
      }
    }
  },
  data() {
    return {
      show_more: false,
      active_account_limit: 6,
      show_more_limit: 6,
      overall: [],
      all_accounts: {
        overall: [],
        facebook: [],
        linkedin: [],
        instagram: [],
        tiktok: [],
        youtube: []
      },
      platformFollowersMap: {
        facebook: 'fan_count',
        instagram: 'followers_count',
        linkedin: 'total_follower_count',
        tiktok: 'total_follower_count'
      },
    }
  },
  computed: {
    ...mapGetters([
      'getFacebookPagesList',
      'getInstagramAccounts',
      'getLinkedinAccounts',
      'getTiktokAccounts',
      'getYoutubeAccounts',
    ]),
  },
  watch: {
    show_more(newVal, oldVal) {
      if (newVal) {
        this.show_more_limit = this.accounts.length
      } else {
        this.show_more_limit = this.active_account_limit
      }
    },
  },
  mounted() {
    if (this.type === 'report') {
      this.active_account_limit = this.accounts.length
    }
    this.show_more_limit = this.active_account_limit
    this.getOverallAccounts()
  },
  methods: {
    getOverallAccounts() {
      const types = ['overall'];
      Object.keys(this.accountSelectFlags).forEach(flag => {
        if (this.accountSelectFlags[flag]) {
          types.push(flag);
        } else {
          delete this.all_accounts[flag];
        }
      });

      for (const type in types) {
        for (const account in this.accounts) {
          if (
            types[type] === 'overall' ||
            this.accounts[account].account_type === types[type]
          ) {
            const key = types[type] === 'tiktok' || types[type] === 'youtube' || types[type] ==='overall' ? 'platform_identifier' : this.accounts[account].account_type + '_id'
            const field =
              this.accounts[account][
                key
              ]
            const accountId = types[type] !== 'youtube' ? this.accounts[account].account_type + '_id' : 'channel_id'
            const response = this.findAccount(
              field,
              accountId
            )
            if(this.accounts[account].account_type === 'instagram'){
                this.accounts[account].comments = response?.post_comments
                ? response.post_comments
                : 0
                this.accounts[account].reposts = response?.post_saves
                ? response.post_saves
                : 0
                this.accounts[account].reactions = response?.post_reactions
                ? response.post_reactions
                : 0
                this.accounts[account].total_engagement = response?.post_engagement
                ? response.post_engagement
                : 0
                this.accounts[account].total_followers = response?.followers_count
                ? response.followers_count
                : 0
            this.all_accounts[types[type]].push(this.accounts[account])
            }
            else if (this.accounts[account].account_type === 'linkedin'){
                this.accounts[account].comments = response?.comments
                ? response.comments
                : 0
                this.accounts[account].reposts = response?.reposts
                ? response.reposts
                : 0
                this.accounts[account].reactions = response?.favorites
                ? response.favorites
                : 0
                this.accounts[account].total_engagement = response?.total_engagement
                ? response.total_engagement
                : 0
                this.accounts[account].total_followers = response?.followers
                ? response.followers
                : 0
            this.all_accounts[types[type]].push(this.accounts[account])
            }
            else if (this.accounts[account].account_type === 'tiktok'){
                this.accounts[account].comments = response?.total_comments
                ? response.total_comments
                : 0
                this.accounts[account].reposts = response?.total_shares
                ? response.total_shares
                : 0
                this.accounts[account].reactions = response?.total_likes
                ? response.total_likes
                : 0
                this.accounts[account].total_engagement = response?.total_engagement
                ? response.total_engagement
                : 0
                this.accounts[account].total_followers = response?.total_follower_count
                ? response.total_follower_count
                : 0
                this.all_accounts[types[type]].push(this.accounts[account])
            }
            else if (this.accounts[account].account_type === 'youtube'){
                this.accounts[account].comments = response?.comment
                ? response.comment
                : 0
                this.accounts[account].reposts = response?.share
                ? response.share
                : 0
                this.accounts[account].reactions = response?.like
                ? response.like
                : 0
                this.accounts[account].total_engagement = response?.engagement
                ? response.engagement
                : 0
                this.accounts[account].total_followers = response?.subscribers
                ? response.subscribers
                : 0
                this.all_accounts[types[type]].push(this.accounts[account])
            }
            else {
                this.accounts[account].comments = response.comments
                ? response.comments
                : 0
                this.accounts[account].reposts = response.repost
                ? response.repost
                : 0
                this.accounts[account].reactions = response.reactions
                ? response.reactions : 0
                this.accounts[account].total_engagement = response.total_engagements
                ? response.total_engagement
                : 0
                this.accounts[account].total_followers = response.fan_count
                ? response.fan_count
                : 0
            this.all_accounts[types[type]].push(this.accounts[account])

            }
          }
        }
      }
    },
    findAccount(id, fieldName) {
      if (fieldName === 'facebook_id')
      {
        id = String(id)
        fieldName = 'page_id'
      }
      else if (fieldName === 'instagram_id'){
        id = String(id)
        fieldName = 'instagram_id'
      }
      else if (fieldName === 'tiktok_id' || fieldName==='channel_id'){
        id = String(id)
      }
      else{
        id = String(id)
        fieldName = 'linkedin_id'
      }
      if (this.accountPerformance) {
        return this.accountPerformance.find(el => el[fieldName] === id) ?
        this.accountPerformance.find(el => el[fieldName] === id) : []
      }
    },
    getWidth(value, total) {
      if (total === 0 || total === 'N/A') {
        return 0
      }
      return (value / total) * 100
    },
    getFollowers(account) {
      const { insights, platform } = account

      if (insights && platform in this.platformFollowersMap) {
        const followersPropName = this.platformFollowersMap[platform]
        return insights[followersPropName]?.value
      }
    },
  },
}
</script>
