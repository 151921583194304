<script setup>
import { ref } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import useYoutubeAnalytics from '@src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'
import MainGraph from '@src/modules/analytics/views/youtube/components/graphs/MainGraph.vue'

// props
const props = defineProps({
  defaultChartType: {
    type: String,
    default: 'line',
    validator: (v) => {
      return ['line', 'bar'].includes(v)
    },
  },
  lineChartOptions: {
    type: Object,
    required: true,
  },
  barChartOptions: {
    type: Object,
    required: true,
  },
  dailyLabel: {
    type: String,
    default: 'Daily',
  },
  cumulativeLabel: {
    type: String,
    default: 'Cumulative',
  },
  isNoData: {
    type: Boolean,
    default: false,
  },
})

const { dataZoomOptions } = useYoutubeAnalytics()

// state
const optionsDropdown = [
  {
    label: props.cumulativeLabel,
    mode: 'cumulative',
    chartType: 'line',
    chartOptions: props.lineChartOptions,
  },
  {
    label: props.dailyLabel,
    mode: 'daily',
    chartType: 'bar',
    chartOptions: props.barChartOptions,
  },
]

const selectedOptionType = ref(
  optionsDropdown.find((i) => i.chartType === props.defaultChartType)
)
</script>

<template>
  <AnalyticsCardWrapper
    :type="`specific-type-${Math.random()}`"
    :enable-modal="true"
  >
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <CstDropdown
            dropdown-placement="bottom"
            container-classes="!max-h-96"
            dropdown-classes="!rounded-md !border-[#70707029]"
            button-classes="flex !px-5 !w-[16rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
          >
            <template v-slot:arrow>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </template>
            <template v-slot:selected>
              <p class="text-[14px] font-weight-500 capitalize">
                {{ selectedOptionType.label }}
              </p>
            </template>
            <template v-slot>
              <template
                v-for="(item, index) in optionsDropdown"
                :key="`activity_type_${index}`"
              >
                <CstDropdownItem @click="selectedOptionType = item">
                  <p class="text-gray-900 text-sm">{{ item.label }}</p>
                </CstDropdownItem>
              </template>
            </template>
          </CstDropdown>
          <h2 class="text-base font-weight-500 select-none">
            <slot name="label" :chart-mode="selectedOptionType.mode" />
          </h2>
        </div>
        <div>
          <slot name="header-center" />
        </div>
        <div>
          <slot name="header-right" />
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <MainGraph
          v-if="!isNoData"
          :key="Math.random()"
          :type="selectedOptionType.chartType"
          :chart-options="
            slotProps.isModal
              ? {
                  ...selectedOptionType.chartOptions,
                  ...dataZoomOptions,
                  grid: { ...selectedOptionType.chartOptions.grid, bottom: 70 },
                }
              : selectedOptionType.chartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
