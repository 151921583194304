<template>
  <div
    class="relative w-full min-w-[30rem] max-w-[30rem] overflow-y-auto flex flex-col p-5"
  >
    <!-- Image Limit Exceeded -->
    <div class="p-14 text-center">
      <h4 class="mb-4 text-2xl"> Image Generation Credits Fully Consumed </h4>
      <p v-if="isActiveUserSuperAdmin">
        Hi <b>{{ store.getters.getProfile.full_name }}</b
        >, the image generation credits allowed for this account have been fully
        consumed. If you think the credits
        {{ formatNumber(getCreditSubscribeLimit) }}
        are not enough for your monthly usage, you can always purchase more by
        clicking on the button below.
      </p>
      <p v-else>
        Hi {{ store.getters.getProfile.full_name }}, the image generation
        credits allowed for this workspace's account have been fully consumed.
        If you think the credits
        {{ getCreditSubscribeLimit }}
        are not enough for your monthly usage, please ask the super admin of the
        workspace
        <b>{{ store.getters.getWorkspaces.activeWorkspace.name }}</b> to
        purchase more uninterrupted usage.
      </p>

      <div
        class="compose_warning_box text-center mt-3 mb-4 flex justify-center items-center p-1"
      >
        <p>
          Note: Credits automatically get fully restored on 1st of every month
          :)
        </p>
      </div>
      <div v-if="isActiveUserSuperAdmin && !store.getters.getPlan?.subscription?.paddle_billing" class="">
        <div
          class="text_center"
          @click="handleModalClose(), $bvModal.show('increase-limits-dialog')"
        >
          <b-button variant="studio-theme-space"
            >Increase Image Generation Limits Now
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// libraries
import { defineComponent, computed, inject } from 'vue'

// constants
import { useStore } from '@state/base'

export default defineComponent({
  name: 'LimitExceeds',
  components: {},
  props: {
    modal_heading: {
      type: String,
      default: '',
    },
    modalClose: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const root = inject('root')
    const store = useStore()

    // computed properties
    /**
     * check if user credits limits exceeds
     * @type {ComputedRef<unknown>}
     */
    const isLimitExceeded = computed(() => {
      if (store.getters.getPlan.subscription.limits?.image_generation_credit)
        return (
          store.getters.getPlan.used_limits.image_generation_credit >=
          store.getters.getPlan.subscription.limits.image_generation_credit
        )
      return true
    })

    /**
     * fetch admin full name
     * @type {ComputedRef<unknown>}
     */
    const getSuperAdminDetails = computed(() => {
      const teamMembers = store.getters.getWorkspaces.activeWorkspace.members
      return teamMembers
        ? teamMembers.find((member) => member.role === 'super_admin').user
        : { full_name: '' }
    })

    /**
     * validate user is super admin or not
     * @type {ComputedRef<unknown>}
     */
    const isActiveUserSuperAdmin = computed(() => {
      const teamMembers = store.getters.getWorkspaces.activeWorkspace.members
      return teamMembers
        ? teamMembers.find(
            (member) =>
              member.role === 'super_admin' &&
              store.getters.getProfile.email === member.user.email
          )
        : false
    })
    /**
     * fetch user credits limit
     * @type {ComputedRef<number|*>}
     */
    const getCreditSubscribeLimit = computed(() => {
      return store.getters.getPlan?.subscription?.limits
        ?.image_generation_credit
    })

    /**
     * fetch credits used by user
     * @type {ComputedRef<number>}
     */
    const getCreditUsedLimit = computed(() => {
      return store.getters.getPlan?.used_limits?.image_generation_credit
    })

    // methods
    /**
     * hide modal
     */
    const handleModalClose = () => {
      root.$bvModal.hide('image_generator_modal')
      props.modalClose()
    }

    const formatNumber = (number) => {
      const formatter = Intl.NumberFormat('en', { notation: 'compact' })
      return formatter.format(number)
    }

    return {
      handleModalClose,
      isLimitExceeded,
      getSuperAdminDetails,
      store,
      isActiveUserSuperAdmin,
      getCreditSubscribeLimit,
      getCreditUsedLimit,
      formatNumber,
    }
  },
})
</script>
