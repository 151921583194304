<template>
  <div class="auth_parent_container">
    <div class="flex mx-auto h-full">
      <!-- Auth form -->
      <SignupSideComponent v-if="!isWhiteLabelDomain" />

      <!-- Auth slides -->
      <div class="w-full flex flex-col h-full bg-white justify-between py-[1rem] 2xl:py-[2rem] 3xl:py-[3rem] px-28 2xl:px-40">
        <p class="flex justify-end text-[#3A4557] 2xl:py-[2rem] py-[1rem] text-right">Already have an account?<div @click.prevent="logoutData" class="cursor-pointer text-[#157FFF] hover:text-[#157FFF] hover:underline ml-2">Sign in</div></p>
        <div class="flex flex-col items-center justify-center flex-grow">
          <form class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <div v-if="isWhiteLabelDomain" class="px-[5rem] py-[1rem] 2xl:py-[2rem]">
                <LogoComponent />
              </div>
              <h2 class="text-left text-[#2D2D2D] font-bold text-md 2xl:text-3xl">Join ContentStudio</h2>
              <p class="text-sm text-center text-[#757A8A] mt-2">Please enter your basic details.</p>
            </div>

            <div class="flex flex-col gap-2 pt-12">

              <CstFloatingLabelInput
                  id="memberfirstName"
                  v-model="firstName"
                  class="!h-[3.3rem] mb-2.5"
                  type="text"
                  label="First Name"
                  :maxlength="18"
                  show-icon-left
              >
                <template v-slot:icon>
                  <i class="icon-User"></i>
                </template>
              </CstFloatingLabelInput>

              <CstFloatingLabelInput
                  id="memberlastName"
                  v-model="lastName"
                  class="!h-[3.3rem] mb-2.5"
                  type="text"
                  label="Last Name"
                  :maxlength="18"
                  show-icon-left
              >
                <template v-slot:icon>
                  <i class="icon-User"></i>
                </template>
              </CstFloatingLabelInput>

              <!-- Email -->
              <CstFloatingLabelInput
                  id="email"
                  v-model="getEmail"
                  type="email"
                  label="Email Address"
                  show-icon-left
                  class="!h-[3.3rem] mb-2.5"
                  :disabled="true">
                <template v-slot:icon>
                  <i class="icon-Email"></i>
                </template>
              </CstFloatingLabelInput>

              <!-- Password -->
              <div>
                <CstFloatingLabelInput
                    id="password"
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    :maxlength="18"
                    show-icon-left
                    show-icon-right
                    class="!h-[3.3rem] mb-2.5"
                    value="account.password">
                  <template v-slot:icon>
                    <i class="icon-Password"></i>
                  </template>
                  <template v-slot:icon-right>
                    <i
                        class="cursor-pointer"
                        :class="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                        @click="togglePassword('password')"
                    ></i>
                  </template>
                </CstFloatingLabelInput>

                <div v-if="password_strength_message" class="simple-form__text-field-message flex items-center justify-between mx-1 relative top-[1.11rem]" :class="{ danger: password_state === 'danger' }">
                  <p
                      class="text-xs"
                  >
                    {{ password_strength_message }}
                  </p>
                  <div class="flex gap-1">
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-red-500': password_state === 'weak', ' !bg-green-500': password_state === 'strong', '!bg-yellow-500': password_state === 'fair' }"></div>
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong', 'bg-yellow-500': password_state === 'fair' }"></div>
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong' }"></div>
                  </div>
                </div>
              </div>

              <CstFloatingLabelInput
                  id="cpassword"
                  v-model="cpassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  label="Confirm Password"
                  :maxlength="18"
                  show-icon-left
                  show-icon-right
                  class="!h-[3.3rem]"
                  value="account.password">
                <template v-slot:icon>
                  <i class="icon-Password"></i>
                </template>
                <template v-slot:icon-right>
                  <i
                      class="cursor-pointer"
                      :class="showConfirmPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                      @click="togglePassword('confirmPassword')"
                  ></i>
                </template>
              </CstFloatingLabelInput>

            </div>

            <div class="auth-form__button-field mt-7">
              <button
                  :disabled="loader || disable"
                  data-cy="sign_up_button"
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
                  @click.prevent="setPassword"
              >
                <span class="w-full">Sign in <img
                    v-if="loader"
                    style="width: 20px; margin-left: 8px"
                    src="../../assets/img/common/gif_loader_white.gif"
                    alt=""
                /></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setPasswordURL } from '@src/config/api-utils'
import CstInputFields from '@ui/Input/CstInputFields'
import { mapGetters } from 'vuex'
import vueRecaptcha from "vue3-recaptcha2";
import CstFloatingLabelInput from "@ui/Input/CstFloatingLabelInput.vue";
import LogoComponent from "@modules/account/components/LogoComponent.vue";
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
const SignupSideComponent = () => import("@src/components/authentication/SignupSideComponent.vue")

export default {
  components: {LogoComponent, vueRecaptcha, CstInputFields, SignupSideComponent, CstFloatingLabelInput },
  data() {
    return {
      firstName: '',
      lastName: '',
      password: '',
      cpassword: '',
      loader: false,
      disable: false,
      showPassword: false,
      showConfirmPassword: false,
      password_strength_message: '',
      password_state: '',
    }
  },
  setup(){
    const {isWhiteLabelDomain} = useWhiteLabelApplication()

    return {
      isWhiteLabelDomain
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getActiveWorkspace']),
    getEmail() {
      return this.getProfile?.email
    },
  },
  created() {
    console.log('getActiveWorkspace', this.getActiveWorkspace)
  },
  methods: {
    logoutData() {
      this.logout()
    },
    /**
     * this method is responsible for show or hide password
     * @param field
     */
    togglePassword(field) {
      if (field === 'password') {
        this.showPassword = !this.showPassword;
      } else if (field === 'confirmPassword') {
        this.showConfirmPassword = !this.showConfirmPassword;
      }
    },
    async setPassword() {
      if (this.firstName === '') {
        return this.alertMessage('Please enter first name', 'error')
      }
      if (this.password === '') {
        return this.alertMessage('Please enter password.', 'error')
      }
      if (this.cpassword === '') {
        return this.alertMessage('Please confirm your password.', 'error')
      }
      if (this.password !== this.cpassword) {
        return this.alertMessage(
          'password and confirm password is not matched.',
          'error'
        )
      }
      this.loader = true

      this.postRequest(
        setPasswordURL,
        {
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password,
        },
        (response) => {
          this.loader = false
          if (response.data.status === false)
            return this.alertMessage(response.data.message, 'error')
          this.getProfile.need_to_reset_password = false
          this.fetchWorkspaces(true)
          this.disable = true
        },
        (error) => {
          console.debug('Exception in setPassword', error)
          this.loader = false
          this.alertMessage(
            'Uh-oh! An unknown error occurred, support has been notified.',
            'error'
          )
        }
      )
    },
  },
  watch: {
    'password'(value) {
      if (value) {
        if (value.length === 0) {
          this.password_strength_message = ''
          this.password_state = ''
        }
        if (value.length < 12) {
          this.password_strength_message = 'Password must be 12 characters or more';
          this.password_state = 'danger';
        } else if (value.length < 14) {
          this.password_strength_message = 'Password could be more secure';
          this.password_state = 'weak';
        } else if (value.length < 16) {
          this.password_strength_message = 'Password is satisfactory';
          this.password_state = 'fair';
        } else {
          this.password_strength_message = 'Password is strong';
          this.password_state = 'strong';
        }
      } else {
        this.password_strength_message = ''
        this.password_state = ''
      }
    },
  },
}
</script>
