<script setup>
import { computed, watch } from 'vue'
import DualChartComponent from '@src/modules/analytics/views/pinterest/components/graphs/DualChartComponent.vue'
import usePinterestAnalytics from '@/src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'

const props = defineProps({
  defaultType: {
    type: String,
    default: 'line',
    validator: (v) => {
      return ['line', 'bar'].includes(v)
    },
  },
  label: {
    type: String,
    default: 'Followers',
  },
})

const {
  barChartOptions,
  lineChartOptions,
  followersData,
  isReportView,
  DUAL_GRAPHS,
  getDualGraphTitle,
  getDualGraphTooltip,
  isNoAnalyticsData,
  axisLabelFormatter,
  analyticsDesignSystem,
} = usePinterestAnalytics(props.label)

const isNoData = computed(() => {
  return isNoAnalyticsData(followersData)
})

watch(
  () => followersData,
  async () => {
    const options = followersData.value

    // line chart options
    lineChartOptions.value.series[0].data = options?.followers_gained
    lineChartOptions.value.xAxis.data = options?.buckets

    // bar chart options
    barChartOptions.value.series[0].itemStyle = {}
    barChartOptions.value.series[0].data = options?.followers_daily
    barChartOptions.value.xAxis.data = lineChartOptions.value.xAxis.data

    barChartOptions.value.yAxis = {
      ...barChartOptions.value.yAxis,
      nameGap: 50,
      axisLabel: {
        ...barChartOptions.value.yAxis.axisLabel,
        formatter: axisLabelFormatter,
        rich: analyticsDesignSystem?.graphs?.richStylesBySentiment,
      },
    }
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <DualChartComponent
    :default-chart-type="defaultType"
    :line-chart-options="lineChartOptions"
    :bar-chart-options="barChartOptions"
    :daily-label="getDualGraphTitle(DUAL_GRAPHS.FOLLOWER, 'daily')"
    :cumulative-label="getDualGraphTitle(DUAL_GRAPHS.FOLLOWER, 'cumulative')"
    :is-no-data="isNoData"
  >
    <template v-slot:label="{ chartMode }">
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  {{ getDualGraphTooltip(DUAL_GRAPHS.FOLLOWER, chartMode) }}
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
  </DualChartComponent>
</template>
