import proxy from '@common/lib/http-common'
import {
  FETCH_QUEUE_SLOTS_ERROR,
  UNKNOWN_ERROR,
  UPDATE_QUEUE_SLOTS_ERROR,
  UPDATE_QUEUE_SLOTS_SUCCESS,
} from '@common/constants/messages'
import { getPlatformName } from '@common/lib/integrations'
import {
  facebook,
  pinterest,
  social,
  twitter,
  threads,
  linkedin,
  tumblr,
  instagram,
  gmb,
  youtube,
  tiktok,
} from '@src/modules/integration/store/states/mutation-types'
import {
  fetchAccountQueueSlotsURL,
  fetchSocialAccountsURL,
  updateAccountQueueSlotsURL,
} from '@src/modules/integration/config/api-utils'
import { setting } from '@src/modules/setting/store/states/mutation-types'
import { EventBus } from '@common/lib/event-bus'

const defaultAuthorizationState = {
  facebook: null,
  twitter: null,
  pinterest: null,
  linkedin: null,
  tumblr: null,
  instagram: null,
  instagram_personal: null,
  gmb: null,
  youtube: null,
  tiktok: null,
  tumblr_details: []
}
const defaultQueueTimesState = {
  selected_item: {
    type: '',
    platform: '',
    name: '',
    user_id: '',
    workspace_id: '',
  },
  plans_count: 0,
  type: '',
  custom_queue: [
    {
      name: 'Sun',
      status: true,
      times: ['18:00'],
    },
    {
      name: 'Mon',
      status: true,
      times: ['09:50', '16:15'],
    },
    {
      name: 'Tue',
      status: true,
      times: ['16:05', '18:40'],
    },
    {
      name: 'Wed',
      status: true,
      times: ['13:50', '15:40', '19:20'],
    },
    {
      name: 'Thu',
      status: true,
      times: ['14:20', '16:10', '18:40'],
    },
    {
      name: 'Fri',
      status: true,
      times: ['09:40', '16:05'],
    },
    {
      name: 'Sat',
      status: true,
      times: ['16:10'],
    },
  ],
  prime_status: 'none',
  prime_queue: [],
}
const defaultLoadersState = {
  fetch_accounts: false,
  fetch_slots_loader: false,
}

export default {
  state: {
    authorization: JSON.parse(JSON.stringify(defaultAuthorizationState)),
    fetch_accounts_status: false,
    account_queue_times: JSON.parse(JSON.stringify(defaultQueueTimesState)),
    loaders: JSON.parse(JSON.stringify(defaultLoadersState)),
  },
  getters: {
    getSocialIntegrationsNames: (state) => [
      'facebook',
      'twitter',
      'linkedin',
      'pinterest',
      'tumblr',
      'instagram',
      'gmb',
      'youtube',
      'tiktok',
      'threads'
    ],
    getSocialAuthorization: (state) => state.authorization,
    getFetchAccountsStatus: (state) => state.fetch_accounts_status,
    getSocialLoader: (state) => state.loaders,
    getAccountQueueTimes: (state) => state.account_queue_times,
  },
  actions: {
    async fetchWorkspaceSocialAccounts(
      { commit, getters, rootGetters, dispatch },
      payload
    ) {
      return proxy.post(fetchSocialAccountsURL, payload).then((response) => {
        if (response.data.status === true) {
          return response.data
        }
      })
    },
    async fetchSocialAccounts({ commit, getters, rootGetters, dispatch }, force = false) {
      console.debug('Action::fetchSocialAccounts')
      if (
          ( getters.getFetchAccountsStatus === false &&
            getters.getWorkspaces.activeWorkspace !== false) || force
      ) {
        commit(social.SET_ACCOUNTS_STATUS, true)
        commit(social.SET_ACCOUNTS_LOADER, true)
        // fetching social accounts list
        dispatch('fetchPlatformsList')
        return proxy
          .post(fetchSocialAccountsURL, {
            workspace_id: getters.getWorkspaces.activeWorkspace._id,
          })
          .then((response) => {
            if (response.data.status === true) {
              console.debug('Action::fetchSocialAccounts', response.data)
              dispatch('setPlatforms', {
                items: response.data.facebook,
                type: 'facebook',
                all_item_setter: facebook.SET_ALL_ACCOUNTS,
                item_setter: facebook.SET_ACCOUNTS,
              })
              dispatch('setPlatforms', {
                items: response.data.twitter,
                type: 'twitter',
                all_item_setter: twitter.SET_ALL_ACCOUNTS,
                item_setter: twitter.SET_ACCOUNTS,
              })
              dispatch('setPlatforms', {
                items: response.data.threads,
                type: 'threads',
                all_item_setter: threads.SET_ALL_ACCOUNTS,
                item_setter: threads.SET_ACCOUNTS,
              })
              dispatch('setPlatforms', {
                items: response.data.linkedin,
                type: 'linkedin',
                all_item_setter: linkedin.SET_ALL_ACCOUNTS,
                item_setter: linkedin.SET_ACCOUNTS,
              })
              dispatch('setPlatforms', {
                items: response.data.pinterest,
                type: 'pinterest',
                all_item_setter: pinterest.SET_ALL_ACCOUNTS,
                item_setter: pinterest.SET_ACCOUNTS,
              })
              dispatch('setPlatforms', {
                items: response.data.tumblr,
                type: 'tumblr_profiles',
                all_item_setter: tumblr.SET_ALL_ACCOUNTS,
                item_setter: tumblr.SET_ACCOUNTS,
              })
              commit(
                setting.SET_MOBILE_DEVICES,
                response.data.instagram.devices
              )
              dispatch('setPlatforms', {
                items: response.data.instagram.accounts,
                type: 'instagram',
                all_item_setter: instagram.SET_ALL_ACCOUNTS,
                item_setter: instagram.SET_ACCOUNTS,
              })
              dispatch('setPlatforms', {
                items: response.data.gmb,
                type: 'gmb',
                all_item_setter: gmb.SET_ALL_ACCOUNTS,
                item_setter: gmb.SET_ACCOUNTS,
              })
              dispatch('setPlatforms', {
                items: response.data.youtube,
                type: 'youtube',
                all_item_setter: youtube.SET_ALL_ACCOUNTS,
                item_setter: youtube.SET_ACCOUNTS,
              })
              dispatch('setPlatforms', {
                items: response.data.tiktok,
                type: 'tiktok',
                all_item_setter: tiktok.SET_ALL_ACCOUNTS,
                item_setter: tiktok.SET_ACCOUNTS,
              })
              dispatch('setAllPlatforms', {
                items: response.data
              })
            }
            commit(social.SET_ACCOUNTS_LOADER, false)
            EventBus.$emit('fetched-social-accounts')
            return true
          })
          .catch((error) => {
            console.debug('Exception in fetchSocialAccounts', error)
            dispatch('toastNotification', {
              message: UNKNOWN_ERROR,
              type: 'error',
            })
            commit(social.SET_ACCOUNTS_LOADER, false)
            return true
          })
      }
      return true
    },

    async fetchAccountQueueSlots(
      { commit, getters, rootGetters, dispatch },
      platform
    ) {
      console.debug('Method:fetchAccountQueueSlots', platform)

      const selectedItem = {
        type: platform.platform_type,
        user_id: platform.user_id,
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
      }
      selectedItem.name = getPlatformName(platform)

      let key = ''
      switch (platform.platform_type) {
        case 'pinterest':
          key = 'board_id'
          break
        case 'tumblr':
        case 'tiktok':
        case 'youtube':
        case 'threads':
          key = 'platform_identifier'
          break
        case 'gmb':
          key = 'name'
          break
        default:
          key = platform.platform_type.toLowerCase() + '_id'
      }
      selectedItem.platform = platform[key]
      commit(social.SET_QUEUE_SELECTED_ITEM, selectedItem)

      commit(social.SET_FETCH_QUEUE_SLOTS_LOADER, true)
      return proxy
        .post(fetchAccountQueueSlotsURL, selectedItem)
        .then((response) => {
          commit(social.SET_FETCH_QUEUE_SLOTS_LOADER, false)
          if (
            response.data.queueSlots ||
            (platform.platform_type !== 'facebook' &&
              response.data.queueSlots.length)
          ) {
            if (platform.platform_type === 'facebook') {
              // in case of prime & custom slots
              commit(
                social.SET_QUEUE_CUSTOM_SLOTS,
                response.data.queueSlots.customQueue
              )

              // setting type either custom or prime
              const queueType = response.data.queueSlots.queueType
                ? response.data.queueSlots.queueType
                : 'custom'
              commit(social.SET_QUEUE_TYPE, queueType)

              // setting prime slots status in states for using the check of having enough data or not
              const primeSlotsStatus =
                response.data.queueSlots.primeSlotsStatus === true ||
                response.data.queueSlots.primeSlotsStatus === false
                  ? response.data.queueSlots.primeSlotsStatus
                  : 'none'
              commit(social.SET_QUEUE_PRIME_STATUS, primeSlotsStatus)

              // setting prime slots in states
              if (response.data.queueSlots.prime_queue) {
                commit(
                  social.SET_QUEUE_PRIME_SLOTS,
                  response.data.queueSlots.primeQueue
                )
              }
            }
            else if(platform.platform_type === 'threads'){
              commit(social.SET_QUEUE_TYPE, 'custom')
              commit(social.SET_QUEUE_CUSTOM_SLOTS, response.data?.queueSlots?.QueueSlots || [])
            }
            else {
              commit(social.SET_QUEUE_TYPE, 'custom')
              commit(social.SET_QUEUE_CUSTOM_SLOTS, response.data.queueSlots)
            }
            commit(social.SET_QUEUE_PLANS_COUNT, response.data.queued_plans)
            return true
          }

          dispatch('toastNotification', {
            message: response.data.message
              ? response.data.message
              : FETCH_QUEUE_SLOTS_ERROR,
            type: 'error',
          })
          return false
        })
        .catch((error) => {
          console.debug('Exception in fetchAccountQueueSlots', error)
          commit(social.SET_FETCH_QUEUE_SLOTS_LOADER, false)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          return false
        })
    },

    async updateAccountQueueSlots(
      { commit, getters, rootGetters, dispatch },
      data
    ) {
      console.debug('Method:updateAccountQueueSlots')

      return proxy
        .post(updateAccountQueueSlotsURL, data)
        .then((response) => {
          if (response.data.status === true) {
            dispatch('toastNotification', {
              message: UPDATE_QUEUE_SLOTS_SUCCESS,
              type: 'success',
            })
            let key = ''
            switch (data.type) {
              case 'pinterest':
                key = 'board_id'
                break
              case 'tumblr':
              case 'gmb':
                key = 'name'
                break
              default:
                key = data.type.toLowerCase() + '_id'
            }
            const getter =
              'get' +
              data.type.charAt(0).toUpperCase() +
              data.type.slice(1) +
              'Accounts'
            getters[getter].items.forEach(function (account, index) {
              if (account[key] === data.platform) {
                getters[getter].items[index].QueueSlots = data.slots
              }
            })
            return true
          }
          dispatch('toastNotification', {
            message: UPDATE_QUEUE_SLOTS_ERROR,
            type: 'error',
          })
          return false
        })
        .catch((error) => {
          console.debug('Exception in updateAccountQueueSlots', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          return false
        })
    },

    setDefaultSocialStates({ commit, getters, rootGetters, dispatch }) {
      commit(social.SET_DEFAULT_STATES)
      commit(facebook.SET_DEFAULT)
      commit(twitter.SET_DEFAULT)
      commit(linkedin.SET_DEFAULT)
      commit(pinterest.SET_DEFAULT)
      commit(tumblr.SET_DEFAULT)
      commit(instagram.SET_DEFAULT)
      commit(gmb.SET_DEFAULT)
    },
  },
  mutations: {
    [social.SET_AUTHORIZATION_LINKS](state, authorization) {
      state.authorization.facebook = authorization.facebook
      state.authorization.twitter = authorization.twitter
      state.authorization.pinterest = authorization.pinterest
      state.authorization.linkedin = authorization.linkedin
      state.authorization.instagram = authorization.instagram
      state.authorization.instagram_personal = authorization.instagram_personal
      state.authorization.gmb = authorization.gmb
      state.authorization.youtube = authorization.youtube
      state.authorization.tiktok = authorization.tiktok
      state.authorization.threads = authorization.threads
      if (authorization.tumblr && authorization.tumblr.link) {
        state.authorization.tumblr = authorization.tumblr.link
        state.authorization.tumblr_details = authorization.tumblr
      } else {
        state.authorization.tumblr = ''
      }
    },

    [social.SET_ACCOUNTS_STATUS](state, status) {
      state.fetch_accounts_status = status
    },

    [social.SET_ACCOUNTS_LOADER](state, status) {
      state.loaders.fetch_accounts = status
    },

    [social.SET_QUEUE_SELECTED_ITEM](state, item) {
      state.account_queue_times.selected_item = item
    },

    [social.SET_FETCH_QUEUE_SLOTS_LOADER](state, status) {
      state.loaders.fetch_slots_loader = status
    },

    [social.SET_QUEUE_PLANS_COUNT](state, count) {
      state.account_queue_times.plans_count = count
    },

    [social.SET_QUEUE_TYPE](state, type) {
      state.account_queue_times.type = type
    },

    [social.SET_QUEUE_CUSTOM_SLOTS](state, customQueue) {
      state.account_queue_times.custom_queue = customQueue
    },

    [social.SET_QUEUE_PRIME_STATUS](state, status) {
      state.account_queue_times.prime_status = status
    },

    [social.SET_QUEUE_PRIME_SLOTS](state, primeQueue) {
      state.account_queue_times.prime_queue = primeQueue
    },

    [social.SET_DEFAULT_STATES](state) {
      state.fetch_accounts_status = false
      state.account_queue_times = JSON.parse(
        JSON.stringify(defaultQueueTimesState)
      )
      state.loaders = JSON.parse(JSON.stringify(defaultLoadersState))
    },
  },
}
