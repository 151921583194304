import { inject, nextTick, onMounted, ref } from 'vue'
import { getPaddleInstance, initializePaddle } from '@paddle/paddle-js'
import { useStore } from '@state/base'
import { userMavenMixin } from '@src/tracking/userMavenMixin'

const checkoutData = ref(null)
const isCheckoutLoading = ref(true)
const checkoutError = ref(null)

export function usePaddle() {
  const root = inject('root')
  const { $bvModal } = root
  const { getters } = useStore()
  const classicInitialized = ref(false)
  const v1Initialized = ref(false)

  const priceIds = {
    'qa-features': {
      standard: {
        monthly: 'pri_01jasghfpwf6t6qsrvsgr8v96c',
        annually: 'pri_01jasgk83hfpbqqp5ay34hmym4',
      },
      advanced: {
        monthly: 'pri_01jasgrxa26wk5xcnxt440gvt7',
        annually: 'pri_01jasgt4dqqvs5n70jkr8nc1cj',
      },
      'agency-unlimited': {
        monthly: 'pri_01jasgq8a85a7jwwbg6fx0tbqt',
        annually: 'pri_01jasgr75gyvqnp52jfqkczqyd',
      },
      socialAccounts: {
        monthly: {
          tier1: 'pri_01jc5sk5knzz4z7njjrsmcdzkr', // $1
          tier2: 'pri_01jc5sjjx59dng9xxmhd5e1b6q', // $2
          tier3: 'pri_01jc5shkyenkj3jrama1qw6kwg', // $3
          tier4: 'pri_01jc5sgzwve893d7a1e22tyv8b', // $4
          tier5: 'pri_01jbbyyqjy1skwpy1zcm63pvtg', // $5
        },
        annually: {
          tier1: 'pri_01jc5sqdx56f4j6aq72cxx1zdd', // $1
          tier2: 'pri_01jc5spptneteavsgasf0wmmeq', // $2
          tier3: 'pri_01jc5snw6fvqh40q1mn5qq6zrt', // $3
          tier4: 'pri_01jc5smqm9evkzptf95cm957d4', // $4
          tier5: 'pri_01jbef1fne251wfrh5d2km3zbd', // $5
        },
      },
    },
    develop: {
      standard: {
        monthly: 'pri_01jcq85ade257knb2jw6y89jqe',
        annually: 'pri_01jcq87638xp3eec51egftz2mz',
      },
      advanced: {
        monthly: 'pri_01jcq89smrkw1sb91wrfbpgda2',
        annually: 'pri_01jcq8ba85qf4z22qtm280she2',
      },
      'agency-unlimited': {
        monthly: 'pri_01jcq8dx247wkqm017t2825bqq',
        annually: 'pri_01jcq8f5a89m3ffyw16px6bfa6',
      },
      socialAccounts: {
        monthly: {
          tier1: 'pri_01jcq93q42vzhr1brc2varcy9z', // $1
          tier2: 'pri_01jcq92gn53p1kfjj8gcn8cq6x', // $2
          tier3: 'pri_01jcq923bjts1z5k2srsc051ts', // $3
          tier4: 'pri_01jcq91ka9fx1yz5c1yh10pn8r', // $4
          tier5: 'pri_01jcq907a8mvms6ezv7dg7584j', // $5
        },
        annually: {
          tier1: 'pri_01jcq9f8es73yxxd0sk959hry5', // $1
          tier2: 'pri_01jcq9ere975f5zxj4td0v83fw', // $2
          tier3: 'pri_01jcq9dzwkbehr4bvxxj54bzv0', // $3
          tier4: 'pri_01jcq9dcewver2kemq5hd911k0', // $4
          tier5: 'pri_01jcq9cn2rwfhpv3p8wn0am4m8', // $5
        },
      },
    },
    staging: {
      standard: {
        monthly: 'pri_01jcq85ade257knb2jw6y89jqe',
        annually: 'pri_01jcq87638xp3eec51egftz2mz',
      },
      advanced: {
        monthly: 'pri_01jcq89smrkw1sb91wrfbpgda2',
        annually: 'pri_01jcq8ba85qf4z22qtm280she2',
      },
      'agency-unlimited': {
        monthly: 'pri_01jcq8dx247wkqm017t2825bqq',
        annually: 'pri_01jcq8f5a89m3ffyw16px6bfa6',
      },
      socialAccounts: {
        monthly: {
          tier1: 'pri_01jcq93q42vzhr1brc2varcy9z', // $1
          tier2: 'pri_01jcq92gn53p1kfjj8gcn8cq6x', // $2
          tier3: 'pri_01jcq923bjts1z5k2srsc051ts', // $3
          tier4: 'pri_01jcq91ka9fx1yz5c1yh10pn8r', // $4
          tier5: 'pri_01jcq907a8mvms6ezv7dg7584j', // $5
        },
        annually: {
          tier1: 'pri_01jcq9f8es73yxxd0sk959hry5', // $1
          tier2: 'pri_01jcq9ere975f5zxj4td0v83fw', // $2
          tier3: 'pri_01jcq9dzwkbehr4bvxxj54bzv0', // $3
          tier4: 'pri_01jcq9dcewver2kemq5hd911k0', // $4
          tier5: 'pri_01jcq9cn2rwfhpv3p8wn0am4m8', // $5
        },
      },
    },
    uat: {
      standard: {
        monthly: 'pri_01jcz75fa5t730rnje8apb13f7',
        annually: 'pri_01jcz7614thanvnjke0b7ad461',
      },
      advanced: {
        monthly: 'pri_01jcz7c0fm1eka9gmn3pp607m2',
        annually: 'pri_01jcz7csbhebm8ewhaaby969he',
      },
      'agency-unlimited': {
        monthly: 'pri_01jcz7egvpr9gjn6016nad3zz1',
        annually: 'pri_01jcz7g2jj4t75663rf0g58ka8',
      },
      socialAccounts: {
        monthly: {
          tier1: 'pri_01jcz7nxjw6sf6sw8knmm4c2p6', // $1
          tier2: 'pri_01jcz7p7nc6k606spgw0wfthfn', // $2
          tier3: 'pri_01jcz7pgkbsnhpf0xf1kjgm0s4', // $3
          tier4: 'pri_01jcz7pqdr0d1977np626adjcz', // $4
          tier5: 'pri_01jcz7q08yw0dm4gn729rj8h57', // $5
        },
        annually: {
          tier1: 'pri_01jcz7k42h3t8p60vsb3n60p13', // $1
          tier2: 'pri_01jcz7kkpbcc2a5kggyr4fgrxr', // $2
          tier3: 'pri_01jcz7kygjh6f1ada4n2x7pkk8', // $3
          tier4: 'pri_01jcz7msg0167wh28z22kyshyn', // $4
          tier5: 'pri_01jcz7n7jehfgyr2z9pgt9kzeg', // $5
        },
      },
    },
    production: {
      standard: {
        monthly: 'pri_01jcz75fa5t730rnje8apb13f7',
        annually: 'pri_01jcz7614thanvnjke0b7ad461',
      },
      advanced: {
        monthly: 'pri_01jcz7c0fm1eka9gmn3pp607m2',
        annually: 'pri_01jcz7csbhebm8ewhaaby969he',
      },
      'agency-unlimited': {
        monthly: 'pri_01jcz7egvpr9gjn6016nad3zz1',
        annually: 'pri_01jcz7g2jj4t75663rf0g58ka8',
      },
      socialAccounts: {
        monthly: {
          tier1: 'pri_01jcz7nxjw6sf6sw8knmm4c2p6', // $1
          tier2: 'pri_01jcz7p7nc6k606spgw0wfthfn', // $2
          tier3: 'pri_01jcz7pgkbsnhpf0xf1kjgm0s4', // $3
          tier4: 'pri_01jcz7pqdr0d1977np626adjcz', // $4
          tier5: 'pri_01jcz7q08yw0dm4gn729rj8h57', // $5
        },
        annually: {
          tier1: 'pri_01jcz7k42h3t8p60vsb3n60p13', // $1
          tier2: 'pri_01jcz7kkpbcc2a5kggyr4fgrxr', // $2
          tier3: 'pri_01jcz7kygjh6f1ada4n2x7pkk8', // $3
          tier4: 'pri_01jcz7msg0167wh28z22kyshyn', // $4
          tier5: 'pri_01jcz7n7jehfgyr2z9pgt9kzeg', // $5
        },
      },
    },
  }

  // Initialize Paddle instance
  onMounted(async () => {
    try {
      console.log('Initializing both Paddle versions...')

      // Initialize both versions simultaneously
      await Promise.all([
        // Classic initialization
        initializePaddle({
          version: 'classic',
          environment:
            process.env.VUE_APP_ENVIRONMENT === 'production' ||
            process.env.VUE_APP_ENVIRONMENT === 'uat'
              ? 'production'
              : 'sandbox',
          vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID),
          eventCallback: (event) => console.log('Classic event:', event),
          enableConcurrentVersions: true,
          debug: true,
          completeDetails: true,
        }).then(() => {
          console.log('Classic initialized successfully')
          classicInitialized.value = true
        }),

        // V1 initialization
        initializePaddle({
          version: 'v1',
          environment:
            process.env.VUE_APP_ENVIRONMENT === 'production' ||
            process.env.VUE_APP_ENVIRONMENT === 'uat'
              ? 'production'
              : 'sandbox',
          token: process.env.VUE_APP_PADDLE_BILLING_CLIENT_ID,
          eventCallback: (event) => handleCheckoutEvent(event),
          enableConcurrentVersions: true,
          debug: true,
        }).then(() => {
          console.log('V1 initialized successfully')
          v1Initialized.value = true
        }),
      ])

      console.log('Both Paddle versions initialized')
    } catch (error) {
      console.error('Failed to initialize Paddle:', error)
    }
  })

  // Function to open the Paddle checkout
  const openCheckout = async (payload, forceVersion = null) => {
    const currentPlan = getters.getPlan?.subscription?.slug
    // Use forced version or determine based on plan
    const paddleVersion =
      forceVersion ||
      (getters.getPlan?.subscription?.paddle_billing ? 'v1' : 'classic')

    console.log('Opening checkout with:', {
      paddleVersion,
      currentPlan,
      isPaddleV2Plan: getters.getPlan?.subscription?.paddle_billing,
      payload,
    })

    // Check if the required version is initialized
    if (paddleVersion === 'classic' && !classicInitialized.value) {
      throw new Error('Paddle Classic is not initialized yet')
    }
    if (paddleVersion === 'v1' && !v1Initialized.value) {
      throw new Error('Paddle V1 is not initialized yet')
    }

    try {
      const paddleInstance = getPaddleInstance(paddleVersion)

      if (!paddleInstance) {
        console.error(`Failed to get ${paddleVersion} instance`)
      }

      $bvModal.show('paddle-checkout-modal')
      await nextTick()

      payload.settings = {
        displayMode: 'inline',
        frameTarget: 'checkout-container',
        frameInitialHeight: '450',
        frameStyle:
          'width: 100%; min-width: 312px; background-color: transparent; border: none;',
      }

      await paddleInstance.Checkout.open(payload)
    } catch (error) {
      console.error(`Failed to open ${paddleVersion} checkout:`, error)
      throw error
    }
  }

  const handleCheckoutEvent = (event) => {
    if (!event.name) {
      return
    }

    const { name, data } = event

    // Update checkout data for all events that contain data
    if (data) {
      checkoutData.value = data
    }

    switch (name) {
      case 'checkout.error':
        checkoutError.value = data.error?.message || 'Checkout error occurred'
        isCheckoutLoading.value = false
        break

      case 'checkout.completed':
        // eslint-disable-next-line
        gtag('event', 'paid_account_upgrade', {
          send_to: process.env.VUE_APP_GTAG_SEND_TO,
          email: getters?.getProfile?.email,
          mrr: data?.totals?.total || 0,
          currency: data?.currency_code,
          transaction_id: data?.transaction_id || '',
          plan: 'plan',
        })
        // Track Facebook Pixel signup event
        if (window.fbq) {
          window.fbq('track', 'paid_account_upgrade', {
            value: parseInt(data?.totals?.total || 0),
            currency: data?.currency_code,
            email: getters?.getProfile?.email,
            transaction_id: data?.transaction_id || '',
            plan: 'plan',
          });
        }

        userMavenMixin?.methods?.trackUserMaven('plan_upgraded', {
          plan_name: data?.items[0]?.product?.name || 'Plan Name',
          plan_price: data?.totals?.total || 0,
          plan_billing_period:
            data?.items[0]?.billing_cycle.interval === 'year'
              ? 'yearly'
              : 'monthly',
          paddle_customer: data?.customer,
        })

        setTimeout(() => {
          window.location.reload()
        }, 3000)
        break

      case 'checkout.loaded':
        isCheckoutLoading.value = false
        break
      default:
        break
    }

    // Log the event for debugging
    console.log(`Paddle event: ${name}`, data)
  }

  // Reset checkout state
  const resetCheckoutState = () => {
    checkoutData.value = null
    isCheckoutLoading.value = true
    checkoutError.value = null
  }

  const formatPrice = (amount, currency = 'USD') => {
    // Amount comes in cents, so divide by 100
    const numberAmount = amount / 100

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberAmount)
  }

  return {
    priceIds,
    checkoutData,
    isCheckoutLoading,
    checkoutError,
    resetCheckoutState,
    openCheckout,
    formatPrice,
  }
}
