<template>
  <div ref="inbox-main" class="main_wrapper_sidebar inbox_main_view">
    <InboxLinkSidebar
      @conversation-type-changed="conversationTypeChanged"
    ></InboxLinkSidebar>
    <InboxBody v-if="getInboxFilter !== null" />
    <div v-else class="w-full h-full flex flex-col justify-center items-center">
      <clip-loader
        class="spinner ml-2"
        :color="'#5773fa'"
        :size="'3rem'"
      ></clip-loader>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InboxLinkSidebar from './InboxLinkSidebar'
import InboxBody from './InboxBody'
import {useFeatures} from "@modules/billing/composables/useFeatures";

export default {
  components: {
    InboxLinkSidebar,
    InboxBody,
  },
 setup(){
  const { canAccess } = useFeatures()
   const inboxAccess = canAccess('social_inbox')

    return {
      inboxAccess
    }
  },
  data() {
    return {
      filter: null,
      windowWidth: window.innerWidth,
      isSidebarHover: false,
    }
  },
  computed: {
    ...mapGetters([
      'getPlan',
      'getActiveWorkspaceAddonsLifetime',
      'getActiveWorkspace',
      'getWorkspaces',
      'getInboxFilter',
    ]),
  },

  watch: {
    'getActiveWorkspace._id': async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const inboxNotificationChannel =
          'channel-inbox-notifications-' + oldVal + '-' + this.getProfile._id
        this.setInboxNotificationBroadcast(inboxNotificationChannel)

        this.setUsage(newVal)
      }
    },
    'getProfile._id': async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const inboxNotificationChannel =
          'channel-inbox-notifications-' +
          this.getActiveWorkspace._id +
          '-' +
          oldVal
        this.setInboxNotificationBroadcast(inboxNotificationChannel)
      }
    },
  },

  async created() {
    if (this.getPlan.subscription.is_lifetime && !this.inboxAccess.allowed) {
      await this.$router.push({
        name: 'upgrade_lifetime_addon',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug },
      })
    }
  },

  beforeUnmount() {
    this.removeNotificationSocket()
  },

  async mounted() {
    console.log('getPlans details', this.getPlan)
    this.setUsage(this.getActiveWorkspace._id)

    this.$nextTick(async function () {
      this.toggleSidebarStyle()

      window.addEventListener('resize', () => {
        this.toggleSidebarStyle()
      })
    })
  },

  methods: {
    ...mapActions(['setInboxUsage']),
    async setUsage(workspaceId) {
      if (this.hasSocialInboxAccess) {
        const resp = await this.setInboxUsage({ workspace_id: workspaceId })
        console.log('setUsage', resp)
      }
    },
    toggleSidebarStyle() {
      this.windowWidth = window.innerWidth

      if (typeof this.$refs['inbox-main'] !== 'undefined') {
        if (this.windowWidth <= 1377) {
          this.$refs['inbox-main']?.classList.add('fixed-sidebar-view')
        } else {
          this.$refs['inbox-main']?.classList.remove('fixed-sidebar-view')
        }
      }
    },
    conversationTypeChanged(payload) {
      this.filter = payload
    },
  },
}
</script>

<style lang="scss" scoped>
.inbox_main_view {
  display: flex;
  height: calc(100vh - 60px);
}

.fixed-sidebar-view {
  .inbox_sidebar {
    position: fixed;
    z-index: 1040;
    height: calc(100vh - 60px);
    overflow: unset;

    .inbox_header__colapse-btn {
      display: none !important;
    }
  }

  .inbox-body {
    padding-left: 58px;
  }
}
</style>
