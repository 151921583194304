<template>
  <div class="auth_parent_container">
    <div v-if="resetLoader" class="flex flex-col h-full items-center justify-center">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <div v-else class="flex mx-auto h-full">
      <!-- Auth form -->
      <LoginSideComponent v-if="!isWhiteLabelDomain" />

      <!-- Auth slides -->
      <div class="w-full flex flex-col h-full bg-white justify-between py-[1rem] 2xl:py-[2rem] 3xl:py-[3rem] px-28 2xl:px-40">
        <p class="text-[#3A4557] 2xl:py-[2rem] py-[1rem] text-right">Back to<router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline ml-2" :to="{ name: 'login' }">Sign in</router-link></p>
        <div class="flex flex-col items-center justify-center flex-grow">
          <form v-if="is_valid_token" class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <div v-if="isWhiteLabelDomain" class="px-[5rem] py-[1rem] 2xl:py-[2rem]">
                <LogoComponent />
              </div>
              <h2 class="text-left text-[#2D2D2D] font-bold text-md 2xl:text-3xl">Recover your password.</h2>
              <p class="text-md text-[#757A8A] mt-5">Enter your new password and confirm password below.</p>
            </div>

            <div class="flex flex-col gap-2 mt-12">

              <!-- Password -->
              <CstFloatingLabelInput
                  id="reset_new_password"
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  label="New Password"
                  show-icon-left
                  show-icon-right
                  value="password"
                  class="!h-[3.3rem]"
              >
                <template v-slot:icon>
                  <i class="icon-Password"></i>
                </template>
                <template v-slot:icon-right>
                  <i
                      class="cursor-pointer"
                      :class="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                      @click="togglePassword('password')"
                  ></i>
                </template>
              </CstFloatingLabelInput>

              <!-- Password -->
              <CstFloatingLabelInput
                  id="reset_confirm_password"
                  v-model="confirm_password"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  label="Confirm Password"
                  show-icon-left
                  show-icon-right
                  value="password"
                  class="!h-[3.3rem]"
              >
                <template v-slot:icon>
                  <i class="icon-Password"></i>
                </template>
                <template v-slot:icon-right>
                  <i
                      class="cursor-pointer"
                      :class="showConfirmPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                      @click="togglePassword('confirmPassword')"
                  ></i>
                </template>
              </CstFloatingLabelInput>

            </div>
            <div class="auth-form__button-field mt-7">
              <button
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
                  type="submit"
                  @click.prevent="recoverPassword"
              >
                <span class="w-full">Reset</span>
              </button>
            </div>
          </form>
          <form v-else class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <div v-if="isWhiteLabelDomain" class="px-[5rem] py-[1rem] 2xl:py-[2rem]">
                <LogoComponent />
              </div>
              <h2 class="text-left text-[#2D2D2D] font-bold text-3xl">Link invalid or expired</h2>
              <p class="text-md text-center text-[#757A8A] mt-5">Your token is either invalid or has been expired. Please
                request for the new password reset link from here.</p>
            </div>
            <div class="auth-form__button-field mt-7">
              <button
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
                  type="button"
                  @click.prevent="$router.push({ name: 'forgotpassword' })"
              >
                <span class="w-full">Request New Password</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'
import { authenticationTypes } from '@state/mutation-types'
import {
  recoverPasswordURL,
  resetPasswordTokenURL,
} from '@src/config/api-utils.js'
import LogoComponent from "@modules/account/components/LogoComponent.vue";
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
const LoginSideComponent = () => import("@src/components/authentication/LoginSideComponent.vue");
const CstFloatingLabelInput = () => import('@ui/Input/CstFloatingLabelInput')


export default {
  components: {
    LogoComponent,
    LoginSideComponent,
    CstFloatingLabelInput
  },
  setup() {
    const { isWhiteLabelDomain } = useWhiteLabelApplication()

    return {
      isWhiteLabelDomain
    }
  },
  data() {
    return {
      is_valid_token: false,
      password: '',
      confirm_password: '',
      resetLoader: false,
      showPassword: false,
      showConfirmPassword: false
    }
  },
  computed: {
    ...mapGetters(['getProfile']),
  },
  created() {
    this.verifyResetToken()
  },
  methods: {
    ...mapMutations(['SET_PROFILE']),
    /**
     * this method is responsible for show or hide password
     * @param field
     */
    togglePassword(field) {
      if (field === 'password') {
        this.showPassword = !this.showPassword;
      } else if (field === 'confirmPassword') {
        this.showConfirmPassword = !this.showConfirmPassword;
      }
    },
    verifyResetToken() {
      this.resetLoader = true
      let resetToken = ''
      if (this.$route.query && this.$route.query.token) {
        resetToken = this.$route.query.token
      }
      this.$http.post(resetPasswordTokenURL, { token: resetToken }).then(
        async (response) => {
          this.resetLoader = false
          if (response.data.status) {
            this.is_valid_token = true
          }
        },
        (response) => {}
      )
    },

    recoverPassword() {
      let resetToken = ''
      if (this.$route.query && this.$route.query.token) {
        resetToken = this.$route.query.token
      }
      this.$http
        .post(recoverPasswordURL, {
          password: this.password,
          confirm_password: this.confirm_password,
          token: resetToken,
        })
        .then(
          async (response) => {
            if (response.data.status) {
              this.alertMessage(response.data.message)

              // check if user has enabled 2FA
              if (response.data['2fa_enabled']) {
                await this.$router.push({
                  name: 'twoFactor',
                  params: {
                    token: response.data.user_info,
                  },
                  query: {
                    ...this.$route.query,
                  },
                })
                return
              }

              this.$store.commit(
                authenticationTypes.SET_JWT_TOKEN,
                response.data.token
              )
              this.$store.commit(
                authenticationTypes.SET_LOGGED_USER,
                response.data.logged_user
              )
              this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
              if (response.data.user) {
                this.SET_PROFILE(response.data.user)
              }
              if (response.data.activeWorkspace) {
                this.$store.commit(
                  workspaceTypes.SET_ACTIVE_WORKSPACE,
                  response.data.activeWorkspace.workspace
                )
                console.debug('activate workspace set')
                const self = this

                this.fetchWorkspaces()
                let redirectRoute = 'dashboard'
                const activeWorkspaceMember = this.getLoggedUserRole(
                  response.data.activeWorkspace.workspace
                )
                if (
                  activeWorkspaceMember &&
                  activeWorkspaceMember.role === 'approver'
                )
                  redirectRoute = 'planner_v2'
                  setTimeout(function () {
                    self.$router.push({
                      name: redirectRoute,
                      params: {
                        workspace: response.data.activeWorkspace.workspace.slug,
                      },
                    })
                  }, 100)
              } else {
                // if (this.getProfile.onBoarding) {
                if (this.getProfile.email_verify === false) {
                  this.$router.push({ name: 'verify_email' })
                } else if (this.getWorkspaces.length > 0) {
                  this.$router.push({ name: 'dashboard', params: { workspace: this.getWorkspaces[0].slug }})
                } else {
                  this.$router.push({ name: 'onboardingWorkspace' })
                }
                // } else {
                //   console.debug('ResetPassword: Redirecting to workspaces')
                //   this.$router.push({ name: 'workspaces' })
                // }
              }
            } else {
              this.alertMessage(response.data.message, 'error')
            }
          },
          (response) => {}
        )
    },
  },
}
</script>
