<script>
import { mapGetters } from 'vuex'
import { swalAttributes } from '@common/constants/common-attributes'
import { authenticationTypes } from '@src/store/mutation-types'
import { EventBus } from '@common/lib/event-bus'
import {useBilling} from "@modules/billing/composables/useBilling";
import { baseUrl } from '@/src/config/api-utils'
import { commonMethods } from '@/src/modules/common/store/common-methods'

export default {
  setup() {
    const {showUpgradeModal} = useBilling()

    return {
      showUpgradeModal
    }
  },
  data () {
    return {
      reopen_account_loader: false,
      remove_data_loader: false
    }
  },
  computed: {
    ...mapGetters(['getProfile'])
  },
  created () {
    const profile = this.getProfile
    if (profile.state && profile.state !== 'deleted') {
      window.location.href = baseUrl
      return
    }
    this.Plan()
    this.fetchWorkspaces()
  },
  mounted () {
    commonMethods.toggleAiChat(true)
  },
  unmounted () {
    commonMethods.toggleAiChat()
  },
  methods: {
    async purchasePlan () {
      console.debug('Method::purchasePlan')
      this.reopen_account_loader = true
      const response = await this.$store.dispatch('reopenAccount')
      this.reopen_account_loader = false
      if (response) window.location.href = baseUrl
    },
    async removeAccountData () {
      console.debug('Method::removeAccountData')
      const res = await this.$bvModal
        .msgBoxConfirm(
          'Are you sure you want to delete your account permanently?',
          {
            title: 'Remove Account Data',
            ...swalAttributes()
          }
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          return false
        })

      if (res) {
        this.remove_data_loader = true
        const response = await this.$store.dispatch('removeAccountData')
        this.remove_data_loader = false
        if (response) {
          this.$store.commit(authenticationTypes.SET_EXPIRE_TOKEN)
          this.$store.commit(authenticationTypes.SET_LOGGED_USER_EXPIRE)
          this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, false)
          setTimeout(() => {
            window.location.href = baseUrl + 'login'
          }, 2000)
        }
      }
    },
    openContactUs () {
      EventBus.$emit('open-help-widget')
    }
  }
}
</script>

<template>
  <section>
    <div class="billing-upgrade-page">
      <div class="billing-close-button left_nav">
        <b-button
          variant="studio-theme-transparent-grey"
          class="mr-4 mt-3"
          @click="logout"
        >
          Logout
        </b-button>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-4 margin-auto">
          <div class="subscript-cancel-image">
            <img src="../images/account-close.png" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="subscript-cancel-message">
            <p>Your account is suspended.</p>
            <p style="font-size: 17px; margin-top: 12px">
              There can be different reasons for account suspension such as
              non-payment of your dues, violation of TOS or voluntary account
              closure.
            </p>
          </div>

          <div class="change-cancel-mind">
            <p style="margin-bottom: 12px"
              >If you wish to reactivate your account, please use the options
              given below.</p
            >
            <button
              class="btn mybutton"
              :disabled="reopen_account_loader"
              @click="showUpgradeModal('upgrade-plan', false, true)"
            >
              <span>Subscribe</span>
              <clip-loader
                v-if="reopen_account_loader"
                :color="'#db5353'"
                :size="'16px'"
              ></clip-loader>
            </button>
            <button
              class="btn mybutton"
              style="margin-left: 10px"
              :disabled="reopen_account_loader"
              @click="openContactUs"
            >
              <span>Contact support</span>
              <clip-loader
                v-if="reopen_account_loader"
                :color="'#db5353'"
                :size="'16px'"
              ></clip-loader>
            </button>
            <a
              class="btn delete-account-btn"
              style="margin-left: 10px"
              :disabled="remove_data_loader"
              @click="removeAccountData"
            >
              <span>Permanently delete data</span>
              <clip-loader
                v-if="remove_data_loader"
                :color="'#db5353'"
                :size="'16px'"
              ></clip-loader>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
