<template>
  <div class="w-full flex flex-row overflow-hidden" :style="mainDivStyle">
    <aside
      class="!max-w-[15rem] w-full overflow-auto bg-white max-h-[calc(100vh - 60px)]"
    >
      <AnalyticsSidebar
        :competitor-analytics-access="competitorAnalyticsAccess"
      />
    </aside>
    <main
      class="overflow-y-hidden overflow-x-hidden"
      :class="
        $route.name.includes('_competitor') ? 'flex-1' : 'analytics-wrapper'
      "
    >
      <MainAnalyticsHeader v-if="$route.name.includes('_competitor')" />
      <div
        class="w-full overflow-y-auto max-w-[125rem] mx-auto"
        :class="{
          'max-h-[calc(100vh-140px)] h-[calc(100vh-140px)]':
            $route.name.includes('_competitor_overview_v3') ||
            $route.name.includes('_competitor_v3'),
        }"
      >
        <router-view
          :key="$route.params.reportId"
          :competitor-analytics-access="competitorAnalyticsAccess"
        />
      </div>
    </main>
    <ManageCompetitorsModal />
    <SendReportByEmailModal></SendReportByEmailModal>
    <ScheduleReportModal></ScheduleReportModal>
    <AnalyticPreview />
  </div>
</template>

<script setup>
// libraries
import { computed, onMounted, onBeforeUnmount } from 'vue'

// components
import AnalyticsSidebar from '@src/modules/analytics_v3/components/AnalyticsSidebar.vue'
import MainAnalyticsHeader from '@src/modules/analytics_v3/components/MainAnalyticsHeader.vue'
import ManageCompetitorsModal from '@src/modules/analytics_v3/components/ManageCompetitorsModal.vue'
import SendReportByEmailModal from '@src/modules/analytics/components/reports/modals/SendReportByEmailModal.vue'
import ScheduleReportModal from '@src/modules/analytics/components/reports/modals/ScheduleReportModal.vue'
import AnalyticPreview from '@src/modules/analytics/components/common/AnalyticPreview.vue'
import usePlatform from '@common/composables/usePlatform'
import { useRoute } from 'vue-router'

// constants
import { useFeatures } from '@modules/billing/composables/useFeatures'

const { topBannersHeight } = usePlatform()
const { canAccess } = useFeatures()

const competitorAnalyticsAccess = canAccess('competitor_analytics')
const route = useRoute()

const mainDivStyle = computed(() => {
  const isCompetitorRoute =
    route.name.includes('_competitor_overview_v3') ||
    route.name.includes('_competitor_v3')

  return {
    height: `calc(100vh - ${topBannersHeight.value}px)`,
    maxHeight: isCompetitorRoute
      ? `calc(100vh - ${topBannersHeight.value}px)`
      : 'none',
  }
})

onBeforeUnmount(() => {
  localStorage.removeItem('defaultCompetitorRoute')
})

onMounted(() => {
  document.body.style.overflow = 'hidden'
})

onBeforeUnmount(() => {
  document.body.style.overflow = 'revert'
})
</script>
