<template>
  <div class="head_section d-flex align-items-center">
    <div class="head_left">
      <img
          v-if="logo"
           :src="logo"
           alt="client logo"
           class="!h-[50px]"
      />
      <img
        v-else
        :src="
          getActiveWorkspace?.whitelabel?.logo ||
          require('@assets/img/logo/logo_text_logo.png')
        "
        class="h-[50px]"
        :alt="appData.businessName"
      />
    </div>
    <div class="head_right ml-auto">
      <p
        >{{ reportName }} Analytics Report |
        <template v-if="current && total"
          >{{ current }} of {{ total }}</template
        >
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import useWhiteLabelApplication from '@modules/setting/composables/whitelabel/useWhiteLabelApplication'

export default {
  props: {
    reportName: {
      type: String,
      default: 'X (Twitter)',
    },
    current: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  emits: ['increment-page'],
  setup() {
    const { appData, isWhiteLabelDomain } = useWhiteLabelApplication()

    return {
      appData,
      isWhiteLabelDomain,
    }
  },
  data() {
    return {
      logo: '',
      name: '',
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace', 'getSettingLoaders']),
  },
  mounted: async function () {
    this.getCompanyDetails()
    this.$emit('increment-page')
  },
  methods: {
    getCompanyDetails() {
      this.logo = this.getActiveWorkspace.company_logo
      this.name = this.getActiveWorkspace.company_name
    },
  },
}
</script>
