<script setup>
import { inject, onUnmounted, computed } from 'vue'
import { useStore } from '@state/base'
import CstButton from '@ui/Button/CstButton.vue'
import { usePaddle } from '@modules/billing/composables/usePaddle'

const root = inject('root')
const { $bvModal } = root
const store = useStore()
const { checkoutData, checkoutError, isCheckoutLoading, resetCheckoutState } =
  usePaddle()

// Get user details from store
const userEmail = computed(() => store.getters.getProfile?.email)

const formatPrice = (amount, currency = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(amount)
}

const handleClose = () => {
  $bvModal.hide('paddle-checkout-modal')
  resetCheckoutState()
}

// Cleanup on unmount
onUnmounted(() => {
  resetCheckoutState()
})
</script>

<template>
  <b-modal
    id="paddle-checkout-modal"
    centered
    dialog-class="flex justify-center items-center"
    modal-class="!p-0"
    hide-footer
    hide-header
    size="xl"
    :return-focus="false"
    no-enforce-focus
    @hidden="handleClose"
  >
    <div class="p-6">
      <!-- Header -->
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-xl font-bold">Checkout</h3>
        <div
          v-tooltip="'Close'"
          class="flex items-center justify-center bg-gray-100 rounded w-8 h-8 cursor-pointer p-1"
          @click="handleClose"
        >
          <i class="fa fa-times font-thin text-lg"></i>
        </div>
      </div>

      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <!-- Left Column: Checkout Frame -->
        <div class="checkout-container"></div>

        <!-- Right Column: Order Summary -->
        <div v-if="checkoutData" class="bg-gray-50 p-6 rounded-lg">
          <div
            class="border !border-[#eef1f4] !border-t-0 !border-r-0 !border-l-0 pb-4"
          >
            <div class="flex items-center justify-between">
              <h4 class="text-xl font-semibold text-gray-900">Order Summary</h4>
              <div
                class="rounded-full bg-blue-50 px-3 py-1.5 text-sm font-medium text-blue-700"
              >
                {{
                  checkoutData.items[0]?.billing_cycle?.interval === 'year'
                    ? 'Annual Plan'
                    : 'Monthly Plan'
                }}
              </div>
            </div>
          </div>

          <!-- Items -->
          <div class="space-y-4 my-6">
            <div
              v-for="(item, index) in checkoutData.items"
              :key="index"
              class="border !border-[#eef1f4] !border-t-0 !border-r-0 !border-l-0 pb-4"
            >
              <div class="flex justify-between items-start">
                <div>
                  <h5 class="font-medium">{{ item.product.name }}</h5>
                  <p class="text-sm text-gray-600">{{ item.price_name }}</p>
                  <p class="text-sm text-gray-500">
                    Quantity:
                    <span class="text-gray-600"
                      >{{ item.quantity }} ×
                      {{ formatPrice(item.totals.subtotal / item.quantity) }}
                    </span>
                  </p>
                </div>
                <div class="text-right">
                  <p class="font-medium">{{
                    formatPrice(item.totals.subtotal)
                  }}</p>
                  <p v-if="item.totals.discount" class="text-sm text-green-600">
                    -{{ formatPrice(item.totals.discount) }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Totals -->
          <div class="space-y-2">
            <div class="flex justify-between text-gray-600">
              <span>Subtotal</span>
              <span>{{ formatPrice(checkoutData.totals.subtotal) }}</span>
            </div>
            <div
              v-if="checkoutData.totals.discount"
              class="flex justify-between text-green-600"
            >
              <span>Discount</span>
              <span>-{{ formatPrice(checkoutData.totals.discount) }}</span>
            </div>
            <div
              v-if="checkoutData.totals.tax"
              class="flex justify-between text-gray-600"
            >
              <span>Tax</span>
              <span>{{ formatPrice(checkoutData.totals.tax) }}</span>
            </div>
            <div
              class="flex justify-between font-bold text-lg pt-2 border !border-[#eef1f4] !border-b-0 !border-r-0 !border-l-0"
            >
              <span>Total</span>
              <span>{{ formatPrice(checkoutData.totals.total) }}</span>
            </div>
          </div>

          <!-- Billing Details -->
          <div
            class="mt-6 space-y-2 text-sm text-gray-600 border !border-[#eef1f4] !border-b-0 !border-r-0 !border-l-0 pt-4"
          >
            <p
              >Billing cycle:
              {{
                checkoutData.items[0]?.billing_cycle?.interval === 'year'
                  ? 'Annual'
                  : 'Monthly'
              }}</p
            >
            <p v-if="checkoutData.discount"
              >Discount applied: {{ checkoutData.discount.code }}</p
            >
            <p>Email: {{ checkoutData.customer?.email || userEmail }}</p>
          </div>
        </div>
      </div>

      <!-- Loading State -->
      <div
        v-if="isCheckoutLoading"
        class="flex items-center justify-center py-8"
      >
        <clip-loader :color="'#ffffff'" :size="'16px'"></clip-loader>
      </div>

      <!-- Error State -->
      <div v-if="checkoutError" class="p-4 bg-red-50 mt-4 rounded-md">
        <div class="flex items-center justify-between">
          <p class="text-red-600">{{ checkoutError }}</p>
          <CstButton text="Try Again" variant="primary" @click="handleClose" />
        </div>
      </div>
    </div>
  </b-modal>
</template>
