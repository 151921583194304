<script setup>
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import { EventBus } from '@common/lib/event-bus'
import useTiktokGridView from '@src/modules/planner_v2/composables/SocialMediaManager/Tiktok/useTiktokGridView'
import { onMounted, onBeforeUnmount, watch } from 'vue'
import {commonMethods} from "@common/store/common-methods";
import {useRouter} from "@src/router";
import {useFeatures} from "@modules/billing/composables/useFeatures";
import useSocialGridView from '@/src/modules/planner_v2/composables/useSocialGridView'
import {SUPPORTED_PLATFORMS, TIKTOK_TABS, INSTAGRAM_TABS} from '@/src/modules/planner_v2/constants/GridView'
import ActionButtonsModals from '@/src/modules/planner_v2/components/ActionButtonsModals'
import useInstagramGridView from '@/src/modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'
import { useStore } from '@/src/store/base'

const route = useRoute()
const router = useRouter()
const { getters } = useStore()
const { selectedPlatform, selectedAccount, tabs } = useSocialGridView()
const { fetchMedia } = useInstagramGridView(true)
const { fetchMedia: fetchTiktokMedia } = useTiktokGridView(true)

const {canAccess} = useFeatures()
const gridViewAccess = canAccess('grid_view_planning')

const handleComponentChange = (routeName) => {
  if (routeName.includes(SUPPORTED_PLATFORMS.INSTAGRAM.route_suffix)) {
    selectedPlatform.value = SUPPORTED_PLATFORMS.INSTAGRAM
    tabs.value = INSTAGRAM_TABS
  } else if (routeName.includes(SUPPORTED_PLATFORMS.TIKTOK.route_suffix)) {
    selectedPlatform.value = SUPPORTED_PLATFORMS.TIKTOK
    tabs.value = TIKTOK_TABS
  }
}

handleComponentChange(route.name)

watch(
  () => route.name,
  (routeName) => {
    if(route.name?.includes('grid_view')){
      EventBus.$emit('grid-view-mount')
      handleComponentChange(routeName)
    }
  }
)

onBeforeRouteLeave((to, from, next) => {
  commonMethods?.toggleAiChat(false)
  next()
})

onMounted(async () => {
  if(!gridViewAccess.value?.allowed){
    await router.push({
      name: 'dashboard',
      params: { workspace: getters.getActiveWorkspace.slug },
    })
  }
  document.body.style.overflow = 'hidden'
  EventBus.$emit('grid-view-mount')

  EventBus.$on('refresh-planner-grid-view', (planData) => {
    if (
      planData?.account_selection?.instagram?.includes(
        selectedAccount.value?.instagram_id
      )
    ) {
      fetchMedia()
      EventBus.$emit('refreshPlannerTableV2')
    } else if (
      planData?.account_selection?.tiktok?.includes(
        selectedAccount.value?.platform_identifier
      )
    ) {
      fetchTiktokMedia()
      EventBus.$emit('refreshPlannerTableV2')
    }
  })
})

onBeforeUnmount(() => {
  document.body.style.overflow = 'revert'
  EventBus.$off('refresh-planner-grid-view')
  EventBus.$emit('grid-view-unmount')
})
</script>

<template>
  <component :is="selectedPlatform.component" />

  <ActionButtonsModals />
</template>
