<template>
  <div
    class="setting_profile_component"
    :class="{ 'pl-4': $route.name === 'reports_setting' }"
  >
    <div class="component_inner">
      <div class="ps_box">
        <h2 class="box_heading">Reports Settings</h2>
        <div class="padding-1em">
          <div class="flex-row d-flex m-0">
            <div class="col-7">
              <div class="field_group">
                <p
                  >Add your company name and logo and make them automatically
                  appear in the analytics reports.</p
                >
              </div>

              <div class="field_group">
                <input
                  id="companyName"
                  v-model="name"
                  type="text"
                  placeholder="Type here..."
                />
                <label for="companyName" class="label-animated"
                  >Company Name</label
                >
              </div>

              <div class="field_group">
                <div
                    v-tooltip="!whiteLabelReportsAccess?.allowed ? whiteLabelReportsAccess.error?.message : ''"
                    class="relative inline-block"
                >
                  <button
                      v-if="whiteLabelReportsAccess?.allowed"
                      class="btn btn-studio-theme-space"
                      :disabled="!NameChange"
                      @click="updateCompanyName"
                  >
                    <span>Update</span>
                  </button>
                  <button
                      v-else
                      class="btn btn-studio-theme-space opacity-70 cursor-default pointer-events-none"
                      disabled
                  >
                    <span>Update</span>
                  </button>
                </div>
                &nbsp;<clip-loader
                  v-if="getSettingLoaders.saveCompanyName"
                  class="spinner"
                  :color="'#6d76ff'"
                  :size="'12px'"
              ></clip-loader>
              </div>
            </div>

            <div class="col-3">
              <div
                  v-tooltip="!whiteLabelReportsAccess?.allowed ? whiteLabelReportsAccess.error?.message : ''"
                  class="relative"
              >
                <div class="field_group">
                  <p class="company-logo">Company Logo</p>
                </div>
                <div
                    class="logo-dotted-box"
                    :class="{ 'opacity-70 cursor-default pointer-events-none': !whiteLabelReportsAccess?.allowed }"
                >
                  <div class="upload_section">
                    <div class="img">
                      <img v-if="logo" :src="logo" alt="" />
                    </div>

                    <clip-loader
                        v-if="getSettingLoaders.saveCompanyImage"
                        class="center_loader"
                        :color="'#ffffff'"
                        :size="'16px'"
                    ></clip-loader>
                    <template v-else>
                      <div
                          v-if="whiteLabelReportsAccess?.allowed"
                          class="upload_btn_block"
                          @click="triggerImageFileChange"
                      >
                        <input
                            id="upload"
                            type="file"
                            autocomplete="off"
                            accept="image/*"
                            @change.prevent="updateCompanyImage"
                        />
                        <i class="fal fa-upload"><span>Upload</span></i>
                      </div>
                      <div
                          v-else
                          class="upload_btn_block opacity-70 cursor-default"
                      >
                        <i class="fal fa-upload"><span>Upload</span></i>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="file-info">
                  *Recommended: JPG, GIF or PNG(100x100). Max size 100KB.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { reportSettingURL } from '@src/modules/account/config/api-utils.js'
import proxy from '../../common/lib/http-common';
import {useFeatures} from "@modules/billing/composables/useFeatures";

export default {
  setup(){
    const {canAccess} = useFeatures()
    const whiteLabelReportsAccess = canAccess('white_label_reports')

    return {
      whiteLabelReportsAccess
    }
  },
  data() {
    return {
      NameChange : false,
      loader: false,
      name: '',
      logo: '',
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace', 'getSettingLoaders']),
  },
  watch: {
    name: function (val) {
      if (val === this.getActiveWorkspace.company_name) {
        this.NameChange = false
      } else {
        this.NameChange = true
      }
    },
  },
  mounted() {
    this.getCompanyDetails()
    this.loader = false
  },
  methods: {
    ...mapActions(['setCompanyImageLoader', 'setCompanyNameLoader']),
    triggerImageFileChange() {
      document.getElementById('upload').click()
    },
    async updateCompanyName() {
      const formData = new FormData()
      formData.append('company_name', this.name)
      formData.append('workspace_id', this.getActiveWorkspace._id)
      formData.append('type', 'name')
      this.setCompanyNameLoader(true)
      return await proxy
        .post(reportSettingURL, formData)
        .then((response) => {
          if (response.data.status === true) {
            this.getActiveWorkspace.company_name = this.name
            this.alertMessage(
              'Company name has been successfully updated.',
              'success'
            )
          } else if (response.data.message) {
            this.alertMessage(response.data.message, 'error')
          } else {
            this.alertMessage('Unable to change the company name.', 'error')
          }
          this.setCompanyNameLoader(false)
          return
        })
        .catch((error) => {
          this.setCompanyNameLoader(false)
          this.alertMessage(UNKNOWN_ERROR, 'error')
        })
    },
    async updateCompanyImage(event) {
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()
      formData.append('file', files[0])
      formData.append('workspace_id', this.getActiveWorkspace._id)
      formData.append('type', 'image')
      this.setCompanyImageLoader(true)
      return await proxy
        .post(reportSettingURL, formData)
        .then((response) => {
          if (response.data.status === true) {
            this.logo = response.data.media.link
            this.getActiveWorkspace.company_logo = this.logo
            this.alertMessage(
              'Company image has been successfully updated.',
              'success'
            )
          } else if (response.data.message) {
            this.alertMessage(response.data.message, 'error')
          } else {
            this.alertMessage('Unable to change the profile image.', 'error')
          }
          this.setCompanyImageLoader(false)
          return
        })
        .catch((error) => {
          this.setCompanyImageLoader(false)
          event.target.value = ''
          this.alertMessage(UNKNOWN_ERROR, 'error')
        })
    },
    getCompanyDetails() {
      this.name = this.getActiveWorkspace.company_name
        ? this.getActiveWorkspace.company_name
        : ''
      this.logo = this.getActiveWorkspace.company_logo
    },
  },
}
</script>

<style scoped>
.spinner {
  bottom: 0.75rem;
  position: absolute;
  left: 6rem;
}
</style>
