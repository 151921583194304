<script setup>
import { ref, watch } from 'vue'
import useGeneralSettings from '@modules/setting/composables/whitelabel/useGeneralSettings'
import LayoutCard from '@modules/setting/components/white-label/LayoutCard.vue'
import CstButton from '@src/components/UI/Button/CstButton.vue'

const emit = defineEmits(['next'])
const {
  businessName,
  websiteURL,
  brandLogo,
  favicon,
  isLoading,
  shouldDisableNext,
    isApiError,
    isFormValid,

  handleSubmit,
} = useGeneralSettings()

const brandLogoPreview = ref(null)
const faviconPreview = ref(null)
const fileInputRef = ref(null)
const faviconInputRef = ref(null)

watch(
  () => brandLogo.value,
  (newVal) => {
    if (typeof newVal.value === 'string') {
      brandLogoPreview.value = newVal.value
    }
  },
  { immediate: true, deep: true }
)

watch(
  () => favicon.value,
  (newVal) => {
    if (typeof newVal.value === 'string') {
      faviconPreview.value = newVal.value
    }
  },
  { immediate: true, deep: true }
)

const handleFileUpload = (event, type) => {
  isApiError.value = false
  const file = event.target.files[0]
  if (file) {
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()
      img.onload = () => {
        const fileSizeInMB = file.size / (1024 * 1024) // Convert bytes to MB
        if (type === 'logo' && fileSizeInMB > 5) {
          brandLogo.value.error = 'Logo image size must be 5MB or smaller.'
          return
        } else if (type === 'favicon' && fileSizeInMB > 3) {
          favicon.value.error = 'Favicon image size must be 3MB or smaller.'
          return
        }

        if (type === 'logo' && (img.width >= 251 || img.height >= 151)) {
          brandLogo.value.error = `Logo image must be 250x150 pixels or smaller. Current size is (${img.width}x${img.height})px`
          return
        } else if (
          type === 'favicon' &&
          (img.width >= 33 || img.height >= 33)
        ) {
          favicon.value.error = `Favicon image must be 32x32 pixels or smaller. Current size is (${img.width}x${img.height})px`
          return
        }

        if (type === 'logo') {
          brandLogoPreview.value = e.target.result
          brandLogo.value.value = file
          brandLogo.value.error = ''
        } else {
          faviconPreview.value = e.target.result
          favicon.value.value = file
          favicon.value.error = ''
        }
      }
      img.src = e.target.result
    }
    reader.readAsDataURL(file)
  }
}

const triggerFileUpload = (ref) => {
  if (ref === 'logo') {
    fileInputRef.value.click()
  } else {
    faviconInputRef.value.click()
  }
}

const onSubmit = async () => {
  if (await handleSubmit()) {
    emit('next')
  }
}
</script>

<template>
  <form @submit.prevent="onSubmit">
    <div class="grid grid-cols-2 gap-6">
      <div class="mb-6">
        <label class="block mb-2 font-medium text-gray-900" for="businessName"
          >Business Name<span class="text-red-600">*</span>
          <i
              v-tooltip="businessName.tooltip"
              class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label
        >
        <input
          id="businessName"
          v-model="businessName.value"
          placeholder="e.g., Your Company Name"
          :class="{ '!border-red-300': businessName.error }"
          :required="businessName.isRequired"
          class="w-full border border-gray-300 rounded-lg px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 color-border cst-editor"
          type="text"
        />
        <p v-if="businessName.error" class="mt-1 text-sm text-red-600">{{
          businessName.error
        }}</p>
      </div>

      <div class="mb-6">
        <label class="block mb-2 font-medium text-gray-900" for="websiteURL"
          >Website<span class="text-red-600">*</span>
          <i
              v-tooltip="websiteURL.tooltip"
              class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label
        >
        <input
          id="websiteURL"
          v-model="websiteURL.value"
          placeholder="e.g., https://www.yourcompany.com"
          :class="{ '!border-red-300': websiteURL.error }"
          :required="websiteURL.isRequired"
          class="w-full border border-gray-300 rounded-lg px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 color-border cst-editor"
          type="url"
        />
        <p v-if="websiteURL.error" class="mt-1 text-sm text-red-600">{{
          websiteURL.error
        }}</p>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-6 mb-6">
      <LayoutCard
        custom-classes="border !border-gray-100"
        header-classes="!pb-2"
      >
        <template v-slot:header>
          <label class="block font-medium text-gray-900"
            >Brand Logo<span class="text-red-600">*</span>
            <i
                v-tooltip="brandLogo.tooltip"
                class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
            ></i>
          </label
          >
        </template>
        <template v-slot:body>
          <div
            :class="{ 'border !border-red-300': brandLogo.error }"
            class="flex flex-col sm:flex-row items-center rounded p-2 cursor-pointer hover:bg-gray-50 transition-colors"
            @click="triggerFileUpload('logo')"
          >
            <div
              class="my-2 w-[107px] h-[86px] flex-shrink-0 overflow-hidden flex items-center justify-center"
            >
              <img
                v-if="brandLogoPreview"
                :src="brandLogoPreview"
                alt="Brand Logo"
                class="max-w-full max-h-full rounded-lg"
              />
              <img
                v-else
                alt="ContentStudio logo"
                class="max-w-full max-h-full"
                src="/favicons/favicon.ico"
              />
            </div>
            <p class="mt-4 sm:mt-0 sm:ml-4 text-sm text-gray-600 flex flex-col">
              <span>
                {{
                  brandLogoPreview
                      ? 'Click to change logo.'
                      : ''
                }}
              </span>
              <span>
              Please use a logo image in .png, .jpg, or .jpeg format (Max 250x150px) with a size of 5MB or smaller.
            </span>
            </p>

            <input
              ref="fileInputRef"
              accept="image/png,image/jpeg,image/jpg"
              class="hidden"
              type="file"
              @change="(e) => handleFileUpload(e, 'logo')"
            />
          </div>
          <p v-if="brandLogo.error" class="mt-1 text-sm text-red-600">{{
            brandLogo.error
          }}</p>
        </template>
      </LayoutCard>

      <LayoutCard
        custom-classes="border !border-gray-100"
        header-classes="!pb-2"
      >
        <template v-slot:header>
          <label class="block font-medium text-gray-900"
            >Favicon<span class="text-red-600">*</span>
            <i
                v-tooltip="favicon.tooltip"
                class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
            ></i>
          </label
          >
        </template>
        <template v-slot:body>
          <div
            :class="{ 'border !border-red-300': favicon.error }"
            class="flex flex-col sm:flex-row items-center rounded p-2 cursor-pointer hover:bg-gray-50 transition-colors"
            @click="triggerFileUpload('icon')"
          >
            <div
              class="my-2 w-[107px] h-[86px] flex-shrink-0 overflow-hidden flex items-center justify-center"
            >
              <img
                v-if="faviconPreview"
                :src="faviconPreview"
                alt="Favicon"
                class="max-w-full max-h-full rounded-lg"
              />
              <img
                v-else
                alt="ContentStudio Icon"
                class="max-w-full max-h-full"
                src="/favicons/favicon.ico"
              />
            </div>
            <p class="mt-4 sm:mt-0 sm:ml-4 text-sm text-gray-600 flex flex-col">
              <span>
                {{
                  faviconPreview
                      ? 'Click to change icon.'
                      : ''
                }}
              </span>
              <span>
                Please use a favicon image in .ico or .png format (Max 32x32px) with a size of 3MB or smaller.
              </span>
            </p>
            <input
              id="faviconUpload"
              ref="faviconInputRef"
              accept="image/png,image/x-icon"
              class="hidden"
              type="file"
              @change="(e) => handleFileUpload(e, 'favicon')"
            />
          </div>
          <p v-if="favicon.error" class="mt-1 text-sm text-red-600">{{
            favicon.error
          }}</p>
        </template>
      </LayoutCard>
    </div>
<!--    <div class="grid grid-cols-2 gap-6">-->
<!--      <div class="mb-6">-->
<!--        <label-->
<!--            class="block mb-2 font-medium text-gray-900"-->
<!--            for="termsAndConditions"-->
<!--        >Terms & Conditions<span class="text-red-600">*</span></label-->
<!--        >-->
<!--        <input-->
<!--            id="termsAndConditions"-->
<!--            v-model="termsAndConditions.value"-->
<!--            placeholder="e.g., https://www.yourcompany.com/terms"-->
<!--            :class="{ '!border-red-300': termsAndConditions.error }"-->
<!--            :required="termsAndConditions.isRequired"-->
<!--            class="w-full border border-gray-300 rounded-lg px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 color-border cst-editor"-->
<!--            type="url"-->
<!--        />-->
<!--        <p v-if="termsAndConditions.error" class="mt-1 text-sm text-red-600">{{-->
<!--            termsAndConditions.error-->
<!--          }}</p>-->
<!--      </div>-->

<!--      <div class="mb-6">-->
<!--        <label class="block mb-2 font-medium text-gray-900" for="privacyPolicy"-->
<!--        >Privacy Policy<span class="text-red-600">*</span></label-->
<!--        >-->
<!--        <input-->
<!--            id="privacyPolicy"-->
<!--            v-model="privacyPolicy.value"-->
<!--            placeholder="e.g., https://www.yourcompany.com/privacy"-->
<!--            :class="{ '!border-red-300': privacyPolicy.error }"-->
<!--            :required="privacyPolicy.isRequired"-->
<!--            class="w-full border border-gray-300 rounded-lg px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 color-border cst-editor"-->
<!--            type="url"-->
<!--        />-->
<!--        <p v-if="privacyPolicy.error" class="mt-1 text-sm text-red-600">{{-->
<!--            privacyPolicy.error-->
<!--          }}</p>-->
<!--      </div>-->
<!--    </div>-->
    <div class="flex justify-end">
      <CstButton
        :disabled="shouldDisableNext"
        :loading="isLoading"
        type="submit"
      >
        Next
      </CstButton>
    </div>
  </form>
</template>
