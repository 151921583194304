<script setup>
import { plansComparison } from '@modules/billing/constants/plansComparison'
import FeatureValue from '@modules/billing/components/FeatureValue.vue'
import CstButton from '@ui/Button/CstButton.vue'
import pricingPlans, {
  planTypesWithoutSuffix,
} from '@modules/billing/constants/plansDetails'
import { inject, ref } from 'vue'
import { useBilling } from '@modules/billing/composables/useBilling'
import CstConfirmationPopup from '@ui/Popup/CstConfirmationPopup.vue'

const props = defineProps({
  isAnnually: {
    type: Boolean,
    default: true,
  },
  changeTrialPlan: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['toggle-yearly'])

// Initialize composables and services
const root = inject('root')
const { $bvModal } = root
const { isCurrentPlan, handlePlanChange } = useBilling()

// Local state
const loadingPlans = ref({})
const selectedPlanId = ref(null)
// Get plan details
const getPlan = (planId) => {
  return pricingPlans.find((plan) => plan.id === planId)
}

// Methods
const getCTAText = (planId) => {
  if (
    planId !== 'agency-unlimited' &&
    isCurrentPlan(planId, props.isAnnually, props.changeTrialPlan)
  )
    return 'Current Plan'
  if (props.changeTrialPlan) return 'Choose Plan'
  return 'Upgrade Now'
}

const handleCTAClick = (planId) => {
  if (isCurrentPlan(planId, props.isAnnually, props.changeTrialPlan)) return

  selectedPlanId.value = planId
  $bvModal.show('plan-comparison-purchase-confirmation-modal')
}

const handleConfirmPurchase = () => {
  if (!selectedPlanId.value) return

  handlePlanChange(
    getPlan(selectedPlanId.value),
    props.isAnnually,
    props.changeTrialPlan
  )

  $bvModal.hide('plan-comparison-purchase-confirmation-modal')
  selectedPlanId.value = null
}

const isPlanLoading = (planId) => !!loadingPlans.value[planId]
</script>

<template>
  <div class="max-w-6xl mx-auto">
    <!-- Main Table -->
    <div class="bg-white rounded-lg shadow-sm min-w-[800px] relative">
      <!-- Header Row -->
      <div class="sticky -top-6 pt-6 bg-white z-20">
        <div v-if="!changeTrialPlan" class="grid grid-cols-5 mb-8 gap-[2rem]">
          <div class="col-span-2 font-semibold">
            <span class="text-xl">Features</span>
            <!-- Monthly/Yearly Toggle -->
            <div class="flex items-center rounded-md bg-gray-100 p-1 w-max">
              <button
                :class="
                  isAnnually ? 'bg-transparent' : 'bg-white font-semibold'
                "
                class="rounded-md px-5 py-2.5 text-center transition !border-0"
                @click="$emit('toggle-yearly', false)"
              >
                Monthly
              </button>
              <button
                :class="
                  isAnnually ? 'bg-white font-semibold' : 'bg-transparent'
                "
                class="rounded-md px-4 py-2 text-center transition !border-0"
                @click="$emit('toggle-yearly', true)"
              >
                <span class="flex items-center justify-center">
                  Yearly
                  <span
                    class="ml-2 bg-blue-50 text-blue-500 uppercase font-bold text-xs px-3 py-1 rounded-full whitespace-nowrap"
                  >
                    Save up to 34%
                  </span>
                </span>
              </button>
            </div>
          </div>
          <div class="col-span-3 grid grid-cols-3 gap-4">
            <div class="text-center">
              <h3 class="text-lg font-semibold mb-2">Standard</h3>
              <div class="text-2xl font-bold">
                ${{ isAnnually ? '19' : '29'
                }}<span class="text-sm font-normal">/month</span>
              </div>
            </div>
            <div class="text-center">
              <h3 class="text-lg font-semibold mb-2">Advanced</h3>
              <div class="text-2xl font-bold">
                ${{ isAnnually ? '49' : '69'
                }}<span class="text-sm font-normal">/month</span>
              </div>
            </div>
            <div class="text-center">
              <h3 class="text-lg font-semibold mb-2">Agency Unlimited</h3>
              <div class="text-2xl font-bold">
                ${{ isAnnually ? '99' : '139'
                }}<span class="text-sm font-normal">/month</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Categories and Features -->
      <div
        v-for="category in plansComparison.categories"
        :key="category.name"
        class="mb-8"
      >
        <h3
          class="text-lg font-semibold mb-4 border !border-t-0 !border-l-0 !border-r-0 !border-b !border-[#202324] py-2 sticky bg-white z-10"
          :class="{
            'top-[4.75rem]': !changeTrialPlan,
            '-top-6 pt-6': changeTrialPlan,
          }"
        >
          {{ category.name }}
        </h3>

        <div
          v-for="feature in category.features"
          :key="feature.name"
          class="grid grid-cols-5 py-3 border !border-t-0 !border-l-0 !border-r-0 !border-b !border-[#D1D5DB] gap-[2rem]"
        >
          <FeatureValue
            :feature="feature"
            :is-annually="isAnnually"
            :change-trial-plan="changeTrialPlan"
          />
        </div>
      </div>

      <!-- Buttons Row -->
      <div class="grid grid-cols-5 py-6 gap-[2rem] border-t border-[#D1D5DB]">
        <div class="col-span-2"></div>
        <div class="col-span-3 grid grid-cols-3 gap-4">
          <div
            v-for="plan in planTypesWithoutSuffix"
            :key="plan"
            class="text-center"
          >
            <CstButton
              :disabled="
                (plan !== 'agency-unlimited' &&
                  isCurrentPlan(plan, isAnnually)) ||
                Object.values(loadingPlans)?.some(Boolean)
              "
              :loading="isPlanLoading(plan)"
              :text="getCTAText(plan)"
              :variant="plan === 'agency-unlimited' ? 'primary' : 'outlined'"
              class="w-full"
              @click="handleCTAClick(plan)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <CstConfirmationPopup
    modal-name="plan-comparison-purchase"
    primary-button-text="Proceed"
    secondary-button-text="Cancel"
    @confirmed="handleConfirmPurchase"
  >
    <template v-slot:head>
      <p class="text-lg font-medium">Confirm Purchase</p>
    </template>
    <template v-slot:body>
      <p class="text-sm py-4">
        Are you sure you want to proceed with the purchase? This will update
        your current subscription plan and cannot be undone.
      </p>
    </template>
  </CstConfirmationPopup>
</template>
