<script>
import { usePosting } from '@/src/modules/common/composables/usePosting';
import { EventBus } from '@/src/modules/common/lib/event-bus';

export default {
  setup(){
    const { pausePosting } = usePosting()
    return {
      pausePosting
    }
  },
  props: {
    workspace_id: {
      default: null
    },
    id: {
      default: 'pausePosting'
    }
  },
  data () {
    return {
      processing_loader: false,
      stage: ''
    }
  },
  computed: {
    isProcessingLoader () {
      return this.processing_loader
    },
    isNormalStage () {
      return this.stage === ''
    },
    isSuccessStage () {
      return this.stage === 'success'
    },
    isFailStage () {
      return this.stage === 'fail'
    }
  },
  methods: {
    hideModal () {
      this.$bvModal.hide(this.id)
    },
    async pausePostingWorkspace () {
      console.debug(
        'Method::pausePostingWorkspace',
        this.type,
        this.platform,
        this.workspace_id
      )
      const payload = { workspace_id: this.workspace_id }
      this.processing_loader = true
      const response = await this.pausePosting(payload)
      if (!response) {
        this.stage = 'fail'
      }
      if (response) {
        this.stage = 'success'
        EventBus.$emit('update-users-workspaces', {
          workspace_id: this.workspace_id,
          type: 'update',
          fields: { on_hold: true },
          updateType: 'onhold'
        })

      }

      this.processing_loader = false
      const stateObject = this
      setTimeout(() => {
        setTimeout(() => {
          stateObject.stage = ''
        }, 500)
        stateObject.$bvModal.hide(this.id)
      }, 1000)
    }
  }
}
</script>

<template>
  <b-modal
    :id="id"
    no-close-on-esc
    no-close-on-backdrop
    modal-class="alertBox"
    hide-footer
    hide-header
  >
    <div class="modal_body">
      <div v-if="isSuccessStage" class="svg_animation_success">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle
            class="path circle"
            fill="none"
            stroke="#73AF55"
            stroke-width="6"
            stroke-
            miterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <polyline
            class="path check"
            fill="none"
            stroke="#73AF55"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            points="100.2,40.2 51.5,88.8 29.8,67.5 "
          />
        </svg>
      </div>

      <div v-if="isFailStage" class="svg_animation_success">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle
            class="path circle"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-miterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <line
            class="path line"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="34.4"
            y1="37.9"
            x2="95.8"
            y2="92.3"
          />
          <line
            class="path line"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="95.8"
            y1="38"
            x2="34.4"
            y2="92.2"
          />
        </svg>
      </div>

      <div v-if="isNormalStage" class="col-sm-12 alert_content">
        <div class="col-sm-12 alert_content">
          <h2>Pause Posting</h2>
        </div>
        <p>By pausing this workspace</p>
        <ul class="alert_list warning">
          <li>All automation campaigns will be paused.</li>
          <li
            >All posts (scheduled, under review) will be changed to 'Draft'.</li
          >
          <li
            >You won't be able to schedule new content until you resume
            publishing.</li
          >
        </ul>

        <div class="col-sm-12 footer_box">
          <button
            class="btn gray cancel_btn"
            :disabled="isProcessingLoader"
            @click="hideModal"
            >No</button
          >
          <button
            class="btn red confirm_btn btn_loader loader_right"
            :disabled="isProcessingLoader"
            @click.prevent="pausePostingWorkspace"
          >
            <span>Yes</span>
            <clip-loader
              v-if="isProcessingLoader"
              :color="'#ffffff'"
              :size="'16px'"
            ></clip-loader>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
