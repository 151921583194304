<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import { blog } from '@src/modules/integration/store/states/mutation-types.js'
import { blogIntegrationsConfigrations } from '@src/modules/integration/config/api-utils.js'
import useIntegrationComposable from "@modules/integration/Composables/useIntegrationComposable";
import {useFeatures} from "@modules/billing/composables/useFeatures";
import {useBilling} from "@modules/billing/composables/useBilling";

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup(){
    const {getAuthorizationUrl} = useIntegrationComposable()
    const {canAccess} = useFeatures()
    const blogPostAccess = canAccess('blog_publishing')
    const {showUpgradeModal} = useBilling()

    return {getAuthorizationUrl, blogPostAccess, showUpgradeModal}
  },
  data() {
    return {
      link_visibility: true,
    }
  },
  computed: {
    ...mapGetters([
      'getBlogAuthorization',
      'getWorkspaces',
      'getProfile',
      'getBlogsLoader',
    ]),
    integrationsConfigrations() {
      return blogIntegrationsConfigrations[this.type]
    },
    listingHeadingName() {
      return _.startCase(this.type)
    },
    canSaveBlogs() {
      return this.hasPermission('can_save_blog')
    },
    connectLinkVisibility() {
      return this.integrationsConfigrations.authorization_type === 'oauth2'
        ? this.link_visibility && this.canSaveBlogs
        : this.canSaveBlogs
    },
    reconnectLinkVisibility() {
      return (
        this.integrationsConfigrations.authorization_type === 'oauth2' &&
        this.connectLinkVisibility
      )
    },
    isBlogLoading() {
      return this.getBlogsLoader.fetch_blogs
    },
    hasBlogItems() {
      return !this.isBlogLoading && this.blogItems && this.blogItems.length
    },
    blogItems() {
      return this.$store.getters.getBlogs[this.type].items
    },
    isBasicAuthorization() {
      return this.integrationsConfigrations.authorization_type === 'basic'
    },
    showConnectionDisabledActions() {
      return !this.connectLinkVisibility && !this.link_visibility
    },
  },
  created() {
    console.log('BlogListing:: Created')
    console.log('BlogListing :: Type -----> ', this.type)
  },
  methods: {
    async connectAccount(process, connector_id) {
      console.debug('Method::connectAccount', process, connector_id)
      if (this.type === 'wordpress') {
        if (this.checkSubscriptionLimits('blogs')) return false
        this.$store.commit(blog.SET_BLOG_DETAILS, null)
        this.$bvModal.show('save_wordpress_blog_modal')
        return false
      }

      if (this.type === 'shopify' && process === 'connect') {
        if (this.checkSubscriptionLimits('blogs')) return false
        this.$store.commit(blog.SET_BLOG_DETAILS, null)
        this.$bvModal.show('manage_shopify_store_modal')
        return false
      }

      let link = ''
      const details = { process: process, type: this.type }
      if (process === 'reconnect') details.connector_id = connector_id
      this.link_visibility = false

      if (this.type === 'shopify') {
        const item = this.$store.getters.getBlogs[this.type].items.find(
          (item) => item.platform_identifier === connector_id
        )
        link = item
          ? this.getBlogAuthorization.shopify.replace(
              'https://{shop}.myshopify.com',
              item.shop_url
            )
          : ''
        link = link.replace('{api_key}', item.api_key)
        details.api_key = item.api_key
        details.api_secret = item.api_secret

        const baseUrl = window.location.href.split('#')[0];
        details.callback_url = `${baseUrl}#${this.type}`;

        await this.getAuthorizationUrl({
          platform: this.type,
          connectionDetails: details,
        })

        return false
      }


      link = this.getBlogAuthorization[this.type]
      console.log('Link ---_> ', link)

      const baseUrl = window.location.href.split('#')[0];
      details.callback_url = `${baseUrl}#${this.type}`;

      await this.getAuthorizationUrl({
        platform: this.type,
        connectionDetails: details,
      })
    },
    async fetchBlog(platform_identifier) {
      console.debug('Method::fetchBlog', platform_identifier)
      if (this.type === 'wordpress') {
        this.$bvModal.show('save_wordpress_blog_modal')
      }
      const response = await this.$store.dispatch('fetchBlog', {
        platform_identifier: platform_identifier,
        type: this.type,
      })
      if (!response && this.type === 'wordpress') {
        this.$bvModal.hide('save_wordpress_blog_modal')
      }
    },
    removeBlog(type, blog) {
      console.debug('Method::removeBlog', type, blog)
      this.$parent.type = this.type
      this.$parent.platform = blog.platform_identifier
      this.$bvModal.show('removeIntegration')
    },
    showPluginToken(token) {
      console.debug('Method:fetchPluginToken')
      this.$store.commit(blog.SET_PLUGIN_TOKEN, token)
      this.$bvModal.show('plugin_token_modal')
    },
    isConnectedViaPlugin(platform) {
      return platform.platform_connection_type === 'plugin'
    },
    isConnectedViaOfficialPlugin(platform) {
      return platform.platform_connection_type === 'official_plugin'
    },
  },
}
</script>
<template>
  <div class="ps_box">
    <div class="table_box_head">
      <div class="head_left">
        <h2 :class="integrationsConfigrations.heading_classes">
          <i :class="integrationsConfigrations.icon_classes"></i>
          <span class="text">{{
            listingHeadingName === 'Wordpress'
              ? 'WordPress'
              : listingHeadingName
          }}</span>
        </h2>
      </div>
      <div class="head_right ml-auto">
        <template v-if="blogPostAccess?.allowed">
          <a
            v-if="connectLinkVisibility"
            @click.prevent="connectAccount('connect')"
            class="btn btn-studio-theme-space"
            ><span>{{ integrationsConfigrations.connection_text }}</span></a
          >
          <a
            v-if="showConnectionDisabledActions"
            disabled="disabled"
            class="btn btn-studio-theme-space"
            ><span>{{ integrationsConfigrations.connection_text }}</span></a
          >
        </template>
        <template v-else>
          <a
            class="btn btn-studio-theme-space opacity-70 !cursor-default"
            v-tooltip="blogPostAccess?.error?.message"
            @click="showUpgradeModal"
            >
            <img
              src="@assets/img/icons/lock_black.svg"
              class="w-3 mr-1.5"
              alt="Locked"
            />
            <span>{{ integrationsConfigrations.connection_text }}</span>
            </a
          >
          </template>
      </div>
    </div>

    <div class="w-full rounded-xl px-4 pb-2">
      <template v-if="!isBlogLoading">
        <template v-if="hasBlogItems">
          <table class="w-full p-2">
            <thead class="focus:outline-none h-16 bg-gray-50">
              <tr>
                <th class="pl-3 w-1/2">Blog</th>
                <th class="text-left px-2 w-1/4">Added By</th>
                <th
                  style="width: 200px"
                  v-if="canSaveBlogs"
                  class="w-1/4 text-center"
                  >Actions</th
                >
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="blog in blogItems"
                class="border-solid border-t-[1px] border-r-0 border-l-0 border-b-0"
              >
                <td class="py-4 px-2 w-1/2">
                  <div class="profile_picture">
                    <div class="picture_block">
                      <img :src="blog.platform_logo" alt="profile_image"
                           @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                    " />
                    </div>
                    <a :href="blog.platform_url" target="_blank">
                      <div class="text_block">
                        <p class="link">{{ blog.platform_url }}</p>
                      </div>
                    </a>
                  </div>
                </td>
                <td class="py-4 px-2 text-left w-1/4">{{ blog.added_by }}</td>
                <td class="text-center py-4 px-2 w-1/4" v-if="canSaveBlogs">
                  <div>
                    <i
                      v-tooltip.top="'Api Key'"
                      class="action_icon sm eye_icon cs-key"
                      v-if="
                        isConnectedViaPlugin(blog) ||
                        isConnectedViaOfficialPlugin(blog)
                      "
                      @click.prevent="showPluginToken(blog.token)"
                    ></i>
                    <i
                      v-tooltip.top="'Reconnect'"
                      class="action_icon sm cs-refresh"
                      v-if="reconnectLinkVisibility"
                      @click.prevent="
                        connectAccount('reconnect', blog.platform_identifier)
                      "
                    ></i>
                    <i
                      v-if="
                        blog.policy &&
                        blog.policy.can_edit &&
                        isBasicAuthorization
                      "
                      v-tooltip.top="'Edit'"
                      class="action_icon sm icon-edit-cs"
                      @click.prevent="fetchBlog(blog.platform_identifier)"
                    ></i>
                    <i
                      v-tooltip.top="'Remove'"
                      v-if="blog.policy && blog.policy.can_remove"
                      class="action_icon sm icon-delete-cs"
                      @click.prevent="removeBlog(type, blog)"
                    ></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </template>
      <div v-if="isBlogLoading" class="text-center py-4 px-2">
        <clip-loader color="#4165ed" :size="'12px'"></clip-loader>
      </div>
      <div v-else-if="!hasBlogItems">
        <p
          class="message w-full text-center pt-5 pb-4"
          >{{canSaveBlogs?"You have not connected any website/blog yet, click on the button to get started.":"You have not connected any website/blog."}}</p
        >
      </div>
    </div>
  </div>
</template>
