<script setup>
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import { EventBus } from '@common/lib/event-bus'
import useYoutubeAnalytics from '@src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import YoutubePostModal from '@src/modules/analytics/views/youtube/components/YoutubePostModal.vue'
import EngagementIcon from '@src/assets/img/icons/analytic/engagement.svg'
import EngagementRateIcon from '@src/assets/img/icons/analytic/rate.svg'
import ViewsIcon from '@src/assets/img/icons/analytic/eye-icon.svg'
import WatchTimeIcon from '@src/assets/img/icons/analytic/watch-time.svg'
import useNumber from '@common/composables/useNumber'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import { debounce } from 'lodash'
import PerformingPostsCard from '@/src/modules/analytics_v3/components/PerformingPostsCard.vue'

const props = defineProps({
  defaultOption: {
    type: String,
    default: 'Engagement',
    validator: (v) => {
      return ['Engagement', 'Views'].includes(v)
    },
  },
  selectedAccount: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  topPosts: {
    type: Object,
    required: true,
    default: () => {},
  },
  leastPosts: {
    type: Object,
    required: true,
    default: () => {},
  },
  threshold: {
    type: Number,
    default: 5,
  },
})

const { formatNumber, roundNumber } = useNumber()
const { handleImageError } = useComposerHelper()
const { isReportView, topAndLeastDropDownOptions, screenWidth } =
  useYoutubeAnalytics()

const root = inject('root')
const { $bvModal } = root
const selectedPost = ref({})

const topPerformingSort = ref(
  topAndLeastDropDownOptions.find((i) => i.label === props.defaultOption)
)

const leastPerformingSort = ref(
  topAndLeastDropDownOptions.find((i) => i.label === props.defaultOption)
)

const postsDisplayLimit = computed(() =>
  screenWidth.value >= 1510 ? props.threshold : 4
)

const displayedTopPosts = computed(() =>
  props.topPosts[topPerformingSort.value?.api_key]?.slice(
    0,
    postsDisplayLimit.value
  )
)

const displayedLeastPosts = computed(() =>
  props.leastPosts[leastPerformingSort.value?.api_key]?.slice(
    0,
    postsDisplayLimit.value
  )
)

const updateScreenWidth = debounce(() => {
  screenWidth.value = window.innerWidth
}, 200)

onMounted(() => {
  window.addEventListener('resize', updateScreenWidth)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateScreenWidth)
})

const hasTopPosts = computed(() => displayedTopPosts.value?.length)
const hasLeastPosts = computed(() => displayedLeastPosts.value?.length)

const topPostsClass = computed(() =>
  topPerformingSort.value?.api_key === 'byEngagement'
    ? 'items-baseline'
    : 'items-center'
)

const leastPostsClass = computed(() =>
  leastPerformingSort.value?.api_key === 'byEngagement'
    ? 'items-baseline'
    : 'items-center'
)

const getIcon = (key) => {
  if (key === 'byEngagement') {
    return {
      icon1: EngagementIcon,
      icon2: EngagementRateIcon,
    }
  } else {
    return {
      icon1: ViewsIcon,
      icon2: WatchTimeIcon,
    }
  }
}

const handlePostSelect = (post) => {
  selectedPost.value = post

  $bvModal.show('post-details-modal')
}

const handleShowAll = () => EventBus.$emit('tab-change', '#posts')

const handleTopDropDownClick = (item = {}) => {
  topPerformingSort.value = item
}

const handleLeastDropDownClick = (item = {}) => {
  leastPerformingSort.value = item
}
</script>

<template>
  <div
    class="color-border cst-editor relative w-full rounded-md bg-white p-5 h-full"
  >
    <div class="grid grid-cols-2 gap-28 mb-6">
      <div class="flex gap-3 items-center justify-start">
        <p class="text-base font-weight-500 select-none"
          >Top Performing Posts By</p
        >
        <CstDropdown
          v-if="topPerformingSort.label"
          dropdown-placement="bottom"
          container-classes="!max-h-96"
          dropdown-classes="!rounded-md !border-[#70707029]"
          button-classes="flex !px-5 !w-auto !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
        >
          <template v-slot:arrow>
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </template>
          <template v-slot:selected>
            <p class="text-[14px] font-weight-500 capitalize">
              {{ topPerformingSort.label }}
            </p>
          </template>
          <template v-slot>
            <template
              v-for="(item, index) in topAndLeastDropDownOptions"
              :key="`activity_type_${index}`"
            >
              <CstDropdownItem @click="handleTopDropDownClick(item)">
                <p class="text-gray-900 text-sm">{{ item.label }}</p>
              </CstDropdownItem>
            </template>
          </template>
        </CstDropdown>
        <v-menu
          v-if="!isReportView"
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
          :delay="300"
        >
          <i class="far fa-question-circle p-0 cursor-pointer"></i>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
              The top performing posts of the account by
              {{ topPerformingSort?.label }}, published during the selected time
              period.
            </p>
          </template>
        </v-menu>
      </div>

      <div
        class="flex items-center"
        :class="{ 'justify-between': hasTopPosts || hasLeastPosts }"
      >
        <div class="flex gap-3 items-center justify-center">
          <p class="text-base font-weight-500 select-none"
            >Least Performing Posts By</p
          >
          <CstDropdown
            v-if="leastPerformingSort?.label"
            dropdown-placement="bottom"
            container-classes="!max-h-96"
            dropdown-classes="!rounded-md !border-[#70707029]"
            button-classes="flex !px-5 !w-auto !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
          >
            <template v-slot:arrow>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </template>
            <template v-slot:selected>
              <p class="text-[14px] font-weight-500 capitalize">
                {{ leastPerformingSort?.label }}
              </p>
            </template>
            <template v-slot>
              <template
                v-for="(item, index) in topAndLeastDropDownOptions"
                :key="`activity_type_${index}`"
              >
                <CstDropdownItem @click="handleLeastDropDownClick(item)">
                  <p class="text-gray-900 text-sm">{{ item.label }}</p>
                </CstDropdownItem>
              </template>
            </template>
          </CstDropdown>
          <v-menu
            v-if="!isReportView"
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
          >
            <i class="far fa-question-circle p-0 cursor-pointer"></i>
            <template v-slot:popper>
              <p class="text-sm text-gray-900">
                The least performing posts of the account by
                {{ leastPerformingSort?.label }}, published during the selected
                time period.
              </p>
            </template>
          </v-menu>
        </div>

        <p
          v-if="(hasTopPosts || hasLeastPosts) && !isReportView"
          class="text-base font-medium text-[#2961D2] cursor-pointer"
          @click="handleShowAll"
        >
          Show All Posts
        </p>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-28 rounded !p-2 mx-1">
      <!-- top posts -->
      <div
        v-if="hasTopPosts"
        class="grid w-full mt-2 grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-2"
      >
        <!-- Item -->
        <PerformingPostsCard
          v-for="post in displayedTopPosts"
          :key="post"
          :data="post"
          class="max-w-[8rem]"
          parent-classes="h-full"
          @preview-performance-post="handlePostSelect(post)"
        >
          <template v-slot:media-content>
            <img
              :src="post.coverImage"
              loading="lazy"
              alt=""
              class="rounded-lg h-[7.25rem] w-full max-w-[8rem]"
              @error="handleImageError"
            />
          </template>

          <template v-slot:card-footer>
            <div class="grid grid-cols-2 mt-1 w-full p-2">
              <span
                class="flex justify-center w-full select-none"
                :class="topPostsClass"
              >
                <img
                  :src="getIcon(topPerformingSort.api_key).icon1"
                  alt=""
                  class="mr-0.5 h-[0.85rem]"
                />
                <span
                  class="text-sm"
                  :class="
                    post[
                      topPerformingSort.api_key === 'byEngagement'
                        ? 'engagement'
                        : 'views'
                    ] < 0
                      ? 'text-red-500'
                      : 'text-[#56C288]'
                  "
                >
                  {{
                    topPerformingSort.api_key === 'byEngagement'
                      ? formatNumber(post.engagement)
                      : formatNumber(post.views)
                  }}
                </span>
              </span>
              <span class="flex justify-center items-center w-full select-none">
                <img
                  :src="getIcon(topPerformingSort.api_key).icon2"
                  alt=""
                  class="mr-0.5 h-[0.85rem]"
                />
                <span class="text-sm">{{
                  topPerformingSort.api_key === 'byEngagement'
                    ? roundNumber(post.engagementRate)
                    : formatNumber(post.averageViewDuration)
                }}</span>
              </span>
            </div>
          </template>

          <template v-slot:tooltip-content>
            <p class="text-sm flex justify-between text-gray-900">
              Views
              <span class="ml-3 font-bold">
                {{ post.views }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Engagements
              <span class="ml-3 font-bold">
                {{ post.engagement }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Eng. Rate
              <span class="ml-3 font-bold">
                {{ roundNumber(post.engagementRate) }}%
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Likes
              <span class="ml-3 font-bold">
                {{ post.likes }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Comments
              <span class="ml-3 font-bold">
                {{ post.comments }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Post Type
              <span class="ml-3 font-bold">
                {{ post.mediaType }}
              </span>
            </p>
          </template>
        </PerformingPostsCard>
      </div>
      <div v-else class="flex justify-content-center w-full">
        <p class="font-medium text-sm">No posts found.</p>
      </div>

      <!--      least posts-->
      <div
        v-if="hasLeastPosts"
        class="grid w-full mt-2 grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-2"
      >
        <!-- Item -->
        <PerformingPostsCard
          v-for="post in displayedLeastPosts"
          :key="post"
          :data="post"
          class="max-w-[8rem]"
          parent-classes="h-full"
          footer-classes="p-2"
          @preview-performance-post="handlePostSelect(post)"
        >
          <template v-slot:media-content>
            <img
              :src="post.coverImage"
              loading="lazy"
              alt=""
              class="rounded-lg h-[7.25rem] w-full max-w-[8rem]"
              @error="handleImageError"
            />
          </template>

          <template v-slot:card-footer>
            <div class="grid grid-cols-2 mt-1 w-full p-2">
              <span
                class="flex justify-center w-full select-none"
                :class="leastPostsClass"
              >
                <img
                  :src="getIcon(leastPerformingSort.api_key).icon1"
                  alt=""
                  class="mr-0.5 h-[0.85rem]"
                />
                <span
                  class="text-sm"
                  :class="
                    post[
                      leastPerformingSort.api_key === 'byEngagement'
                        ? 'engagement'
                        : 'views'
                    ] < 0
                      ? 'text-red-500'
                      : 'text-[#56C288]'
                  "
                >
                  {{
                    leastPerformingSort.api_key === 'byEngagement'
                      ? formatNumber(post.engagement)
                      : formatNumber(post.views)
                  }}
                </span>
              </span>
              <span class="flex justify-center items-center w-full select-none">
                <span
                  class="flex justify-center items-center w-full select-none"
                >
                  <img
                    :src="getIcon(leastPerformingSort.api_key).icon2"
                    alt=""
                    class="mr-0.5 h-[0.85rem]"
                  />
                  <span class="text-sm">{{
                    leastPerformingSort.api_key === 'byEngagement'
                      ? roundNumber(post.engagementRate)
                      : formatNumber(post.averageViewDuration)
                  }}</span>
                </span>
              </span>
            </div>
          </template>

          <template v-slot:tooltip-content>
            <p class="text-sm flex justify-between text-gray-900">
              Engagements
              <span class="ml-3 font-bold">
                {{ post.engagement }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Eng. Rate
              <span class="ml-3 font-bold">
                {{ roundNumber(post.engagementRate) }}%
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Likes
              <span class="ml-3 font-bold">
                {{ post.likes }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Comments
              <span class="ml-3 font-bold">
                {{ post.comments }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Post Type
              <span class="ml-3 font-bold">
                {{ post.mediaType }}
              </span>
            </p>
          </template>
        </PerformingPostsCard>
      </div>
      <div v-else class="flex justify-content-center w-full">
        <p class="font-medium text-sm">No posts found.</p>
      </div>
    </div>
  </div>

  <YoutubePostModal
    :selected-account="selectedAccount"
    :selected-post="selectedPost"
  />
</template>
