<script setup>
import { EventBus } from '@common/lib/event-bus'
import usePlannerActions from '@/src/modules/planner_v2/composables/usePlannerActions'
import {useComposerHelper} from "@modules/composer_v2/composables/useComposerHelper";
import {inject} from "vue";
import {useBilling} from "@modules/billing/composables/useBilling";
import {useFeatures} from "@modules/billing/composables/useFeatures";

const props = defineProps({
  item: {
    type: Object,
    required: true,
    default: () => null,
  },
  isTiktok: {
    type: Boolean,
    default: false,
  }
})

const {
  commentsLength,
  internalNotesLength,
  showDuplicateButton,
  isActionProcessing,
  showEditButton,
  showDelButton,
  handleExtra,
  showApprovalStatus,
  clientProfile,
  hideFromClientAction,
  hideFromClientMessage,
  hasThreadedTweetResponse,
  hasFirstCommentResponse,
  sharePlan,
  editPlan,
  clonePlan,
  replacePlan,
  removePlan,
} = usePlannerActions(props.item)

const root = inject('root')

const { openDraftComposer, draftPlanId } = useComposerHelper()
const {showUpgradeModal} = useBilling()
const {canAccess} = useFeatures()
const shareAbleLinkAccess = canAccess('shareable_link')

const editPlanData = async (item) => {
  const res = await openDraftComposer(
      '⚠️ Unfinished Post is in the Composer!',
      'You have a post currently in the composer that is minimized. What would you like to do?',
      'Save & Edit Selected',
      'Return to Composer'
  )

  if(res === null) {
    return
  } else if (!res) {
    EventBus.$emit('reset-composer-data')
    editPlan(item)
  } else {
    const workspace = root.$route.params.workspace
    history.pushState({}, null, `/${workspace}/composer/${draftPlanId.value}`)
    root.$bvModal.show('composer-modal')
  }
}

const clonePlanData = async (item) => {
  const res = await openDraftComposer(
      '⚠️ Unfinished Post is in the Composer!',
      'You have a post currently in the composer that is minimized. What would you like to do?',
      'Save & Create Duplicate',
      'Return to Composer'
  )

  if(res === null) {
    return
  } else if (!res) {
    EventBus.$emit('reset-composer-data')
    clonePlan(item)
  } else {
    const workspace = root.$route.params.workspace
    history.pushState({}, null, `/${workspace}/composer/${draftPlanId.value}`)
    root.$bvModal.show('composer-modal')
  }
}
</script>

<template>
  <b-dropdown
    v-if="item"
    class="icon-btn-no-pad relative"
    menu-class="planner-more-actions-dropdown"
    no-caret
    right
    size="sm"
    toggle-class="text-decoration-none p-0"
    variant="link"
  >
    <template v-slot:button-content>
      <span
        v-tooltip.top="'More actions'"
        class="flex items-center justify-center cursor-pointer"
      >
        <span
          class="
            relative
            bg-white
            p-1
            flex
            items-center
            justify-center
            rounded-md
            w-8
            h-8
          "
        >
          <span class="flex items-center justify-center">
            <img
              alt="approval_publish"
              src="@assets/img/icons/evergreen/move-icon.svg"
            />
          </span>
          <span
            v-if="commentsLength || internalNotesLength"
            class="
              absolute
              -top-3
              -right-3
              w-6
              h-6
              text-xs text-white
              bg-red-500
              rounded-full
              flex
              items-center
              justify-center
              p-1
            "
          >
              {{clientProfile ? commentsLength : commentsLength + internalNotesLength }}
          </span>
        </span>
      </span>
    </template>

    <!-- Duplicate Post -->
    <b-dropdown-item
      v-if="showDuplicateButton"
      :disabled="isActionProcessing"
      class="
        text-gray-800
        block
        cursor-pointer
        hover:bg-gray-100
        active:bg-gray-100
        focus:outline-none
        focus:ring-1
        focus:ring-offset-1
        focus:ring-offset-gray-100
        focus:ring-gray-500
        py-0.5
        text-sm
      "
      link-class="px-2"
      @click.stop="clonePlanData(item)"
    >
      <div class="flex items-center space-x-2">
        <div class="flex items-center justify-center w-6 h-6">
          <img
            alt="approval_publish"
            src="@assets/img/icons/planner/more_actions_duplicate.svg"
          />
        </div>
        <div> Duplicate Post </div>
      </div>
    </b-dropdown-item>

    <!-- Replace Post -->
    <b-dropdown-item
      v-if="item.can_perform.replace"
      :disabled="isActionProcessing"
      class="
        text-gray-800
        block
        cursor-pointer
        hover:bg-gray-100
        active:bg-gray-100
        focus:outline-none
        focus:ring-1
        focus:ring-offset-1
        focus:ring-offset-gray-100
        focus:ring-gray-500
        py-0.5
        text-sm
      "
      link-class="px-2"
      @click.stop="replacePlan(item)"
    >
      <div class="flex items-center space-x-2">
        <div class="flex items-center justify-center w-6 h-6">
          <img
            alt="approval_publish"
            src="@assets/img/icons/planner/more_actions_replace.svg"
          />
        </div>
        <div> Replace Post</div>
      </div>
    </b-dropdown-item>

    <!-- Edit Post -->
    <b-dropdown-item
      v-if="showEditButton"
      :disabled="isActionProcessing"
      class="
        text-gray-800
        block
        cursor-pointer
        hover:bg-gray-100
        active:bg-gray-100
        focus:outline-none
        focus:ring-1
        focus:ring-offset-1
        focus:ring-offset-gray-100
        focus:ring-gray-500
        py-0.5
        text-sm
      "
      link-class="px-2"
      @click.stop="editPlanData(item)"
    >
      <div class="flex items-center space-x-2">
        <div class="flex items-center justify-center w-6 h-6">
          <img
            alt="approval_publish"
            src="@assets/img/icons/planner/more_actions_edit.svg"
          />
        </div>
        <div> Edit Post</div>
      </div>
    </b-dropdown-item>


    <!-- Comments -->
    <b-dropdown-item
      class="
        text-gray-800
        block
        cursor-pointer
        hover:bg-gray-100
        active:bg-gray-100
        focus:outline-none
        focus:ring-1
        focus:ring-offset-1
        focus:ring-offset-gray-100
        focus:ring-gray-500
        py-0.5
        text-sm
      "
      link-class="px-2"
      @click.stop="handleExtra(item, 'commentsAccordion')"
    >
      <div class="flex items-center justify-between space-x-2">
        <div class="flex flex-row items-center space-x-2">
          <div class="flex items-center justify-center w-6 h-6">
            <img
              alt="approval_publish"
              src="@assets/img/icons/planner/more_actions_comments.svg"
            />
          </div>
          <div> Comments</div>
        </div>
        <div
          v-if="commentsLength || internalNotesLength > 0"
          class="
            px-1
            text-xs text-white
            bg-red-500
            font-semibold
            rounded-full
            flex
            items-center
            justify-center
          "
        >
          {{clientProfile ? commentsLength : commentsLength + internalNotesLength }}
        </div>
      </div>
    </b-dropdown-item>

    <!-- Approval Status-->
    <b-dropdown-item
      v-if="showApprovalStatus"
      class="
        text-gray-800
        block
        cursor-pointer
        hover:bg-gray-100
        active:bg-gray-100
        focus:outline-none
        focus:ring-1
        focus:ring-offset-1
        focus:ring-offset-gray-100
        focus:ring-gray-500
        py-0.5
        text-sm
      "
      link-class="px-2"
      @click.stop="handleExtra(item)"
    >
      <div class="flex items-center space-x-2">
        <div class="flex items-center justify-center w-6 h-6">
          <img
            alt="approval_publish"
            src="@assets/img/icons/planner/more_plan_actions_approval_status_1.svg"
          />
        </div>
        <div> Approval Status</div>
      </div>
    </b-dropdown-item>

    <!-- Post Details -->
    <b-dropdown-item
      class="
        text-gray-800
        block
        cursor-pointer
        hover:bg-gray-100
        active:bg-gray-100
        focus:outline-none
        focus:ring-1
        focus:ring-offset-1
        focus:ring-offset-gray-100
        focus:ring-gray-500
        py-0.5
        text-sm
      "
      link-class="px-2"
      @click.stop="EventBus.$emit('preview-plan', item._id)"
    >
      <div class="flex items-center space-x-2">
        <div class="flex items-center justify-center w-6 h-6">
          <img
            alt="approval_publish"
            src="@assets/img/icons/planner/publishing_detial_light_gray.svg"
            class="w-5 h-5"
          />
        </div>
        <div> Publishing Status</div>
      </div>
    </b-dropdown-item>

    <!-- Hide from client -->
    <b-dropdown-item
      v-if="
        !clientProfile &&
        item.publish_time_options &&
        ((item.status === 'draft' && item.publish_time_options.hide_client) ||
          (item.status === 'draft' && !item.publish_time_options.hide_client))
      "
      class="
        text-gray-800
        block
        cursor-pointer
        hover:bg-gray-100
        active:bg-gray-100
        focus:outline-none
        focus:ring-1
        focus:ring-offset-1
        focus:ring-offset-gray-100
        focus:ring-gray-500
        py-0.5
        text-sm
      "
      link-class="px-2"
    >
      <div
        v-if="item.status === 'draft' && item.publish_time_options.hide_client"
        @click.prevent="hideFromClientAction(item, false)"
      >
        <div class="flex items-center space-x-2">
          <div class="flex items-center justify-center w-6 h-6">
            <img
              alt="approval_publish"
              src="@assets/img/icons/planner/hide_from_client.svg"
            />
          </div>
          <div> {{ hideFromClientMessage }}</div>
        </div>
      </div>
      <div
        v-else-if="
          item.status === 'draft' && !item.publish_time_options.hide_client
        "
        @click.prevent="hideFromClientAction(item, true)"
      >
        <div class="flex items-center space-x-2">
          <div class="flex items-center justify-center w-6 h-6">
            <img
              alt="approval_publish"
              src="@assets/img/icons/planner/unhide_from_client.svg"
            />
          </div>
          <div> {{ hideFromClientMessage }}</div>
        </div>
      </div>
    </b-dropdown-item>

    <!-- @if isTwitterPosting && item.status === 'published && threads_response && threads_response.length > 0'-->

    <!-- Threaded Tweets -->
    <b-dropdown-item
      v-if="hasThreadedTweetResponse"
      class="
        text-gray-800
        block
        cursor-pointer
        hover:bg-gray-100
        active:bg-gray-100
        focus:outline-none
        focus:ring-1
        focus:ring-offset-1
        focus:ring-offset-gray-100
        focus:ring-gray-500
        py-0.5
        text-sm
      "
      link-class="px-2"
      @click.stop="EventBus.$emit('preview-plan', item._id)"
    >
      <div class="flex items-center space-x-2">
        <div class="flex items-center justify-center w-6 h-6">
          <img
            alt="approval_publish"
            src="@assets/img/icons/planner/more_actions_threaded_tweet.svg"
          />
        </div>
        <div> Threaded Tweet</div>
      </div>
    </b-dropdown-item>

    <!-- First Comments -->
    <b-dropdown-item
      v-if="hasFirstCommentResponse && !isTiktok"
      class="
        text-gray-800
        block
        cursor-pointer
        hover:bg-gray-100
        active:bg-gray-100
        focus:outline-none
        focus:ring-1
        focus:ring-offset-1
        focus:ring-offset-gray-100
        focus:ring-gray-500
        py-0.5
        text-sm
      "
      link-class="px-2"
      @click.stop="EventBus.$emit('preview-plan', item._id)"
    >
      <div class="flex items-center space-x-2">
        <div class="flex items-center justify-center w-6 h-6">
          <img
            alt="approval_publish"
            src="@assets/img/icons/planner/more_actions_first_comment.svg"
          />
        </div>
        <div> First Comment</div>
      </div>
    </b-dropdown-item>

    <!-- Share Plan -->
    <b-dropdown-item
        v-tooltip="!shareAbleLinkAccess.allowed ? shareAbleLinkAccess.error?.message : ''"
        class="text-gray-800 block cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 py-0.5 text-sm"
        link-class="px-2"
        @click.stop="!shareAbleLinkAccess.allowed ? showUpgradeModal() : sharePlan(item._id)"
    >
      <div class="flex items-center justify-between space-x-2">
        <div class="flex items-center space-x-2">
          <div class="flex items-center justify-center w-6 h-6">
            <i class="far fa-link text-gray-700 !bg-transparent !shadow-none"></i>
          </div>
          <div>Share via Link</div>
        </div>
        <img
            v-if="!shareAbleLinkAccess.allowed"
            src="@assets/img/icons/lock_black.svg"
            class="w-4 h-4"
            alt="Locked"
        />
      </div>
    </b-dropdown-item>

    <!-- Delete Post -->
    <b-dropdown-item
        v-if="showDelButton"
        :disabled="isActionProcessing"
        class="
        text-gray-800
        block
        cursor-pointer
        hover:bg-gray-100
        active:bg-gray-100
        focus:outline-none
        focus:ring-1
        focus:ring-offset-1
        focus:ring-offset-gray-100
        focus:ring-gray-500
        py-0.5
        text-sm
      "
        link-class="px-2"
        @click.stop="removePlan(item._id, item?.post_state,item?.blog_reference)"
    >
      <div class="flex items-center space-x-2">
        <div class="flex items-center justify-center w-6 h-6">
          <i
              class="far fa-trash-alt fa-lg text-red-500 !shadow-none"
          ></i>
        </div>
        <div class="text-red-500">Delete Post</div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>
