<template>
  <!--<b-modal @hide="onhide" id="pintura-editor-modal" modal-class="social_share_modal doka-editor-modal top_left_design_box static no-enforce-focus hide-footer hide-header">-->

  <!--<Doka
                    ref="doka"
                    style="height:400px"
                    crop-aspect-ratio="1"
                    :crop-aspect-ratio-options="cropAspectRatioOptions"
                    crop-min-image-width="120"
                    crop-min-image-height="120"
                    :src="image"
                    @confirm="processImageEnhance"
                    @cancel="cancelImageEnhance"/>-->

  <!--<PinturaEditor
                        v-bind="editorProps"
                        :src="image"
                        @pintura:load="handleInlineLoad($event)"
                        @pintura:process="handleInlineProcess($event)"
                />-->
  <PinturaEditorModal
    v-if="modalVisible"
    v-bind="editorProps"
    :src="image"
    :autofocus="true"
    @pintura:hide="modalVisible = false"
    @pintura:show="handleModalShow"
    @pintura:close="handleModalClose"
    @pintura:load="handleModalLoad($event)"
    @pintura:process="processImageEnhance($event)"
  />
  <!--</b-modal>-->
</template>

<script>
// import { Doka } from '@lumotive/app/public/otherPlugins/vue-doka'
import { EventBus } from '@common/lib/event-bus'
import {UNKNOWN_ERROR} from '@common/constants/messages'
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
const { isWhiteLabelDomain, appData } = useWhiteLabelApplication()
// Import vue-pintura
import {
  PinturaEditor,
  PinturaEditorModal,
  PinturaEditorOverlay
} from '@pqina/vue-pintura/vue-2'

// Import pintura
import {
  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  locale_en_gb,
  getEditorDefaults,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_resize,
  plugin_resize_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  plugin_frame,
  plugin_frame_defaults,
  plugin_frame_locale_en_gb,
  plugin_sticker,
  plugin_sticker_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb
} from '@pqina/pintura'

import plugin_crop_locale_en_gb from '@pqina/pintura/locale/en_GB/crop/en_GB'
import isEmpty from "is-empty";
import {
  uploadImageURL,
  fetchStorageLinkUrl,
  fetchMediaBlob,
  uploadMediaAssetUrl
} from '../../config/api-utils'
import {
  blogPostingTypes,
  blogPosting,
  linkedinSharingTypes,
  instagramTypes,
  gmb
} from '../../store/states/mutation-types'

import {MULTIMEDIA_ALLOWED_PLATFORMS} from "@common/constants/composer";

import proxy from '@/src/modules/common/lib/http-common'
const clone = require("rfdc/default")


setPlugins(
  plugin_crop,
  plugin_resize,
  plugin_finetune,
  plugin_filter,
  plugin_annotate,
  plugin_frame,
  plugin_sticker
)

export default {
  // name: 'demo-modal',
  components: {
    // Doka,
    PinturaEditorModal,
    PinturaEditor
  },
  setup(){
    const { isWhiteLabelDomain } = useWhiteLabelApplication()
    return { isWhiteLabelDomain }
  },
  data () {
    return {
      uploaded: false,
      type: '',
      image: '',
      index: '',
      threadedTweetIndex: '',
      cropAspectRatioOptions: [
        {
          label: 'Free',
          value: null
        },
        {
          label: 'Instagram-Portrait',
          value: '4:5'
        },
        {
          label: 'Square',
          value: '1:1'
        },
        {
          label: 'Instagram-Landscape',
          value: '1.91:1'
        }
      ],
      /* editorProps: {
                    // This will read the image data (required)
                    imageReader: createDefaultImageReader(),

                    // This will write the output image
                    imageWriter: createDefaultImageWriter(),

                    // The markup editor default options, tools, shape style controls
                    ...markup_editor_defaults,

                    // The finetune util controls
                    ...plugin_finetune_defaults,

                    // This handles complex shapes like arrows / frames
                    shapePreprocessor: createDefaultShapePreprocessor(),

                    // This will set a square crop aspect ratio
                    imageCropAspectRatio: 1,

                    // The icons and labels to use in the user interface (required)
                    locale: {
                        ...locale_en_gb,
                        ...plugin_crop_locale_en_gb,
                        ...plugin_finetune_locale_en_gb,
                        ...plugin_annotate_locale_en_gb,
                        ...markup_editor_locale_en_gb,
                    },
                }, */
      editorProps: {
        imageReader: createDefaultImageReader(),
        imageWriter: createDefaultImageWriter(),
        shapePreprocessor: createDefaultShapePreprocessor(),
        ...plugin_finetune_defaults,
        ...plugin_filter_defaults,
        ...markup_editor_defaults,
        ...plugin_frame_defaults,
        resizeMinSize: { width: 1, height: 1 },
        resizeMaxSize: { width: 9999, height: 9999 },
        locale: {
          ...locale_en_gb,
          ...plugin_crop_locale_en_gb,
          ...plugin_resize_locale_en_gb,
          ...plugin_finetune_locale_en_gb,
          ...plugin_filter_locale_en_gb,
          ...plugin_annotate_locale_en_gb,
          ...markup_editor_locale_en_gb,
          ...plugin_frame_locale_en_gb,
          ...plugin_sticker_locale_en_gb,
        },
        cropSelectPresetOptions: [
          [
            'Crop',
            [
              [undefined, 'Custom'],
              [1, 'Square'],
              [1.91 / 1, 'Instagram-Landscape'],
              [4 / 5, 'Instagram-Portrait']
            ]
          ],
          [
            'Size',
            [
              [[180, 180], 'Profile Picture'],
              [[1200, 600], 'Header Image'],
              [[800, 400], 'Timeline Photo']
            ]
          ]
        ],
        stickers: ['🎉', '😄', '👍', '👎', '🍕']
      },
      modalResult: undefined,
      inlineResult: undefined,
      modalVisible: false,
      stateObject: null
    }
  },
  mounted () {
    const self = this
    EventBus.$on('enhanceImagePintura', (data) => {
      console.log("EVENTBUS::enhanceImagePintura ~ data -> ", data)
      this.type = data.type
      this.index = data.index
      this.stateObject = data.stateObject || null
      this.threadedTweetIndex = data.threadedTweetIndex
          ? data.threadedTweetIndex
          : 0

      if (typeof data.image === 'object') {
        const tempImg = data.image
        data.image = tempImg.href
      }

      if (
          data.image &&
          (data.image.indexOf('lumotive-web-storage') !== -1 ||
              data.image.indexOf('contentstudio-media-library-nearline') !== -1)
      ) {
        if(isWhiteLabelDomain.value){
          proxy
          .post(fetchMediaBlob, { url: data.image})
          .then((response) => {
            if(response.status){
              this.image = response.data?.base64
              this.modalVisible = true
            }
            return true
          })
        }else{
          this.image = data.image
          this.modalVisible = data.modalVisible
          return true
        }
      }
      this.showProcessingLoader()
      this.postRequest(
          fetchStorageLinkUrl,
          {link: data.image, workspace_id: this.getActiveWorkspace._id},
          (response) => {
            this.image =
                response.data.status === true ? response.data.link : data.image
            if (response.data.status) {
              this.uploaded = true
              self.modalVisible = data.modalVisible
              self.hideProcessingLoader()
            }
        },
        (response) => {
          console.debug(
              'Exception in fetching storage link to enhance image ',
              response
          )
          this.image = data.image
          self.modalVisible = data.modalVisible
          self.hideProcessingLoader()
        }
      )
    })

  },
  beforeUnmount () {
    EventBus.$off('enhanceImagePintura')
  },
  methods: {
    onhide (event) {
      this.$bvModal.hide('pintura-editor-modal')
    },
    processImageEnhance (output) {
      console.debug('Method::processImageEnhance', output)

      // let testFile = new File([output.resp], image.name, {type: image.mimeType})
      // this.$bvModal.hide('pintura-editor-modal')

      // resetting states and setting loader for upload
      if (this.stateObject) {
        this.stateObject.updateImageLoader = {
          status: true,
          position: this.index
        }
      } else {
        switch (this.type) {
          case 'Blog':
            this.$store.commit(
                blogPostingTypes.SET_UPLOAD_POSTING_IMAGE_LOADER,
                true
            )
            break
          case 'Common':
            if (this.index === -1) {
              this.$store.commit('setCommonSharingImage', [])
              this.$store.commit('setCommonSharingPreview', null)
              this.$store.commit('SET_COMMON_SHARING_VIDEO', null)
            }
            this.$store.commit('setUploadCommonSharingImageLoader', {
              status: true,
              position: this.index
            })
            break
          case 'Facebook':
            if (this.index === -1) {
              this.$store.commit('setFacebookSharingImage', [])
              this.$store.commit('setFacebookSharingPreview', null)
              this.$store.commit('SET_FACEBOOK_SHARING_VIDEO', null)
            }
            this.$store.commit('setUploadFacebookSharingImageLoader', {
              status: true,
              position: this.index
            })
            break
          case 'Twitter':
            if (this.index === -1) {
              this.$store.commit('setTwitterSharingImage', [])
              this.$store.commit('setTwitterSharingPreview', null)
              this.$store.commit('SET_TWITTER_SHARING_VIDEO', null)
            }
            this.$store.commit('setUploadTwitterSharingImageLoader', {
              status: true,
              position: this.index
            })
            break
          case 'ThreadedTweets':
            if (this.index === -1) {
              this.$store.commit('setThreadedTweetsSharingImage', {
                image: [],
                index: this.threadedTweetIndex
              })
              // this.$store.commit('setTwitterSharingPreview', null)
              this.$store.commit('setThreadedTweetsSharingVideo', {
                video: null,
                index: this.threadedTweetIndex
              })
            }
            this.$store.commit('setUploadThreadedTweetsSharingImageLoader', {
              loader: {status: true, position: this.index},
              index: this.threadedTweetIndex
            })
            break
          case 'Linkedin':
            if (this.index === -1) {
              this.$store.commit(linkedinSharingTypes.SET_SHARING_IMAGE, [])
              this.$store.commit(linkedinSharingTypes.SET_SHARING_PREVIEW, null)
            this.$store.commit(linkedinSharingTypes.SET_SHARING_VIDEO, null)
          }
          this.$store.commit(
            linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
            { status: true, position: this.index }
          )
          break
        case 'Pinterest':
          if (this.index === -1) {
            this.$store.commit('setPinterestSharingPreview', null)
            this.$store.commit('SET_PINTEREST_SHARING_VIDEO', null)
          }
          this.$store.commit('setUploadPinterestSharingImageLoader', true)
          break
        case 'Tumblr':
          if (this.index === -1) {
            this.$store.commit('setTumblrSharingPreview', null)
          }
          this.$store.commit('SET_TUMBLR_SHARING_VIDEO', null)
          this.$store.commit('setUploadTumblrSharingImageLoader', true)
          break
        case 'Instagram':
          // resetting video and preview in case of new image or replacing image type
          if (this.index === -1) {
            this.$store.commit(instagramTypes.SET_SHARING_IMAGE, [])
            this.$store.commit(instagramTypes.SET_SHARING_PREVIEW, null)
            this.$store.commit(instagramTypes.SET_SHARING_VIDEO, null)
          }
          this.$store.commit(instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER, {
            status: true,
            position: this.index
          })
          break
          case 'Gmb':
            if (this.index === -1) {
              this.$store.commit(gmb.SET_SHARING_PREVIEW, null)
            }
            this.$store.commit(gmb.SET_SHARING_VIDEO, null)
            this.$store.commit(gmb.SET_UPLOAD_SHARING_IMAGE_LOADER, true)
            break
        }
      }

      const form = new FormData()
      // form.append('image', output.file)
      form.append('source', 'doka')
      form.append('media_assets[0]', output.dest)
      form.append('folder_id', null)
      form.append('workspace_id', this.getActiveWorkspace._id)

      // making request to upload image

      return proxy
        .post(uploadMediaAssetUrl, form)
        .then((response) => {
          this.hideProcessingLoader()

          if (response.data.status) {
            this.alertMessage('Image has been successfully uploaded.', 'success')

            this.$store.dispatch('updateSocialSharingMedia', response.data.media_container)

            // updating editing image and tagging in case of multiple type media
            if(this.stateObject && ['common'].concat(MULTIMEDIA_ALLOWED_PLATFORMS).includes(this.type)) {

              console.debug('update multimedia in stateObject ')
              const multimedia = clone(this.stateObject.sharingDetails?.multimedia)

              if (this.stateObject.sharingDetails.image_tagging_details && !isEmpty(this.stateObject.sharingDetails.image_tagging_details)) {
                const imageTaggingDetails = clone(this.stateObject.sharingDetails.image_tagging_details)
                delete imageTaggingDetails[multimedia[this.index]]
                this.stateObject.sharingDetails.image_tagging_details = imageTaggingDetails
              }

              multimedia[this.index] = response.data.media_container[0].link
              this.stateObject.sharingDetails.multimedia = multimedia
              return
            }

            if (this.stateObject) {
              const images = clone(this.stateObject.sharingDetails.image)

              if (this.stateObject.sharingDetails.image_tagging_details && !isEmpty(this.stateObject.sharingDetails.image_tagging_details)) {
                const imageTaggingDetails = clone(this.stateObject.sharingDetails.image_tagging_details)
                delete imageTaggingDetails[images[this.index]]
                this.stateObject.sharingDetails.image_tagging_details = imageTaggingDetails
              }

              images[this.index] = response.data.media_container[0].link
              this.stateObject.sharingDetails.image = images

            } else {
              response.data.link = response.data.media_container[0].link

              if (!this.stateObject) {
                // setting image in state
                switch (this.type) {
                  case 'Blog':
                    this.$store.commit(blogPosting.SET_BLOG_POSTING_IMAGE, {
                      source: 'Doka',
                      link: response.data.link
                    })
                    break
                  case 'Common':
                    this.index === -1
                        ? this.addSharingImage(response.data.link, 'Common')
                        : this.updateSharingImage(
                            response.data.link,
                            this.index,
                            'Common'
                        )
                    break
                  case 'Facebook':
                    this.index === -1
                        ? this.addSharingImage(response.data.link, 'Facebook')
                        : this.updateSharingImage(
                            response.data.link,
                            this.index,
                            'Facebook'
                        )
                    break
                  case 'Twitter':
                    this.index === -1
                        ? this.addSharingImage(response.data.link, 'Twitter')
                        : this.updateSharingImage(
                            response.data.link,
                            this.index,
                            'Twitter'
                        )
                    break
                  case 'ThreadedTweets':
                    this.index === -1
                        ? this.addSharingImage(
                            response.data.link,
                            'ThreadedTweets',
                            this.threadedTweetIndex
                        )
                        : this.updateSharingImage(
                            response.data.link,
                            this.index,
                            'ThreadedTweets',
                            this.threadedTweetIndex
                        )
                    break
                  case 'Linkedin':
                    this.index === -1
                        ? this.addSharingImage(response.data.link, 'Linkedin')
                        : this.updateSharingImage(
                            response.data.link,
                            this.index,
                            'Linkedin'
                        )
                    break
                  case 'Pinterest':
                    this.$store.commit(
                        'setPinterestSharingImage',
                        response.data.link
                    )
                    break
                  case 'Tumblr':
                    this.$store.commit('setTumblrSharingImage', response.data.link)
                    break
                  case 'Instagram':
                    this.index === -1
                        ? this.addSharingImage(response.data.link, 'Instagram')
                        : this.updateSharingImage(
                            response.data.link,
                            this.index,
                            'Instagram'
                        )
                    break
                  case 'Gmb':
                    this.$store.commit(gmb.SET_SHARING_IMAGE, response.data.link)
                    break
                }
              }
            }
            return true
          } else if (response.data.storageFull) {
            this.setMediaStorageLimit(response.usedStorage)
            this.$bvModal.show('media-storage-limits-exceeded-modal')
          } else {
            this.alertMessage(response.data.message, 'error')
          }
          // this.alertMessage(response.data.message, 'error')
        },
        (response) => {
          console.debug('Exception in processImageEnhance', response)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.hideProcessingLoader()
        }
      )
    },
    showProcessingLoader () {
      if (this.stateObject) {
        this.stateObject.updateImageLoader = {
          status: true,
          position: this.index
        }
        return
      }
      switch (this.type) {
        case 'Blog':
          this.$store.commit(
              blogPostingTypes.SET_UPLOAD_POSTING_IMAGE_LOADER,
              true
          )
          break
        case 'Common':
        case 'common':
          this.$store.commit('setUploadCommonSharingImageLoader', {
            status: true,
            position: this.index
          })
          break
        case 'Facebook':
        case 'facebook':
          this.$store.commit('setUploadFacebookSharingImageLoader', {
            status: true,
            position: this.index
          })
          break
        case 'Twitter':
        case 'twitter':
          this.$store.commit('setUploadTwitterSharingImageLoader', {
            status: true,
            position: this.index
          })
          break
        case 'ThreadedTweets':
        case 'threaded-tweets':
          this.$store.commit('setUploadThreadedTweetsSharingImageLoader', {
            loader: {status: true, position: this.index},
            index: this.threadedTweetIndex
          })
          break
        case 'Linkedin':
        case 'linkedin':
          this.$store.commit(
              linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
              {status: true, position: this.index}
          )
          break
        case 'Pinterest':
        case 'pinterest':
          this.$store.commit('setUploadPinterestSharingImageLoader', true)
          break
        case 'Tumblr':
        case 'tumblr':
          this.$store.commit('setUploadTumblrSharingImageLoader', true)
          break
        case 'Instagram':
        case 'instagram':
          this.$store.commit(instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER, {
            status: true,
            position: this.index
          })
          break
        case 'Gmb':
        case 'gmb':
          this.$store.commit(gmb.SET_UPLOAD_SHARING_IMAGE_LOADER, true)
          break
      }
    },
    hideProcessingLoader () {
      if (this.stateObject) {
        this.stateObject.updateImageLoader = {
          status: false,
          position: -1
        }
        return
      }
      switch (this.type) {
        case 'Blog':
          this.$store.commit(
              blogPostingTypes.SET_UPLOAD_POSTING_IMAGE_LOADER,
              false
          )
          break
        case 'Common':
          this.$store.commit('setUploadCommonSharingImageLoader', {
            status: false,
            position: -1
          })
          break
        case 'Facebook':
          this.$store.commit('setUploadFacebookSharingImageLoader', {
            status: false,
            position: -1
          })
          break
        case 'Twitter':
          this.$store.commit('setUploadTwitterSharingImageLoader', {
            status: false,
            position: -1
          })
          break
        case 'ThreadedTweets':
          this.$store.commit('setUploadThreadedTweetsSharingImageLoader', {
            loader: { status: false, position: -1 },
            index: this.threadedTweetIndex
          })
          break
        case 'Linkedin':
          this.$store.commit(
            linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
            { status: false, position: -1 }
          )
          break
        case 'Pinterest':
          this.$store.commit('setUploadPinterestSharingImageLoader', false)
          break
        case 'Tumblr':
          this.$store.commit('setUploadTumblrSharingImageLoader', false)
          break
        case 'Instagram':
          this.$store.commit(instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER, {
            status: false,
            position: -1
          })
          break
        case 'Gmb':
          this.$store.commit(gmb.SET_UPLOAD_SHARING_IMAGE_LOADER, false)
          break
      }
    },
    cancelImageEnhance () {
      console.log('Method::cancelImageEnhance')
      this.$bvModal.hide('pintura-editor-modal')
    },

    // modal
    handleModalLoad: function (res) {
      console.log('modal load', res)
    },
    handleModalShow: function () {
      console.log('modal show')
    },
    handleModalClose: function () {
      console.log('modal close')
    },
    handleModalProcess: function (res) {
      console.log('modal process', res)
      this.modalResult = URL.createObjectURL(res.dest)
    },

    // inline
    handleInlineLoad: function (res) {
      console.log('inline load', res)
    },
    handleInlineProcess: function (res) {
      console.log('inline process', res)
      this.inlineResult = URL.createObjectURL(res.dest)
    }
  }
}
</script>

<style>
.pintura-editor {
  --editor-max-width: 95em;
  --editor-max-height: 55em;
  --editor-modal-overlay-opacity: 0.5;
  --color-background: 0, 0, 0;
  --color-foreground: 255, 255, 255;
}
</style>
