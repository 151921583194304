<template>
  <b-modal
      id="public-password-protected-external-link"
      centered
      dialog-class="w-[27.813rem]"
      body-class="px-10 py-11 text-center"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
  >
    <div class="mb-4 flex items-center justify-center gap-2">
      <img src="@assets/img/common/key-password.svg" class="w-4 h-4" alt="key">
      <p class="text-[#202324] font-semibold">Password Required</p>
    </div>
    <p class="text-[#757A8A] text-sm mb-1">You need a password to access</p>
    <p class="text-[#202324] font-medium text-s mb-8">{{appData.businessName}} - EasyConnect</p>

    <span class="relative">
      <input
          id="external-password"
          v-model="state.password"
          :type="state.passInputType"
          placeholder="Enter Password"
          class="color-border w-[22.813rem] h-10 p-4 pr-9 text-[#3a4557] rounded-lg bg-[#F2F3F8] cst-editor mb-8"
          autocomplete="off"
          :disabled="verifyLoading"
          @keydown.enter="verifyPassword"
      />
      <img
          :src="secretEyeIconSrc"
          class="w-5 h-5 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
          alt=""
          @click="changePasswordType"
      />
    </span>

    <!--    Footer    -->
    <CstButton
        variant="primary"
        size="large"
        text="Continue"
        :loading="verifyLoading"
        :disabled="verifyLoading || !state.password.trim()"
        @click="verifyPassword"
    />
  </b-modal>
</template>

<script setup>
import {computed, reactive} from 'vue'
import CstButton from '@ui/Button/CstButton'
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";

const emit = defineEmits(['verify-password'])
defineProps({
  verifyLoading: {
    type: Boolean,
    default: false
  }
})

const {appData} = useWhiteLabelApplication()

const state = reactive({
  password: '',
  loading: false,
  passInputType: 'password'
})

const verifyPassword = () => {
  state.loading = true
  emit('verify-password',state.password)
}


const secretEyeIconSrc = computed(() =>
    state.passInputType === 'password'
        ? '/img/eye_disabled.svg'
        : '/img/eye_allowed.svg'
)

const changePasswordType = () => {
  state.passInputType = state.passInputType === 'password' ? 'text' : 'password'
}

</script>

<style lang="scss">
#public-password-protected___BV_modal_outer_ {
  .modal-backdrop {
    opacity: 1 !important;
  }
}
</style>
