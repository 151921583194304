<template>
  <div class="auth_parent_container">
    <div class="flex mx-auto h-full">
      <!-- Auth form -->
      <SignupSideComponent />

      <!-- Auth slides -->
      <div class="w-full flex flex-col h-full bg-white justify-between py-[1rem] 2xl:py-[2rem] 3xl:py-[3rem] px-28 2xl:px-40">
        <p class="text-[#3A4557] 2xl:py-[2rem] py-[1rem] text-right">Already have an account?<router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline ml-2" :to="{ name: 'login' }">Sign in</router-link></p>
        <div class="flex flex-col items-center justify-center flex-grow">
          <form class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <h2 class="text-left text-[#2D2D2D] font-bold text-md 2xl:text-3xl">Create account for free</h2>
              <p class="text-sm text-[#757A8A] mt-2">14 days free trial, no credit card required</p>
            </div>

            <div class="flex w-full justify-center pt-12 gap-3">

              <button
                  v-tooltip="'Sign up with Facebook'"
                  type="button"
                  class="w-full transition font-normal ease-in-out delay-150 text-gray-900 hover:!text-[#0068E5] bg-white border border-gray-200   font-medium rounded-[7px] text-sm p-2.5 text-center inline-flex items-center justify-center mr-2"
                  data-cy="facebook"
                  :disabled="!getSSOAuthorizationLinks.facebook"
                  @click.prevent="
                  redirectSSO(getSSOAuthorizationLinks.facebook, 'signed_up')
                ">
                <span class="flex align-items-center gap-2">
                  <img src="@assets/img/icons/facebook.svg" class="w-[22px] h-[22px]"  alt=""/>
                  Facebook
                </span>
              </button>

              <button
                  v-tooltip="'Sign up with X (formerly Twitter)'"
                  type="button"
                  class="w-full transition font-normal ease-in-out delay-150 text-gray-900 hover:!text-[#0068E5] bg-white border border-gray-200   font-medium rounded-[7px] text-sm p-2.5 text-center inline-flex items-center justify-center mr-2"
                  data-cy="twitter"
                  :disabled="!getSSOAuthorizationLinks.twitter"
                  @click.prevent="
                    redirectSSO(getSSOAuthorizationLinks.twitter, 'signed_up')
                  ">
                  <span class="flex align-items-center gap-2">
                    <img src="@assets/img/icons/twitter-x-rounded.svg" class="w-[22px] h-[22px]"  alt=""/>
                    X (Twitter)
                  </span>
              </button>

              <GoogleSignin v-tooltip="'Sign up with Google'" :authorization-link="getSSOAuthorizationLinks.google" ></GoogleSignin>
            </div>

            <div class="flex items-center w-full text-center leading-[0.1rem] py-4  custom-border">
              <hr class="w-full"/>
              <span class="bg-white w-16 text-[#757A8A]">or</span>
              <hr class="w-full"/>
            </div>

            <div class="flex flex-col gap-2">

              <!-- Email -->
              <CstFloatingLabelInput
                  id="email"
                  v-model="account.email"
                  type="email"
                  label="Email Address"
                  show-icon-left
                  class="!h-[3.3rem] mb-2.5"
                  value="account.email">
                <template v-slot:icon>
                  <i class="icon-Email"></i>
                </template>
              </CstFloatingLabelInput>

              <!-- Password -->
              <div>
                <CstFloatingLabelInput
                    id="password"
                    v-model="account.password"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    :maxlength="18"
                    show-icon-left
                    show-icon-right
                    class="!h-[3.3rem] mb-2.5"
                    value="account.password">
                  <template v-slot:icon>
                    <i class="icon-Password"></i>
                  </template>
                  <template v-slot:icon-right>
                    <i
                        class="cursor-pointer"
                        :class="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                        @click="toggleShowPassword"
                    ></i>
                  </template>
                </CstFloatingLabelInput>

                <div v-if="password_strength_message" class="simple-form__text-field-message flex items-center justify-between mx-1 relative top-[1.11rem]" :class="{ danger: password_state === 'danger' }">
                  <p
                      class="text-xs"
                  >
                    {{ password_strength_message }}
                  </p>
                  <div class="flex gap-1">
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-red-500': password_state === 'weak', ' !bg-green-500': password_state === 'strong', '!bg-yellow-500': password_state === 'fair' }"></div>
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong', 'bg-yellow-500': password_state === 'fair' }"></div>
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong' }"></div>
                  </div>
                </div>
              </div>

              <!-- Business Name -->
              <CstFloatingLabelInput
                  id="bName"
                  v-model="account.business_name"
                  type="text"
                  label="Workspace Name"
                  show-icon-left
                  show-icon-right
                  class="!h-[3.3rem]"
                  value="account.business_name">
                <template v-slot:icon>
                  <i class="far fa-briefcase"></i>
                </template>
                <template v-slot:icon-right>
                  <i v-tooltip="'A workspace is a unique (or dedicated) dashboard for each brand or client segregating content, calendar, teams, and tasks.'" class="far fa-question-circle text-lg text-blue-900 cursor-pointer"></i>
                </template>
              </CstFloatingLabelInput>

              <!-- Google Recaptcha-->
              <vueRecaptcha
                  ref="recaptcha"
                  :sitekey="getGoogleRecaptchaKey"
                  size="invisible"
                  theme="light"
                  loading-timeout="30000"
                  @verify="createAccount"
                  @expire="onCaptchaExpired"
                  @fail="onCaptchaExpired"
                  @error="onCaptchaExpired">
              </vueRecaptcha>

            </div>
            <!-- Terms & Conditions -->
            <div class="py-7">
              <p class="text-[#3A4557]">
                By signing up, you agree to our
                <a
                    class="text-[157FFF] hover:text-[#157FFF] hover:underline"
                    href="https://contentstudio.io/terms-and-conditions"
                    target="_blank">terms of service</a>
                and
                <a
                    class="text-[157FFF] hover:text-[#157FFF] hover:underline"
                    href="https://contentstudio.io/privacy-policy"
                    target="_blank"
                >privacy policy.</a
                >
              </p>
            </div>
            <div class="auth-form__button-field">
              <button
                  :disabled="registerLoader"
                  data-cy="sign_up_button"
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
                  @click.prevent="registerUser"
              >
                <span class="w-full">Create account <img
                    v-if="registerLoader"
                    style="width: 20px; margin-left: 8px"
                    src="../assets/img/common/gif_loader_white.gif"
                    alt=""
                /></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {registerURL} from '@src/config/api-utils'
import {authenticationTypes} from '@src/store/mutation-types'
import VueCookie from 'vue-cookie'
import vueRecaptcha from 'vue3-recaptcha2';
import NewFeaturesSlider from '@src/modules/account/components/NewFeaturesSlider.vue'
import { Slide } from '@jambonn/vue-concise-carousel'
import {workspaceTypes} from "@src/modules/setting/store/states/mutation-types";
import {useIdentify} from "@common/composables/useIdentify";
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
import useTime from "@/src/modules/common/composables/useTime"
import useIp from "@/src/modules/common/composables/useIp"
const CstFloatingLabelInput = () => import('@ui/Input/CstFloatingLabelInput')
const SignupSideComponent = () => import("@src/components/authentication/SignupSideComponent.vue")
const GoogleSignin = () => import("@src/modules/account/components/GoogleSignin.vue")
export default {
  name: 'SignUp',
  components: {
    SignupSideComponent,
    NewFeaturesSlider,
    Slide,
    vueRecaptcha,
    CstFloatingLabelInput,
    GoogleSignin
  },
  setup() {
    const { getClientTimeZone } = useTime()
    const { identity } = useIdentify()
    const {isWhiteLabelDomain} = useWhiteLabelApplication()
    const { ipv4 } = useIp()

    return {
      getClientTimeZone,
      ipv4,
      identity,
      isWhiteLabelDomain,
    }
  },
  data() {
    return {
      account: {
        email: this.$route.query.email ? this.$route.query.email : '',
        password: '',
        plan: 'trial',
        business_name: '',
        phone_no: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      isValidPhone: false,
      registerLoader: false,
      password_strength_message: '',
      password_state: '',
      showPassword: false
    }
  },
  computed: {
    ...mapGetters(['getSSOAuthorizationLinks']),
    getGoogleRecaptchaKey() {
      return process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY
    },
  },
  watch: {
    'account.password'(value) {
      if (value) {
        if (value.length === 0) {
          this.password_strength_message = ''
          this.password_state = ''
        }
        if (value.length < 12) {
          this.password_strength_message = 'Password must be 12 characters or more';
          this.password_state = 'danger';
        } else if (value.length < 14) {
          this.password_strength_message = 'Password could be more secure';
          this.password_state = 'weak';
        } else if (value.length < 16) {
          this.password_strength_message = 'Password is satisfactory';
          this.password_state = 'fair';
        } else {
          this.password_strength_message = 'Password is strong';
          this.password_state = 'strong';
        }
      } else {
        this.password_strength_message = ''
        this.password_state = ''
      }
    },
  },
  async created() {
    this.fetchSSOLinks()
    if (this.$route.query && typeof this.$route.query.package !== 'undefined') {
      this.account.plan = this.$route.query.package
    }
    if (this.isWhiteLabelDomain) {
      await this.$router.push({
        name: 'login',
      })
    }
  },
  mounted() {
    console.debug('Email', this.$route.query.email)
    this.trackUserMaven('pageview')
  },
  methods: {
    ...mapActions(['fetchProfile']),
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
    onCaptchaExpired() {
      this.registerLoader = false
      this.$refs.recaptcha.reset()
    },

    registerUser() {
      if (!this.account.email) {
        this.alertMessage('Please enter an email address', 'error')
        return
      }
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.account.email.toLowerCase()
        )
      ) {
        this.alertMessage('Please enter a valid email address', 'error')
        return
      }
      if (!this.account.password) {
        this.alertMessage('Please enter a password', 'error')
        return
      }
      if (this.password_state === 'danger') {
        this.alertMessage(this.password_strength_message, 'error')
        return
      }

      if (!this.account.business_name?.length) {
        this.alertMessage('Please enter a workspace name', 'error')
        return
      }

      const trimmedBusinessName = this.account.business_name?.trim()

      if (trimmedBusinessName?.length > 35) {
        this.alertMessage('Workspace name should not be greater than 35 characters', 'error')
        return
      }

      const regexp = /^[\p{L} .0-9]+$/u
      if (!regexp.test(trimmedBusinessName)) {
        this.alertMessage('Workspace name should be letters and numbers only (Special characters are not allowed)', 'error')
        return
      }
      this.$refs.recaptcha.execute()
    },


    async createAccount(recaptchaToken) {
      // console.debug('here', VueCookie.get('_fprom_tid', { domain: '.contentstudio.io' }))
      if (this.password_state !== 'danger') {
        this.registerLoader = true
        // this.account['fp_ref_id'] = VueCookie.get('_fprom_tid')
        this.account.fp_tid = VueCookie.get('_fprom_tid')
        this.account.captcha_code = recaptchaToken

        // if (this.$route.query.fpr) {
        //   this.account['fp_ref_id'] = this.$route.query.fpr
        // }

        try {
          this.$refs.recaptcha.reset()
        } catch (e) {
          console.error(e)
        }

        const response = await this.$http
            .post(registerURL, {...this.account, ip: this.ipv4} )
            .then((response) => {
              if (response?.data?.status) {
                this.$store.commit(
                    authenticationTypes.SET_JWT_TOKEN,
                    response?.data?.token
                )
                this.$store.commit(
                    authenticationTypes.SET_LOGGED_USER,
                    response?.data?.logged_user
                )
                this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
              } else {
              this.registerLoader = false
              this.alertMessage(response?.data?.message, 'error')
            }
            return response
          })
          .catch((response) => {
            this.registerLoader = false
            this.alertMessage(
                response?.response?.data?.message,
              'error'
            )
            return response
          })

        if (response && response.data.status) {
          await this.fetchProfile()
          this.$store.commit(workspaceTypes.SET_ACTIVE_WORKSPACE, null)
          // await this.Plan()
          // await this.identify()
          await this.trackSignUpEvent()

          if(this.$store.getters?.getProfile?.email_verify) {
            gtag('event', 'account_sign_up', {
              'send_to': process.env.VUE_APP_GTAG_SEND_TO,
              'email' : this.$store.getters?.getProfile?.email,
            });

            // Track Facebook Pixel signup event
            if (window.fbq) {
              window.fbq('track', 'account_sign_up');
            }

            this.trackUserMaven('email_verified',{
              auto: true
            })
            this.identity()

            await this.fetchWorkspaces(true)

            const workspaceSlug = await this.getWorkspaces?.activeWorkspace?.slug

            // In this we redirecting to dashboard for onboarding
            this.$router.push({
              name: workspaceSlug ? 'dashboard' : 'onboardingWorkspace',
              params: { workspace: workspaceSlug },
            })
          }else{
           await this.$router.push({ name: 'email_verification' })
          }
        }
        this.registerLoader = false
      } else {
        this.registerLoader = false
        this.alertMessage(this.password_strength_message, 'error')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.auth-form-container {
  border-radius: 0 !important;
  padding: 4rem 5.4rem !important;

  .auth-form__social-field {
    margin-top: 2.3rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  .custom-border {
    border-bottom: 1px solid #cfcece92;
    border-radius: 50%;
  }
}
</style>
