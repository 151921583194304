<script setup>
import { watch, computed } from 'vue'
import DualChartComponent from '@src/modules/analytics/views/youtube/components/graphs/DualChartComponent.vue'
import useYoutubeAnalytics from '@/src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'

const props = defineProps({
  defaultType: {
    type: String,
    default: 'line',
    validator: (v) => {
      return ['line', 'bar'].includes(v)
    },
  },
  label: {
    type: String,
    default: 'Subscribers',
  },
})

const {
  barChartOptions,
  lineChartOptions,
  channelSubscribersData,
  isReportView,
  analyticsDesignSystem,
  DUAL_GRAPHS,
  getDualGraphTitle,
  getDualGraphTooltip,
  axisLabelFormatter,
} = useYoutubeAnalytics(props.label)

const noDataFound = computed(() => {
  return !channelSubscribersData.value?.buckets?.length
})

watch(
  () => channelSubscribersData,
  async () => {
    // line chart options
    lineChartOptions.value.series[0].data =
      channelSubscribersData.value?.subscribers_total
    lineChartOptions.value.xAxis.data = channelSubscribersData.value?.buckets

    // bar chart options
    barChartOptions.value.series[0].itemStyle = {}
    barChartOptions.value.series[0].data =
      channelSubscribersData.value?.subscribers_gained_daily
    barChartOptions.value.xAxis.data = lineChartOptions.value.xAxis.data

    barChartOptions.value.yAxis = {
      ...barChartOptions.value.yAxis,
      nameGap: 50,
      axisLabel: {
        ...barChartOptions.value.yAxis.axisLabel,
        formatter: axisLabelFormatter,
        rich: analyticsDesignSystem?.graphs?.richStylesBySentiment,
      },
    }
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <DualChartComponent
    :default-chart-type="defaultType"
    :line-chart-options="lineChartOptions"
    :bar-chart-options="barChartOptions"
    :daily-label="getDualGraphTitle(DUAL_GRAPHS.SUBSCRIBER, 'daily')"
    :cumulative-label="getDualGraphTitle(DUAL_GRAPHS.SUBSCRIBER, 'cumulative')"
    :is-no-data="noDataFound"
  >
    <template v-slot:label="{ chartMode }">
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  {{ getDualGraphTooltip(DUAL_GRAPHS.SUBSCRIBER, chartMode) }}
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
  </DualChartComponent>
</template>
