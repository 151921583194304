<script>
import { mapGetters, mapActions } from 'vuex'
import integrations, {getPlatformName, platformImage} from '@common/lib/integrations'
import SimpleTypeahead from 'vue3-simple-typeahead'
import {useFeatures} from "@modules/billing/composables/useFeatures";
import {useBilling} from "@modules/billing/composables/useBilling";

export default {
  components: {SimpleTypeahead},
  setup() {
    const {showUpgradeModal} = useBilling()
    const {canAccess} = useFeatures()
    const approvalAccess = canAccess('approval_workflow')
    const clientAccess = canAccess('complete_client_management')

    return {
      approvalAccess,
      clientAccess,
      showUpgradeModal
    }
  },
  data () {
    return {
      integrations,
      gmbView: 'off',
      // selectAll: false
      query: '',
      selecteduser: null
    }
  },
  computed: {
    ...mapGetters([
      'getTeam',
      'getSettingLoaders',
      'getTumblrAccounts',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getThreadsAccounts',
      'getLinkedinAccounts',
      'getPinterestAccounts',
      'getInstagramAccounts',
      'getTiktokAccounts',
      'getFolders',
      'getTopics',
      'getTeamSellectAllOptions',
      'getGmbAccounts',
      'getPinterestProfiles',
      'getTeamAccountSelectAll',
      'getUserEmails'
    ]),
    getRoleText () {
      if (this.getTeam.role === 'admin') return 'Administrator'
      if (this.getTeam.role === 'collaborator') return 'Collaborator'
      if (this.getTeam.role === 'approver') return 'Approver'
      return ''
    },
    featureFlagYoutube () {
      const email = this.$store.getters.getProfile.email
      return (
        email.includes('@contentstudio.io') ||
        email.includes('@d4interactive.io')
      )
    },
    getMembershipText () {
      if (this.getTeam.membership === 'team') return 'Team'
      if (this.getTeam.membership === 'client') return 'Client'
      return ''
    },

    showBillingAccessCheckbox () {
      if(this.getUserRole !== 'Super Admin') return false
      return this.getTeam.role === 'admin' && this.getTeam.membership !== 'client'
    },
  },
  watch: {
    'getTeamSellectAllOptions.wordpress' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.twitter' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.facebook' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.pinterest' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.linkedin' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.tumblr' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.instagram' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.gmb' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.medium' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.shopify' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.youtube' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.tiktok' () {
      this.processAccountSelectAll()
    },
    'getTeamSellectAllOptions.threads' () {
      this.processAccountSelectAll()
    }
  },
  created () {
    console.debug('Add team Member Initialize')
    this.initializeTeamSection()
  },
  methods: {
    ...mapActions(['setTeamAccountSelectAll', 'setTeam']),
    platformImage (platform) {
      return platformImage(platform)
    },
    getBlogItems (type) {
      return this.$store.getters.getBlogs[type].items
    },
    /**
     * this method will check specific type existance in the list.
     */
    checkTypeExist (accounts, type) {
      let exist = false
      accounts.forEach(function (element) {
        if (element.type === type) {
          exist = true
        }
      })

      return exist
    },

    platformText (platform) {
      return getPlatformName(platform)
    },
    accountIsPinterestAndIsManuallyConnected (account) {
      return (
        account &&
        (account.pinterest_id || account.board_id) &&
        account.manually_added
      )
    },
    inputHandler(event) {
      this.getTeam.email = event.input
      this.getUsersByEmails()
    },
    handleApproverClick() {
      if (!this.approvalAccess.allowed) {
        this.showUpgradeModal()
        return
      }
      this.getTeam.role = 'approver'
    },
    handleClientClick() {
      if (!this.clientAccess.allowed) {
        this.showUpgradeModal()
        return
      }
      this.getTeam.membership = 'client'
    },
  }
}
</script>

<template>
  <b-modal
    id="addTeamMember"
    modal-class="addTeamMember"
    hide-header
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="modal_head">
      <h4 v-if="getTeam.id">Update Team Member</h4>
      <h4 v-else>Add Team Member</h4>
      <button
        type="button"
        class="modal_head__close"
        data-dismiss="modal"
        @click="$bvModal.hide('addTeamMember')"
        >&times;</button
      >
    </div>

    <div class="modal_body">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="field_group">
            <SimpleTypeahead
                id="typeahead_id"
                placeholder="Enter email address here.."
                :default-item="getTeam.email"
                :items="getUserEmails"
                :min-input-length="1"
                @on-input="inputHandler"
                @select-item="selecteduser = $event"
            >
            </SimpleTypeahead>

            <label class="label-animated" for="memberEmail"
              >Email Address</label
            >
          </div>
        </div>


        <div class="col-md-6 col-sm-12">
          <div class="field_group">
            <div class="dropdown dropdown_right">
              <div
                data-boundary="window"
                class="dropdown_header"
                data-toggle="dropdown"
                data-cy="select-permission"
              >
                <span class="text">{{ getRoleText }}</span>
                <span class="ml-auto">
                  <i class="dropdown_arrow icon_last cs-angle-down"></i>
                </span>
              </div>
              <div
                class="dropdown-menu dropdown-menu-right extended-team-dropdown-menu"
              >
                <ul class="inner">
                  <li
                    class="list_item_li"
                    data-cy="select-admin"
                    @click="getTeam.role = 'admin'"
                  >
                    <i v-if="getTeam.role === 'admin'" class="far fa-check"></i>
                    <p>Administrator</p>
                    <p class="role_description"
                      >Has all the privileges except they cannot view/manage
                      billing and plan details.</p
                    >
                  </li>
                  <li
                    class="list_item_li"
                    data-cy="select-collabrator"
                    @click="getTeam.role = 'collaborator'"
                  >
                    <i
                      v-if="getTeam.role === 'collaborator'"
                      class="far fa-check"
                    ></i>
                    <p>Collaborator</p>
                    <p class="role_description"
                      >Can create and edit posts, can comment on posts, other
                      permissions can be set according to the needs.</p
                    >
                  </li>
                  <!-- Approver role with access check -->
                  <li
                      v-tooltip="!approvalAccess.allowed ? approvalAccess.error?.message : ''"
                      class="list_item_li"
                      :class="{ 'opacity-70 cursor-default': !approvalAccess.allowed }"
                      data-cy="select-approver"
                      @click="handleApproverClick"
                  >
                    <i v-if="getTeam.role === 'approver'" class="far fa-check"></i>
                    <p class="flex items-center gap-2">
                      Approver
                      <img
                          v-if="!approvalAccess.allowed"
                          src="@assets/img/icons/lock_black.svg"
                          class="w-4 h-4"
                          alt="Locked"
                      />
                    </p>
                    <p class="role_description">Can review posts and comment on them, has access to the planner/calendar only.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="field_group">
            <!--<label for="">User Type</label>-->
            <div class="dropdown dropdown_right">
              <div
                data-boundary="window"
                class="dropdown_header d-flex align-items-center"
                data-toggle="dropdown"
                ><span class="text">{{ getMembershipText }}</span>
                <span class="ml-auto"
                  ><i class="dropdown_arrow icon_last cs-angle-down"></i
                ></span>
              </div>

              <div
                class="dropdown-menu dropdown-menu-right extended-team-dropdown-menu"
              >
                <ul class="inner">
                  <li class="list_item_li" @click="getTeam.membership = 'team'">
                    <i
                      v-if="getTeam.membership === 'team'"
                      class="far fa-check"
                    ></i>
                    <p>Team</p>
                    <p class="role_description"
                      >Can view internal/private posts and comments.</p
                    >
                  </li>
                  <!-- Client membership with access check -->
                  <li
                      v-tooltip="!clientAccess.allowed ? clientAccess.error?.message : ''"
                      class="list_item_li"
                      :class="{ 'opacity-70 cursor-default': !clientAccess.allowed }"
                      @click="handleClientClick"
                  >
                    <i v-if="getTeam.membership === 'client'" class="far fa-check"></i>
                    <p class="flex items-center gap-2">
                      Client
                      <img
                          v-if="!clientAccess.allowed"
                          src="@assets/img/icons/lock_black.svg"
                          class="w-4 h-4"
                          alt="Locked"
                      />
                    </p>
                    <p class="role_description">Cannot view internal/private posts and comments.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>

      <!-- Admin Billing Access Permission -->
      <div v-if="showBillingAccessCheckbox" class="row">
        <div class="col-md-6 col-sm-12">
          <div class="field_group">
            <div class="checkbox_container">
              <label for="editPost">
                Enable access to billing
                <input
                    id="editPost"
                    v-model="getTeam.permissions.hasBillingAccess"
                    type="checkbox"
                    name="billing-access"
                />
                <span class="check check-sm"></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <template v-if="getTeam.role !== 'admin'">
        <div  class="clear permission_block">
          <h2 class="heading">Permissions</h2>
          <!-- Collaborator Permissions-->
          <div v-if="getTeam.role === 'collaborator'" class="row">
            <div class="col-md-6 col-sm-12">
              <div class="field_group">
                <div class="checkbox_container">
                  <label for="addBlog">
                    Can add blogs
                    <input
                      id="addBlog"
                      v-model="getTeam.permissions.addBlog"
                      type="checkbox"
                      name=""
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>

                <div class="checkbox_container">
                  <label for="addSource">
                    Can add sources
                    <input
                      id="addSource"
                      v-model="getTeam.permissions.addSource"
                      type="checkbox"
                      name=""
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>

                <div class="checkbox_container">
                  <label for="viewTeam">
                    Can view team
                    <input
                      id="viewTeam"
                      v-model="getTeam.permissions.viewTeam"
                      type="checkbox"
                      name=""
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>

                <div class="checkbox_container">
                  <label for="rescheduleQueue">
                    Can reschedule queue
                    <input
                      id="rescheduleQueue"
                      v-model="getTeam.permissions.rescheduleQueue"
                      type="checkbox"
                      name=""
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-12">
              <div class="field_group">
                <div class="checkbox_container">
                  <label for="addSocial">
                    Can add social accounts
                    <input
                      id="addSocial"
                      v-model="getTeam.permissions.addSocial"
                      type="checkbox"
                      name=""
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>

                <div class="checkbox_container">
                  <label id="addTopic">
                    Can add topics
                    <input
                      id="addTopic"
                      v-model="getTeam.permissions.addTopic"
                      type="checkbox"
                      name=""
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>

                <div class="checkbox_container">
                  <label for="postsReview">
                    Can approve and publish posts
                    <input
                      id="postsReview"
                      v-model="getTeam.permissions.postsReview"
                      type="checkbox"
                      name=""
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>

                <div class="checkbox_container">
                  <label for="fbPublishAs">
                    Can change Facebook group publishing method
                    <input
                      id="fbPublishAs"
                      v-model="getTeam.permissions.changeFBGroupPublishAs"
                      type="checkbox"
                      name=""
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- Approver Permissions-->
          <div v-else-if="getTeam.role === 'approver'" class="row">
            <div class="col-md-6 col-sm-12">
              <div class="field_group flex flex-row">
                <div class="checkbox_container">
                  <label for="editPost">
                    Can edit posts
                    <input
                        id="editPost"
                        v-model="getTeam.permissions.approverCanEditPost"
                        type="checkbox"
                        name=""
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>
                <i v-tooltip="'Enable this option to allow clients to edit posts assigned to them for approval'" class="far fa-info-circle pt-1/2 text-base" ></i>
              </div>
            </div>
          </div>
        </div>

        <div class="permissions_option_panel">
          <div id="accordion" class="panel-group" data-collapse-type="manual">
            <div
              class="permission_block d-flex justify_space_between align-items-center"
            >
              <h2 class="heading">Select accounts</h2>
              <div class="field_group my-0">
                <div class="checkbox_container">
                  <label for="select-all-account">
                    Select All
                    <input
                      id="select-all-account"
                      v-model="getTeamAccountSelectAll.status"
                      type="checkbox"
                      @change="
                        toggleMemberPermission(getTeamAccountSelectAll.status)
                      "
                    />
                    <span class="check check-sm"></span>
                  </label>
                </div>
              </div>
            </div>

            <!--..........Facebook............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                        data-toggle="collapse"
                        data-target="#team_facebook"
                        class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                          class="picture_block facebook_social_icon facebook_icon_color"
                      >
                        <i class="cs-facebook fb"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Facebook</p>
                        <p class="sub_text"
                        >Selected:
                          {{ getTeam.permissions.facebook.length }}/{{
                            getFacebookAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                      v-if="getFacebookAccounts.items.length"
                      class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="facebook_select_all">
                          <input
                              id="facebook_select_all"
                              v-model="getTeamSellectAllOptions.facebook"
                              type="checkbox"
                              @change="
                              memberPermissionsChanges('facebook', 'All')
                            "
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  id="team_facebook"
                  class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Pages</h3>
                    </div>
                    <div class="items">
                      <template
                          v-if="checkTypeExist(getFacebookAccounts.items, 'Page')"
                      >
                        <template v-for="account in getFacebookAccounts.items">
                          <template v-if="account.type === 'Page'">
                            <div class="option_row d-flex">
                              <div class="row_left">
                                <div class="profile_picture">
                                  <div class="picture_block">
                                    <img
                                        v-if="channelImage(account, 'facebook')"
                                        :src="channelImage(account, 'facebook')"
                                        alt=""
                                    />
                                    <img
                                        v-else
                                        src="../../../../../assets/img/profile_default.svg"
                                        alt=""
                                    />
                                  </div>
                                  <div class="text_block">
                                    <p class="text">{{ account.name }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="row_right">
                                <div class="field_group">
                                  <div class="checkbox_container">
                                    <label :for="account.facebook_id">
                                      <input
                                          :id="account.facebook_id"
                                          v-model="getTeam.permissions.facebook"
                                          type="checkbox"
                                          :value="account.facebook_id"
                                          @change="
                                          memberPermissionsChanges(
                                            'facebook',
                                            'Single',
                                          )
                                        "
                                      />
                                      <span class="check"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>

                      <p v-else>No Facebook pages found.</p>
                    </div>
                  </div>

                  <div class="section">
                    <div class="heading">
                      <h3>Groups</h3>
                    </div>
                    <div class="items">
                      <template
                          v-if="
                          checkTypeExist(getFacebookAccounts.items, 'Group')
                        "
                      >
                        <template v-for="account in getFacebookAccounts.items">
                          <template v-if="account.type === 'Group'">
                            <div class="option_row d-flex">
                              <div class="row_left">
                                <div class="profile_picture">
                                  <div class="picture_block">
                                    <img
                                        v-if="channelImage(account, 'facebook')"
                                        :src="channelImage(account, 'facebook')"
                                        alt=""
                                    />
                                    <img
                                        v-else
                                        src="../../../../../assets/img/profile_default.svg"
                                        alt=""
                                    />
                                  </div>
                                  <div class="text_block">
                                    <p class="text">{{ account.name }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="row_right">
                                <div class="field_group">
                                  <div class="checkbox_container">
                                    <label :for="account.facebook_id">
                                      <input
                                          :id="account.facebook_id"
                                          v-model="getTeam.permissions.facebook"
                                          type="checkbox"
                                          :value="account.facebook_id"
                                          @change="
                                          memberPermissionsChanges(
                                            'facebook',
                                            'Single',
                                          )
                                        "
                                      />
                                      <span class="check"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>

                      <p v-else>No Facebook groups found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Instagram............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                        data-toggle="collapse"
                        data-target="#team_instagram"
                        class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                          class="picture_block instagram_social_icon instagram_icon_color"
                      >
                        <i class="cs-instagram ins"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Instagram</p>
                        <p class="sub_text"
                        >Selected:
                          {{ getTeam.permissions.instagram.length }}/{{
                            getInstagramAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                      v-if="getInstagramAccounts.items.length"
                      class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="instagram_select_all">
                          <input
                              id="instagram_select_all"
                              v-model="getTeamSellectAllOptions.instagram"
                              type="checkbox"
                              @change="
                              memberPermissionsChanges('instagram', 'All')
                            "
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  id="team_instagram"
                  class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getInstagramAccounts.items.length">
                        <div
                            v-for="account in getInstagramAccounts.items"
                            class="option_row d-flex"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img
                                    v-if="instagramImage(account)"
                                    :src="instagramImage(account)"
                                    alt=""
                                />
                                <img
                                    v-else
                                    src="../../../../../assets/img/profile_default.svg"
                                    alt=""
                                />
                              </div>
                              <div class="text_block">
                                <p class="text">{{ account.name }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="account.instagram_id">
                                  <input
                                      :id="account.instagram_id"
                                      v-model="getTeam.permissions.instagram"
                                      type="checkbox"
                                      :value="account.instagram_id"
                                      @change="
                                      memberPermissionsChanges(
                                        'instagram',
                                        'Single',
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Instagram profiles found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Threads............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                        data-toggle="collapse"
                        data-target="#team_threads"
                        class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                          class="picture_block threads_social_icon threads_icon_color"
                      >
                        <img src="../../../../../assets/img/integration/threads-icon.svg" class="w-6" alt="threads"/>
                      </div>
                      <div class="text_block">
                        <p class="text">Threads</p>
                        <p class="sub_text"
                        >Selected: {{ getTeam.permissions.threads.length }}/{{
                            getThreadsAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                      v-if="getThreadsAccounts.items.length"
                      class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="threads_select_all">
                          <input
                              id="threads_select_all"
                              v-model="getTeamSellectAllOptions.threads"
                              type="checkbox"
                              @change="memberPermissionsChanges('threads', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  id="team_threads"
                  class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getThreadsAccounts.items.length">
                        <div
                            v-for="account in getThreadsAccounts.items"
                            class="option_row d-flex"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img
                                    v-if="platformImage(account)"
                                    :src="platformImage(account)"
                                    alt=""
                                />
                                <img
                                    v-else
                                    src="../../../../../assets/img/profile_default.svg"
                                    alt=""
                                />
                              </div>
                              <div class="text_block">
                                <p class="text">{{ account.platform_name }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="account.platform_identifier">
                                  <input
                                      :id="account.platform_identifier"
                                      v-model="getTeam.permissions.threads"
                                      type="checkbox"
                                      :value="account.platform_identifier"
                                      @change="
                                      memberPermissionsChanges(
                                        'threads',
                                        'Single',
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Threads profiles found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!--..........wordpress............-->

            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_wordpress"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block wordpress_social_icon wordpress_icon_color"
                      >
                        <i class="cs-wordpress wp"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">WordPress</p>
                        <p class="sub_text"
                          >Selected:
                          {{ getTeam.permissions.wordpress.length }}/{{
                            getBlogItems('wordpress').length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="getBlogItems('wordpress').length"
                    class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="wordpress_select_all">
                          <input
                            id="wordpress_select_all"
                            v-model="getTeamSellectAllOptions.wordpress"
                            type="checkbox"
                            @change="
                              memberPermissionsChanges('wordpress', 'All')
                            "
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_wordpress"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Blogs</h3>
                    </div>
                    <div class="items">
                      <template v-if="getBlogItems('wordpress').length">
                        <div
                          v-for="blog in getBlogItems('wordpress')"
                          class="option_row d-flex"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="blog.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{
                                  trimURL(blog.platform_url)
                                }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="blog.platform_identifier">
                                  <input
                                    :id="blog.platform_identifier"
                                    v-model="getTeam.permissions.wordpress"
                                    type="checkbox"
                                    :value="blog.platform_identifier"
                                    @change="
                                      memberPermissionsChanges(
                                        'wordpress',
                                        'Single',
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No WordPress blogs found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Twitter............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_twitter"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block twitter_social_icon twitter_icon_color"
                      >
                        <i class="cs-twitter tw"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">X (Twitter)</p>
                        <p class="sub_text"
                          >Selected: {{ getTeam.permissions.twitter.length }}/{{
                            getTwitterAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="getTwitterAccounts.items.length"
                    class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="twitter_select_all">
                          <input
                            id="twitter_select_all"
                            v-model="getTeamSellectAllOptions.twitter"
                            type="checkbox"
                            @change="memberPermissionsChanges('twitter', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_twitter"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getTwitterAccounts.items.length">
                        <div
                          v-for="account in getTwitterAccounts.items"
                          class="option_row d-flex"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img
                                  v-if="twitterImage(account)"
                                  :src="twitterImage(account)"
                                  alt=""
                                />
                                <img
                                  v-else
                                  src="../../../../../assets/img/profile_default.svg"
                                  alt=""
                                />
                              </div>
                              <div class="text_block">
                                <p class="text">{{ account.screen_name }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="account.twitter_id">
                                  <input
                                    :id="account.twitter_id"
                                    v-model="getTeam.permissions.twitter"
                                    type="checkbox"
                                    :value="account.twitter_id"
                                    @change="
                                      memberPermissionsChanges(
                                        'twitter',
                                        'Single',
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No X (Twitter) profiles found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Pinterest............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_pinterest"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block pinterest_social_icon pinterest_icon_color"
                      >
                        <i class="cs-pinterest pin"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Pinterest</p>
                        <p class="sub_text"
                          >Selected:
                          {{ getTeam.permissions.pinterest.length }}/{{
                            getPinterestProfiles.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="getPinterestAccounts.items.length"
                    class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="pinterest_select_all">
                          <input
                            id="pinterest_select_all"
                            v-model="getTeamSellectAllOptions.pinterest"
                            type="checkbox"
                            @change="
                              memberPermissionsChanges('pinterest', 'All')
                            "
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_pinterest"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getPinterestAccounts.items.length">
                        <template v-for="account in getPinterestAccounts.items">
                          <template v-if="account.type === 'Profile'">
                            <div class="option_row d-flex">
                              <div class="row_left">
                                <div class="profile_picture">
                                  <div class="picture_block">
                                    <img
                                      v-if="pinterestImage(account)"
                                      :src="pinterestImage(account)"
                                      alt=""
                                    />
                                    <img
                                      v-else
                                      src="../../../../../assets/img/profile_default.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div class="text_block">
                                    <p
                                      v-if="
                                        !accountIsPinterestAndIsManuallyConnected(
                                          account,
                                        )
                                      "
                                      class="text"
                                      >{{ account.username }}
                                    </p>
                                    <p class="text"
                                      >{{ account.first_name }}
                                      {{ account.last_name }}</p
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="row_right">
                                <div class="field_group">
                                  <div class="checkbox_container">
                                    <label :for="account.pinterest_id">
                                      <input
                                        :id="account.pinterest_id"
                                        v-model="getTeam.permissions.pinterest"
                                        type="checkbox"
                                        :value="account.pinterest_id"
                                        @change="
                                          memberPermissionsChanges(
                                            'pinterest',
                                            'Single',
                                          )
                                        "
                                      />
                                      <span class="check"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>

                      <p v-else>No Pinterest Profile found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........LinkedIn............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_linkedin"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block linkedin_social_icon linkedin_icon_color"
                      >
                        <i class="cs-linkedin lin"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">LinkedIn</p>
                        <p class="sub_text"
                          >Selected:
                          {{ getTeam.permissions.linkedin.length }}/{{
                            getLinkedinAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="getLinkedinAccounts.items.length"
                    class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="linkedin_select_all">
                          <input
                            id="linkedin_select_all"
                            v-model="getTeamSellectAllOptions.linkedin"
                            type="checkbox"
                            @change="
                              memberPermissionsChanges('linkedin', 'All')
                            "
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_linkedin"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template
                        v-if="
                          checkTypeExist(getLinkedinAccounts.items, 'Profile')
                        "
                      >
                        <template v-for="account in getLinkedinAccounts.items">
                          <template v-if="account.type === 'Profile'">
                            <div class="option_row d-flex">
                              <div class="row_left">
                                <div class="profile_picture">
                                  <div class="picture_block">
                                    <img
                                      v-if="linkedinImage(account)"
                                      :src="linkedinImage(account)"
                                      alt=""
                                    />
                                    <img
                                      v-else
                                      src="../../../../../assets/img/profile_default.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div class="text_block">
                                    <p class="text">{{ account.name }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="row_right">
                                <div class="field_group">
                                  <div class="checkbox_container">
                                    <label :for="account.linkedin_id">
                                      <input
                                        :id="account.linkedin_id"
                                        v-model="getTeam.permissions.linkedin"
                                        type="checkbox"
                                        :value="account.linkedin_id"
                                        @change="
                                          memberPermissionsChanges(
                                            'linkedin',
                                            'Single',
                                          )
                                        "
                                      />
                                      <span class="check"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>

                      <p v-else>No LinkedIn profile found.</p>
                    </div>
                  </div>

                  <div class="section">
                    <div class="heading">
                      <h3>Pages</h3>
                    </div>
                    <div class="items">
                      <template
                        v-if="checkTypeExist(getLinkedinAccounts.items, 'Page')"
                      >
                        <template v-for="account in getLinkedinAccounts.items">
                          <template v-if="account.type === 'Page'">
                            <div class="option_row d-flex">
                              <div class="row_left">
                                <div class="profile_picture">
                                  <div class="picture_block">
                                    <img
                                      src="../../../../../assets/img/profile_default.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div class="text_block">
                                    <p class="text">{{ account.name }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="row_right">
                                <div class="field_group">
                                  <div class="checkbox_container">
                                    <label :for="account.linkedin_id">
                                      <input
                                        :id="account.linkedin_id"
                                        v-model="getTeam.permissions.linkedin"
                                        type="checkbox"
                                        :value="account.linkedin_id"
                                        @change="
                                          memberPermissionsChanges(
                                            'linkedin',
                                            'Single',
                                          )
                                        "
                                      />
                                      <span class="check"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>

                      <p v-else>No LinkedIn page found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Tumblr............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_tumblr"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block tumblr_social_icon tumblr_icon_color"
                      >
                        <i class="cs-tumblr tum"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Tumblr</p>
                        <p class="sub_text"
                          >Selected:
                          {{
                            getTeam.permissions.tumblr_blogs.length +
                            getTeam.permissions.tumblr_profiles.length
                          }}/{{
                            getBlogItems('tumblr').length +
                            getTumblrAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      getTumblrAccounts.items.length ||
                      getBlogItems('tumblr').length
                    "
                    class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="tumblr_select_all">
                          <input
                            id="tumblr_select_all"
                            v-model="getTeamSellectAllOptions.tumblr"
                            type="checkbox"
                            @change="memberPermissionsChanges('tumblr', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="team_tumblr" class="panel-collapse collapse collapse in">
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getTumblrAccounts.items.length">
                        <div
                          v-for="account in getTumblrAccounts.items"
                          class="option_row d-flex"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="account.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text"> {{ account.platform_name }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label
                                  :for="'profile' + account.platform_identifier"
                                >
                                  <input
                                    :id="
                                      'profile' + account.platform_identifier
                                    "
                                    v-model="
                                      getTeam.permissions.tumblr_profiles
                                    "
                                    type="checkbox"
                                    :value="account.platform_identifier"
                                    @change="
                                      memberPermissionsChanges(
                                        'tumblr',
                                        'Single',
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Tumblr profile found.</p>
                    </div>
                  </div>

                  <div class="section">
                    <div class="heading">
                      <h3>Blogs</h3>
                    </div>
                    <div class="items">
                      <template v-if="getBlogItems('tumblr').length">
                        <div
                          v-for="blog in getBlogItems('tumblr')"
                          class="option_row d-flex"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="blog.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{
                                  trimURL(blog.platform_url)
                                }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="'blog' + blog.platform_identifier">
                                  <input
                                    :id="'blog' + blog.platform_identifier"
                                    v-model="getTeam.permissions.tumblr_blogs"
                                    type="checkbox"
                                    :value="blog.platform_identifier"
                                    @change="
                                      memberPermissionsChanges(
                                        'tumblr',
                                        'Single',
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Tumblr blogs found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Gmb............-->
            <div class="panel panel-default">
              <!--v-if="gmbView === 'on'"-->

              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_gmb"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div class="picture_block gmb_social_icon gmb_icon_color">
                        <!--<i class="fa fa-google google-b"></i>-->
                        <img
                          style="width: 26px"
                          src="/img/google-business.png"
                          alt=""
                        />
                      </div>
                      <div class="text_block">
                        <p class="text">Google My Business</p>
                        <p class="sub_text"
                          >Selected: {{ getTeam.permissions.gmb.length }}/{{
                            getGmbAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="getGmbAccounts.items.length"
                    class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="gmb_select_all">
                          <input
                            id="gmb_select_all"
                            v-model="getTeamSellectAllOptions.gmb"
                            type="checkbox"
                            @change="memberPermissionsChanges('gmb', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="team_gmb" class="panel-collapse collapse collapse in">
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Locations</h3>
                    </div>
                    <div class="items">
                      <template v-if="getGmbAccounts.items.length">
                        <div
                          v-for="account in getGmbAccounts.items"
                          class="option_row d-flex"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <!--<img :src="integrations.platformImage(account)" alt="">-->
                                <img src="/img/google-business.png" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{ platformText(account) }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="account.name">
                                  <input
                                    :id="account.name"
                                    v-model="getTeam.permissions.gmb"
                                    type="checkbox"
                                    :value="account.name"
                                    @change="
                                      memberPermissionsChanges('gmb', 'Single')
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Google My Business location found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Youtube............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_youtube"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block youtube_social_icon youtube_icon_color"
                      >
                        <i class="cs-youtube"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">YouTube</p>
                        <p class="sub_text"
                          >Selected: {{ getTeam.permissions.youtube.length }}/{{
                            getYoutubeAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="getYoutubeAccounts.items.length"
                    class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="youtube_select_all">
                          <input
                            id="youtube_select_all"
                            v-model="getTeamSellectAllOptions.youtube"
                            type="checkbox"
                            @change="memberPermissionsChanges('youtube', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_youtube"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getYoutubeAccounts.items.length">
                        <div
                          v-for="account in getYoutubeAccounts.items"
                          class="option_row d-flex"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="account.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{ account.platform_name }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="account.platform_identifier">
                                  <input
                                    :id="account.platform_identifier"
                                    v-model="getTeam.permissions.youtube"
                                    type="checkbox"
                                    :value="account.platform_identifier"
                                    @change="
                                      memberPermissionsChanges(
                                        'youtube',
                                        'Single',
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Youtube profiles found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Medium............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_medium"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block medium_social_icon medium_icon_color"
                      >
                        <i class="cs-medium medium"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Medium</p>
                        <p class="sub_text"
                          >Selected: {{ getTeam.permissions.medium.length }}/{{
                            getBlogItems('medium').length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="getBlogItems('medium').length"
                    class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="medium_select_all">
                          <input
                            id="medium_select_all"
                            v-model="getTeamSellectAllOptions.medium"
                            type="checkbox"
                            @change="memberPermissionsChanges('medium', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="team_medium" class="panel-collapse collapse collapse in">
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Blogs</h3>
                    </div>
                    <div class="items">
                      <template v-if="getBlogItems('medium').length">
                        <div
                          v-for="blog in getBlogItems('medium')"
                          class="option_row d-flex"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="blog.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{
                                  trimURL(blog.platform_url)
                                }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="blog.platform_identifier">
                                  <input
                                    :id="blog.platform_identifier"
                                    v-model="getTeam.permissions.medium"
                                    type="checkbox"
                                    :value="blog.platform_identifier"
                                    @change="
                                      memberPermissionsChanges(
                                        'medium',
                                        'Single',
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Medium blogs found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........Shopify............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                      data-toggle="collapse"
                      data-target="#team_shopify"
                      class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                        class="picture_block shopify_social_icon shopify_icon_color"
                      >
                        <i class="shopify_icon"></i>
                      </div>
                      <div class="text_block">
                        <p class="text">Shopify</p>
                        <p class="sub_text"
                          >Selected: {{ getTeam.permissions.shopify.length }}/{{
                            getBlogItems('shopify').length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="getBlogItems('shopify').length"
                    class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="shopify_select_all">
                          <input
                            id="shopify_select_all"
                            v-model="getTeamSellectAllOptions.shopify"
                            type="checkbox"
                            @change="memberPermissionsChanges('shopify', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="team_shopify"
                class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Blogs</h3>
                    </div>
                    <div class="items">
                      <template v-if="getBlogItems('shopify').length">
                        <div
                          v-for="blog in getBlogItems('shopify')"
                          class="option_row d-flex"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="blog.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{
                                  trimURL(blog.platform_url)
                                }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="blog.platform_identifier">
                                  <input
                                    :id="blog.platform_identifier"
                                    v-model="getTeam.permissions.shopify"
                                    type="checkbox"
                                    :value="blog.platform_identifier"
                                    @change="
                                      memberPermissionsChanges(
                                        'shopify',
                                        'Single',
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Shopify blogs found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--..........TikTok.............-->
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="panel-title d-flex">
                  <div class="flex_left d-flex align-items-center">
                    <span
                        data-toggle="collapse"
                        data-target="#team_tiktok"
                        class="dropdown_arrow"
                    >
                      <i class="cs-angle-down"></i>
                    </span>

                    <div class="profile_picture ml-4">
                      <div
                          class="picture_block"
                      >
                          <img src="@assets/img/integration/tiktok-icon.svg" class="ml-1 w-7" alt="tiktok_logo" />
                      </div>
                      <div class="text_block">
                        <p class="text">TikTok</p>
                        <p class="sub_text"
                        >Selected: {{ getTeam.permissions.tiktok.length }}/{{
                            getTiktokAccounts.items.length
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                  <div
                      v-if="getTiktokAccounts.items.length"
                      class="flex_right ml-auto"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="tiktok_select_all">
                          <input
                              id="tiktok_select_all"
                              v-model="getTeamSellectAllOptions.tiktok"
                              type="checkbox"
                              @change="memberPermissionsChanges('tiktok', 'All')"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  id="team_tiktok"
                  class="panel-collapse collapse collapse in"
              >
                <div class="panel-body">
                  <div class="section">
                    <div class="heading">
                      <h3>Profiles</h3>
                    </div>
                    <div class="items">
                      <template v-if="getTiktokAccounts.items.length">
                        <div
                            v-for="account in getTiktokAccounts.items"
                            class="option_row d-flex"
                        >
                          <div class="row_left">
                            <div class="profile_picture">
                              <div class="picture_block">
                                <img :src="account.platform_logo" alt="" />
                              </div>
                              <div class="text_block">
                                <p class="text">{{ account.platform_name }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="row_right">
                            <div class="field_group">
                              <div class="checkbox_container">
                                <label :for="account.platform_identifier">
                                  <input
                                      :id="account.platform_identifier"
                                      v-model="getTeam.permissions.tiktok"
                                      type="checkbox"
                                      :value="account.platform_identifier"
                                      @change="
                                      memberPermissionsChanges(
                                        'tiktok',
                                        'Single',
                                      )
                                    "
                                  />
                                  <span class="check"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <p v-else>No Tiktok profiles found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="row col-md-12 col-sm-12">
        <div class="field_group">
          <button
            v-if="getTeam.id"
            class="btn btn-studio-theme-space"
            data-cy="add-team"
            :disabled="getSettingLoaders.saveTeamMember"
            @click.prevent="updateTeamMember"
          >
            <span>Save</span>
            <clip-loader
              v-if="getSettingLoaders.saveTeamMember"
              :color="'#ffffff'"
              :size="'12px'"
            ></clip-loader>
          </button>
          <button
            v-else
            class="btn btn-studio-theme-space"
            data-cy="add-team"
            :disabled="getSettingLoaders.saveTeamMember"
            @click.prevent="saveTeamMember"
          >
            <span>Save</span>
            <clip-loader
              v-if="getSettingLoaders.saveTeamMember"
              :color="'#ffffff'"
              :size="'12px'"
            ></clip-loader>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss">
.dropdown_right {
  border-radius: 5px;
  font-size: 0.875rem;
  color: #3a4557;
  border: solid 1px rgba(152, 158, 181, 0.5);
  .dropdown_header {
    padding-bottom: 11px !important;
    box-shadow: 0 0 0px 1px rgba(152, 158, 181, 0.5);
  }
  .dropdown_header.disable_manage_dropdown {
    position: relative;
    &:after {
      content: '';
    }
  }
  .dropdown-menu:after,
  .dropdown-menu:before {
    display: none !important;
  }
  .dropdown-menu {
    margin-top: 10px;
    border-radius: 10px;
    li {
      position: relative;
      padding-right: 30px;
      padding: 7px 20px;
      i {
        position: absolute;
        right: 10px;
        color: #1ecb7b;
        top: 50%;
        transform: translateY(-50%);
      }
      p {
        font-weight: 500;
      }
      .role_description {
        font-size: 12px;
        color: #989eb5;
        font-weight: 400;
      }
      &:hover {
        background: #f4f7fa;
        cursor: pointer;
      }
    }
  }
}

.autoComplete {
  height: 100% !important;
}
</style>
