import { inject, nextTick, onUnmounted, ref } from 'vue'
import { useStore } from '@state/base'
import { EventBus } from '@common/lib/event-bus'
import { usePaddle } from '@modules/billing/composables/usePaddle'
import useWorkspace from '@common/composables/useWorkspace'
import { useRoute } from 'vue-router'
import proxy from '@common/lib/http-common'
import { billingBaseUrl } from '@src/config/api-utils'
import usePlatform from '@common/composables/usePlatform'
import usePermissions from '@common/composables/usePermissions'

const socialAccounts = ref(25) // Default value

export function useBilling() {
  const store = useStore()
  const { $bvModal } = inject('root')
  const { priceIds } = usePaddle()
  const { getSuperAdmin } = useWorkspace()
  const { showToast } = usePlatform()
  const { openCheckout } = usePaddle()
  const { hasPermission } = usePermissions()
  const route = useRoute()
  // Computed
  const isAgencyPlan = (planId = '') => planId.includes('agency')

  const calculatePrice = (plan, isAnnually, accounts = 25) => {
    const basePrice = isAnnually
      ? plan.billing.yearly.price
      : plan.billing.monthly.price

    return isAgencyPlan(plan.id)
      ? basePrice + getExtraCharge(accounts)
      : basePrice
  }

  const getExtraCharge = (accounts) => {
    if (!accounts || accounts <= 25) return 0
    let extraCharge = 0
    let remainingAccounts = accounts

    if (remainingAccounts > 500) {
      extraCharge += remainingAccounts - 500
      remainingAccounts = 500
    }
    if (remainingAccounts > 200) {
      extraCharge += (remainingAccounts - 200) * 2
      remainingAccounts = 200
    }
    if (remainingAccounts > 100) {
      extraCharge += (remainingAccounts - 100) * 3
      remainingAccounts = 100
    }
    if (remainingAccounts > 50) {
      extraCharge += (remainingAccounts - 50) * 4
      remainingAccounts = 50
    }
    if (remainingAccounts > 25) {
      extraCharge += (remainingAccounts - 25) * 5
    }
    return extraCharge
  }

  const calculateSocialAccountsTiers = (accounts) => {
    const tiers = []
    let remainingAccounts = accounts - 25 // Base accounts

    if (remainingAccounts <= 0) return tiers

    // Calculate tier 5 ($5 per account)
    if (remainingAccounts > 0 && remainingAccounts <= 25) {
      tiers.push({
        tier: 'tier5',
        quantity: remainingAccounts,
      })
      remainingAccounts = 0
    } else if (remainingAccounts > 25) {
      tiers.push({
        tier: 'tier5',
        quantity: 25,
      })
      remainingAccounts -= 25
    }

    // Calculate tier 4 ($4 per account)
    if (remainingAccounts > 0 && remainingAccounts <= 50) {
      tiers.push({
        tier: 'tier4',
        quantity: remainingAccounts,
      })
      remainingAccounts = 0
    } else if (remainingAccounts > 50) {
      tiers.push({
        tier: 'tier4',
        quantity: 50,
      })
      remainingAccounts -= 50
    }

    // Calculate tier 3 ($3 per account)
    if (remainingAccounts > 0 && remainingAccounts <= 100) {
      tiers.push({
        tier: 'tier3',
        quantity: remainingAccounts,
      })
      remainingAccounts = 0
    } else if (remainingAccounts > 100) {
      tiers.push({
        tier: 'tier3',
        quantity: 100,
      })
      remainingAccounts -= 100
    }

    // Calculate tier 2 ($2 per account)
    if (remainingAccounts > 0 && remainingAccounts <= 300) {
      tiers.push({
        tier: 'tier2',
        quantity: remainingAccounts,
      })
      remainingAccounts = 0
    } else if (remainingAccounts > 300) {
      tiers.push({
        tier: 'tier2',
        quantity: 300,
      })
      remainingAccounts -= 300
    }

    // Calculate tier 1 ($1 per account)
    if (remainingAccounts > 0) {
      tiers.push({
        tier: 'tier1',
        quantity: remainingAccounts,
      })
    }

    return tiers
  }

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const isCurrentPlan = (planId, isAnnually, changeTrialPlan = false) => {
    const subscription = store.getters?.getPlan?.subscription
    const planSlug = subscription?.slug

    if (!planSlug) return false

    if (changeTrialPlan) {
      return planSlug.includes(planId)
    }

    const billingPeriod = isAnnually ? 'annually' : 'monthly'
    const expectedSlug = `${planId}-${billingPeriod}`

    return planSlug === expectedSlug
  }

  const getPaddleCheckoutPayload = (plan, isAnnually, accounts = 25) => {
    const { items, discountCode } = generatePlanItems(
      plan,
      isAnnually,
      accounts
    )

    const payload = {
      items,
      customer: {
        email: store.getters.getProfile?.email,
      },
      customData: {
        user_id: getSuperAdmin()?._id,
      },
    }

    // Add discount code if available
    if (discountCode) {
      payload.discountCode = discountCode
    }

    return payload
  }

  // Listen for the event
  EventBus.$on('show-upgrade-modal', async (type = 'upgrade-plan') => {
    await showUpgradeModal(type)
  })

  // Clean up event listener
  onUnmounted(() => {
    EventBus.$off('show-upgrade-modal')
  })

  const showUpgradeModal = async (
    type = 'upgrade-plan',
    isSuperAdmin = false,
    newBillingModal = false
  ) => {
    const routeName = route.name
    if (
      ![
        'subscription_cancelled',
        'subscription_deleted',
        'subscription_paused',
        'trial_expired',
        'upgrade_subscription',
      ].some((name) => routeName.includes(name))
    ) {
      console.log('permissions', hasPermission('can_see_subscription'))
      if (!hasPermission('can_see_subscription') && !isSuperAdmin) return
    }

    if (
      store.getters?.getPlan?.subscription?.paddle_billing ||
      newBillingModal
    ) {
      $bvModal.show('subscription-plans-modal')
      await nextTick(() => {
        EventBus.$emit('set-subscription-modal-context', type)
      })
    } else {
      $bvModal.show('upgrade-plan-dialog')
    }
  }

  const hideUpgradeModal = () => {
    if (store.getters?.getPlan?.subscription?.paddle_billing) {
      $bvModal.hide('subscription-plans-modal')
    } else {
      $bvModal.hide('upgrade-plan-dialog')
    }
  }

  const isUpgrading = ref(false)

  const handleSubscriptionUpgrade = async (plan, isAnnually) => {
    if (!plan?.id) return

    isUpgrading.value = true

    try {
      const { items, planPriceId, discountCode } = generatePlanItems(
        plan,
        isAnnually,
        socialAccounts.value
      )
      const payload = {
        workspace_id: store.getters?.getActiveWorkspace?._id,
        plan_paddle_id: planPriceId,
        items,
      }

      // Add discount code if available
      if (discountCode) {
        payload.discountCode = discountCode
      }

      const { data } = await proxy.post(
        `${billingBaseUrl}paddle/billing/upgradeSubscription`,
        payload
      )

      if (data?.status) {
        showToast(data.message || 'Plan upgraded successfully', 'success')

        // Reload after successful upgrade
        setTimeout(() => {
          window.location.reload()
        }, 3000)
        return true
      } else {
        showToast(data.message || 'Failed to upgrade plan', 'error')
      }
    } catch (error) {
      console.error('Subscription upgrade error:', error)
      showToast(error.message || 'Failed to upgrade plan', 'error')
      return false
    } finally {
      isUpgrading.value = false
      $bvModal.hide('subscription-plans-modal')
    }
  }

  const handleTrialPlanChange = async (planId) => {
    if (!planId) return false

    try {
      const payload = {
        name: `trial-${planId}`,
        workspace_id: store.getters?.getActiveWorkspace?._id,
      }

      const { data } = await proxy.post(`${billingBaseUrl}trialChange`, payload)

      if (data?.status) {
        showToast(data.message, 'success')
        window.location.reload()
        return true
      } else {
        console.error(data?.message || 'Failed to change trial plan')
      }
    } catch (error) {
      console.error('Trial plan change error:', error)
      showToast(error.message || 'Failed to change trial plan', 'error')
      return false
    } finally {
      $bvModal.hide('subscription-plans-modal')
    }
  }

  const handlePlanChange = async (
    plan,
    isAnnually,
    changeTrialPlan = false
  ) => {
    // Check if it's a trial plan change
    if (changeTrialPlan) {
      return handleTrialPlanChange(plan.id)
    }

    // Check if user has an active subscription
    const hasActiveSubscription =
      store.getters?.getPlan?.subscription?.paddle_id &&
      store.getters.getProfile?.state === 'active'

    if (hasActiveSubscription) {
      // Handle plan upgrade for existing subscribers
      return handleSubscriptionUpgrade(plan, isAnnually)
    } else {
      // Handle new subscription via Paddle checkout
      const payload = getPaddleCheckoutPayload(
        plan,
        isAnnually,
        socialAccounts.value
      )
      return openCheckout(payload, 'v1')
    }
  }

  const generatePlanItems = (plan, isAnnually, accounts = 25) => {
    const billingCycle = isAnnually ? 'annually' : 'monthly'
    const planPriceId =
      priceIds[process.env.VUE_APP_ENVIRONMENT][plan.id][billingCycle]

    // Start with base plan item
    const items = [
      {
        price_id: planPriceId,
        quantity: 1,
      },
    ]

    // Add social accounts items for agency-unlimited plan
    if (isAgencyPlan(plan.id) && accounts > 25) {
      const tiers = calculateSocialAccountsTiers(accounts)
      tiers.forEach((tier) => {
        items.push({
          price_id:
            priceIds[process.env.VUE_APP_ENVIRONMENT]?.socialAccounts[
              billingCycle
            ][tier.tier],
          quantity: tier.quantity,
        })
      })
    }

    // Include discount code for annual plans
    const discountCode = isAnnually ? plan.billing.yearly.discountCode : null

    return {
      items,
      planPriceId,
      billingCycle,
      discountCode,
    }
  }

  return {
    socialAccounts,
    calculatePrice,
    calculateSocialAccountsTiers,
    formatPrice,
    isCurrentPlan,
    getPaddleCheckoutPayload,
    showUpgradeModal,
    hideUpgradeModal,
    handleTrialPlanChange,
    handlePlanChange,
  }
}
