import proxy from '@common/lib/http-common'
import { FETCH_BLOG_ERROR, UNKNOWN_ERROR } from '@common/constants/messages'
import { blog } from '@src/modules/integration/store/states/mutation-types.js'
import {
  fetchBlogsURL,
  fetchBlogURL,
  saveBlogURL,
  blogIntegrationsConfigrations,
  blogIntegrationsNames
} from '@src/modules/integration/config/api-utils.js'

const getDefaultBlogAuthorizationOptions = () => {
  const options = {}
  for (const key in blogIntegrationsConfigrations) {
    if (blogIntegrationsConfigrations[key].authorization_type === 'oauth2') {
      options[key] = null
    }
  }
  return options
}

const getDefaultBlogsDetails = () => {
  const options = {
    fetch_status: false,
    blog_details: getDefaultBlogDetails(),
    plugin_token: null
  }

  for (const key in blogIntegrationsConfigrations) {
    options[key] = { items: [] }
  }
  return options
}

const getDefaultBlogDetails = () => {
  const details = {}
  for (const key in blogIntegrationsConfigrations) {
    if (blogIntegrationsConfigrations[key].blog_details) {
      details[key] = JSON.parse(
        JSON.stringify(blogIntegrationsConfigrations[key].blog_details)
      )
    }
  }
  return details
}

const getDefaultLoaders = () => {
  return {
    fetch_blogs: false,
    fetch_blog: false
  }
}

export default {
  state: {
    authorization: getDefaultBlogAuthorizationOptions(),
    blogs: getDefaultBlogsDetails(),
    loaders: getDefaultLoaders()
  },
  getters: {
    getBlogAuthorization: (state) => state.authorization,
    getFetchBlogsStatus: (state) => state.blogs.fetch_status,
    getBlogs: (state) => state.blogs,
    getBlogDetails: (state) => state.blogs.blog_details,
    getPluginToken: (state) => state.blogs.plugin_token,
    getBlogsLoader: (state) => state.loaders
  },
  actions: {
    async fetchWSBlogs ({ commit, getters, rootGetters, dispatch }, payload) {
      console.debug('Method:fetchWSBlogs')
      return proxy
        .post(fetchBlogsURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            const dataContainer = []
            blogIntegrationsNames.forEach((type) => {
              if (response.data[type]) {
                dataContainer.push({ type, items: response.data[type] })
              }
            })

            return dataContainer
          }
          return []
        })
        .catch((error) => {
          console.debug('Exception in fetchBlogs', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          commit(blog.SET_FETCH_BLOGS_LOADER, false)
          return true
        })
    },

    async fetchBlogs ({ commit, getters, rootGetters, dispatch }) {
      console.debug('Method:fetchBlogs', getters.getWorkspaces.activeWorkspace)
      if (getters.getFetchBlogsStatus === false && getters.getWorkspaces.activeWorkspace !== false) {
        commit(blog.SET_FETCH_STATUS, true)
        commit(blog.SET_FETCH_BLOGS_LOADER, true)
        return proxy
          .post(fetchBlogsURL, {
            workspace_id: getters.getWorkspaces.activeWorkspace._id
          })
          .then((response) => {
            if (response.data.status === true) {
              blogIntegrationsNames.forEach((type) => {
                if (response.data[type]) {
                  commit(blog.SET_BLOGS, {
                    type,
                    items: response.data[type]
                  })
                }
              })
            }
            commit(blog.SET_FETCH_BLOGS_LOADER, false)
            return true
          })
          .catch((error) => {
            console.debug('Exception in fetchBlogs', error)
            dispatch('toastNotification', {
              message: UNKNOWN_ERROR,
              type: 'error'
            })
            commit(blog.SET_FETCH_BLOGS_LOADER, false)
            return true
          })
      }
      return true
    },

    async fetchBlog ({ commit, getters, rootGetters, dispatch }, payload) {
      console.debug('Method:fetchBlog')
      commit(blog.SET_FETCH_BLOG_LOADER, true)
      payload.workspace_id = getters.getWorkspaces.activeWorkspace._id
      return proxy
        .post(fetchBlogURL, payload)
        .then((response) => {
          commit(blog.SET_FETCH_BLOG_LOADER, false)
          if (response.data.status === true) {
            if (payload.type === 'wordpress') {
              commit(blog.SET_BLOG_DETAILS, {
                type: payload.type,
                details: {
                  platform_identifier: response.data.blog.platform_identifier,
                  url: response.data.blog.platform_url,
                  username: response.data.blog.platform_username,
                  password: response.data.blog.platform_password,
                  connection_type: response.data.blog.platform_connection_type
                }
              })
            }
            return true
          }
          dispatch('toastNotification', {
            message: FETCH_BLOG_ERROR,
            type: 'error'
          })
          return false
        })
        .catch((error) => {
          console.debug('Exception in fetchBlog', error)
          commit(blog.SET_FETCH_BLOG_LOADER, false)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return false
        })
    },

    async saveBlog ({ commit, getters, rootGetters, dispatch }, payload) {
      console.debug('Action:saveBlog')
      return proxy
        .post(saveBlogURL, payload)
        .then((response) => {
          return response
        })
        .catch((error) => {
          console.debug('Exception in saveBlog', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return false
        })
    },

    setDefaultBlogStates ({ commit, getters, rootGetters, dispatch }) {
      commit(blog.SET_FETCH_STATUS, false)
      commit(blog.SET_BLOG_DETAILS, null)
      commit(blog.SET_PLUGIN_TOKEN, null)
      commit(blog.SET_FETCH_BLOGS_LOADER, false)
      commit(blog.SET_FETCH_BLOG_LOADER, false)
      blogIntegrationsNames.forEach((type) => {
        commit(blog.SET_BLOGS, { type, items: [] })
      })
    }
  },
  mutations: {
    [blog.SET_AUTHORIZATION] (state, authorization) {
      if (!authorization) {
        state.authorization = getDefaultBlogAuthorizationOptions()
        return false
      }

      for (const type in blogIntegrationsConfigrations) {
        if (
          blogIntegrationsConfigrations[type].authorization_type === 'oauth2'
        ) {
          if (type === 'tumblr') {
            if (authorization.tumblr && authorization.tumblr.link) {
              state.authorization.tumblr = authorization.tumblr.link
              continue
            }
            state.authorization.tumblr = ''
            continue
          }
          if (type === 'webflow') {
            if (authorization.webflow && authorization.webflow.link) {
              state.authorization.webflow = authorization.webflow.link
              continue
            }
            state.authorization.webflow = ''
            continue
          }
          state.authorization[type] = authorization[type]
        }
      }
    },

    [blog.SET_FETCH_STATUS] (state, status) {
      state.blogs.fetch_status = status
    },

    [blog.SET_BLOGS] (state, blog) {
      state.blogs[blog.type].items = blog.items
    },

    [blog.SET_BLOG_DETAILS] (state, item) {
      console.debug('blog.SET_BLOG_DETAILS', item, getDefaultBlogDetails())
      if (item) {
        state.blogs.blog_details[item.type] = item.details
        return false
      }
      state.blogs.blog_details = getDefaultBlogDetails()
    },

    [blog.SET_PLUGIN_TOKEN] (state, token) {
      state.blogs.plugin_token = token
    },

    [blog.SET_FETCH_BLOGS_LOADER] (state, status) {
      state.loaders.fetch_blogs = status
    },

    [blog.SET_FETCH_BLOG_LOADER] (state, status) {
      state.loaders.fetch_blog = status
    }
  }
}
