<template>
  <div
id="onboading-widget" v-click-away="closeDropdownFixed"
       class="getting-started-wrapper select-none bottom-4 left-8"
       :class="{ 'hidden': $route.path.includes('/planner/') || $route.path.includes('/composer/blog/') || $route.path.includes('/settings/') }">
    <div v-if="true" class="help_dropdown_fixed_inner">
      <div
          class="dropdown-icon px-3 d-flex align-items-center justify-content-center"
          @click.prevent="toggleDropdownFixed"
      >
        <div class="flex justify-center items-center px-0.5">

          <i v-if="!toggleDropdownFixedStatus" class="fas fa-user-check w-5 text-center text-base" ></i>
          <i  v-else class="fas fa-times text-lg w-5  text-center"></i>

          <div  class="dropdown-icon-text flex-1 ml-2.5 text-base font-semibold" :class="{ 'dropdown-icon-text__hide': toggleDropdownFixedStatus }">
            {{ onBoardingProgressValue !== 100 ? 'Get Started' : 'Completed'}}
          </div>

        </div>

      </div>

      <transition name="fade">
        <div
v-if="!toggleDropdownFixedStatus && remainingChecks" class=" absolute -right-2 -top-2.5
        bg-red-500 flex items-center justify-center font-bold text-gray-100
        h-7 w-7 text-sm
        rounded-full

      ">
          {{ remainingChecks }}
        </div>
      </transition>


      <transition name="slide-bottom">
         <div
          v-if="toggleDropdownFixedStatus"
          class="dropdown_content shadow-2xl mb-3 bottom-16"
          style="min-width: 24.5rem;pointer-events: auto;"
      >
        <div class="title-wrapper">
          <h4 class="main-title ">Let's get you started with {{appData.businessName}}</h4>
          <div class="w-full bg-white rounded-full mt-3 overflow-hidden">
            <div
class="progress-bar text-xs font-medium text-center p-0.3 py-0.5 leading-none rounded-full"
                 :style="{ width: onBoardingProgressValue + '%' }"> {{onBoardingProgressValue}}%
            </div>
          </div>
        </div>

        <div ref="main-content-wrapper" class="main-content-wrapper">

          <div class="steps-wrapper-box">
            <div class="steps-wrapper hover:bg-gray-100 hover:bg-opacity-50 rounded px-3 py-2" :class="{ 'completed': getActiveWorkspace.onboarding_steps.connect_social_account.status }">
              <div
class="steps-icon-wrapper"
                   :class="{ 'shadow-2xl': getActiveWorkspace.onboarding_steps.connect_social_account.status }">
                <i v-if="getActiveWorkspace.onboarding_steps.connect_social_account.status" class="fas fa-check"></i>
              </div>
              <div class="steps-content cursor-pointer" @click="handleClick('connect_social_account')">
                <h4 class="title colored-text">
                  Connect social accounts
                </h4>
                <p class="description">Easily manage all your social channels in one place.</p>

              </div>
              <div class="connector"></div>
            </div>

            <div
class="steps-wrapper hover:bg-gray-100 hover:bg-opacity-50 rounded px-3 py-2"
                 :class="{ 'completed': getActiveWorkspace.onboarding_steps.create_first_post.status }">
              <div
class="steps-icon-wrapper"
                   :class="{ 'shadow-2xl': getActiveWorkspace.onboarding_steps.create_first_post.status }">
                <i v-if="getActiveWorkspace.onboarding_steps.create_first_post.status" class="fas fa-check"></i>
              </div>
              <div class="steps-content cursor-pointer"  @click="handleClick('create_first_post')" >
                <h4 class="title colored-text link">Create your first post!</h4>
                <p class="description">Customize content for each network and schedule at the recommended best times.</p>
              </div>
              <div class="connector"></div>
            </div>

            <div class="steps-wrapper hover:bg-gray-100 hover:bg-opacity-50 rounded px-3 py-2" :class="{ 'completed': getActiveWorkspace.onboarding_steps?.content_category?.status }">
              <div class="steps-icon-wrapper">
                <i v-if="getActiveWorkspace.onboarding_steps?.content_category?.status" class="fas fa-check"></i>
              </div>
              <div class="steps-content cursor-pointer"  @click="handleClick('content_category')">
                <h4 class="title colored-text">
                  Create publishing queues
                </h4>
                <p class="description">Using content categories create a plan for publishing different types of content.</p>

              </div>
              <div class="connector"></div>
            </div>

            <div class="steps-wrapper hover:bg-gray-100 hover:bg-opacity-50 rounded px-3 py-2" :class="{ 'completed': getActiveWorkspace.onboarding_steps?.discover_content?.status }">
              <div class="steps-icon-wrapper">
                <i v-if="getActiveWorkspace.onboarding_steps?.discover_content?.status" class="fas fa-check"></i>
              </div>
              <div class="steps-content cursor_pointer"  @click="handleClick('discover_content')">
                <h4 class="title colored-text">
                  Discover engaging content
                </h4>
                <p class="description">Curate highly-relevant and trending 3rd-party content to keep your audience engaged.</p>
              </div>
              <div v-if="getPlan.subscription.limits && getPlan.subscription.limits.members > 0" class="connector"></div>
            </div>

            <div
v-if="getPlan.subscription.limits && getPlan.subscription.limits.members > 0"
                 class="steps-wrapper  hover:bg-gray-100 hover:bg-opacity-50 rounded px-3 py-2"
                 :class="{ 'completed': getActiveWorkspace.onboarding_steps.invite_team.status }"
            >
              <div class="steps-icon-wrapper">
                <i v-if="getActiveWorkspace.onboarding_steps.invite_team.status" class="fas fa-check"></i>
              </div>
              <div class="steps-content cursor_pointer"  @click="handleClick('invite_team')">
                <h4 class="title colored-text">
                  Invite your team members
                </h4>
                <p class="description">Collaborate with team members and get content approval from clients</p>
              </div>
            </div>
          </div>

          <div class="bottom-content-wrapper flex justify-between px-3">
            <div class="left-content">
              <p>
                <span class="font-normal mr-2">Need Help?</span>
                <a
class="btn colored-text p-0 hover:underline" target="_blank"
                   href="https://contentstudio.io/book-a-demo/">
                  <strong>Book a demo</strong>
                </a>
              </p>
            </div>
            <div class="right-content">
              <p>
                <a
class="btn text-gray-800 hover:text-gray-900 p-0"
                   @click.prevent="showConfirmationModal">
                  <strong>Don't show again</strong></a>
              </p>
            </div>
          </div>
        </div>
      </div>
      </transition>

    </div>
    <OnboardingConfirmation/>
  </div>
</template>

<script>
import party from 'party-js'
import { mapGetters } from 'vuex'
import OnboardingConfirmation from "@src/modules/onboarding/components/OnboardingConfirmation";
import {EventBus} from "@common/lib/event-bus";
import useSocialAccountModal from '@/src/modules/account/composables/useSocialAccountsModal'
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
export default {
  components: {
    OnboardingConfirmation
  },
  setup(){
    const {appData} = useWhiteLabelApplication()
    const {buttonText} = useSocialAccountModal()

    return {
      buttonText,
      appData,
    }
  },
  data() {
    return {
      toggleDropdownFixedStatus: false,
      isFirstModalOpen: true
    }
  },
  computed: {
    ...mapGetters(['getAppStatus', 'getPlan', 'getPublications', 'getContentCategoryList', 'getTopicList', 'getWorkspaces', 'getActiveWorkspace']),

    remainingChecks () {
      let count = 0;
      if (this.getActiveWorkspace.onboarding_steps) {
        for (const property in this.getActiveWorkspace.onboarding_steps) {
          if (this.getActiveWorkspace.onboarding_steps[property].status === false &&
            //! not including count of these 2 steps in get started widget. remove watch_video once video is ready
            !['watch_video', 'accounts_connection_modal_closed'].includes(property)) {
            if (property === 'invite_team') {
              if (this.getPlan.subscription.limits && this.getPlan.subscription.limits.members > 0) {
                count++;
              }
            } else {
              count++;
            }

          }
        }
      }
      return count;
    }
  },
  watch:{
    $route (to, from){
      if (this.toggleDropdownFixedStatus) {
        this.toggleDropdownFixedStatus = false;
      }
    },
    'getActiveWorkspace.onboarding_steps.watch_video.status' (val) {
       if (val) {
        if(this.getActiveWorkspace.onboarding_steps?.accounts_connection_modal_closed &&
          this.getActiveWorkspace.onboarding_steps.accounts_connection_modal_closed.status) {
          if(this.buttonText !== 'Close' && this.buttonText !== 'Continue') {
            this.$bvModal.hide('get-started-modal')
          }
        }
       } else {
        //  this.$bvModal.show('get-started-modal')
       }
    },
    'onBoardingProgressValue'(newVal, oldVal) {
      console.log('onBoardingProgressValue', newVal)
      if (this.getActiveWorkspace.onboarding_steps && newVal === 100) {

        this.onboardingParty()

        setTimeout(() => {
          this.onboardingCompleted()
        }, 1500)
      }
    }
  },

  mounted () {

    EventBus.$on('close-widget', () => {
      if (this.toggleDropdownFixedStatus) {
        this.toggleDropdownFixedStatus = false;
      }
    })

    this.$nextTick(() => {
      this.showModal()
    })


    this.fetchPublications()
    this.actionFetchTopicsAndFavorites()
    // this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
    //   if (modalId === 'removeOnboardingWidget') this.$refs["main-content-wrapper"].style.pointerEvents = "none"
    // })



    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'get-started-modal') {

        const steps = this.getActiveWorkspace.onboarding_steps

        if(!steps?.watch_video?.status) {
          this.toggleDropdownFixedStatus = true
        }

        if(!steps?.accounts_connection_modal_closed?.status) {
          this.toggleDropdownFixedStatus = true
        }

        if(this.isFirstModalOpen) {
          this.toggleDropdownFixedStatus = true
          this.isFirstModalOpen = false
          return
        }

        this.toggleDropdownFixedStatus =false
      }
    })
  },
  beforeUnmount() {
    EventBus.$off('close-widget')
  },
  methods: {
    showModal () {
      const steps = this.getActiveWorkspace.onboarding_steps

      if (
          steps !== undefined &&
          steps.accounts_connection_modal_closed !== undefined &&
          steps.accounts_connection_modal_closed.status === false
      ) {
        // this.$bvModal.show('get-started-modal')
      }
    },
    onboardingParty() {
      if (document.getElementById('onboading-widget')) {
        party.confetti(document.getElementById('onboading-widget'), {
          count: party.variation.range(70, 150),
          size: party.variation.range(0.6, 1.2),
          spread: party.variation.range(10, 40),
        });
      }
    },
    handleClick (step = null) {
      if (step) {
        switch (step) {
          case 'connect_social_account':
            this.trackUserMaven('onboarding_widget_click_connect_social_accounts')

            this.$router.push({
              name: 'social',
              params: {
                workspace: this.getWorkspaces.activeWorkspace.slug,
              },
            })
            break;
          case 'create_first_post':
            this.trackUserMaven('onboarding_widget_click_create_first_post')

            this.changePublishType('Composer Social')
            break;
          case 'content_category':

            this.trackUserMaven('onboarding_widget_click_content_category')

            this.$router.push({
              name: 'content_categories',
              params: {
                workspace: this.getWorkspaces.activeWorkspace.slug,
              },
            })
            break;
          case 'discover_content':

            this.trackUserMaven('onboarding_widget_click_discover_content')

            this.$router.push({
              name: 'discover_search',
              params: {
                workspace: this.getWorkspaces.activeWorkspace.slug,
              },
            })
            break;
          case 'invite_team':
            this.trackUserMaven('onboarding_widget_click_invite_team')

            this.$router.push({
              name: 'team',
              params: {
                workspace: this.getWorkspaces.activeWorkspace.slug,
              },
            })
            break;
        }
      }
    },
    toggleDropdownFixed() {
      this.toggleDropdownFixedStatus = !this.toggleDropdownFixedStatus
    },
    closeDropdownFixed() {
      if (this.toggleDropdownFixedStatus ) {
        this.toggleDropdownFixedStatus = false

      }
    },
    showConfirmationModal() {
      this.toggleDropdownFixedStatus = false
      this.$bvModal.show('removeOnboardingWidget')
    },
  }
}
</script>

<style lang="scss" scoped>
  .getting-started-wrapper {
    position: fixed;
    // left: 40px;
    // bottom: 58px;
    z-index: 1040;

    .dropdown-icon {
      height: 45px;
      min-width: 55px;
      border-radius: 50px;
      text-align: center;
      -webkit-box-shadow: 0 0 0 2px #2f8ae0;
      box-shadow: 0 0 0 2px #2f8ae0;
      cursor: pointer;
      @apply bg-blue-500;
      // background: #436aff;
      // background: -webkit-gradient(linear, left top, right top, from(#436aff), to(#6473ff));
      // background: linear-gradient(to right, #436aff, #6473ff);
      -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
      border: 0;
      color: #fff;

      .dropdown-icon-text {
        transition: 0.3s all ease;
        width: 83px;
        white-space: nowrap;

        &__hide {
          width: 0px;
          overflow: hidden;
          margin: 0;
        }
      }
    }
    .help_dropdown_fixed_inner {
      position: relative;
    }

    .help-dropdown-icon {
      border: 1px solid #2f8ae0;
      background: #f5f5f5 !important;
    }

    .dropdown_content {
      pointer-events: none;
      position: absolute;
      // bottom: 58px;
      right: 6px;
      min-width: 12.625rem;

      background: #fff;
      width: 23.5rem;
      border-radius: 20px;
      left: 0;

      .title-wrapper {
        @apply bg-blue-500;
        // background: #436aff;
        // background: -webkit-gradient(linear, left top, right top, from(#436aff), to(#6473ff));
        // background: linear-gradient(to right, #436aff, #6473ff);
        padding: 20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        .progress-bar {
          @apply bg-blue-200;
          color: #fff;
        }
      }

      p {
        font-size: .8rem;
        line-height: 1rem;
        color: #6a6a6b;
      }

      .colored-text {
        color: #3a4557;
        a {
          color: #3a4557;
        }
      }

      .main-title {
        font-weight: 600;
        font-size: 1rem;
        color: #fff;
      }

      .main-content-wrapper {
        padding:  0.7rem 0.4rem  0.8rem  0.4rem;

        .steps-wrapper-box {



          .steps-wrapper {
            display: flex;
            position: relative;

            &:last-child {
              margin-bottom: 10px;
            }

            .steps-icon-wrapper {
              width: 2.8rem;
              height: 1.9rem;
              background: rgba(38, 40, 44, 0.1);
              border-radius: 50%;
              margin-top: 18px;

              i {
                padding: 9px;
                font-weight: 300;
              }
            }

            .steps-content {
              margin-left: 1rem;
              width: 27rem;
              padding-right: 3rem;
              padding-top: 0.3rem;

              .title {
                font-size: 1rem;
                margin-bottom: 0.3rem;
              }

              .title.link {
                cursor: pointer;
              }
            }

            .connector {
              flex-grow: 1;
              width: 2.9rem;
              content: "";
              display: block;
              height: 1px;
              background-color: #e5e7eb;
              position: absolute;
              transform: rotate(270deg);
              top: 3.8rem;
              left: 0.5rem;
              margin-top: 18px;
            }
          }

        }



        .steps-wrapper.completed {
          .steps-icon-wrapper {
            // background: #40CCB9;
            @apply bg-blue-200;

            i {
              @apply -mt-0.5;
              color: #fff;
            }
          }

          .steps-content {
            .colored-text {
              color: #26282c;

              a {
                color: #26282c;
              }
            }

            p {
              color: #6a6a6b;
            }
          }
        }

        .bottom-content-wrapper {
          border-top: 1px solid #f3f4f6;
          padding-top: 15px;

          p {
            font-size: 0.9rem;
            font-weight: 500;
            color: inherit;
          }

          a {
            color: #2f8ae0;
            font-size: 0.9rem;
          }

          .right-content {
            a {
              color: rgba(38, 40, 44, 0.5);
            }
          }
        }

        .colored-text.disabled {
          pointer-events: none;
        }
      }
    }
  }
</style>
