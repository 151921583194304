<script>
import { mapGetters } from 'vuex'
import {
  BLOG_NULL_STORE_ERROR,
  BLOG_NULL_API_KEY_ERROR,
  BLOG_NULL_API_SECRET_ERROR
} from '@common/constants/messages'
import useIntegrationComposable from "@modules/integration/Composables/useIntegrationComposable";

export default {
  setup(){
    const {getAuthorizationUrl} = useIntegrationComposable()
    return {getAuthorizationUrl}
  },
  data () {
    return {
      store: '',
      api_key: '',
      api_secret: '',
      validations: {
        store: false,
        api_key: false,
        api_secret: false
      },
      messages: {
        store: BLOG_NULL_STORE_ERROR,
        api_key: BLOG_NULL_API_KEY_ERROR,
        api_secret: BLOG_NULL_API_SECRET_ERROR
      }
    }
  },
  computed: {
    ...mapGetters(['getWorkspaces', 'getBlogAuthorization']),
    isEmptyStore () {
      return this.validations.store
    },
    isEmptyApiKey () {
      return this.validations.api_key
    },
    isEmptyApiSecret () {
      return this.validations.api_secret
    }
  },
  watch: {
    store (value) {
      if (value && value.length > 0) {
        console.debug('Watch::store', value)
        this.validations.store = false
      }
    },
    api_key (value) {
      if (value && value.length > 0) {
        console.debug('Watch::api_key', value)
        this.validations.api_key = false
      }
    },
    api_secret (value) {
      if (value && value.length > 0) {
        console.debug('Watch::api_secret', value)
        this.validations.api_secret = false
      }
    }
  },
  methods: {
    hideModal () {
      this.$bvModal.hide('manage_shopify_store_modal')
      return false
    },
    async processStoreConnection () {
      console.debug('Method:processStoreConnection')
      this.validations.store = this.requiredCheck(this.store)
      this.validations.api_key = this.requiredCheck(this.api_key)
      this.validations.api_secret = this.requiredCheck(this.api_secret)
      const result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      )
      if (result) {
        // if (this.isValidUrl(this.store)) return this.alertMessage('Please enter your shopify store subdomain or storename.', 'error')
        if (this.checkSubscriptionLimits('blogs')) return false
        const details = {
          process: 'connect',
          type: 'shopify',
          api_key: this.api_key,
          api_secret: this.api_secret,
          store: this.store,
        }

        this.trackUserMaven('connect_shopify', {
          store: this.store,
          api_key: this.api_key,
          api_secret: this.api_secret
        })

        const baseUrl = window.location.href.split('#')[0];
        details.callback_url = `${baseUrl}#shopify`;

        await this.getAuthorizationUrl({
          platform: 'shopify',
          connectionDetails: details,
        })
      }
    }
  }
}
</script>

<template>
  <b-modal
    id="manage_shopify_store_modal"
    modal-class="manage_shopify_store_modal"
    hide-footer
    hide-header
  >
    <div class="modal_head">
      <h4>Add your Shopify Store</h4>
      <button type="button" class="modal_head__close" @click="hideModal"
        >&times;</button
      >
    </div>

    <div class="modal_body basic_form m_t_15">
      <div class="row">
        <div class="col-sm-12">
          <div class="warning_box text-center">
            <i class="far fa-question-circle"></i>
            <a
              href="https://docs.contentstudio.io/article/682-how-to-integrate-shopify"
              target="_blank"
              class="insta-link"
              >Get started with this help article</a
            >
            to integrate your Shopify blog/store in ContentStudio.
          </div>
        </div>
      </div>
      <br />

      <div class="row">
        <div class="col-sm-12 input_field no_margin">
          <!--<label for="">Type in the ID of your Shopify store</label>-->
          <div class="shopify-connect">
            <span style="width: 15%">https://</span>
            <div class="input_validation">
              <input
                v-model="store"
                :class="{ 'input-field-error': validations.store }"
                type="text"
                placeholder="Your store name/url e.g contentstudio"
                @keyup.enter="processStoreConnection"
              />
              <span v-if="isEmptyStore" class="input-error">{{
                messages.store
              }}</span>
            </div>
            <span style="width: 30%">.myshopify.com</span>
          </div>
        </div>
      </div>
      <br />
      <div class="row api_body">
        <div class="col-md-6 col-sm-12">
          <div class="field_group">
            <input
              id="apiKey"
              v-model="api_key"
              :class="{ 'input-field-error': validations.api_key }"
              type="text"
              placeholder="Api Key..."
            />
            <label for="apiKey" class="label-animated">Client ID</label>
            <div class="input_validation">
              <span v-if="isEmptyApiKey" class="input-error">{{
                messages.api_key
              }}</span>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="field_group">
            <input
              id="apiSecret"
              v-model="api_secret"
              :class="{ 'input-field-error': validations.api_secret }"
              type="text"
              placeholder="Api Secret..."
            />
            <label for="apiSecret" class="label-animated">Client Secret</label>
            <div class="input_validation">
              <span v-if="isEmptyApiSecret" class="input-error">{{
                messages.api_secret
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 field_group mt-4">
          <button
            class="btn btn-studio-theme-space"
            @click.prevent="processStoreConnection"
          >
            <span>Connect</span>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style lang="less">
.manage_shopify_store_modal {
  .modal-dialog:not(.modal-xl) {
    max-width: 680px;
  }
}
.shopify-connect {
  background: #f5f5f6;
  padding: 5px 5px;
  border-radius: 10px;
  display: -webkit-flex;
  width: 100%;
  .input_validation {
    width: 55% !important;
  }
  input {
    background: white !important;
    border-radius: 10px !important;
    padding: 1rem 1.5rem;
    border: 0 !important;
    &:hover {
    }
  }
  span {
    padding: 0.625rem;
    align-self: center;
    color: #585858;
    font-weight: 500;
    width: fit-content;
    padding: 0rem 1rem;
  }
}
.api_body {
  .input_field {
    margin-top: 0px !important;
    label {
      font-size: 1em;
      padding: 0px 3px;
    }
  }
}
.manage_shopify_store_modal {
  .modal-content {
    border: none;
  }
  .modal-body,
  .modal_body {
    padding: 1rem;
  }
  .warning_box {
    i {
      float: left;
    }
  }
}
</style>
