<template>
  <div class="billing-upgrade-page bg-white pt-5">
    <template v-if="is_loading_direct_upgrade">
      <beat-loader :color="'#6173ff'"></beat-loader>
    </template>
    <template v-else>
      <div class="billing-close-button">
        <b-button
          variant="studio-theme-transparent-grey"
          class="mr-4 mt-3"
          @click="$router.push({ name: 'workspaces' })"
        >
          Go to workspaces
        </b-button>
      </div>
      <div class="billing-main-heading">
        <h2>Hey {{ $store.getters.getProfile.full_name }}!</h2>
        <p>Upgrade your subscription plan to get more features.</p>
      </div>
      <SubscriptionPlansMain
          custom-class="!overflow-visible !h-auto !w-full"
          :hide-close="true"
      />
    </template>
  </div>
</template>

<script>
import SubscriptionPlansMain from "@modules/billing/components/SubscriptionPlansMain.vue";
import {commonMethods} from "@common/store/common-methods";

export default {
  name: 'DirectUpgradePlan',
  components: {
    SubscriptionPlansMain,
  },
  data () {
    return {
      is_loading_direct_upgrade: true
    }
  },
  computed: {},
  created () {},
  async mounted () {
    commonMethods.toggleAiChat(true)
    await this.fetchWorkspaces()
    await this.Plan()
    this.is_loading_direct_upgrade = false
  },
  unmounted() {
    commonMethods.toggleAiChat()
  },
  methods: {}
}
</script>
