<template>
  <div class="block w-full cst-drop-shadow rounded-xl mb-3 scroll-snap-start overflow-y-hidden bg-white">
    <div v-if="!togglePreview" class="flex w-full items-center px-3 py-3">
      <div
          class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-blue"
      >
        <img
            src="@assets/img/integration/facebook-icon.svg"
            alt=""
            class="w-5"
        />
      </div>
    </div>
    <div class="relative text-white">
      <!--   background   -->
      <b-carousel
          v-if="detail?.multimedia?.length"
          ref="fb_carousel"
          class="instagram-preview-carousel cursor-pointer"
          :interval="0"
          :no-wrap="false"
          :controls="detail?.multimedia?.length > 1"
          @sliding-end="onSlideChange"
      >
        <b-carousel-slide
            v-for="(image, index) in detail.multimedia"
            :key="index"
            class="relative"
        >
          <template v-slot:img>
            <div
                class="!bg-[#020B12] ratio ratio-9x16 group"
                :class="{ 'preview-background': getVideoThumbnail(image) }"
                @click="changeVideoState(getMediaId(image, index))"
            >
              <template v-if="typeof image === 'string'" >
                <div class="flex items-center justify-center">
                  <img :id="`image${index}`" ref="fb_mediaRefs"  class="w-full max-h-full object-cover" :src="image" alt="thumbnail" />
                </div>
              </template>
              <template v-else>
                <video
                      ref="fb_mediaRefs"
                      :id="`video${index}`"
                      :key="image.link"
                      class="cursor-pointer"
                      width="100%"
                      height="100%"
                  >
                    <source :src="image?.converted_video ? image.converted_video : image.link" type="video/mp4" />
                  </video>
                <i
                      class="fas bg-[#0000007a] active:bg-gray-200/50 p-2 rounded-full playback-icon w-8 h-8 flex items-center justify-center absolute top-1/2 left-[48%] group-hover:!opacity-100"
                      :class="[playBackIcon(`video${index}`), state[`video${index}`].shouldHideIcon && 'opacity-0']"
                  ></i>
              </template>
            </div>
          </template>
          <div></div>
        </b-carousel-slide>
      </b-carousel>

      <!--   header   -->
      <div class="absolute top-px left-0 w-full text-xl text-white py-5">
        <div class="flex items-center w-full">
          <div :class="{'mr-[7px]': detail.multimedia.length === index + 1}" v-for="(item, index) in detail.multimedia" class="h-0.5 w-full bg-[#3C4549] rounded-[2px] mb-[10px] ml-[7px]">
            <div
                :id="getMediaId(item, index)"
                ref="fb_progressBarRefs"
                class="h-0.5 bg-[#757A8A] rounded-[2px] mb-[10px]"
                :style="getVideoThumbnail(item) ? { width: getProgressWidth(getMediaId(item, index)) } : { width: state[getMediaId(item, index)].currentTime }"
                :class="[getVideoThumbnail(item) ? 'progress-bar-video' : 'progress-bar-image']"
            >
            </div>
          </div>
        </div>

        <div class="flex justify-between items-center px-5">
          <div class="flex items-center gap-2">
            <img
                :src="accountImage"
                alt=""
                class="rounded-full object-cover w-[34px] h-[34px]"
                @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <p class="select-none text-xs font-semibold">
              {{ accountName }}
            </p>
          </div>


          <div class="flex items-center gap-5">
            <i class="far fa-ellipsis-h text-xl w-6 h-6 flex justify-center items-center text-[#D2D5DF]"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  onBeforeUnmount,
  reactive,
  ref, watch,
  nextTick
} from 'vue'

const props = defineProps({
  accountName: {
    type: String,
    default: '',
    required: true,
  },
  accountImage: {
    type: String,
    required: true,
  },
  detail: {
    type: Object,
    default: () => ({}),
  },
  description: {
    type: String,
    default: '',
  },
  facebookPostType: {
    type: String,
    default: '',
  },
  togglePreview: {
    type: Boolean,
    default: false,
  },
})


const fb_progressBarRefs = ref([])
const fb_mediaRefs = ref([])
const state = reactive({})
const fb_isFirstCall = ref(true)

/**
 * returns the media id
 * @param {string | object} item
 * @returns {string}
 */
const getMediaId = (item, index) => {
  return typeof item === 'string' ? `image${index}` : `video${index}`
}

// onCreated
const initializeState = () => {
  fb_progressBarRefs.value.forEach(item => {
    item.classList.add('no-transition')
  })

  props.detail?.multimedia?.forEach((media, index) => {
    const mediaId = getMediaId(media, index)
    state[mediaId] = {
      isVideoPlaying: false,
      shouldHideIcon: false,
      currentTime: 0,
      duration: 0
    }
  })
}

// Watch for changes in detail.multimedia
watch(() => props.detail.multimedia, async () => {

  for (const key in state) {
    delete state[key]
  }

  initializeState()
  await nextTick(() => {
    fb_mediaRefs.value?.forEach((item, index) => {
          if (item?.id?.includes('video')) {
            item.addEventListener('timeupdate', () => handleTimeUpdate(item, item.id))
          }
        }
    )

    if (typeof props.detail.multimedia[0] === 'string') {
      fb_progressBarRefs.value[0]?.classList?.remove('no-transition')
      state[`image${0}`].currentTime = '0'
      setTimeout(() => {
        state[`image${0}`].currentTime = '100%'
      }, 1000)
    }

  })

  fb_isFirstCall.value = false
}, {immediate: true, deep: true})

// methods

const getProgressWidth = (mediaId) => {
  if (mediaId?.includes('video') && state[mediaId]?.currentTime && state[mediaId]?.duration) {
    const progress = (state[mediaId].currentTime / state[mediaId].duration) * 100

    if (progress === 100) {
      state[mediaId].isVideoPlaying = false
    }

    return `${progress}%`
  }
  return '0%'
}


// returns the playback icon on basis of video state
const playBackIcon = (mediaId) => {
  return state[mediaId].isVideoPlaying ? 'fa-pause' : 'fa-play'
}

const onSlideChange = (index) => {
  fb_progressBarRefs.value[index].classList.remove('no-transition')
  const media = props.detail.multimedia[index]
  if (typeof media === 'string') {
    state[`image${index}`].currentTime = '100%'
  }
}

// handles the time update event of video player
const handleTimeUpdate = (videoPlayer, mediaId) => {
  if (videoPlayer) {
    state[mediaId].currentTime = videoPlayer.currentTime
    state[mediaId].duration = videoPlayer.duration
  }
}

// handles the click event of video player
const changeVideoState = (mediaId) => {
  const videoElement = fb_mediaRefs.value.find(item => item.id === mediaId)

  if (videoElement && videoElement.paused) {
    // If video is paused, play it
    videoElement.play()
    state[mediaId].isVideoPlaying = true
    setTimeout(() => (state[mediaId].shouldHideIcon = true), 700)
  } else if (videoElement) {
    // If video is playing, pause it
    videoElement.pause()
    state[mediaId].isVideoPlaying = false
    state[mediaId].shouldHideIcon = false
  }
}

// on before component gets unmounted
onBeforeUnmount(() => {
  fb_mediaRefs.value.forEach((item, index) => {
    item.removeEventListener('timeupdate', () => handleTimeUpdate(item, item.id))
  })
})

const getVideoThumbnail = (item) => {

  if (typeof item === 'string') {
    return false
  }

  let thumbnail = item.thumbnail
  if (props.facebookPostType !== 'feed')
      // custom thumbnail is supported only for reels
    return thumbnail
  // If the thumbnail url includes the /media_library/, then it is a custom thumbnail.
  // If the thumbnail url includes the ?offset= then it is 'choose from video' thumbnail.
  // If its custom thumbnail and not 'choose from video' then use the first thumbnail
  // from the suggestions as custom thumbnail is not supported in instagram.
  if (
      thumbnail.indexOf('/media_library/') > -1 &&
      thumbnail.indexOf('?offset=') === -1
  ) {
    thumbnail = item.thumbnails_suggestions[0]
  }
  return thumbnail
}
</script>

<style lang="scss" scoped>
.playback-icon {
  transition: opacity 0.5s ease;
}

.progress-bar-video {
  transition: width 0.3s ease;
}

.progress-bar-image {
  transition: width 5s ease;
}

.no-transition {
  transition: none;
}
</style>
