<template>
  <div>
    <div
      v-if="loading"
      class="p-6 grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4"
    >
      <SkeletonBox class="!h-[10rem] !w-80" />
      <SkeletonBox class="!h-[10rem] !w-80" />
      <SkeletonBox class="!h-[10rem] !w-80" />
      <SkeletonBox class="!h-[10rem] !w-80" />
      <SkeletonBox class="!h-[10rem] !w-80" />
      <SkeletonBox class="!h-[10rem] !w-80" />
      <SkeletonBox class="!h-[10rem] !w-80" />
      <SkeletonBox class="!h-[10rem] !w-80" />
      <SkeletonBox class="!h-[10rem] !w-80" />
      <SkeletonBox class="!h-[10rem] !w-80" />
    </div>
    <div
      v-else
      class="p-6 grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4"
    >
      <ReportTile :is-empty="true" />
      <template
        v-for="(listItem, index) in allReports"
        :key="`instagramListItem_${index}`"
      >
        <ReportTile
          :report-data="listItem"
          :report-id="listItem._id"
          :name="listItem.name"
          :created-at="listItem.created_at"
          :accounts-list="listItem.competitors"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
// libraries
import {onBeforeMount, watch, inject} from 'vue'
import { useRoute } from 'vue-router'

// components
import ReportTile from '@src/modules/analytics_v3/components/ReportTile.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'

// store
import { useStore } from '@state/base'

// constants
import {
  COMMON_API_ENDPOINTS,
  PLATFORMS,
  COMMON_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'

const props = defineProps({
  competitorAnalyticsAccess: {
    type: Object,
    default: () => {},
  },
})

const root = inject('root')
const { getters } = useStore()
const $route = useRoute()
const { $router } = root
const { fetchCompetitorInfo, allReports, loading, platform } =
  useCompetitorsFactory()

onBeforeMount(() => {
  // router in component guard not used due to vue router migration
  redirectIfCompetitorAnalyticsDisabled()
})

watch(
  () => getters.getActiveWorkspace?._id,
  async () => {
    redirectIfCompetitorAnalyticsDisabled()
    if (!props.competitorAnalyticsAccess?.allowed) return
    await fetchCompetitorReports()
  }
)

// This function checks if competitor analytics is enabled, and if not, redirects to the corresponding page.
const redirectIfCompetitorAnalyticsDisabled = () => {
  if (props.competitorAnalyticsAccess?.allowed) return
  $router.push({ name: 'competitor_analytics' })
}

const fetchCompetitorReports = async () => {
  if (props.competitorAnalyticsAccess?.allowed) {
    const payload = {
      workspace_id: getters.getActiveWorkspace._id,
      platform_type: platform.value,
    }
    try {
      await fetchCompetitorInfo(
        COMMON_API_ENDPOINTS.FETCH_ALL_REPORTS,
        payload,
        COMMON_ERROR_MESSAGES.FETCH_ALL_REPORTS
      )
    } catch (error) {
      console.error(error)
    }
  }
}

const setPlatform = (routeName = '') => {
  if (routeName.includes('facebook_competitor')) {
    localStorage.setItem('defaultCompetitorRoute', 'facebook_competitor_overview_v3');
    platform.value = PLATFORMS.FACEBOOK;
  } else if (routeName.includes('instagram_competitor')) {
    localStorage.setItem('defaultCompetitorRoute', 'instagram_competitor_overview_v3');
    platform.value = PLATFORMS.INSTAGRAM;
  }
}

watch(() => $route.name, (newVal, oldVal) => {
  if(newVal && newVal !== oldVal){
    setPlatform(newVal)
    if(newVal !== 'facebook_competitor_v3' && newVal !== 'instagram_competitor_v3') fetchCompetitorReports()
  }
}, {immediate: true})
</script>