// src/modules/billing/constants/featureMessages.js
import { ALL_FEATURES } from '@modules/billing/constants/featureList'

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`
}

export const FEATURE_MESSAGES = {
  features: {
    // Basic Publishing Features
    publishing_scheduling:
      'Publishing and scheduling is not available in the current plan',
    visual_calendar:
      'Visual content calendar is not available in the current plan',
    auto_first_comment:
      'Auto first comment feature is not available in the current plan',
    twitter_threads:
      'Twitter threads feature is not available in the current plan',
    reels_shorts_scheduling:
      'Reels and shorts scheduling is not available in the current plan',
    carousel_posts_scheduling:
      'Carousel posts scheduling is not available in the current plan',

    // Analytics Features
    social_media_analytics:
      'Social media analytics is not available in the current plan',
    competitor_analytics:
      'Competitor analytics is not available in the current plan',
    campaign_label_analytics:
      'Campaign and label analytics is not available in the current plan',
    exports_schedule_reports:
      'Report exports and scheduling is not available in the current plan',

    // AI Features
    ai_content_creation:
      'AI content creation is not available in the current plan',
    media_assets_library:
      'Media and assets library is not available in the current plan',

    // Advanced Publishing Features
    publishing_queues:
      'Publishing Queues are not available in the current plan',
    evergreen_recycling:
      'Evergreen recycling is not available in the current plan',
    bulk_upload_csv: 'Bulk upload via CSV is not available in the current plan',
    rss_scheduling: 'RSS feed scheduling is not available in the current plan',
    grid_view_planning:
      'Grid view planning is not available in the current plan',
    blog_publishing: 'Blog publishing is not available in the current plan',

    // Content Discovery Features
    content_discovery: 'Content discovery is not available in the current plan',
    influencer_discovery:
      'Influencer discovery is not available in the current plan',

    // Team Features
    team_collaboration:
      'Team collaboration is not available in the current plan',
    approval_workflow: 'Approval workflow is not available in the current plan',

    // Integrations
    canva_integration: 'Canva integration is not available in the current plan',
    custom_link_shortener:
      'Custom link shortener is not available in the current plan',
    custom_video_thumbnails:
      'Custom video thumbnails are available in all paid plans',
    feedly_integration:
      'Feedly integration is not available in the current plan',
    twitter_custom_app:
      'Twitter custom app integration is not available in the current plan',

    // Communication Features
    social_inbox: 'Social inbox is not available in the current plan',

    // Agency-Exclusive Features
    white_label_reports:
      'White label reports are not available in the current plan',
    easy_connect: 'EasyConnect is not available in the current plan',
    complete_client_management:
      'Complete client management is not available in the current plan',

    // Support Features
    dedicated_onboarding:
      'Dedicated onboarding is not available in the current plan',
    concierge_setup: 'Concierge setup is not available in the current plan',
    priority_support: 'Priority support is not available in the current plan',
    live_training: 'Live training is not available in the current plan',

    media_library_shared_folder:
      'Media library shared folder is not available in the current plan',
    article_automation:
      'Article automation is not available in the current plan',
    video_automation: 'Video automation is not available in the current plan',
    shareable_link:
      'Shareable post link feature is not available in the current plan',
    insta_collab_post:
      'Instagram collaboration posts feature is not available in the current plan',
    twitter_cs_app:
      'ContentStudio Twitter app integration is not available in the current plan',
    white_label_addon:
      'White label add-on is not available in the current plan',
    inbox: 'Inbox feature is not available in the current plan',
  },

  limits: {
    workspaces: (limit) =>
      `Your plan allows up to ${limit} workspace${
        limit !== 1 ? 's' : ''
      }. Upgrade to add more workspaces`,
    profiles: (limit) =>
      `You can connect up to ${limit} social profile${
        limit !== 1 ? 's' : ''
      } in your current plan`,
    members: (limit) =>
      `Your plan allows up to ${limit} team member${
        limit !== 1 ? 's' : ''
      }. Upgrade to add more members`,
    posts_per_month: (limit) =>
      limit === 0
        ? 'Unlimited posts available'
        : `You can publish up to ${limit} posts per month`,
    media_storage: (limit) =>
      `Storage limit of ${formatBytes(limit)}. Upgrade for more storage`,
    blogs: (limit) =>
      `You can connect up to ${limit} blog${
        limit !== 1 ? 's' : ''
      } in your current plan`,
    caption_generation_credit: (limit) =>
      `Your plan includes ${limit} AI caption generations per month`,
    image_generation_credit: (limit) =>
      `Your plan includes ${limit} AI image generations per month`,
    automations: (limit) =>
      `You can create up to ${limit} automation${
        limit !== 1 ? 's' : ''
      } in your current plan`,
  },
}

const EXPECTED_LIMITS = [
  'workspaces',
  'profiles',
  'members',
  'posts_per_month',
  'media_storage',
  'blogs',
  'caption_generation_credit',
  'image_generation_credit',
  'automations',
]

const validateMessages = () => {
  const missingFeatures = ALL_FEATURES.filter(
    (feature) => !FEATURE_MESSAGES.features[feature]
  )
  const missingLimits = EXPECTED_LIMITS.filter(
    (limit) => !FEATURE_MESSAGES.limits[limit]
  )

  if (missingFeatures.length > 0) {
    console.warn(`Missing feature messages for: ${missingFeatures.join(', ')}`)
  }

  if (missingLimits.length > 0) {
    console.warn(`Missing limit messages for: ${missingLimits.join(', ')}`)
  }
}

// Run validation in development mode only
if (process.env.NODE_ENV === 'development') {
  validateMessages()
}

export { validateMessages }
