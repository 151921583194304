<template>
  <div class="w-[63.5rem]">
    <div class="h-full w-full left_section">
      <div class="flex flex-col h-full justify-between py-[1rem] 2xl:py-[2rem] 3xl:py-[3rem]">
        <div class="px-[5rem] mb-4 py-[1rem] 2xl:py-[2rem]">
          <LogoComponent />
        </div>
        <div class="flex-grow flex flex-col justify-center">
          <div class="px-[5rem]">
            <div class="flex items-center px-4 w-max py-1 gap-x-2  text-[#0D2859] mb-4 xl:mb-8 2xl:mb-10 bg-white rounded-full">
              <img
                  class="w-5"
                  draggable="false"
                  src="@assets/img/onboarding/crown.svg"
                  alt="crown"
              />
              New Feature
            </div>
            <h2 class="text-left text-[#0D2859] font-bold text-md 2xl:text-3xl">White Label</h2>
            <p class="text-[#0D2859] leading-6 text-md 2xl:text-lg mt-3">🎉 White Label feature is now live! Customize the platform with your domain and branding! 🚀</p>
          </div>
          <img
              class="w-full px-10 object-contain mt-10 2xl:mt-20"
              draggable="false"
              src="@assets/img/new-features/feature.png"
              alt="Aug 2024 Product Updates"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import LogoComponent from "@modules/account/components/LogoComponent.vue";
</script>
<style lang="scss" scoped>
.left_section {
  background: linear-gradient(22deg, #C8E8FF 0%, #EEF4F7 32.18%, #EEF0F7 95.94%);
  box-shadow: 0px 0px 0px 1px #D1E3FB;

  .trusted_section {
    border-radius: 7.24px;
    border-top: 1px solid rgba(255, 255, 255, 0.30);
    background: radial-gradient(314.45% 139.15% at 3.59% 3.24%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.07) 100%);
    backdrop-filter: blur(14.395000457763672px);
  }
}

</style>
