<template>
  <b-modal
    id="set-location-modal"
    ref="set-location-modal"
    centered
    hide-header
    hide-footer
    @hidden="onModalHidden"
    @shown="onModalShow"
  >
    <div class="modal_head">
      <div class="flex items-center mx-auto gap-x-2">
        <img
          src="@assets/img/common/location-pin.svg"
          alt="location"
          class="w-6 h-6"
        />
        <h4 class="font-semibold">Add Location</h4>
      </div>

      <button
        type="button"
        class="modal_head__close float-right mx-0"
        data-dismiss="modal"
        aria-label="Close"
        @click.prevent="$bvModal.hide('set-location-modal')"
        >&times;
      </button>
    </div>

    <div class="modal_body p-2">
      <!--Show Facebook and Instagram icons conditionally based on accounts -->
      <div class="flex items-center justify-center my-2">
        <img
          v-if="groupedAccounts.facebook.length"
          src="@assets/img/integration/facebook-rounded.svg"
          class="w-8 h-8"
          alt="facebook"
        />
        <img
          v-if="groupedAccounts.instagram.length"
          src="@assets/img/integration/instagram-rounded.svg"
          class="w-8 h-8"
          alt="instagram"
        />
      </div>

      <!-- Customize Location -->
      <div class="flex items-center gap-x-2 my-4 ml-4">
        <CstSimpleCheckbox
          v-model="customLocation"
          name="custom-location"
          reverse
        >
          <template v-slot:label>
              <span class="text-sm pt-1">Customize location for each individual platform</span>
          </template>
        </CstSimpleCheckbox>

        <v-menu
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
        >
          <!-- This will be the popover target (for the events and position) -->
          <img
            src="@assets/img/composer/info-icon.svg"
            alt="info-icon"
            class="w-4 h-4"
        />
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <span class="font-normal text-sm"
            >Customize location for each individual platform
            <a
              href="#"
              class="beacon"
              data-beacon-article-modal="674950c81cf7ab71871ced1b"
              >Learn More</a
            >
            </span>

          </template>
        </v-menu>


      </div>

      <!-- Common Location Search -->
      <div v-if="!customLocation">
        <div
          class="flex items-center border border-gray-300 bg-gray-50 rounded-md px-4 py-2 my-4 mx-2"
        >
          <img
            src="@src/assets/img/chat_bot/search-icon.svg"
            class="h-5 w-5"
            alt="search"
          />
          <input
            v-model="commonSearchTerm"
            type="text"
            class="bg-transparent w-full ml-2 text-sm border-0 p-1 rounded"
            placeholder="Search Location"
            @input="searchLocation('common')"
          />
          <!-- Loading indicator -->
          <clip-loader
            v-if="isLoading.common"
            :color="'#9da6ac'"
            :size="'13px'"
            class="spinner"
            variant="info"
          />
          <img
            v-if="commonSearchTerm && !isLoading.common"
            src="@src/assets/img/common/circle-xmark.svg"
            alt="x-mark"
            class="h-5 w-5 cursor-pointer"
            @click="clearSearch('common')"
          />
        </div>
        <ul
          v-if="commonLocations.length"
          class="max-h-40 overflow-y-auto border border-gray-300 rounded-md mx-2 mb-4"
        >
          <li
            v-for="location in commonLocations"
            :key="location.id"
            class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            :class="{
              'text-blue-600': location.id === commonSelectedLocation?.id,
            }"
            @click="selectLocation('common', location)"
          >
            <span>{{ location.name }}</span>
            <img
              v-if="location.verified"
              src="@src/assets/img/composer/verified-icon.svg"
              alt="verified"
              class="w-4 h-4 ml-1 inline"
            />
          </li>
        </ul>
        <template
            v-if="!commonSelectedLocation && commonSearchTerm && !commonLocations.length && !isLoading.common"
            class="pt-2 text-sm"
        >
          <span class="ml-12 text-gray-700">No results found. Please check the page ID or location name</span>
        </template>
      </div>

      <!-- Custom Locations -->
      <div v-if="customLocation" class="max-h-80 overflow-y-auto">
        <template
          v-for="(accounts, platform) in groupedAccounts"
          :key="platform"
        >
          <div v-for="account in accounts" :key="account._id" class="mb-4">
            <div class="flex items-center gap-x-2 mb-2">
              <div class="relative">
                <img
                  v-tooltip="account?.name"
                  :src="account?.image"
                  :alt="account.name"
                  onerror="this.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
                  class="w-8 h-8 rounded-full"
                />
                <!-- Position the platform icon in the bottom right corner of account image -->
                <img
                  v-if="account?.channel_type === 'facebook'"
                  src="@src/assets/img/integration/facebook-rounded.svg"
                  alt="facebook"
                  class="w-4 h-4 rounded-full absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4"
                />
                <img
                  v-if="account?.channel_type === 'instagram'"
                  src="@src/assets/img/integration/instagram-rounded.svg"
                  alt="instagram"
                  class="w-4 h-4 rounded-full absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4"
                />
              </div>

              <div
                class="flex-grow flex items-center border border-gray-300 rounded-md px-4 py-2"
              >
                <img
                  src="@src/assets/img/chat_bot/search-icon.svg"
                  class="h-5 w-5 flex-shrink-0"
                  alt="search"
                />
                <input
                  v-model="customSearchTerms[account._id]"
                  type="text"
                  class="bg-transparent w-full ml-2 text-sm border-0 p-1 rounded"
                  placeholder="Search Location"
                  @input="searchLocation(account._id)"
                />
                <!-- Loading indicator -->
                <clip-loader
                  v-if="isLoading[account._id]"
                  :color="'#9da6ac'"
                  :size="'13px'"
                  class="spinner"
                  variant="info"
                />
                <img
                  v-if="
                    customSearchTerms[account._id] && !isLoading[account._id]
                  "
                  src="@src/assets/img/common/circle-xmark.svg"
                  alt="x-mark"
                  class="h-5 w-5 cursor-pointer flex-shrink-0"
                  @click="clearSearch(account._id)"
                />
              </div>
            </div>
            <ul
              v-if="customLocations[account._id]?.length"
              class="ml-[40px] max-h-40 overflow-y-auto border border-gray-300 rounded-md mt-2"
            >
              <li
                v-for="location in customLocations[account._id]"
                :key="location.id"
                class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                :class="{
                  'text-blue-600':
                    location.id === customSelectedLocations[account._id]?.id,
                }"
                @click="selectLocation(account._id, location)"
              >
                {{ location.name }}
                <img
                  v-if="location.verified"
                  src="@src/assets/img/composer/verified-icon.svg"
                  alt="verified"
                  class="w-4 h-4 ml-1 inline"
                />
              </li>
            </ul>
            <template
                v-if="!customSelectedLocations[account._id]
                && !customLocations[account._id]?.length && customSearchTerms[account._id] && !isLoading[account._id]"
                class="px-4">
              <span class="ml-12 text-gray-700">No results found. Please check the page ID or location name</span>
            </template>
          </div>
        </template>
      </div>

      <!-- Action Buttons -->
      <div class="flex items-center justify-end gap-x-2 py-4 px-2">
        <CstButton
          variant="text"
          @click.prevent="$bvModal.hide('set-location-modal')"
        >
          Cancel
        </CstButton>
        <CstButton
          variant="primary"
          @click="handleSaveLocation"
        >
          Save
        </CstButton>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { ref, inject, computed } from 'vue'
import CstButton from '@ui/Button/CstButton.vue'
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox.vue'
import _debounce from 'lodash.debounce'
import proxy from '@common/lib/http-common'
import { searchLocationsURL } from '@src/modules/publish/config/api-utils'

const props = defineProps({
  selectedAccounts: {
    type: Array,
    required: true,
  },
  selectedLocations: {
    type: Object,
    required: true,
  },
})

const { $bvModal } = inject('root')
const emit = defineEmits(['update-locations'])
const customLocation = ref(false)
const commonSearchTerm = ref('')
const customSearchTerms = ref({})
const commonLocations = ref([])
const customLocations = ref({})
const commonSelectedLocation = ref(null)
const customSelectedLocations = ref({})
const isLoading = ref({
  common: false,
})

// Group the accounts based on the platform
const groupedAccounts = computed(() => {
  const groups = { facebook: [], instagram: [] }
  props.selectedAccounts.forEach((account) => {
    if (account.channel_type === 'facebook') {
      groups.facebook.push(account)
    } else if (account.channel_type === 'instagram') {
      groups.instagram.push(account)
    }
  })
  return groups
})

// Search the location
const searchLocation = _debounce(async (id) => {
  const term =
    id === 'common' ? commonSearchTerm.value : customSearchTerms.value[id]
  if (term.length < 3) return

  isLoading.value[id] = true

  try {
    const response = await proxy.post(searchLocationsURL, {
      keyword: term,
      type: 'FACEBOOK',
    })

    if (response.data.status) {
      if (id === 'common') {
        commonLocations.value = response.data.locations || []
      } else {
        customLocations.value[id] = response.data.locations || []
      }
    } else {
      if (id === 'common') {
        commonLocations.value = []
      } else {
        customLocations.value[id] = []
      }
    }
  } catch (error) {
    console.error('Exception in location search', error)
    if (id === 'common') {
      commonLocations.value = []
    } else {
      customLocations.value[id] = []
    }
  } finally {
    isLoading.value[id] = false
  }
}, 500)

// Clear the search term
const clearSearch = (id) => {
  if (id === 'common') {
    commonSearchTerm.value = ''
    commonLocations.value = []
    commonSelectedLocation.value = null
  } else {
    customSearchTerms.value[id] = ''
    customLocations.value[id] = []
    customSelectedLocations.value[id] = null
  }
}

// Select the location
const selectLocation = (id, location) => {
  if (id === 'common') {
    commonSelectedLocation.value = location
    commonSearchTerm.value = location.name
    commonLocations.value = []
  } else {
    customSelectedLocations.value[id] = location
    customSearchTerms.value[id] = location.name
    customLocations.value[id] = []
  }
}

// Save the selected locations
const handleSaveLocation = () => {
  const updatedLocations = {}

  if (customLocation.value) {
    Object.entries(groupedAccounts.value).forEach(([platforms, accounts]) => {
      accounts.forEach((account) => {
        const selectedLocation = customSelectedLocations.value[account._id]
        if (selectedLocation) {
          // save location with key as page_id and value as location
          updatedLocations[account._id] = {
            id: account._id,
            name: selectedLocation.name,
            page_id: selectedLocation.page_id,
            platform: account.channel_type,
            location_title: selectedLocation.location_title,
            location: selectedLocation.location,
          }
        }
      })
    })
  } else {
    if (commonSelectedLocation.value) {
      Object.entries(groupedAccounts.value).forEach(([platforms, accounts]) => {
        accounts.forEach((account) => {
          updatedLocations[account._id] = {
            id: account._id,
            name: commonSelectedLocation.value.name,
            page_id: commonSelectedLocation.value.page_id,
            platform: account.channel_type,
            location: commonSelectedLocation.value.location,
            location_title: commonSelectedLocation.value.location_title,
          }
        })
      })
    }
  }

  emit('update-locations', updatedLocations)
  $bvModal.hide('set-location-modal')
}

// Set the selected locations in the modal when it is shown
const onModalShow = () => {

  // if selected locations are already present, set them in the modal
  if (props.selectedLocations && Object.keys(props.selectedLocations).length) {
    customLocation.value = true
    Object.entries(props.selectedLocations).forEach(([id, location]) => {
      if (location?.name) { // Only set if location exists and has a name
        customSearchTerms.value[id] = location.name
        customSelectedLocations.value[id] = location
      }
      customLocations.value[id] = []
    })

    // Set common location from the first valid location
    const firstValidLocation = Object.values(props.selectedLocations).find(loc => loc?.name)
    if (firstValidLocation) {
      commonSelectedLocation.value = firstValidLocation
      commonSearchTerm.value = firstValidLocation.name
    }
  } else {
    // Default will be custom location
    // Handle default locations from accounts
    customLocation.value = true
    const accountsWithLocations = props.selectedAccounts.filter(account => account?.location?.name)

    props.selectedAccounts.forEach((account) => {
      // Only set location if it exists and has a name
      if (account?.location?.name) {
        customSearchTerms.value[account._id] = account.location.name
        customSelectedLocations.value[account._id] = account.location
      } else {
        customSearchTerms.value[account._id] = ''
        customSelectedLocations.value[account._id] = null
      }
      customLocations.value[account._id] = []
    })

    // Set common location from the first account with a valid location
    const firstAccountWithLocation = accountsWithLocations[0]
    if (firstAccountWithLocation?.location) {
      commonSelectedLocation.value = firstAccountWithLocation.location
      commonSearchTerm.value = firstAccountWithLocation.location.name
    }
  }
}

// Clear the states when the modal is hidden
const onModalHidden = () => {
  customLocation.value = false
  commonSearchTerm.value = ''
  customSearchTerms.value = {}
  commonLocations.value = []
  customLocations.value = {}
  commonSelectedLocation.value = null
  customSelectedLocations.value = {}
}
</script>
