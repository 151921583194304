import { mapGetters, mapActions, mapMutations } from 'vuex'
import { evergreen } from '@src/modules/automation/store/automation-mutation-type'
import { platforms } from '@src/modules/integration/store/states/mutation-types'
import { rssTypes } from '@src/modules/automation/store/recipes/mutation-types'
import {
  linkedinSharingTypes,
  rewritingTypes,
  blogPosting,
  instagramTypes,
  social,
  youtubeTypes,
} from '@src/modules/publish/store/states/mutation-types'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'
import {composer} from "@src/modules/composer/store/composer-mutation-type";
import { EventBus } from '@src/modules/common/lib/event-bus'

export const applicationMixin = {
  data() {
    return {}
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapMutations([
      'RESET_WORKSPACE_HASHTAGS',
      'SET_DEFAULT_GMB',
      'RESET_LABELS_STATE',
      'SET_CURATED_STORIES_POSTS',
      'SET_CUSTOM_STORIES_POSTS',
      'SET_COVERED_STORIES_STATUS',
      'RESET_SELECTION_ON_WORKSPACE_CHANGE',
      'SET_CURRENT_NOTIFICATIONS',
      'RESET_USER_NOTIFICATIONS',
    ]),
    ...mapActions([
      'setDefaultFacebook',
      'setDefaultTwitter',
      'setDefaultPinterest',
      'setDefaultLinkedin',
      'setDefaultLinkedin',
      'setDefaultTumblrAccount',
      'setDefaultArticleAutomationStates',
      'setDefaultVideoAutomationStates',
      'setAutomationScheduleOptions',
      'setAutomationCounts',
      'setAccountSelection',
      'setDefaultPublishStates',
      'setDefaultCommonSharing',
      'setDefaultFacebookSharing',
      'setDefaultTwitterSharing',
      'setDefaultPinterestSharing',
      'setDefaultTumblrSharing',
      'setCoveredStoriesLoader',
      'setCoveredStoriesRunOnce',
      'setDefaultEvergreenAutomationStates',
      'setOnlineUserEmails',
      'resetNotificationsPreference',
      'allCuratedTopics',
    ]),
    dropdownArrowSelection() {
      const self = this
      console.debug('Method:dropdownArrowSelection')
      let $listItems = null
      setTimeout(() => {
        $listItems = $('.search_dropdown li.item')
      }, 200)
      $(document).on('keydown', '.discoverySearchInput', function (e) {
        try {
          const key = e.keyCode
          const $selected = $listItems.filter('.selected')
          let $current

          if (key !== 40 && key !== 38) return

          $listItems.removeClass('selected')

          if (key === 40) {
            // Down key
            if (!$selected.length || $selected.is(':last-child')) {
              $current = $listItems.eq(0)
            } else {
              $current = $selected.next()
            }
          } else if (key === 38) {
            // Up key
            if (!$selected.length || $selected.is(':first-child')) {
              $current = $listItems.last()
            } else {
              $current = $selected.prev()
            }
          }
          try {
            if ($current.text() && $current.text() != 'Related Keywords') {
              self.setSearch($current.text())
            }
          } catch (m) {}
          $current.addClass('selected')
        } catch (m) {}
      })
    },
    getBackgroundURLWithDefault(image) {
      return `url("${image}"), url("https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg")`
    },
    async resetDefaultStates() {
      console.log('Method:resetDefaultStates')
      // discovery
      this.$store.commit(workspaceTypes.SET_WORKSPACE_SEARCH, '')
      this.RESET_SELECTION_ON_WORKSPACE_CHANGE()
      EventBus.$emit('reset-users-workspaces');
      this.allCuratedTopics()
      // this.SET_POSTS([])
      this.SET_CURATED_STORIES_POSTS([])
      this.SET_CUSTOM_STORIES_POSTS([])
      this.SET_COVERED_STORIES_STATUS(false)
      this.setCoveredStoriesRunOnce(false)
      this.getFavoriteFolders()

      // this.setCoveredStoriesLoader(false)

      // integrations resetter
      this.$store.commit(platforms.SET_DEFAULT_STATES)
      this.$store.dispatch('setDefaultIntegrationStates')
      this.RESET_WORKSPACE_HASHTAGS()
      this.resetUtmData()
      // this.resetGroupData()

      // automation sections
      this.setAutomationCounts(null)
      this.setDefaultArticleAutomationStates()
      this.setDefaultVideoAutomationStates()
      this.$store.commit(evergreen.SET_EVERGREEN_DEFAULT_STATES)
      // this.setDefaultEvergreenAutomationStates()
      this.$store.commit(rssTypes.RESET_ALL_RSS_AUTOMATION_STATES)
      this.setAutomationScheduleOptions(null)

      // composer sections

      // automation sections
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
      this.setAccountSelection(null)
      this.$store.commit(rewritingTypes.RESET_REWRITING_SELECTION)
      this.setDefaultPublishStates(null)

      // publish states
      this.RESET_LABELS_STATE()
      this.$store.dispatch('resetPlannerStates')
      this.setDefaultCommonSharing()
      this.setDefaultFacebookSharing()
      this.setDefaultTwitterSharing()
      this.$store.commit(linkedinSharingTypes.SET_DEFAULT_SHARING)
      this.setDefaultPinterestSharing()
      this.setDefaultTumblrSharing()
      this.$store.commit(instagramTypes.SET_DEFAULT_SHARING)
      this.$store.commit('SET_GMB_DEFAULT_SHARING')
      this.$store.commit(youtubeTypes.SET_DEFAULT_SHARING)
      this.$store.commit(social.SET_YOUTUBE_OPTIONS, null)
      this.$store.commit(social.SET_PINTEREST_OPTIONS, null)
      this.$store.commit(composer.SET_PUBLICATION_FOLDER_STATUS, false)

      // notificationsStates
      this.resetNotificationsPreference()
      this.$store.commit('RESET_USER_NOTIFICATIONS')

      // broadcast Listener
      this.workspaceBroadCastListeners()
      this.$store.commit(workspaceTypes.SET_WORKSPACE, null)
      return true
    },

    sleep(milliseconds) {
      const start = new Date().getTime()
      for (let i = 0; i < 1e7; i++) {
        if (new Date().getTime() - start > milliseconds) {
          break
        }
      }
    },
    // isTabActive () {
    //   window.onhashchange = function () {
    //     console.debug('hash changed')
    //   }
    //   // setInterval(function () {
    //   //   if (document.hidden) {
    //   //     console.debug('Tab inactive')
    //   //   } else {
    //   //     console.debug('Tab active')
    //   //   }
    //   // }, 2000)
    // }
  },
}
