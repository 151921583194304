<script setup>
import CstButton from '@ui/Button/CstButton.vue'
import { ref } from 'vue'

defineEmits(['close-popup', 'confirm-popup'])

defineProps({
  parentClass: {
    type: String,
    default: '',
  },
  closingText: {
    type: String,
    default: 'Cancel',
  },
  confirmText: {
    type: String,
    default: 'Confirm',
  },
  checkBoxText: {
    type: String,
    default: 'Do not show again',
  },
  hideDoNotShowAgain: {
    type: Boolean,
    default: false,
  },
})

const isChecked = ref(false)
</script>

<template>
  <div
    class="bg-white"
    :class="parentClass"
    style="box-shadow: 0 3px 20px 0 rgba(0, 23, 83, 0.15)"
  >
    <slot name="popup-text">
      <p class="text-secondary-cs font-bold mx-6 mt-5 mb-4"
        >Are you sure you want to close this popup?</p
      >
    </slot>
    <div class="px-6 py-4 flex justify-between items-center bg-gray-cs-50">
      <label v-if="!hideDoNotShowAgain" class="flex items-center gap-x-3 mb-0">
        <input v-model="isChecked" type="checkbox" class="cursor-pointer" />
        <span class="text-sm text-secondary-cs cursor-pointer leading-none">{{
          checkBoxText
        }}</span>
      </label>

      <div class="flex gap-x-2 ml-auto">
        <CstButton
          variant="outlined"
          class=" no-analytics !px-4 !py-2 !rounded-[0.375rem] !border-secondary-cs-700 !bg-transparent"
          @click="$emit('close-popup')"
        >
          <p class="text-xs font-medium text-secondary-cs-700">{{
            closingText
          }}</p>
        </CstButton>
        <CstButton
          variant="primary"
          class=" no-analytics !px-4 !py-2 !rounded-[0.375rem]"
          @click="$emit('confirm-popup', isChecked)"
          ><p class="text-xs font-medium">{{ confirmText }}</p></CstButton
        >
      </div>
    </div>
  </div>
</template>
