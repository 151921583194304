import { computed } from 'vue'
import usePermissions from '@common/composables/usePermissions'
import { useStore } from '@/src/store/base'
import { FEATURE_MESSAGES } from '@/src/modules/billing/constants/featureMessages'

export function useFeatures() {
  const store = useStore()
  const { hasPermission } = usePermissions()

  // Get subscription data from store
  const planData = computed(() => store.getters.getPlan)

  // Feature checking
  const hasFeature = (feature) => {
    return computed(
      () => planData.value?.subscription?.features?.[feature] ?? false
    )
  }

  // Limit checking
  const getLimit = (limitKey) => {
    return computed(() => planData.value?.subscription?.limits?.[limitKey] ?? 0)
  }

  const getUsedLimit = (limitKey) => {
    return computed(() => planData.value?.used_limits?.[limitKey] ?? 0)
  }

  const getRemainingLimit = (limitKey) => {
    return computed(() => {
      const limit = getLimit(limitKey).value
      const used = getUsedLimit(limitKey).value

      // 0 means unlimited
      if (limit === 0) return Infinity

      return Math.max(0, limit - used)
    })
  }

  const isLimitReached = (limitKey) => {
    return computed(() => {
      const limit = getLimit(limitKey).value
      const used = getUsedLimit(limitKey).value

      // 0 means unlimited
      if (limit === 0) return false

      return used >= limit
    })
  }

  // Combined check for feature access and limits
  const canAccess = (feature, limitKey = null) => {
    return computed(() => {
      // Check feature availability
      if (!hasFeature(feature).value) {
        return {
          allowed: false,
          error: {
            type: 'FEATURE_UNAVAILABLE',
            message: hasPermission('can_see_subscription')
              ? FEATURE_MESSAGES.features[feature] ||
                'This feature is not available in your current plan'
              : 'This feature is not available in your current plan. Contact your Super Admin to upgrade.',
          },
        }
      }

      // Check limits if specified
      if (limitKey && isLimitReached(limitKey).value) {
        const limit = getLimit(limitKey).value
        return {
          allowed: false,
          error: {
            type: 'LIMIT_REACHED',
            message:
              FEATURE_MESSAGES.limits[limitKey]?.(limit) ||
              `You've reached the limit for this feature`,
          },
        }
      }

      return { allowed: true }
    })
  }

  // Get descriptive message for a limit
  const getLimitMessage = (limitKey) => {
    return computed(() => {
      const limit = getLimit(limitKey).value
      return FEATURE_MESSAGES.limits[limitKey]?.(limit)
    })
  }

  return {
    hasFeature,
    getLimit,
    getUsedLimit,
    getRemainingLimit,
    isLimitReached,
    canAccess,
    getLimitMessage,
  }
}
