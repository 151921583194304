import { ref, watch } from 'vue'
import debounce from 'lodash/debounce'
import {
  searchLocationsURL,
  saveLocationURL,
} from '@src/modules/publish/config/api-utils'
import proxy from '@common/lib/http-common'
import { useStore } from '@src/store/base'
import {
  facebook,
  instagram,
} from '@modules/integration/store/states/mutation-types'

export const useLocation = () => {
  const { getters, commit, dispatch } = useStore()

  const searchTerm = ref('')
  const locations = ref([])
  const isLoading = ref(false)
  const platformType = ref('')
  const userSelectedLocation = ref(false)
  const skipNextSearch = ref(false)

  // Watch for changes in the search term
  watch(searchTerm, (newTerm, oldTerm) => {
    if (newTerm === oldTerm) return
    if (skipNextSearch.value) {
      skipNextSearch.value = false
      return
    }
    if (newTerm.length >= 3) {
      debouncedSearch(newTerm)
    } else {
      locations.value = []
    }
  })

  // Saves the default location
  const saveLocation = async (payload) => {
    try {
      const { data } = await proxy.post(saveLocationURL, {
        workspace_id: getters.getActiveWorkspace?._id,
        ...payload,
      })
      if (data.status) {

        await dispatch('toastNotification', {
          message: 'Location saved successfully.',
          type: 'success',
        })

        // Update the locations in the store -- added for composer
        dispatch('fetchSocialAccounts',true)
      }
    } catch (error) {
      console.error('Error saving location:', error)
    }
  }

  // Searches for locations based on the search term
  const searchLocations = async (term) => {
    if (term.length < 3 || skipNextSearch.value) {
      skipNextSearch.value = false
      return
    }

    isLoading.value = true
    try {
      const { data } = await proxy.post(searchLocationsURL, {
        keyword: term,
        type: platformType.value.toUpperCase(),
      })
      locations.value = data.locations || []
    } catch (error) {
      console.error('Error searching locations:', error)
      locations.value = []
    } finally {
      isLoading.value = false
    }
  }

  // Debounce the search function to avoid excessive API calls
  const debouncedSearch = debounce(searchLocations, 300)

  const clearStates = () => {
    searchTerm.value = ''
    locations.value = []
    isLoading.value = false
    platformType.value = ''
    skipNextSearch.value = false
  }

  //  Set initial location if provided
  const setInitialLocation = (location) => {
    if (location) {
      searchTerm.value = location
      userSelectedLocation.value = true
      skipNextSearch.value = true
    }
  }

  /**
   * Selects a location from the search results
   * @param {Object} location - The selected location object
   */
  const selectLocation = (location) => {
    searchTerm.value = location.name
    userSelectedLocation.value = true
    skipNextSearch.value = true
    locations.value = []
  }

  return {
    platformType,
    searchTerm,
    locations,
    isLoading,
    userSelectedLocation,
    saveLocation,
    setInitialLocation,
    selectLocation,
    clearStates,
  }
}
