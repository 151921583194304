<template>
  <div
    id="tumblr_sharing_box"
    class="input_field social_content_box social_content_box_multi"
    :class="{
      in_active: getSocialSharingSelection.activeTab !== 'tumblr_tab',
      editor_hover: isFileHover,
    }"
    @dragleave.self="
      $store.dispatch('leaveFileDragOver', { event: $event, type: 'Tumblr' })
    "
    @dragover="
      $store.dispatch('onFileDragOver', { event: $event, type: 'Tumblr' })
    "
    @drop="$store.dispatch('onFileDrop', { event: $event, type: 'Tumblr' })"
  >
    <div class="box_social_tag tum_bg">
      <i class="cs-tumblr"></i>
    </div>

    <div v-if="isFileHover" class="drag_content">
      <p>Drag files here</p>
    </div>

    <div class="text_content text_content_main">
      <resize-textarea
        id="tumblr_sharing_message"
        ref="tumblr_sharing_message"
        :class="{ hide: getSocialSharingSelection.activeTab !== 'tumblr_tab' }"
        class="content_textarea"
        spellcheck="false"
        :min-height="55"
        @input="handleInput"
        @paste="sharingMessageChanges($event, 'Tumblr')"
        @keyup.space="sharingMessageChanges($event, 'Tumblr')"
        @focus="focusSharingBox('Tumblr')"
        @click="focusSharingBox('Tumblr')"
        @keydown.ctrl.b="sharingMessageFormatting('Tumblr', 'bs')"
        @keydown.ctrl.i="sharingMessageFormatting('Tumblr', 'is')"
      ></resize-textarea>
      <div id="clone_txt_tumblr_tab" class="clone_txt">
        <span
          class="desc"
          :class="{
            hide: getSocialSharingSelection.activeTab !== 'tumblr_tab',
          }"
        ></span>
        <span
          class="in_active desc"
          :class="{
            hide: getSocialSharingSelection.activeTab === 'tumblr_tab',
          }"
          @click="
            focusSharingBox('Tumblr'),
              textareaFocus($refs.tumblr_sharing_message)
          "
        ></span>
      </div>

      <HashtagSuggestions
        v-click-away="hideHashtag"
        type="Tumblr"
      ></HashtagSuggestions>

      <!--<caption-generation-modal type="Facebook"></caption-generation-modal>-->

      <template v-if="shouldLockBasedOnSubAddons('caption_generation')">
        <div class="text_content_bottom">
          <button
              v-if="getSocialSharingSelection.activeTab == 'tumblr_tab'"
              class="btn btn-studio-theme-space caption-btn"
              @click.prevent="showUpgradeModal">
            <i class="fas fa-lock mr-2" style="font-size: 0.8rem"></i>
            <span
                v-tooltip="{
                  content: getLockBasedOnSubAddonsMessage('caption_generation'),
                  placement: 'top-center',
                }"
            >
              Generate Caption
            </span>
          </button>
        </div>
      </template>
      <template v-else>
        <div class="text_content_bottom">
          <button
              v-if="getSocialSharingSelection.activeTab == 'tumblr_tab'"
              :disabled="
            getPublishLoaders.fetchTumblrShortLinks ||
            !getTumblrSharingDetails.url
          "
              class="btn btn-studio-theme-space caption-btn"
              @click="fetchAiCaption(getTumblrSharingDetails.url, Tumblr)"
          >
          <span
              v-tooltip="{
              content: getTumblrSharingDetails.url
                ? null
                : 'Caption generation button is disabled until a valid URL is put in the composer box',
              placement: 'top-center',
            }"
          >
            Generate Caption
          </span>
          </button>
        </div>
      </template>
    </div>

    <Options
      type="Tumblr"
      :class="{ hide: getSocialSharingSelection.activeTab !== 'tumblr_tab' }"
    ></Options>
    <clip-loader
      v-if="
        getPublishLoaders.fetchTumblrShortLinks ||
        getPublishLoaders.fetchTumblrSharingPreview
      "
      :class="{ hide: getSocialSharingSelection.activeTab !== 'tumblr_tab' }"
      color="#4165ed"
      :size="'16px'"
    ></clip-loader>
    <template v-else>
      <VideoSection
        v-if="
          (getTumblrSharingDetails.video &&
            getTumblrSharingDetails.video.link) ||
          getPublishLoaders.upload_tumblr_sharing_video
        "
        :class="{ hide: getSocialSharingSelection.activeTab !== 'tumblr_tab' }"
        type="Tumblr"
        :details="getTumblrSharingDetails"
      ></VideoSection>
      <ImageSection
        v-else-if="
          getPublishLoaders.uploadTumblrSharingImage ||
          getTumblrSharingDetails.image
        "
        :class="{ hide: getSocialSharingSelection.activeTab !== 'tumblr_tab' }"
        type="Tumblr"
        :details="getTumblrSharingDetails"
      ></ImageSection>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {useBilling} from "@modules/billing/composables/useBilling";
import Options from '../options/Options'
import ImageSection from '../sections/ImageSection'
import VideoSection from '../sections/VideoSection'
import HashtagSuggestions from '../options/HashtagSuggestion'

export default {
  components: {
    Options,
    ImageSection,
    VideoSection,
    HashtagSuggestions
  },
  setup() {
    const {showUpgradeModal} = useBilling()

    return {
      showUpgradeModal
    }
  },
  data () {
    return {}
  },

  created () {},
  mounted () {
    this.setTumblrboxRef(this.$refs.tumblr_sharing_message)
  },

  computed: {
    ...mapGetters([
      'getPublishLoaders',
      'getTumblrSharingDetails',
      'getSocialSharingSelection'
    ]),
    isFileHover () {
      return (
        this.getSocialSharingSelection.drag_over_status.tumblr &&
        this.getSocialSharingSelection.activeTab === 'tumblr_tab'
      )
    }
  },
  methods: {
    ...mapActions(['setTumblrboxRef']),
    handleRerender () {
      console.debug('Method::handleRerender')
      const text = document.getElementById('tumblr_sharing_message').value
      if (!text) {
        const message = this.getTumblrSharingDetails.message
        document.getElementById('tumblr_sharing_message').value = message
        this.setSharingBoxHtml(
          this.processSharingBoxHtml(message, 'Tumblr'),
          'Tumblr'
        )
      }
    },
    handleInput(event) {
      this.handleRerender()
      this.sharingMessageChanges(event, 'Tumblr')
    },
  },

  watch: {
    'getTumblrSharingDetails.message' (value) {
      this.setSharingBoxHtml(
        this.processSharingBoxHtml(value, 'Tumblr'),
        'Tumblr'
      )
    }
  }
}
</script>
