<template>
  <div class="replug_selection">
    <div class="field_group">
      <div class="checkbox_container">
        <label for="replug">
          Add call-to-actions to shared links using Replug
          <input
            id="replug"
            v-model="getPublishSelection.replug.status"
            type="checkbox"
            name="option2"
          />
          <span class="check"></span>
        </label>
      </div>
    </div>

    <transition name="slide-bottom">
      <ReplugOptions
        v-if="getPublishSelection.replug.status"
        class=""
        :nomargin="true"
        :text-align="false"
      ></ReplugOptions>
    </transition>
  </div>
</template>

<script>
import ReplugOptions from '../../../publish/components/posting/options/ReplugOptions'

export default {
  components: {
    ReplugOptions
  },

  data () {
    return {}
  },

  computed: {},

  watch: {},

  created () {},
  mounted () {
    // fetch Integration Accounts and Authorization links
    this.$store.dispatch('fetchIntegrationsAccounts')
  },
  methods: {}
}
</script>
