<template>
  <b-modal
      id="manage-shared-links-modal"
      body-class="p-0 rounded-md shadow-sm overflow-hidden padding-bottom-0 bg-white"
      modal-class="full_height right side_slide_right"
      header-class="cs-modal-header bg-white"
      title-class="cs-modal-title"
      dialog-class="w-[33.688rem]"
      size="md"
      centered
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="onModalHidden"
      @shown="onModalOpen"
      @hide="commonMethods.toggleWidgets(false)"
  >
    <template v-slot:modal-title>
      <div class="flex items-center">
        EasyConnect Links

        <clip-loader
            v-if="data.updating.status"
            variant="info"
            class="spinner ml-3 mr-1"
            :color="'#9da6ac'"
            :size="'16px'"
        ></clip-loader>
      </div>
    </template>

    <div v-if="data.loader" class="px-4 py-5">
      <SkeletonBox width="100%" height="66px" radius="12px" class="mb-6" />
      <SkeletonBox width="100%" height="66px" radius="12px" class="mb-6" />
      <SkeletonBox width="100%" height="66px" radius="12px" class="mb-6" />
      <SkeletonBox width="100%" height="66px" radius="12px" class="mb-6" />
      <SkeletonBox width="100%" height="66px" radius="12px" class="mb-6" />
    </div>

    <div v-else class="px-4 py-6 h-full overflow-y-auto">
        <div v-if="!data.links.length" class="text-gray-800 text-center">
          No link has been created yet.
          <br/>To create new link, please click <b>Create New Link</b> button.
        </div>
        <div
            v-for="(item, i) in data.links"
            v-else
            :key="i"
            class="rounded-md shadow-sm border !border-[#EEF1F3] mb-4"
        >
          <div class="flex flex-row justify-between items-center bg-white shadow-sm border !border-[#eef1f4] py-3 px-4">
            <div :class="{ grayscale: !item.enabled }">
              <h3 class="text-base font-bold mb-1.5"
              >{{ item.link_name
                }}<i
                    v-if="item.is_secure"
                    v-tooltip.right="{
                    content: 'Link is password protected',
                  }"
                    class="fas fa-lock-alt ml-2 cursor-pointer"
                ></i>
              </h3>
              <p class="text-sm text-blue-400 truncate w-[15rem]"
              >{{ baseUrl }}easy-connect/{{ item.link_url_slug }}</p>
            </div>
            <div class="ml-2 flex items-center text-base" >
              <CstSwitch
                  v-tooltip.top="{
                  content: !item.enabled ? 'Enable Link' : 'Disable Link',
                }"
                  :value="item.enabled"
                  class="mr-2 flex items-center"
                  size="small"
                  :disabled="
                  data.updating.status && data.updating.link === item._id
                "
                  @input="(value) => toggleLinkStatus(item._id, value)"
              />

              <span
                  v-if="item.enabled"
                  v-tooltip.top="{
                  content: 'Copy Link',
                }"
                  class="mx-1 flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-7 h-7 hover:!border-gray-900"
                  @click="
                  copyLinkToClipboard(
                    `${baseUrl}easy-connect/${item.link_url_slug}`,
                  )
                "
              >
                <span class="flex items-center justify-center">
                  <i class="far fa-link text-gray-700 cursor-pointer"></i>
                </span>
              </span>
              <span
                  v-if="item.enabled"
                  v-tooltip.top="'Edit'"
                  class="mx-1 flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-7 h-7 hover:!border-gray-900"
                  @click="editLink(item)"
              >
                <span class="flex items-center justify-center">
                  <img
                      alt="approval_publish"
                      src="@assets/img/icons/planner/more_actions_edit.svg"
                      class="w-4"
                  />
                </span>
              </span>
              <span
                  v-tooltip.top="{
                  content: 'Remove Link',
                }"
                  class="mx-1 flex items-center justify-center cursor-pointer p-2 border rounded-md bg-white w-7 h-7 hover:!border-gray-900"
                  @click="removeLink(item?._id)"
              >
                <span class="flex items-center justify-center">
                  <i class="far fa-trash-alt text-red-500 !shadow-none w-4"></i>
                </span>
              </span>
              <div class="h-6 w-[1px] ml-[0.8125rem] mr-[0.8125rem] bg-[#D2D5DF]"></div>
              <div
                  v-tooltip="{
                  content: (data?.selectedLink?._id === item._id) && data?.toggleLinks === true ? 'Collapse' : 'Expand to view connected accounts',
                  }"
                  class="transition ml-2.5 cursor-pointer text-[#757A8A]"
                  :class="{ 'fa-rotate-180': (data?.selectedLink?._id === item._id) && data?.toggleLinks === true }"
                  @click="linkedAccount(item)"
              >
                  <i class="fa fa-angle-down text-lg"></i>
              </div>
            </div>
          </div>
          <div
                v-if="data.selectedLink !== null" class="h-0 height-transition-all"
               :class="{ 'h-[auto]': (data.selectedLink._id === item._id) && data.toggleLinks === true }">
            <div
                v-if="[...item.facebook_accounts,
              ...item.instagram_accounts,
              ...item.linkedin_accounts,
              ...item.gmb_accounts,
              ...item.pinterest_accounts,
              ...item.tiktok_accounts,
              ...item.tumblr_accounts,
              ...item.twitter_accounts,
              ...item.youtube_accounts].length === 0 && data.selectedLink._id === item._id && data.toggleLinks === true"
                class="py-[0.75rem] px-5 border-bottom-custom hover:!ring-1 hover:!ring-[#90CAFF] text-center text-secondary-cs-700">
              No account connected yet.
            </div>
            <div v-else>
              <ConnectionLinkedAccounts :item="item" :linked-accounts="item.facebook_accounts" :data="data" type="facebook"/>
              <ConnectionLinkedAccounts :item="item" :linked-accounts="item.instagram_accounts" :data="data" type="instagram"/>
              <ConnectionLinkedAccounts :item="item" :linked-accounts="item.linkedin_accounts" :data="data" type="linkedin"/>
              <ConnectionLinkedAccounts :item="item" :linked-accounts="item.gmb_accounts" :data="data" type="gmb"/>
              <ConnectionLinkedAccounts :item="item" :linked-accounts="item.pinterest_accounts" :data="data" type="pinterest"/>
              <ConnectionLinkedAccounts :item="item" :linked-accounts="item.tiktok_accounts" :data="data" type="tiktok"/>
              <ConnectionLinkedAccounts :item="item" :linked-accounts="item.tumblr_accounts" :data="data" type="tumblr"/>
              <ConnectionLinkedAccounts :item="item" :linked-accounts="item.twitter_accounts" :data="data" type="twitter"/>
              <ConnectionLinkedAccounts :item="item" :linked-accounts="item.youtube_accounts" :data="data" type="youtube"/>
            </div>
          </div>
        </div>
    </div>
  </b-modal>
  <CreateShareLinkModal :is-edit=true :edit-link="data.selectedLink" @re-fetch-links="fetchSavedLinks" />
</template>

<script setup>
import CstSwitch from '@ui/Switch/CstSwitch'
import { inject, reactive } from 'vue'
import {
  ACTION_TYPES,
} from '@src/modules/integration/config/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import { swalAttributes } from '@common/constants/common-attributes'
import { useStore } from '@state/base'
import useIntegrationComposable from "@src/modules/integration/Composables/useIntegrationComposable";
import CreateShareLinkModal from "@src/modules/integration/components/dialogs/CreateShareLinkModal.vue";
import ConnectionLinkedAccounts from "@src/modules/integration/components/dialogs/ConnectionLinkedAccounts.vue";
import {commonMethods} from "@common/store/common-methods";
import { baseUrl } from "@src/config/api-utils";

const root = inject('root')
const { $bvModal } = root
const { dispatch, getters } = useStore()
const { serviceManageShareLink } = useIntegrationComposable()

const data = reactive({
  links: [],
  selectedLink: null,
  passwordVisible: false,
  loader: true,
  updating: {
    status: false,
    link: null,
  },
  toggleLinks: false
})

const linkedAccount = (link) => {
  if (data.selectedLink?._id === link._id) {
    // If the clicked link is already the selected link, toggle data.toggleLinks
    data.toggleLinks = !data.toggleLinks;
  } else {
    // If the clicked link is not the selected link, select it and set data.toggleLinks to true
    data.selectedLink = link;
    data.toggleLinks = true;
  }
}

/**
 * Copy link to clipboard
 * @param link
 */
const copyLinkToClipboard = (link) => {
  navigator.clipboard.writeText(link)
  dispatch('toastNotification', {
    message: 'Link copied to clipboard',
    type: 'success',
  })
}


/**
 * Edit link
 * @param linkData
 */
const editLink = (linkData) => {
  data.selectedLink = linkData
  $bvModal.show('edit-share-link-modal')

}

/**
 * Remove link
 * @param linkId
 */
const removeLink = async (linkId) => {
  const linkDetails = data.links.find((link) => link._id === linkId)
  if(linkDetails.facebook_accounts.length > 0 ||
      linkDetails.instagram_accounts .length > 0 ||
      linkDetails.linkedin_accounts.length > 0 ||
      linkDetails.gmb_accounts.length > 0 ||
      linkDetails.pinterest_accounts.length > 0 ||
      linkDetails.tiktok_accounts.length > 0 ||
      linkDetails.tumblr_accounts.length > 0 ||
      linkDetails.twitter_accounts.length > 0 ||
      linkDetails.youtube_accounts.length > 0){
    await dispatch('toastNotification', {
      message: 'You can\'t remove the link when your accounts are connected with EasyConnect. Before deleting it, just make sure to unlink the accounts.',
      type: 'error',
    })
    return
  }
  const confirm = await $bvModal.msgBoxConfirm(
      'Are you sure you want to delete the selected link?',
      {
        title: 'Delete Shared Link',
        ...swalAttributes(),
        cancelTitle: 'Cancel',
        okTitle: 'Delete',
      }
  )

  if (!confirm) {
    return
  }

  // service call to remove link
  // ...
  data.updating = {
    status: true,
    link: linkId,
  }

  try {
    await serviceManageShareLink(ACTION_TYPES.DELETE_LINK, {
      _id: linkId,
    })

    await dispatch('toastNotification', {
      message: 'Link details successfully deleted' || UNKNOWN_ERROR,
      type: 'success',
    })

    // update component state
    data.links = data.links.filter((link) => link._id !== linkId)
    data.selectedLink = null
  } catch (error) {
    await dispatch('toastNotification', {
      message: error.message || UNKNOWN_ERROR,
      type: 'error',
    })
  } finally {
    data.updating = {
      status: false,
      link: null,
    }
  }
}

/**
 * Toggle link active/inactive state
 * @param linkId
 * @param activeState
 */
const toggleLinkStatus = async (linkId, activeState) => {
  data.updating = {
    status: true,
    link: linkId,
  }
  try {
    await serviceManageShareLink(ACTION_TYPES.UPDATE_LINK, {
      ...getLinkDetails(linkId),
      enabled: activeState,
    })

    // update component state
    data.links = data.links.map((link) => {
      if (link._id === linkId) {
        link.enabled = activeState
      }
      return link
    })
  } catch (error) {
    await dispatch('toastNotification', {
      message: error.message || UNKNOWN_ERROR,
      type: 'error',
    })
  } finally {
    data.updating = {
      status: false,
      link: null,
    }
  }
}

// Helper method to get link details
const getLinkDetails = (linkId) => {
  const link = data.links.find((link) => link._id === linkId)
  return {
    _id: link._id,
    link_name: link.link_name,
    is_secure: link.is_secure,
    enabled: link.enabled,
    password: link.password || '',
    workspace_id: getters.getActiveWorkspace._id,
    updated_by: getters.getProfile._id,
  }
}

// Helper method to fetch the saved external links
const fetchSavedLinks = async () => {
  // service call to fetch links
  try {
    const response = await serviceManageShareLink(ACTION_TYPES.GET_LINK, {
      workspace_id: getters.getActiveWorkspace._id,
    })
    data.links = response.data
  } catch (error) {
    await dispatch('toastNotification', {
      message: error.message || UNKNOWN_ERROR,
      type: 'error',
    })
  } finally {
    data.loader = false
  }
}

/**
 * On modal open
 */
const onModalOpen = () => {
  commonMethods.toggleWidgets(true)

  fetchSavedLinks()
}

/**
 * On modal close
 */
const onModalHidden = () => {
  data.selectedLink = null
  data.links = []
  data.loader = true
}
</script>

<style>
.height-transition-all{
  transition: height 0.5s ease-in-out;
}
</style>
