<template>
  <div v-tooltip="folder?.is_global && !globalFolderAccess?.allowed ? globalFolderAccess?.error?.message : ''">
  <div class="stack-folder">
    <div
        :id="folder._id"
        class="stack-folder__wrapper"
        :class="{ 'pointer-events-none opacity-70': folder?.is_global && !globalFolderAccess?.allowed }"
        data-dragover="false"
        data-root="true"
        @drop="handleDrop($event, getElement(folder._id))"
        @dragenter.prevent="handleDragEnter($event, getElement(folder._id))"
        @dragleave.prevent="handleDragLeave($event, getElement(folder._id))"
        @dragover.prevent
    >
      <div
          class="stack-folder__item collapsed"
          :class="{
          'active': $route.query.type === 'folder' && $route.query.folderId === folder._id,
          'no-hover': folder?.is_global && !globalFolderAccess?.allowed
        }"
          @click="handleFolderClick(folder._id, true, folder.folder_name)"
          @dblclick="handleDoubleClick(folder._id + '_collapse')"
      >
        <div class="stack-folder__item-icon">
          <i class="far fa-folder"></i>
        </div>

        <div class="stack-folder__item-text-field">
          <input
            :id="'rename_' + folder._id"
            class="rename-field"
            type="text"
            :value="folder.folder_name"
            maxlength="40"
            @keydown.enter="saveName(folder._id, folder.folder_name, true)"
          />

          <!-- Text Fields  -->
          <div class="stack-folder__item-text" :title="folder.folder_name">
            <span>{{ folder.folder_name }}</span>
            <i
              v-if="folder?.is_global"
              v-tooltip="'This is a globally shared folder. Media in this folder is accessible throughout all your workspaces.'"
              class="far fa-crown text-google ml-2"
            ></i>

            <span
              v-b-toggle="
                hasChild && !mainFolderRenamed ? folder._id + '_collapse' : ''
              "
            >
              <i v-if="hasChild" class="far fa-chevron-down ml-2"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="stack-folder__right">
        <div
          class="justify-content-center align-items-center stack-folder__right-options"
        >
          <i v-if="isOperating" class="fad fa-spinner-third fa-spin"></i>
          <template v-else>
            <i
              class="fas fa-check-circle"
              :class="{ 'pointer-none': isOperating }"
              @click="saveName(folder._id, folder.folder_name, true)"
            ></i>
            <i
              class="fas fa-times-circle"
              :class="{ 'pointer-none': isOperating }"
              @click="handleRename(folder._id, false, true)"
            ></i>
          </template>
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <div class="stack-folder__item-count">
            {{ folder.count ? folder.count : 0 }}
          </div>

          <b-dropdown
            v-if="type === 'primary'"
            class="stack-folder__item-more"
            toggle-class="text-decoration-none pr-0"
            variant="link"
            dropright
            no-caret
            :disabled="isDeleting || (folder?.is_global && !globalFolderAccess?.allowed)"
          >
            <template v-slot:button-content>
              <i v-if="isDeleting" class="fad fa-spinner-third fa-spin"></i>
              <img
                  v-else-if="folder?.is_global && !globalFolderAccess?.allowed"
                  src="@assets/img/icons/lock_black.svg"
                  class="w-3.5 ml-2"
                  alt="Locked"
              />
              <i v-else class="far fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item
              href="#"
              @click="handleNewFolder(folder._id, folder.folder_name)"
              >Add Sub Folder</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="handleRename(folder._id, true, true)"
              >Rename</b-dropdown-item
            >
            <b-dropdown-item v-if="!folder?.is_global" href="#" @click="handleDelete(folder._id)"
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
    <b-collapse :id="folder._id + '_collapse'" v-model="visible">
      <div
        v-for="(subfolder, key) in folder.sub_folders"
        :id="subfolder._id"
        :key="key"
        data-root="false"
        class="stack-folder__subfolder"
        data-dragover="false"
        @drop="$emit('drop', $event, getElement(subfolder._id))"
        @dragenter.prevent="
          $emit('dragenter', $event, getElement(subfolder._id))
        "
        @dragleave.prevent="
          $emit('dragleave', $event, getElement(subfolder._id))
        "
        @dragover.prevent
      >
        <div
          class="stack-folder__item sub-item"
          :class="{
            active:
              $route.query.type === 'folder' &&
              $route.query.folderId === subfolder._id,
          }"
          @click="
            $emit('folderclick', subfolder._id, false, subfolder.folder_name)
          "
        >
          <div class="stack-folder__item-icon">
            <i class="far fa-folder"></i>
          </div>

          <div class="stack-folder__item-text-field">
            <!-- Rename Fields  -->
            <input
              :id="'rename_' + subfolder._id"
              class="rename-field"
              type="text"
              :value="subfolder.folder_name"
              maxlength="40"
              @keydown.enter="
                saveName(subfolder._id, subfolder.folder_name, false)
              "
            />

            <!-- Text Fields  -->
            <div class="stack-folder__item-text" :title="subfolder.folder_name">
              <span>{{ subfolder.folder_name }}</span>
            </div>
          </div>
        </div>
        <div class="stack-folder__right">
          <div
            class="justify-content-center align-items-center stack-folder__right-options"
          >
            <i v-if="isOperating" class="fad fa-spinner-third fa-spin"></i>
            <template v-else>
              <i
                class="fas fa-check-circle"
                :class="{ 'pointer-none': isOperating }"
                @click="saveName(subfolder._id, subfolder.folder_name, false)"
              ></i>
              <i
                class="fas fa-times-circle"
                :class="{ 'pointer-none': isOperating }"
                @click="handleRename(subfolder._id, false)"
              ></i>
            </template>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <div class="stack-folder__item-count">
              {{ subfolder.count ? subfolder.count : 0 }}
            </div>

            <b-dropdown
              v-if="type === 'primary'"
              class="stack-folder__item-more"
              toggle-class="text-decoration-none pr-0"
              variant="link"
              dropright
              no-caret
              :disabled="isDeleting"
            >
              <template v-slot:button-content>
                <i v-if="isDeleting" class="fad fa-spinner-third fa-spin"></i>
                <img
                    v-else-if="folder?.is_global && !globalFolderAccess?.allowed"
                    src="@assets/img/icons/lock_black.svg"
                    class="w-3.5 ml-2"
                    alt="Locked"
                />
                <i v-else class="far fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item
                href="#"
                @click="handleRename(subfolder._id, true)"
                >Rename</b-dropdown-item
              >
              <b-dropdown-item href="#" @click="handleDelete(subfolder._id)"
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
  </div>
</template>

<script>
import { swalAttributes } from '@common/constants/common-attributes'
import {EventBus} from "@common/lib/event-bus";
import {useFeatures} from "@modules/billing/composables/useFeatures";
import { MediaHelperMixin } from '../utils/MediaHelpers'

export default {
  name: 'Folder',
  mixins: [MediaHelperMixin],
  props: ['folder', 'type'],
  setup() {
    const {canAccess} = useFeatures()
    const globalFolderAccess = canAccess('media_library_shared_folder')

    return {
      globalFolderAccess
    }
  },
  data () {
    return {
      mainFolderRenamed: false,
      isOperating: false,
      isDeleting: false,
      visible: false
    }
  },
  computed: {
    hasChild () {
      return this.folder.sub_folders && this.folder.sub_folders.length > 0
    },
    isSubFolderActive () {
      if (this.$route.query.type === 'folder' && this.$route.query.folderId) {
        return (
          this.folder.sub_folders.filter(
            (subfolder) => subfolder._id === this.$route.query.folderId
          ).length > 0
        )
      } else {
        return false
      }
    }
  },
  mounted () {
    this.visible = this.isSubFolderActive
  },
  methods: {
    toggleCollapses (id) {
      this.$root.$emit('bv::toggle::collapse', id)
    },
    getElement (id) {
      return document.getElementById(id)
    },

    async saveName (folderId, oldName, isRoot) {
      console.debug('Method:saveName', folderId)

      this.isOperating = true

      const newText = document.getElementById('rename_' + folderId).value

      if (oldName.toLowerCase() === newText.trim().toLowerCase()) {
        this.handleRename(folderId, false, isRoot)
        // this.alertMessage('Please change the folder name.', 'error')
        this.isOperating = false
        return
      }

      await this.renameFolderHelper(
        { id: folderId, name: newText.trim() },
        (status) => {
          if (status) {
            EventBus.$emit('refetch-folders')
          }
        }
      )

      this.isOperating = false
    },

    handleNewFolder (folderElementId, folderElementName) {
      if (this.folder?.is_global && !this.globalFolderAccess?.allowed) {
        this.showUpgradeModal()
        return
      }
      EventBus.$emit('create-new-folder', {
        folderId: folderElementId,
        folderName: folderElementName
      })
    },

    handleRename (folderElementId, open = true, isMainFolder = false) {
      // closing all other tabs
      document.querySelectorAll('.stack-folder--rename').forEach((ele) => {
        ele.classList.remove('stack-folder--rename')
      })

      if (folderElementId) {
        if (open) {
          document
            .getElementById(folderElementId)
            .classList.add('stack-folder--rename')
        } else {
          document
            .getElementById(folderElementId)
            .classList.remove('stack-folder--rename')
        }

        if (isMainFolder) {
          this.mainFolderRenamed = open
        }
      }
    },

    async handleDelete (folderId) {
      console.debug('handleDelete', folderId)
      const h = this.$createElement
      const titleVNode = h('div', {
        domProps: {
          innerHTML: 'Are you sure, you want to delete this folder?'
        }
      })
      const noteVNode = h('div', {
        domProps: {
          innerHTML:
            '<i style="font-size: 0.9rem">Note: Assets in this folder will be permanently deleted</i>'
        }
      })

      const confirm = await this.$bvModal.msgBoxConfirm(
        [titleVNode, noteVNode],
        {
          title: 'Remove Folder',
          ...swalAttributes()
        }
      )

      if (confirm) {
        this.isDeleting = true
        if (folderId) {
          await this.deleteFolderHelper({ id: folderId }, (status) => {
            if (status) {
              EventBus.$emit('refetch-folders')
              EventBus.$emit('refetch-media')
              this.$router.push({
                name: 'media-library',
                query: {
                  type: 'all'
                }
              })
            }
          })

          document
            .getElementById(folderId)
            ?.classList?.remove('stack-folder--rename')
          this.isDeleting = false
        }
      }
    },

    handleDrop(event, element) {
      if (this.folder?.is_global && !this.globalFolderAccess?.allowed) {
        this.showUpgradeModal()
        return
      }
      this.$emit('drop', event, element)
    },

    handleDragEnter(event, element) {
      if (this.folder?.is_global && !this.globalFolderAccess?.allowed) {
        return
      }
      this.$emit('dragenter', event, element)
    },

    handleDragLeave(event, element) {
      if (this.folder?.is_global && !this.globalFolderAccess?.allowed) {
        return
      }
      this.$emit('dragleave', event, element)
    },

    handleFolderClick(folderId, isRoot, folderName) {
      if (this.folder?.is_global && !this.globalFolderAccess?.allowed) {
        this.showUpgradeModal()
        return
      }
      this.$emit('folderclick', folderId, isRoot, folderName)
    },

    handleDoubleClick(id) {
      if (this.folder?.is_global && !this.globalFolderAccess?.allowed) {
        this.showUpgradeModal()
        return
      }
      this.toggleCollapses(id)
    },
  }
}
</script>
