<template>
  <div
    id="instagram_sharing_box"
    class="input_field social_content_box social_content_box_multi"
    :class="{
      in_active: getSocialSharingSelection.activeTab !== 'instagram_tab',
      editor_hover: isFileHover,
    }"
    @dragleave.self="
      $store.dispatch('leaveFileDragOver', { event: $event, type: 'Instagram' })
    "
    @dragover="
      $store.dispatch('onFileDragOver', { event: $event, type: 'Instagram' })
    "
    @drop="$store.dispatch('onFileDrop', { event: $event, type: 'Instagram' })"
  >
    <div class="box_social_tag ins_bg">
      <i class="cs-instagram"></i>
    </div>

    <div v-if="isFileHover" class="drag_content">
      <p>Drag files here</p>
    </div>

    <div class="text_content text_content_main">
      <resize-textarea
        id="instagram_sharing_message"
        ref="instagram_sharing_message"
        :class="{
          hide: getSocialSharingSelection.activeTab !== 'instagram_tab',
        }"
        class="content_textarea"
        spellcheck="false"
        :min-height="55"
        @input="handleInput"
        @keydown="handleKeysOnPopup"
        @paste="sharingMessageChanges($event, 'Instagram')"
        @keyup.space="sharingMessageChanges($event, 'Instagram')"
        @focus="focusSharingBox('Instagram')"
        @click="focusSharingBox('Instagram')"
        @keydown.ctrl.b="sharingMessageFormatting('Instagram', 'bs')"
        @keydown.ctrl.i="sharingMessageFormatting('Instagram', 'is')"
      ></resize-textarea>
      <div id="clone_txt_instagram_tab" class="clone_txt">
        <span
          class="desc"
          :class="{
            hide: getSocialSharingSelection.activeTab !== 'instagram_tab',
          }"
        ></span>
        <span
          class="in_active desc"
          :class="{
            hide: getSocialSharingSelection.activeTab === 'instagram_tab',
          }"
          @click="
            focusSharingBox('Instagram'),
              textareaFocus($refs.instagram_sharing_message)
          "
        ></span>
        <span
          class="exceed"
          :class="{
            hide: getSocialSharingSelection.activeTab !== 'instagram_tab',
          }"
        ></span>
      </div>

      <HashtagSuggestions
        v-click-away="hideHashtag"
        type="Instagram"
      ></HashtagSuggestions>

      <!--<caption-generation-modal type="Instagram"></caption-generation-modal>-->

      <template v-if="shouldLockBasedOnSubAddons('caption_generation')">
        <div class="text_content_bottom">
          <button
              v-if="getSocialSharingSelection.activeTab == 'instagram_tab'"
              class="btn btn-studio-theme-space caption-btn"
              @click.prevent="showUpgradeModal">
            <i class="fas fa-lock mr-2" style="font-size: 0.8rem"></i>
            <span
                v-tooltip="{
                  content: getLockBasedOnSubAddonsMessage('caption_generation'),
                  placement: 'top-center',
                }"
            >
              Generate Caption
            </span>
          </button>
        </div>
      </template>
      <template v-else>
        <div class="text_content_bottom">
          <button
              v-if="getSocialSharingSelection.activeTab == 'instagram_tab'"
              class="btn btn-studio-theme-space caption-btn"
              :disabled="
            getPublishLoaders.fetchInstagramShortLinks ||
            !getInstagramSharingDetails.url
          "
              @click="fetchAiCaption(getInstagramSharingDetails.url, 'Instagram')"
          >
          <span
              v-tooltip="{
              content: getInstagramSharingDetails.url
                ? null
                : 'Caption generation button is disabled until a valid URL is put in the composer box',
              placement: 'top-center',
            }"
          >
            Generate Caption
          </span>
          </button>
        </div>
      </template>

    </div>

    <Options
      type="Instagram"
      :class="{ hide: getSocialSharingSelection.activeTab !== 'instagram_tab' }"
    ></Options>
    <VideoSection
      v-if="
        (getInstagramSharingDetails.video &&
          getInstagramSharingDetails.video.link) ||
        getPublishLoaders.upload_instagram_sharing_video
      "
      :class="{ hide: getSocialSharingSelection.activeTab !== 'instagram_tab' }"
      type="Instagram"
      :details="getInstagramSharingDetails"
    ></VideoSection>
    <ImageSection
      v-else-if="
        getInstagramSharingLoaders.upload_sharing_image.status ||
        getInstagramSharingDetails.image.length > 0
      "
      :class="{ hide: getSocialSharingSelection.activeTab !== 'instagram_tab' }"
      type="Instagram"
      :details="getInstagramSharingDetails"
    ></ImageSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import {useBilling} from "@modules/billing/composables/useBilling";
import Options from '../options/Options'
import ImageSection from '../sections/ImageSection'
import VideoSection from '../sections/VideoSection'
import HashtagSuggestions from '../options/HashtagSuggestion'

export default {
  components: {
    Options,
    ImageSection,
    VideoSection,
    HashtagSuggestions
  },
  setup() {
    const {showUpgradeModal} = useBilling()

    return {
      showUpgradeModal
    }
  },
  computed: {
    ...mapGetters([
      'getInstagramSharingDetails',
      'getPublishLoaders',
      'getSocialSharingSelection',
      'getInstagramSharingLoaders',
      'getAccountSelection'
    ]),
    isFileHover () {
      return (
        this.getSocialSharingSelection.drag_over_status.instagram &&
        this.getSocialSharingSelection.activeTab === 'instagram_tab'
      )
    }
  },
  watch: {
    'getInstagramSharingDetails.message' (value) {
      this.setSharingBoxHtml(
          this.processSharingBoxHtml(value, 'Instagram'),
          'Instagram'
      )
    }
  },
  mounted () {
    this.setInstagramboxRef(this.$refs.instagram_sharing_message)
  },
  methods: {
    ...mapActions(['setInstagramboxRef']),
    handleKeysOnPopup (e) {
      if (
        this.getSocialSharingSelection.hashTagsSuggestions.dropdown.status &&
        (e.keyCode === 38 || e.keyCode === 40)
      ) {
        e.preventDefault()
      }
    },
    handleRerender () {
      console.debug('Method::handleRerender')
      const text = document.getElementById('instagram_sharing_message').value
      if (!text) {
        const message = this.getInstagramSharingDetails.message
        document.getElementById('instagram_sharing_message').value = message
        this.setSharingBoxHtml(
          this.processSharingBoxHtml(message, 'Instagram'),
          'Instagram'
        )
      }
    },
    handleInput(event) {
      this.handleRerender()
      this.sharingMessageChanges(event, 'Instagram')
    }
  },
}
</script>
