<template>
  <div class="bg-white w-full px-5 pt-2 flex items-center justify-between">
    <div class="flex space-x-2 justify-center items-center">
      <button
        v-if="hideFilterButton === false"
        class="
          filter-button
          bg-[#E3F2FF]
          text-[#2961D2] text-xs
          font-normal
          px-2
          rounded-md
          h-9
          hover:opacity-90
        "
        @click="$emit('toggle-filters')"
      >
        <img
          class="w-3.5 h-3 mb-0.5"
          src="@assets/img/icons/planner/filter.svg"
          alt="Filter Icon"
        />
        Filters
      </button>
      <template v-if="$route.name !== 'planner_calendar_v2' && !isGridView">
        <div class="planner-date-picker !w-[16rem]">
          <CstInputFields
            :date-options="dateOptions"
            :value="dateFilter"
            class="planner-date-picker cursor-pointer"
            placeholder="Select specific date range"
            size="small"
            type="date"
            :date-picker-popup-classes="['planner-date-picker-popup']"
            @change="handleDateFilterChange"
            @clear-date="handleClearDateFilter"
          />
        </div>
        <div>
          <CstDropdown
            button-classes="bg-gray-200 w-[13rem]"
            size="small"
            container-classes="overflow-hidden z-20 !mt-0"
          >
            <template v-slot:selected>
              <span>
                <i
                  v-if="sortEvent === 'Last'"
                  class="fa fa-sort-amount-asc mr-1"
                  aria-hidden="true"
                ></i>
                <i
                  v-if="sortEvent === 'First'"
                  class="fa fa-sort-amount-desc mr-1"
                  aria-hidden="true"
                ></i>
                Sort
                <span v-if="sortName && sortEvent">({{ sortName }})</span>
              </span>
            </template>
            <div>
              <div class="mt-1">
                <CstRadio
                  id="post-created-oldest"
                  v-tooltip.right="'It shows the oldest created post on top.'"
                  name="sort_datatable"
                  :model-value="
                    getSortPreference
                      ? getSortPreference.column + '-' + getSortPreference.order
                      : 'post_created_at'
                  "
                  value="post_created_at-oldest"
                  class="pl-3 py-[0.4rem] w-full hover:bg-gray-100"
                  @change="radioChangeHandler($event, 'oldest')"
                >
                  <div class="w-[12rem]">First Created</div>
                </CstRadio>
                <CstRadio
                  id="post-created-newest"
                  v-tooltip.right="'It shows the recently created post on top.'"
                  name="sort_datatable"
                  :model-value="
                    getSortPreference
                      ? getSortPreference.column + '-' + getSortPreference.order
                      : 'post_created_at'
                  "
                  value="post_created_at-newest"
                  class="pl-3 py-[0.4rem] w-full hover:bg-gray-100"
                  @change="radioChangeHandler($event, 'newest')"
                >
                  <div class="w-[12rem]">Last Created</div>
                </CstRadio>
              </div>
              <div class="mt-1">
                <CstRadio
                  id="post-updated-oldest"
                  v-tooltip.right="'It shows the oldest updated post on top.'"
                  name="sort_datatable"
                  :model-value="
                    getSortPreference
                      ? getSortPreference.column + '-' + getSortPreference.order
                      : 'last_updated_at'
                  "
                  value="last_updated_at-oldest"
                  class="pl-3 py-[0.4rem] w-full hover:bg-gray-100"
                  @change="radioChangeHandler($event, 'oldest')"
                >
                  <div class="w-[12rem]">First Updated</div>
                </CstRadio>
                <CstRadio
                  id="post-updated-newest"
                  v-tooltip.right="'It shows the recently updated post on top.'"
                  name="sort_datatable"
                  :model-value="
                    getSortPreference
                      ? getSortPreference.column + '-' + getSortPreference.order
                      : 'last_updated_at'
                  "
                  value="last_updated_at-newest"
                  class="pl-3 py-[0.4rem] w-full hover:bg-gray-100"
                  @change="radioChangeHandler($event, 'newest')"
                >
                  <div class="w-[12rem]">Last Updated</div>
                </CstRadio>
              </div>
              <div class="mt-1 pb-1">
                <CstRadio
                  id="post-scheduled-upcoming"
                  v-tooltip.right="'It shows the earliest post on top.'"
                  name="sort_datatable"
                  :model-value="
                    getSortPreference
                      ? getSortPreference.column + '-' + getSortPreference.order
                      : 'status'
                  "
                  value="status-oldest"
                  class="pl-3 py-[0.4rem] w-full hover:bg-gray-100"
                  @change="radioChangeHandler($event, 'oldest')"
                >
                  <div class="w-[12rem]">First Scheduled</div>
                </CstRadio>
                <CstRadio
                  id="post-scheduled-last"
                  v-tooltip.right="'It shows the endmost post on top.'"
                  name="sort_datatable"
                  :model-value="
                    getSortPreference
                      ? getSortPreference.column + '-' + getSortPreference.order
                      : 'status'
                  "
                  value="status-newest"
                  class="pl-3 py-[0.4rem] w-full hover:bg-gray-100"
                  @change="radioChangeHandler($event, 'newest')"
                >
                  <div class="w-[12rem]">Last Scheduled</div>
                </CstRadio>
              </div>
            </div>
          </CstDropdown>
        </div>
      </template>
      <!-- Search Field-->
      <template v-if="!isGridView">
        <i
          v-if="hideSearchBar"
          v-tooltip.right="'Search'"
          aria-hidden="true"
          class="
            fa fa-search fa-lg
            text-gray-700
            cursor-pointer
            hover:text-blue-500
            w-10
            h-10
            flex
            justify-center
            items-center
            bg-gray-200
            rounded-lg
          "
          @click="openSearchBar"
        ></i>
        <div v-else class="relative w-[15.5rem]" style="transition: all 0.6s">
          <CstInputFields
            ref="searchBar"
            :value="search"
            class="pr-16 plannerSearchBar"
            placeholder="Search with title..."
            size="small"
            type="text"
            @change="(val) => (search = val)"
            @onPressEnter="$emit('set-search-query', search)"
            @blur="closeSearchBar"
          />
          <span v-if="!searchLoader && search.length">
            <i
              aria-hidden="true"
              class="
                fa fa-times
                absolute
                right-11
                top-1/3
                bg-white
                text-gray-700
                cursor-pointer
                hover:text-blue-500
              "
              @click="clearSearch"
            ></i>
          </span>
          <span v-if="!searchLoader">
            <i
              aria-hidden="true"
              class="
                fa fa-search
                absolute
                right-4
                top-1/3
                bg-white
                text-gray-700
                cursor-pointer
                hover:text-blue-500
              "
              @click="$emit('set-search-query', search)"
            ></i>
          </span>
          <span v-else>
            <clip-loader
              :color="'#4d4d4d'"
              :size="'13px'"
              class="spinner absolute right-4 top-1/3 opacity-70"
              variant="info"
            ></clip-loader>
          </span>
        </div>
      </template>
    </div>
    <div class="flex space-x-2 py-2.5 text-base items-center">
      <!-- Workspace Timezone -->
      <div>
        <v-menu placement="left" :popper-triggers="['hover']">
          <i
            class="
              fa fa-globe fa-lg
              w-10
              h-10
              flex
              justify-center
              items-center
              bg-gray-200
              rounded-xl
            "
            popper-class="text-white bg-gray"
          ></i>

          <template v-slot:popper>
            <div class="p-2">
              <p class="text-sm">
                {{ getWorkspaces.activeWorkspace.timezone }} (
                <clock />
                )
              </p>
            </div>
          </template>
        </v-menu>
      </div>
      <div v-if="!isGridView">
        <button
          class="
            inline-flex
            justify-between
            items-center
            cursor-pointer
            p-1
            rounded-xl
            bg-gray-200
            hover:bg-gray-300
            border-0
          "
        >
          <span
            class="
              h-9
              text-[#595c5f]
              px-2
              inline-flex
              justify-center
              items-center
            "
            @click="$bvModal.show('manage-links-modal')"
          >
            <i class="far fa-link mr-2"></i>
            <span class="hidden lg:inline">Shared Links</span>
          </span>
        </button>
      </div>
      <div class="cst-switch">
        <div
          class="
            inline-flex
            justify-between
            items-center
            cursor-pointer
            p-1
            rounded-xl
            bg-gray-200
          "
        >
          <a
            v-tooltip="'Calendar View'"
            @click="onClickPlannerView('planner_calendar_v2')"
          >
            <div
              :class="{
                'bg-white text-primary-variant-1':
                  $route.name === 'planner_calendar_v2',
              }"
              class="p-2 h-9 w-9 flex justify-center items-center rounded-md"
            >
              <slot name="left-icon">
                <img
                  v-if="$route.name === 'planner_calendar_v2'"
                  src="@assets/img/icons/planner/planner_header/planner-calendar-view-active.svg"
                  alt="Active TikTok Grid View Icon"
                />
                <img
                  v-else
                  src="@assets/img/icons/planner/planner_header/planner-calendar-view.svg"
                  alt="Inactive TikTok Grid View Icon"
                />
              </slot>
            </div>
          </a>
          <a
            v-tooltip="'Feed View'"
            @click="onClickPlannerView('planner_feed_v2')"
          >
            <div
              :class="{
                'bg-white text-primary-variant-1':
                  $route.name === 'planner_feed_v2',
              }"
              class="p-2 h-9 w-9 flex justify-center items-center rounded-md"
            >
              <slot name="right-icon">
                <img
                  v-if="$route.name === 'planner_feed_v2'"
                  src="@assets/img/icons/planner/planner_header/planner-feed-view-active.svg"
                  alt=""
                />
                <img
                  v-else
                  src="@assets/img/icons/planner/planner_header/planner-feed-view.svg"
                  alt=""
                />
              </slot>
            </div>
          </a>
          <a
            v-tooltip="'List View'"
            @click="onClickPlannerView('planner_list_v2')"
          >
            <div
              :class="{
                'bg-white text-primary-variant-1':
                  $route.name === 'planner_list_v2',
              }"
              class="p-2 h-9 w-9 flex justify-center items-center rounded-md"
            >
              <slot name="right-icon">
                <img
                  v-if="$route.name === 'planner_list_v2'"
                  src="@assets/img/icons/planner/planner_header/planner-list-view-active.svg"
                  alt=""
                />
                <img
                  v-else
                  src="@assets/img/icons/planner/planner_header/planner-list-view.svg"
                  alt=""
                />
              </slot>
            </div>
          </a>
          <a
            v-tooltip="'Compact List View'"
            @click="onClickPlannerView('planner_list_compact_v2')"
          >
            <div
              :class="{
                'bg-white text-primary-variant-1':
                  $route.name === 'planner_list_compact_v2',
              }"
              class="p-2 h-9 w-9 flex justify-center items-center rounded-md"
            >
              <slot name="right-icon">
                <img
                  v-if="$route.name === 'planner_list_compact_v2'"
                  src="@assets/img/icons/planner/planner_header/planner-list-view-compact-active.svg"
                  alt=""
                />
                <img
                  v-else
                  src="@assets/img/icons/planner/planner_header/planner-list-view-compact.svg"
                  alt=""
                />
              </slot>
            </div>
          </a>
          <div
              v-tooltip="!gridViewAccess.allowed ? gridViewAccess.error?.message : 'Instagram Grid View'"
              :class="{ 'cursor-default': !gridViewAccess.allowed }"
          >
            <a
                @click="onClickPlannerView('planner_instagram_grid_view', false)"
            >
              <div
                  :class="{
                  'bg-white text-primary-variant-1': $route.name === 'planner_instagram_grid_view',
                  'opacity-50': !gridViewAccess.allowed,
                }"
                  class="relative flex items-center justify-center p-2 rounded-md h-9 w-9"
              >
                <slot name="right-icon">
                  <img
                      v-if="$route.name === 'planner_instagram_grid_view'"
                      src="@assets/img/icons/planner/planner_header/planner-instagram-grid-view-active.svg"
                      alt=""
                  />
                  <img
                      v-else
                      src="@assets/img/icons/planner/planner_header/planner-instagram-grid-view.svg"
                      alt=""
                  />
                </slot>
              </div>
            </a>
          </div>
          <div
              v-tooltip="!gridViewAccess.allowed ? gridViewAccess.error?.message : 'Tiktok Grid View'"
              :class="{ 'cursor-default': !gridViewAccess.allowed }"
          >
            <a
                @click="onClickPlannerView('planner_tiktok_grid_view', false)"
            >
              <div
                  :class="{
                  'bg-white text-primary-variant-1': $route.name === 'planner_tiktok_grid_view',
                  'opacity-50': !gridViewAccess.allowed,
                }"
                  class="relative flex items-center justify-center p-2 rounded-md h-9 w-9"
              >
                <slot name="right-icon">
                  <img
                      v-if="$route.name === 'planner_tiktok_grid_view'"
                      src="@assets/img/icons/planner/planner_header/tiktok-rounded-active.svg"
                      alt=""
                  />
                  <img
                      v-else
                      src="@assets/img/icons/planner/planner_header/tiktok-rounded-inactive.svg"
                      alt=""
                  />
                </slot>
              </div>
            </a>
          </div>
        </div>
      </div>

      <!-- Refresh Button -->
      <div
        v-tooltip="'Refresh Results'"
        class="text-gray-900 bg-gray-100 bg-opacity-50 rounded-md"
      >
        <i
          :class="{ 'fa-spin': loader || fetchingMediaContent }"
          class="
            far
            fa-refresh
            cursor-pointer
            inline-flex
            items-center
            justify-center
            hover:text-black-900
            h-7
            w-7
          "
          @click="$emit('refresh-table')"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import CstInputFields from '@src/components/UI/Input/CstInputFields.vue'
import { datePickerShortcuts } from '@common/constants/common-attributes'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstRadio from '@ui/Radio/CstRadio'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { EventBus } from '@common/lib/event-bus'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import useDateFormat from "@common/composables/useDateFormat";
import {useFeatures} from "@modules/billing/composables/useFeatures";
import {useBilling} from "@modules/billing/composables/useBilling";
import useInstagramGridView from '@/src/modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'

export default {
  name: 'PlannerHeader',
  components: {
    CstInputFields,
    CstDropdown,
    CstRadio,
  },
  mixins: [Helpers],
  props: {
    hideFilterButton: {
      type: Boolean,
      default: false,
    },
    showBulkActions: {
      type: Boolean,
      default: false,
    },
    selectedItemsCount: {
      type: Number,
      default: 0,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    checkAllItems: {
      type: Boolean,
      default: false,
    },
    bulkChangeStatusOptionVisibilityStatus: {
      type: Boolean,
      default: true,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    searchLoader: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'default-view-handler',
    'set-search-query',
    'handle-query-change',
    'sort-handler',
    'toggle-filters',
    'set-search-query',
    'refresh-table',
    'empty-planner-items',
  ],
  setup() {
    const { fetching: fetchingMediaContent } = useInstagramGridView()
    const {momentWrapper,getUserTimePreference} = useDateFormat()
    const {canAccess} = useFeatures()
    const gridViewAccess = canAccess('grid_view_planning')
    const {showUpgradeModal} = useBilling()

    return {
      fetchingMediaContent,
      momentWrapper,
      getUserTimePreference,
      gridViewAccess,
      showUpgradeModal
    }
  },
  data() {
    return {
      hideSearchBar: true,
      sortName: '',
      sortEvent: '',
      dateOptions: {
        defaultValue: null,
        shortcuts: [...datePickerShortcuts],
        format: 'DD MMM YY',
      },
      search: '',
      dateFilter: '',
      timeFormat: this.getUserTimePreference === '12h' ? 'hh:mm A' : 'HH:mm',
    }
  },
  computed: {
    ...mapGetters(['getWorkspaces', 'getSortPreference']),
  },
  mounted() {
    EventBus.$on('set-sort-data', () => {
      if (this.getSortPreference) {
        this.radioChangeHandler(
          this.getSortPreference.column,
          this.getSortPreference.order
        )
      }
    })
    EventBus.$on('reset-date-filter', () => {
      this.dateFilter = [null, null]
      // reset the route query
      delete this.$route.query?.date
    })
  },
  beforeUnmount() {
    EventBus.$off('set-sort-data')
    EventBus.$off('reset-date-filter')
  },
  methods: {
    async openSearchBar() {
      this.hideSearchBar = false
      await this.$nextTick()
      this.$refs.searchBar.$el.focus()
    },
    closeSearchBar() {
      if (this.search === '') {
        this.hideSearchBar = true
      }
    },
    radioChangeHandler(event, order) {
      if (event.includes('-')) {
        event = event.split('-')[0]
      }
      this.setSortNames(event, order)
      this.$emit('sort-handler', event, order)
    },
    setSortNames(event, order) {
      this.sortEvent = order
      if (event === 'post_created_at') {
        if (order === 'newest') {
          this.sortName = 'Last Created'
          this.sortEvent = 'Last'
        } else {
          this.sortName = 'First Created'
          this.sortEvent = 'First'
        }
      } else if (event === 'last_updated_at') {
        if (order === 'newest') {
          this.sortName = 'Last Updated'
          this.sortEvent = 'Last'
        } else {
          this.sortName = 'First Updated'
          this.sortEvent = 'First'
        }
      } else if (event === 'status') {
        if (order === 'newest') {
          this.sortName = 'Last Scheduled'
          this.sortEvent = 'Last'
        } else {
          this.sortName = 'First Scheduled'
          this.sortEvent = 'First'
        }
      }
    },
    async handleDateFilterChange(date) {
      const query = Object.assign({}, this.$route.query)
      if (date[0] && date[1]) {
        const startDate = this.momentWrapper(date[0]).formatDateTime()
        const endDate = this.momentWrapper(date[1]).formatDateTime()

        query.date = startDate + ' - ' + endDate

        await this.$router.push({ query })
        this.$emit('handle-query-change')
      }
    },

    async handleClearDateFilter() {
      const query = Object.assign({}, this.$route.query)
      delete query.date
      await this.$router.push({ query })
      this.$emit('handle-query-change')
    },
    /**
     * debounced search query run when the user stops typing and is fired after 500ms of not typing.
     */
    onChangeSearch: _.debounce(function (val) {
      this.$emit('set-search-query', val)
    }, 500),

    async onClickPlannerView(routeName, fireEmit = true) {
      if(!this.gridViewAccess.allowed && routeName.includes('grid_view')) {
        await this.showUpgradeModal()
        return
      }

      console.log('METHOD::onClickPlannerView', routeName)

      this.$emit('empty-planner-items')
      if(this.$route.name === 'planner_calendar_v2') {
        EventBus.$emit('empty-calendar-events')
      }

      this.$emit('default-view-handler', routeName);
      if (
        this.$route.name === 'planner_calendar_v2' &&
        routeName !== this.$route.name
      )
        delete this.$route.query.date

      await this.$router.push({ name: routeName, query: this.$route.query })
      if (fireEmit && !this.$route.query?.account_id) {
        // don't emit handle-query-change if the route is planner_calendar_v2 as it will cause extra api call
        // because it will be handled by the calendar date change event
        this.$emit('handle-query-change')
      }
    },

    clearSearch() {
      this.search = ''
      this.$emit('set-search-query', this.search)
    },
  },
}
</script>

<style scoped lang="scss">
.planner-date-picker {
  width: 24rem;
}
.filter-button {
  border: 1.5px solid #2961d2 !important;
}
</style>

<style lang="scss">
.planner-date-picker-popup {
  th,
  td {
    height: 40px !important;
    //font-size: 16px !important;
  }
  .mx-calendar {
    height: 325px;
  }
}
</style>
