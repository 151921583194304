import proxy, { easyConnectProxy } from '@common/lib/http-common'
import { integrationsBaseURL, plannerBaseUrl } from '@src/config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { useStore } from '@state/base'
import { reactive, toRefs } from 'vue'
import { ACTION_TYPES } from '@src/modules/integration/config/services'
import { uploadMediaAssetUrl } from '@modules/publish/config/api-utils'
import { fetchAuthorizationURL } from '@src/modules/integration/config/api-utils'
export default function useIntegrationComposable() {
  const { getters, dispatch } = useStore()

  const state = reactive({
    twitterCustomApps: [],
    fetchingLoader: false,
  })

  /**
   * @description fetches Twitter custom apps with respect to the workspace id
   * @return {Array} An array of objects representing the fetched Twitter custom apps.
   */
  const fetchTwitterCustomApps = async (externalLink = false) => {
    state.fetchingLoader = true
    try {
      // Make a GET API call to fetch Twitter custom apps
      const { data } = externalLink
        ? await easyConnectProxy.get(
            `${integrationsBaseURL}developerApp/fetchApps/easy-connect?platform_type=twitter`
          )
        : await proxy.get(
            `${integrationsBaseURL}developerApp/fetchApps?workspace_id=${getters.getActiveWorkspace._id}&platform_type=twitter`
          )

      // Update the local state with fetched Twitter custom apps
      state.twitterCustomApps = data?.data || []
    } catch (error) {
      // Display an error message if the API call fails
      await dispatch('toastNotification', {
        message:
          error.response?.data?.message || error.message || UNKNOWN_ERROR,
        type: 'error',
      })
    } finally {
      state.fetchingLoader = false
    }
  }

  const serviceManageShareLink = (action = '', payload = {}) => {
    let apiUrl = ``
    if (action === ACTION_TYPES.GET_LINK) {
      apiUrl = `${plannerBaseUrl}getExternalLinks`
    } else if (action === ACTION_TYPES.UPDATE_LINK) {
      apiUrl = `${plannerBaseUrl}updateExternalLink`
    } else if (action === ACTION_TYPES.DELETE_LINK) {
      apiUrl = `${plannerBaseUrl}deleteExternalLink`
    }

    return new Promise((resolve, reject) => {
      proxy
        .post(apiUrl, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getters.getJWTToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data)
          else reject(error)
        })
    })
  }

  /**
   * Upload integration image (logo i.e)
   * @param image
   * @returns {Promise<*>}
   */
  const uploadIntegrationImage = async (image) => {
    const formData = new FormData()
    formData.append('media_assets[]', image)
    formData.append('workspace_id', getters.getActiveWorkspace._id)
    formData.append('folder_id', null)
    return await proxy
      .post(uploadMediaAssetUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(async (response) => {
        if (response.data?.status === true) {
          return response.data?.media_container[0]?.link || ''
        } else {
          await dispatch('toastNotification', {
            message: response?.data?.message || UNKNOWN_ERROR,
            type: 'error',
          })
        }
      })
      .catch(async (error) => {
        await dispatch('toastNotification', {
          message: error?.response?.data?.message || UNKNOWN_ERROR,
          type: 'error',
        })
        return ''
      })
  }

  const getAuthorizationUrl = async ({ platform, connectionDetails }) => {
    const payload = {
      workspace_id: getters.getActiveWorkspace._id,
      connection_details: connectionDetails,
      platform,
    }

    try {
      const { data } = await proxy.post(fetchAuthorizationURL, payload)

      window.location.href = data?.auth_url
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }

  const getEasyConnectAuthUrl = async ({ platform, connectionDetails }) => {
    const payload = {
      connection_details: connectionDetails,
      platform,
    }

    try {
      const { data } = await easyConnectProxy.post(
        fetchAuthorizationURL + '/easy-connect',
        payload
      )

      window.location.href = data?.auth_url
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }

  return {
    ...toRefs(state),
    fetchTwitterCustomApps,
    serviceManageShareLink,
    uploadIntegrationImage,
    getAuthorizationUrl,
    getEasyConnectAuthUrl,
  }
}
