<template>
  <div
    id="youtube_sharing_box"
    class="input_field social_content_box social_content_box_multi"
    :class="{
      in_active: getSocialSharingSelection.activeTab !== 'youtube_tab',
      editor_hover: isFileHover,
    }"
    @dragleave.self="
      $store.dispatch('leaveFileDragOver', { event: $event, type: 'Youtube' })
    "
    @dragover="
      $store.dispatch('onFileDragOver', { event: $event, type: 'Youtube' })
    "
    @drop="$store.dispatch('onFileDrop', { event: $event, type: 'Youtube' })"
  >
    <div class="box_social_tag youtube_bg">
      <i class="fab fa-youtube youtube-b"></i>
    </div>

    <div v-if="isFileHover" class="drag_content">
      <p>Drag files here</p>
    </div>

    <div class="text_content text_content_main">
      <resize-textarea
        id="youtube_sharing_message"
        ref="youtube_sharing_message"
        :class="{ hide: getSocialSharingSelection.activeTab !== 'youtube_tab' }"
        class="content_textarea"
        spellcheck="false"
        :min-height="55"
        @input="handleInput"
        @keydown="handleKeysOnPopup"
        @paste="sharingMessageChanges($event, 'Youtube')"
        @keyup.space="sharingMessageChanges($event, 'Youtube')"
        @focus="focusSharingBox('Youtube')"
        @click="focusSharingBox('Youtube')"
        @keydown.ctrl.b="sharingMessageFormatting('Youtube', 'bs')"
        @keydown.ctrl.i="sharingMessageFormatting('Youtube', 'is')"
      ></resize-textarea>
      <div id="clone_txt_youtube_tab" class="clone_txt">
        <span
          class="desc"
          :class="{
            hide: getSocialSharingSelection.activeTab !== 'youtube_tab',
          }"
        ></span>
        <span
          class="in_active desc"
          :class="{
            hide: getSocialSharingSelection.activeTab === 'youtube_tab',
          }"
          @click="
            focusSharingBox('Youtube'),
              textareaFocus($refs.youtube_sharing_message)
          "
        ></span>
        <span
          class="exceed"
          :class="{
            hide: getSocialSharingSelection.activeTab !== 'youtube_tab',
          }"
        ></span>
      </div>

      <HashtagSuggestions
        v-click-away="hideHashtag"
        type="Youtube"
      ></HashtagSuggestions>

      <template v-if="shouldLockBasedOnSubAddons('caption_generation')">
        <div class="text_content_bottom">
          <button
              v-if="getSocialSharingSelection.activeTab == 'youtube_tab'"
              class="btn btn-studio-theme-space caption-btn"
              @click.prevent="showUpgradeModal">
            <i class="fas fa-lock mr-2" style="font-size: 0.8rem"></i>
            <span
                v-tooltip="{
                  content: getLockBasedOnSubAddonsMessage('caption_generation'),
                  placement: 'top-center',
                }"
            >
              Generate Caption
            </span>
          </button>
        </div>
      </template>
      <template v-else>
        <div class="text_content_bottom">
          <button
              v-if="getSocialSharingSelection.activeTab == 'youtube_tab'"
              class="btn btn-studio-theme-space caption-btn"
              :disabled="
            getPublishLoaders.fetchYoutubeShortLinks ||
            !getYoutubeSharingDetails.url
          "
              @click="fetchAiCaption(getYoutubeSharingDetails.url, 'Youtube')"
          >
          <span
              v-tooltip="{
              content: getYoutubeSharingDetails.url
                ? null
                : 'Caption generation button is disabled until a valid URL is put in the composer box',
              placement: 'top-center',
            }"
          >
            Generate Caption
          </span>
          </button>
        </div>
      </template>

    </div>

    <Options
      type="Youtube"
      :class="{ hide: getSocialSharingSelection.activeTab !== 'youtube_tab' }"
    ></Options>
    <VideoSection
      v-if="
        (getYoutubeSharingDetails.video &&
          getYoutubeSharingDetails.video.link) ||
        getPublishLoaders.upload_youtube_sharing_video
      "
      :class="{ hide: getSocialSharingSelection.activeTab !== 'youtube_tab' }"
      type="Youtube"
      :details="getYoutubeSharingDetails"
    ></VideoSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import {useBilling} from "@modules/billing/composables/useBilling";
import { commonTypes } from '@src/modules/publish/store/states/mutation-types.js'
import Options from '../options/Options'
import VideoSection from '../sections/VideoSection'
import HashtagSuggestions from '../options/HashtagSuggestion'

export default {
  components: {
    Options,
    VideoSection,
    HashtagSuggestions
  },
  setup() {
    const {showUpgradeModal} = useBilling()

    return {
      showUpgradeModal
    }
  },
  computed: {
    ...mapGetters([
      'getYoutubeSharingDetails',
      'getPublishLoaders',
      'getSocialSharingSelection',
      'getYoutubeSharingLoaders'
    ]),
    isFileHover () {
      return (
        this.getSocialSharingSelection.drag_over_status.youtube &&
        this.getSocialSharingSelection.activeTab === 'youtube_tab'
      )
    }
  },
  watch: {
    'getYoutubeSharingDetails.message' (value) {
      this.setSharingBoxHtml(
          this.processSharingBoxHtml(value, 'Youtube'),
          'Youtube'
      )
    }
  },
  mounted () {
    this.$store.commit(
        commonTypes.SET_YOUTUBE_BOX_REF,
        this.$refs.youtube_sharing_message
    )
  },
  methods: {
    ...mapActions([]),
    handleKeysOnPopup (e) {
      if (
        this.getSocialSharingSelection.hashTagsSuggestions.dropdown.status &&
        (e.keyCode === 38 || e.keyCode === 40)
      ) {
        e.preventDefault()
      }
    },
    handleRerender () {
      console.debug('Method::handleRerender')
      const text = document.getElementById('youtube_sharing_message').value
      if (!text) {
        const message = this.getYoutubeSharingDetails.message
        document.getElementById('youtube_sharing_message').value = message
        this.setSharingBoxHtml(
          this.processSharingBoxHtml(message, 'Youtube'),
          'Youtube'
        )
      }
    },
    handleInput(event) {
      this.handleRerender()
      this.sharingMessageChanges(event, 'Youtube')
    }
  },
}
</script>
