export default {
  state: {
    authorization: {
      facebook: '',
      twitter: '',
      google: '',
    },
  },
  actions: {
    setSSOAuthorizationLinks({ commit }, authorization) {
      commit('setSSOAuthorizationLinks', authorization)
    },
  },
  mutations: {
    setSSOAuthorizationLinks(state, authorization) {
      state.authorization = authorization
    },
  },
  getters: {
    getSSOAuthorizationLinks: (state) => {
      return state.authorization
    },
  },
}
