<template>
  <div class="box_inner">
    <WorkspaceLockedBilling :workspace="item"></WorkspaceLockedBilling>
    <div class="component_inner__workspace_list__w_box__actions">
      <div class="action_btn">
        <i
          v-if="item.workspace.policy && item.workspace.policy.can_remove"
          v-tooltip.left="'Remove'"
          class="icon-delete-cs action_icon sm"
          data-cy="remove-hover"
          @click.prevent="checkWorkspaceRemovalAction(item)"
        >
        </i>
      </div>

      <div data-cy="default_workspace_button" class="tag set_locked">
        <i class="fas fa-lock"></i> Locked
      </div>
    </div>

    <div
      v-tooltip.top="getWorkspaceLockMessage(item)"
      @click="shouldShowModal"
    >
      <div class="profile_logo">
        <div
          class="img"
          :style="{ background: 'url(' + item.workspace.logo + ')' }"
        ></div>
      </div>
      <h3 data-cy="workspace_listing_name">{{ item.workspace.name }}</h3>

      <div
        v-if="item.workspace.members && item.workspace.members.length"
        class="users"
      >
        <template v-for="(member, index) in item.workspace.members">
          <div
            v-if="index < 3"
            :key="index"
            class="circle_box d-flex align-items-center justify-content-center"
          >
            <img
              v-if="member.user && member.user.image"
              :src="member.user.image"
              alt=""
            />
            <img
              v-else
              src="../../../../../assets/img/profile_default.svg"
              alt=""
            />
          </div>
        </template>

        <div
          v-if="item.workspace.members.length > 3"
          class="circle_box count_box d-flex align-items-center justify-content-center"
        >
          <p class="text">+{{ item.workspace.members.length - 3 }}</p>
        </div>
      </div>

      <div v-else class="pages">
        <p>No profile connected yet</p>
      </div>

      <div class="date">
        <p
          >Created:
          {{ momentWrapper(item.workspace.created_at).formatDate() }}</p
        >
      </div>
      <div class="status-show">
        <div v-if="isOnHold(item)">
          <span class="paused-case">Publishing Paused </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import WorkspaceLockedBilling from '@common/components/subscription-limits-exceeded/WorkspaceLockedBilling'
import useDateFormat from "@common/composables/useDateFormat";
import {useBilling} from "@modules/billing/composables/useBilling";
export default {
  components: {
    WorkspaceLockedBilling
  },
  props: ['item'],
  setup(){
    const { momentWrapper } = useDateFormat()
    const { showUpgradeModal } = useBilling()

    return {
      momentWrapper,
      showUpgradeModal
    }
  },
  computed: {
    ...mapGetters([])
  },
  mounted () {},
  methods: {
    ...mapActions([]),
    isOnHold (item) {
      return !!(item.workspace && item.workspace.on_hold)
    },
    shouldShowModal () {
      if(this.item?.workspace?.super_admin_state === 'past_due') {
        this.alertMessage('Your subscription is past due. Please update your payment details.', 'error')
        return
      }
      if (this.item.role === 'super_admin') {
        this.showUpgradeModal('upgrade-plan', true, true)
      } else {
        this.$bvModal.show('workspace-locked-billing-modal')
      }
    }
  },
}
</script>

<style lang="less" scoped></style>
