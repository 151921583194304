<!-- /src/modules/billing/components/SubscriptionPlansModal.vue -->
<script setup>
import { ref, onUnmounted } from 'vue'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'
import SubscriptionPlansMain from '@/src/modules/billing/components/SubscriptionPlansMain.vue'
import { useBilling } from '@/src/modules/billing/composables/useBilling.js'

defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
})

const { getters } = useStore()

const { socialAccounts } = useBilling()

const showDetailedComparison = ref(false)

onUnmounted(() => {
  EventBus.$off('set-subscription-modal-context')
  const modalBody = document.querySelector('#scroller-container')
  if (modalBody) {
    modalBody.removeEventListener('scroll', () => {})
  }
})

const onHide = () => {
  showDetailedComparison.value = false
  const modalEl = document.querySelector('.subscription-modal')
  if (modalEl) {
    modalEl.classList.add('modal-closing')
  }
}

const updateModalContext = () => {
  if (getters?.getPlan?.subscription?.slug?.includes('agency')) {
    socialAccounts.value = getters?.getPlan?.subscription?.limits?.profiles
  }
}
</script>

<template>
  <b-modal
    id="subscription-plans-modal"
    body-class="overflow-hidden"
    content-class="subscription-modal-content"
    dialog-class="subscription-modal"
    hide-footer
    hide-header
    modal-class="overflow-hidden !p-0"
    @hide="onHide"
    @show="updateModalContext"
  >
    <SubscriptionPlansMain />
  </b-modal>
</template>
