<template>
  <div
    id="facebook_sharing_box"
    class="input_field social_content_box social_content_box_multi"
    :class="{
      in_active: getSocialSharingSelection.activeTab !== 'facebook_tab',
      editor_hover: isFileHover,
    }"
    @dragleave.self="
      $store.dispatch('leaveFileDragOver', { event: $event, type: 'Facebook' })
    "
    @dragover="
      $store.dispatch('onFileDragOver', { event: $event, type: 'Facebook' })
    "
    @drop="$store.dispatch('onFileDrop', { event: $event, type: 'Facebook' })"
  >
    <div class="box_social_tag fb_bg">
      <i class="cs-facebook"></i>
    </div>

    <div v-if="isFileHover" class="drag_content">
      <p>Drag files here</p>
    </div>

    <div class="text_content text_content_main">
      <resize-textarea
        id="facebook_sharing_message"
        ref="facebook_sharing_message"
        :class="{
          hide: getSocialSharingSelection.activeTab !== 'facebook_tab',
        }"
        class="content_textarea"
        spellcheck="false"
        :min-height="55"
        @input="handleInput"
        @keydown="handleKeysOnPopup"
        @paste="sharingMessageChanges($event, 'Facebook')"
        @keyup.space="sharingMessageChanges($event, 'Facebook')"
        @focus="focusSharingBox('Facebook')"
        @click="focusSharingBox('Facebook')"
        @keydown.ctrl.b="sharingMessageFormatting('Facebook', 'bs')"
        @keydown.ctrl.i="sharingMessageFormatting('Facebook', 'is')"
      ></resize-textarea>
      <div id="clone_txt_facebook_tab" class="clone_txt">
        <span
          class="desc"
          :class="{
            hide: getSocialSharingSelection.activeTab !== 'facebook_tab',
          }"
        ></span>
        <span
          class="in_active desc"
          :class="{
            hide: getSocialSharingSelection.activeTab === 'facebook_tab',
          }"
          @click="
            focusSharingBox('Facebook'),
              textareaFocus($refs.facebook_sharing_message)
          "
        ></span>
        <span
          class="exceed"
          :class="{
            hide: getSocialSharingSelection.activeTab !== 'facebook_tab',
          }"
        ></span>
      </div>

      <Mention type="Facebook"></Mention>
      <HashtagSuggestions
        v-click-away="hideHashtag"
        type="Facebook"
      ></HashtagSuggestions>

      <!--            <caption-generation-modal type="Facebook"></caption-generation-modal>-->

      <template v-if="shouldLockBasedOnSubAddons('caption_generation')">
        <div class="text_content_bottom">
          <button
              v-if="getSocialSharingSelection.activeTab == 'facebook_tab'"
              class="btn btn-studio-theme-space caption-btn"
              @click.prevent="showUpgradeModal">
            <i class="fas fa-lock mr-2" style="font-size: 0.8rem"></i>
            <span
                v-tooltip="{
                  content: getLockBasedOnSubAddonsMessage('caption_generation'),
                  placement: 'top-center',
                }"
            >
              Generate Caption
            </span>
          </button>
        </div>
      </template>
      <template v-else>
        <div class="text_content_bottom">
          <button
              class="-mt-2 text-xs bg-cs-white text-white border-0 rounded-md p-1 font-semibold focus:outline-none"
              @click="fetchAiCaption(true,'Facebook')"
          >
            <img
                v-tooltip.top="{
              content: 'Generate AI Text',
              loadingContent: 'Please wait...',
            }"
                src="@assets/img/common/cyrus-bot.svg"
                alt=""
                class="w-10 h-10"
            />
          </button>
        </div>
      </template>

    </div>

    <Options
      type="Facebook"
      :class="{ hide: getSocialSharingSelection.activeTab !== 'facebook_tab' }"
    ></Options>
    <clip-loader
      v-if="
        getPublishLoaders.fetchFacebookShortLinks ||
        getPublishLoaders.fetchFacebookSharingPreview
      "
      class="link_loader"
      :class="{ hide: getSocialSharingSelection.activeTab !== 'facebook_tab' }"
      color="#4165ed"
      :size="'16px'"
    ></clip-loader>
    <template v-else>
      <LinkSection
        v-if="getFacebookSharingDetails.url"
        :class="{
          hide: getSocialSharingSelection.activeTab !== 'facebook_tab',
        }"
        type="Facebook"
        :details="getFacebookSharingDetails"
      ></LinkSection>
      <VideoSection
        v-else-if="
          (getFacebookSharingDetails.video &&
            getFacebookSharingDetails.video.link) ||
          getPublishLoaders.upload_facebook_sharing_video
        "
        :class="{
          hide: getSocialSharingSelection.activeTab !== 'facebook_tab',
        }"
        type="Facebook"
        :details="getFacebookSharingDetails"
      ></VideoSection>
      <ImageSection
        v-else-if="
          getPublishLoaders.uploadFacebookSharingImage.status ||
          getFacebookSharingDetails.image.length > 0 ||
          (getFacebookSharingDetails.image_suggestions &&
            getFacebookSharingDetails.image_suggestions.length > 0)
        "
        :class="{
          hide: getSocialSharingSelection.activeTab !== 'facebook_tab',
        }"
        type="Facebook"
        :details="getFacebookSharingDetails"
      ></ImageSection>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import {useBilling} from "@modules/billing/composables/useBilling";
import Options from '../options/Options'
import LinkSection from '../sections/LinkSection'
import ImageSection from '../sections/ImageSection'
import VideoSection from '../sections/VideoSection'
import Mention from '../options/Mention'
import HashtagSuggestions from '../options/HashtagSuggestion'


export default {
  components: {
    Mention,
    Options,
    ImageSection,
    VideoSection,
    LinkSection,
    HashtagSuggestions,
  },

  setup(){
    const {showUpgradeModal} = useBilling()

    return {
      showUpgradeModal
    }
  },
  computed: {
    ...mapGetters([
      'getFacebookSharingDetails',
      'getPublishLoaders',
      'getSocialSharingSelection'
    ]),
    watch: {
      'getFacebookSharingDetails.message' (value) {
        this.setSharingBoxHtml(
            this.processSharingBoxHtml(value, 'Facebook'),
            'Facebook'
        )
      }
    },
    isFileHover () {
      return (
        this.getSocialSharingSelection.drag_over_status.facebook &&
        this.getSocialSharingSelection.activeTab === 'facebook_tab'
      )
    }
  },
  mounted () {
    this.setFacebookboxRef(this.$refs.facebook_sharing_message)
  },
  methods: {
    ...mapActions(['setFacebookboxRef']),
    handleKeysOnPopup (e) {
      if (
        this.getSocialSharingSelection.hashTagsSuggestions.dropdown.status &&
        (e.keyCode === 38 || e.keyCode === 40)
      ) {
        e.preventDefault()
      }
    },
    handleRerender () {
      console.debug('Method::handleRerender')
      const text = document.getElementById('facebook_sharing_message').value
      if (!text) {
        const message = this.getFacebookSharingDetails.message
        document.getElementById('facebook_sharing_message').value = message
        this.setSharingBoxHtml(
          this.processSharingBoxHtml(message, 'Facebook'),
          'Facebook'
        )
      }
    },
    handleInput(event) {
      this.handleRerender()
      this.sharingMessageChanges(event, 'Facebook')
    }
  },
}
</script>
