import {ref, computed, reactive, watch, inject, nextTick} from 'vue'
import { useStore } from '@/src/store/base'
import { updateWorkspaceTimezone } from '@src/modules/setting/config/api-utils'
import { updateProfileUrl } from '@src/modules/account/config/api-utils'
import UserCredentials from "@modules/account/views/onboarding/UserCredentials.vue";
import BusinessType from "@modules/account/views/onboarding/BusinessType.vue";
import SocialConnect from "@modules/account/views/onboarding/SocialConnect.vue";
import proxy from '@common/lib/http-common'
import { useIdentify } from "@common/composables/useIdentify"
import {userMaven} from "@src/tracking/userMavenMixin";

const store = useStore()
const { identity } = useIdentify()
const onboardingLoader = ref(false)
const phoneField = ref(null)
const localTimezone = ref(null)
const dialCode = ref('')
const stepsList = reactive({
    currentStepIndex: 0,
    steps: [
        { key: 'credentials', component: UserCredentials },
        { key: 'business_type', component: BusinessType },
        { key: 'social_connect', component: SocialConnect }
    ]
})

const userCredentials = reactive({
    phone_no: '',
    firstname: '',
    lastname: '',
    timezone: '',
    business_type: ''
})

const resetUserCredentials = () => {
    userCredentials.phone_no = ''
    userCredentials.firstname = ''
    userCredentials.lastname = ''
    userCredentials.timezone = ''
    userCredentials.business_type = ''
}


const userCredentialsValidation = () => {

    const trimmedFirstname = userCredentials.firstname.trim();

    if (!trimmedFirstname) {
        store.dispatch('toastNotification', {
            message: 'Please enter your full name',
            type: 'error',
        })
        return false;
    }

    if (trimmedFirstname.length < 2) {
        store.dispatch('toastNotification', {
            message: 'The full name must be at least 2 characters',
            type: 'error',
        });
        return false;
    }

    if(!userCredentials?.timezone?.length) {
        store.dispatch('toastNotification', {
            message: 'Please select your workspace timezone',
            type: 'error',
        })
        return false;
    }


    if (stepsList.currentStepIndex === 1 && !userCredentials.business_type) {
        store.dispatch('toastNotification', {
            message: 'Please select your business type',
            type: 'error',
        })
        return false;
    }

    return true

}


// User onboarding
export function useOnboarding() {

    return {
        onboardingLoader,
        dialCode,
        phoneField,
        localTimezone,
        userCredentials,
        resetUserCredentials
    }
}

/**
 * This method is responsible for moving the user to the next or previous step
 * @param move
 */
export function useSteps() {

    // Computed property to get the current step
    const currentStep = computed(() => stepsList.steps[stepsList.currentStepIndex])

    const updateTimezone = async () => {
        await proxy.post(
            updateWorkspaceTimezone,
            {
                workspace_id: store.getters.getActiveWorkspace._id,
                timezone: userCredentials.timezone
            }
        ).then((response) => {
            if (!response.data.status) {
                store.dispatch('toastNotification', {
                    message: response.data.message,
                    type: 'error',
                })
            }
        })
    }

    const updateUser = async () => {

        // Split the full name (stored in firstname field) into first name and last name
        const nameParts = userCredentials.firstname.trim().split(/\s+/);
        let firstName = nameParts[0];
        let lastName = nameParts.slice(1).join(' ') || '';

        // Check if there's an existing lastname that's different from the updated one
        if (userCredentials.lastname && userCredentials.lastname !== lastName) {
            lastName = userCredentials.lastname;
        }

        const phone = (userCredentials.phone_no === dialCode.value || !dialCode.value) ? '' : userCredentials.phone_no;

        console.log('phone', phone, dialCode.value)

        store.getters.getProfile.firstname = firstName
        store.getters.getProfile.lastname = lastName
        store.getters.getProfile.phone_no = phone
        store.getters.getProfile.business_type = userCredentials.business_type
        onboardingLoader.value = true

        await proxy.post(
            updateProfileUrl,
            {
                email: store.getters.getProfile.email,
                firstname: firstName,
                lastname: lastName,
                phone_no: phone,
                business_type: userCredentials.business_type,
                signup_on_boarding : {
                    is_completed : stepsList.currentStepIndex >= 2,
                    steps : {
                        credentials : stepsList.currentStepIndex >= 0,
                        business_type : stepsList.currentStepIndex >= 1,
                        social_connect : stepsList.currentStepIndex >= 2
                    }
                },
            }
        ).then((response) => {
            if (response?.errors) {
                store.dispatch('toastNotification', {
                    message: response.message,
                    type: 'error',
                })
            }
            userCredentials.phone_no = phone
            onboardingLoader.value = false
        })
    }

    const handleSteps = async (move = true, currentStep) => {
        if (move) {
            // Handling the User Credentials step
            if(!userCredentialsValidation()) return
            await updateUser()
            if(userCredentials.timezone !== localTimezone.value) {
                    await updateTimezone()
            }
            identity()
            switch (currentStep.key) {
                case 'credentials':
                    trackUserEvents('personal_details', {
                        first_name: userCredentials.firstname,
                        last_name: userCredentials.lastname,
                        phone_no: userCredentials.phone_no,
                    })
                    break;
                case 'business_type':
                    trackUserEvents('business_type', {
                        business_type: userCredentials.business_type,
                    })
                    break;
            }
            stepsList.currentStepIndex = Math.min(stepsList.currentStepIndex + 1, stepsList.steps.length - 1)
        } else {
            stepsList.currentStepIndex = Math.max(stepsList.currentStepIndex - 1, 0)
        }
    }

    const trackUserEvents = (eventName, payload) => {
        userMaven.track(eventName, payload);
        _cio.track(eventName, payload);
    }

    return {
        stepsList,
        currentStep,

        // Methods
        handleSteps
    }
}
