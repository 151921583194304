/**
 *  This file is composable conversion of platformMixin (src/modules/common/mixins/integrations/platformMixin.js)
 *  You can move required methods from platformMixin to this file and use it in your components
 */
import * as _ from 'underscore'

import { ref } from 'vue'
import { useStore } from '@state/base'
import { planURL } from '@src/modules/setting/config/api-utils'
import proxy from '@src/modules/common/lib/http-common'

const topBannersHeight = ref(0)
export default function usePlatform() {
  const { dispatch, getters, commit } = useStore()

  function isIGPersonalAccount(account) {
    return account?.type === 'personal'
  }
  function hasInstaPermission(channel) {
    if (channel.account_type === 'instagram') {
      const analyticPermission = _.findWhere(channel.permissions, {
        permission: 'instagram_manage_insights',
      })
      return analyticPermission
          ? analyticPermission.status === 'granted'
          : false
    } else return true
  }

  const showToast = async (message, type) => {
    await dispatch('toastNotification', {
      message,
      type,
    })
  }

  const fetchBillingPlan = async()  => {
    return proxy
      .post(planURL, {
        workspace_id: getters.getWorkspaces.activeWorkspace._id || '',
      })
      .then((resp) => {
        if (resp.data.status) {
          commit('SET_PLAN', resp.data.plan)
          commit('SET_PLAN_VIEW_LOADER', false)
        }
      })
      .catch()
  }

  return {
    topBannersHeight,
    isIGPersonalAccount,
    hasInstaPermission,
    showToast,
    fetchBillingPlan,
  }
}
