<template>
  <b-modal
    :id="isEdit ? 'edit-share-link-modal' : 'create-share-link-modal'"
    centered
    no-close-on-backdrop
    hide-header
    hide-footer
    body-class="!px-0"
    dialog-class="!w-[484px]"
    @hide="onModalHide"
    @show="onModalOpen"
  >
    <div class="relative">
      <!--      modal close     -->
      <div class="w-full flex justify-end mt-2 pt-[5px] px-[5px]">
        <img
          src="@src/assets/img/integration/cross_dark_gray.svg"
          class="w-6 h-6 mr-[12px] cursor-pointer"
          alt=""
          @click="
            $bvModal.hide(
              isEdit ? 'edit-share-link-modal' : 'create-share-link-modal'
            )
          "
        />
      </div>

      <!--      main modal body     -->
      <div class="px-10 mb-12 w-full flex flex-col justify-center items-center">
        <!--        modal title     -->
        <div class="w-full flex justify-center items-center gap-x-[8px]">
          <img
            :src="require('@assets/img/icons/chain-link.svg')"
            class="w-4 h-4"
            alt=""
          />
          <p class="font-medium text-lg text-[#202324] select-none">{{
            state.mainText
          }}</p>
        </div>
        <p class="text-base text-[#757A8A] mt-4 text-center px-[2.406rem]">
          {{ state.subText }}
        </p>
        <hr class="text-[#ECEEF5] w-full mb-0 mr-0 ml-0 mt-4" />

        <!--        modal inputs      -->
        <div class="mt-6 w-full flex flex-col justify-center items-center">
          <div v-if="state.isLinkCreated">
            <label for="app-name" class="flex flex-col mb-0 space-y-2">
              <span class="text-[14px] font-medium select-none"
                >Link
                <!--                <span class="ml-1">*</span>-->
              </span>
              <span class="relative">
                <input
                  id="app-secret"
                  v-tooltip="'Click to copy'"
                  :value="`${baseUrl}easy-connect/${state.link}`"
                  type="text"
                  placeholder="Add secret here"
                  class="text-[#202324] color-border w-[404px] h-[42px] pt-4 pl-4 pb-4 pr-4 rounded-[8px] bg-[#F2F3F8] cst-editor cursor-pointer"
                  autocomplete="off"
                  readonly
                  @click="copyToClipboard"
                />
              </span>
            </label>
            <div class="flex justify-center mt-[3rem] gap-[0.625rem]">
              <button
                class="px-8 py-4 rounded-xl border border-[#2961D2] bg-[#2961D2] text-[1rem] leading-none text-white"
                @click.prevent="
                  () => {
                    copyToClipboard()
                    $bvModal.hide(
                      isEdit
                        ? 'edit-share-link-modal'
                        : 'create-share-link-modal'
                    )
                  }
                "
              >
                Copy Link
              </button>
            </div>
          </div>
          <div
            v-else
            @keyup.enter.prevent="isEdit ? editLinkDetail() : createLink()"
          >
            <label for="title-input" class="flex flex-col mb-0 space-y-2">
              <span class="text-[14px] font-medium select-none">Title </span>
              <input
                id="title-input"
                v-model="state.linkName"
                placeholder="e.g. workspace name, client name, brand name etc."
                class="color-border w-[404px] h-[42px] pt-4 pl-4 pb-4 pr-9 text-[#3a4557] rounded-[8px] bg-[#F2F3F8] cst-editor"
                autocomplete="off"
                type="text"
                @input="handleInput"
              />
            </label>
            <label
              for="password-input"
              class="flex flex-col mb-0 space-y-2 mt-5"
            >
              <span class="text-[14px] font-medium select-none"
                >Password Protection
                <CstSwitch
                  v-model="state.toggleActive"
                  size="small"
                  class="inline-block ml-3"
                />
              </span>
              <span v-if="state.toggleActive" class="relative">
                <input
                  id="password-input"
                  v-model="state.password"
                  :type="state.passInputType"
                  placeholder="Password"
                  class="text-[#202324] color-border w-[404px] h-[42px] pt-4 pl-4 pb-4 pr-9 rounded-[8px] bg-[#F2F3F8] cst-editor"
                  autocomplete="off"
                  :disabled="!state.toggleActive"
                />
                <img
                  :src="secretEyeIconSrc"
                  class="w-5 h-5 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                  alt=""
                  @click="changePasswordType"
                />
              </span>
            </label>
            <div class="flex justify-center mt-[3rem] gap-[0.625rem]">
              <button
                class="px-8 py-4 rounded-xl border border-[#757A8A] text-[1rem] leading-none text-[#757A8A]"
                @click.prevent="
                  $bvModal.hide(
                    isEdit ? 'edit-share-link-modal' : 'create-share-link-modal'
                  )
                "
                >Cancel</button
              >
              <button
                v-tooltip="{
                  content: isSaveButtonDisabled
                    ? 'Please make some changes to save'
                    : '',
                }"
                class="px-8 py-4 rounded-xl border border-[#2961D2] bg-[#2961D2] text-[1rem] leading-none text-white"
                :class="{
                  'opacity-50 !cursor-not-allowed': isSaveButtonDisabled,
                }"
                @click.prevent="isEdit ? editLinkDetail() : createLink()"
                >{{ isEdit ? 'Save' : 'Create' }}</button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import CstSwitch from '@ui/Switch/CstSwitch.vue'
import { computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import proxy from '@common/lib/http-common'
import { integrationsBaseURL, baseUrl } from '@src/config/api-utils'
import { userMaven } from '@src/tracking/userMavenMixin'

const emit = defineEmits(['re-fetch-links'])

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false,
  },
  editLink: {
    type: Object,
    default: () => {},
  },
})

const { getters, dispatch } = useStore()

const state = reactive({
  toggleActive: false,
  passInputType: 'password',
  isLinkCreated: false,
  mainText: 'Create a New EasyConnect Link',
  subText:
    'Generate a secure link and share it with your clients for effortless social account connection.',
  linkName: '',
  password: '',
  link: '',
})

const secretEyeIconSrc = computed(() =>
  state.passInputType === 'password'
    ? '/img/eye_disabled.svg'
    : '/img/eye_allowed.svg'
)

const changePasswordType = () => {
  state.passInputType === 'password'
    ? (state.passInputType = 'text')
    : (state.passInputType = 'password')
}

const handleInput = () => {
  // Use a regular expression to remove unwanted characters
  console.log('replace characters', state.linkName)
  state.linkName = state.linkName.replace(/[^\sA-Za-z0-9_-]/g, '')
}

const copyToClipboard = () => {
  navigator.clipboard.writeText(`${baseUrl}easy-connect/${state.link}`)
  dispatch('toastNotification', {
    message: 'Linked Copied to Clipboard',
    type: 'success',
  })
}

const editLinkDetail = async () => {
  if (isSaveButtonDisabled.value) return
  const payload = {
    _id: props.editLink._id,
    updated_by: getters.getProfile._id,
    workspace_id: getters.getActiveWorkspace._id,
    link_name: state.linkName,
    is_secure: state.toggleActive,
    password: state.toggleActive ? state.password : '',
    enabled: true,
  }
  if (state.linkName === '') {
    await dispatch('toastNotification', {
      message: 'Please enter title name',
      type: 'error',
    })
    return ''
  } else if (state.linkName.length > 50) {
    await dispatch('toastNotification', {
      message: 'Link name should be less than 50 characters',
      type: 'error',
    })
    return ''
  } else if (state.toggleActive && state.password.length < 6) {
    await dispatch('toastNotification', {
      message: 'Your password must contain at least 6 characters',
      type: 'error',
    })
    return ''
  }
  try {
    const { data } = await proxy.post(
      `${integrationsBaseURL}updateExternalLink`,
      payload,
      { headers: { Authorization: 'Bearer ' + getters.getJWTToken } }
    )
    state.isLinkCreated = true
    state.mainText = 'Link Created'
    state.subText =
      'Please copy the link and share it with others to connect accounts'
    state.link = data.data?.link_url_slug
    emit('re-fetch-links')
  } catch (e) {
    await dispatch('toastNotification', {
      message: e.response.data.message,
      type: 'error',
    })
  }
}

const createLink = async () => {
  const payload = {
    created_by: getters.getProfile._id,
    workspace_id: getters.getActiveWorkspace._id,
    link_name: state.linkName,
    is_secure: state.toggleActive,
    password: state.toggleActive ? state.password : '',
    enabled: true,
  }
  if (state.linkName === '') {
    await dispatch('toastNotification', {
      message: 'Please enter title name',
      type: 'error',
    })
    return ''
  } else if (state.linkName.length > 50) {
    await dispatch('toastNotification', {
      message: 'Link name should be less than 50 characters',
      type: 'error',
    })
    return ''
  } else if (state.toggleActive && state.password.length < 6) {
    await dispatch('toastNotification', {
      message: 'Your password must contain at least 6 characters',
      type: 'error',
    })
    return ''
  } else {
    try {
      const { data } = await proxy.post(
        `${integrationsBaseURL}generateExternalLink`,
        payload,
        { headers: { Authorization: 'Bearer ' + getters.getJWTToken } }
      )
      state.isLinkCreated = true
      state.mainText = 'Link Created'
      state.subText =
        'Provide this link to your clients for secure social account connection.'
      state.link = data.data.link_url_slug
      // usermaven tracking
      userMaven.track('easy_connect_link_created', {
        link_name: state.linkName,
        is_secure: state.toggleActive,
        link_url_slug: state.link,
      })
    } catch (e) {
      await dispatch('toastNotification', {
        message: e.response.data.message,
        type: 'error',
      })
    }
  }
}

const isSaveButtonDisabled = computed(
  () =>
    props?.editLink?.link_name === state.linkName &&
    props?.editLink?.password === state.password &&
    props?.editLink?.is_secure === state.toggleActive
)

watch(
  () => props.editLink,
  (newVal, oldVal) => {
    if (newVal === oldVal) return
    state.linkName = props?.editLink?.link_name
    state.password = props?.editLink?.password
    state.toggleActive = props?.editLink?.is_secure
    state.mainText = 'EasyConnect Link'
  }
)

const onModalOpen = async () => {
  if (props.isEdit) {
    state.linkName = props.editLink?.link_name
    state.password = props.editLink?.password
    state.toggleActive = props.editLink?.is_secure
  }
}

const onModalHide = () => {
  state.toggleActive = false
  state.passInputType = 'password'
  state.isLinkCreated = false
  state.mainText = 'Create a New EasyConnect Link'
  state.subText =
    'Generate a secure link and share it with your clients for effortless social account connection.'
  state.linkName = ''
  state.password = ''
  state.link = ''
}
</script>
