<template>
  <div class="cst-switch" :class="{ 'focus:outline-none cursor-pointer shadow-sm pointer-events-none filter-none opacity-50': disabled }">
    <div class="inline-flex justify-between items-center cursor-pointer p-1 rounded-xl bg-gray-200 color-border cst-editor" @click="toggleActive = !toggleActive">
      <div v-tooltip="leftTooltip" class=" p-2 h-9 min-w-9  flex justify-center items-center rounded-md" :class="{ 'bg-white text-primary-variant-1': !toggleActive }">
        <slot name="left-icon">
          <i class="fas fa-laptop"></i>
        </slot>
      </div>
      <div v-tooltip="rightTooltip" class=" p-2 h-9 min-w-9  flex justify-center items-center rounded-md" :class="{ 'bg-white text-primary-variant-1': toggleActive }">
        <slot name="right-icon">
          <i class="fas fa-mobile-android-alt "></i>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CstSwitch',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    leftTooltip: {
      type: String,
      default: ''
    },
    rightTooltip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      toggleActive: this.value,
    };
  },

  watch: {
    value: function (value) {
      this.toggleActive = value
    },
    toggleActive: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.cst-switch {

  .cst-switch-active-bg {
    background-color: #0a97f3;
  }
}

</style>
