<script setup>
import { ref } from 'vue'
import CstPopup from '@ui/Popup/CstPopup.vue'

const props = defineProps({
  showCloseIcon: {
    type: Boolean,
    default: false,
  },
  bannerColor: {
    type: String,
    default: 'bg-gray-cs-800',
  },
  pulseColor: {
    type: String,
    default: 'bg-primary-cs-200',
  },
  dotColor: {
    type: String,
    default: 'bg-primary-cs-600',
  },
  popupMessage: {
    type: String,
    default: 'Are you sure you want to hide this banner?',
  },
})
const emits = defineEmits(['hide-banner'])

const showPopup = ref(false)

const closePopup = () => {
  showPopup.value = false
}

const handleConfirmPopup = (dontShowAgain = false) => {
  showPopup.value = false
  emits('hide-banner', dontShowAgain)
}
</script>

<template>
  <div
    :class="[
      `${props.bannerColor}`,
      'flex rounded-lg py-2 w-full pl-8 pr-6 items-center mt-4 relative z-10',
    ]"
  >
    <div class="flex flex-grow my-auto gap-4 items-center justify-center">
      <span class="relative flex h-3 w-3 items-center justify-center">
        <span
          :class="[
            `${props.pulseColor}`,
            'animate-ping absolute inline-flex h-4 w-4 rounded-full opacity-75',
          ]"
        ></span>
        <span
          :class="[
            `${props.dotColor}`,
            'relative inline-flex rounded-full h-3 w-3',
          ]"
        ></span>
      </span>
      <slot name="banner-content">Your content goes here</slot>
    </div>

    <template v-if="showCloseIcon">
      <i
        class="fas fa-times text-white text-xl font-light cursor-pointer ml-auto"
        @click="showPopup = !showPopup"
      />
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <CstPopup
          v-if="showPopup"
          v-click-away="closePopup"
          confirm-text="Yes, Hide"
          parent-class="absolute top-[110%] right-0 w-[27.875rem] h-[7.25rem] cursor-default rounded-b-lg rounded-tl-lg"
          @close-popup="closePopup"
          @confirm-popup="handleConfirmPopup"
        >
          <template v-slot:popup-text>
            <p class="text-secondary-cs font-bold mx-6 mt-5 mb-4">
              {{ props.popupMessage }}
            </p>
          </template>
        </CstPopup>
      </transition>
    </template>
  </div>
</template>
